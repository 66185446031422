import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Simplebar from 'simplebar-react';
import { useEventTracking } from 'state/eventTracking';
import { TrackReportExportConfig } from 'state/eventTracking/types';

import * as S from './styles';
import { BaseTableData, DailyStatsTableConfig } from './types';
import useDailyStatsTable from './useDailyStatsTable';

const DEFAULT_CARD_TITLE = 'Daily Stats';

export interface DailyStatsTableCardProps<TData extends BaseTableData>
  extends DailyStatsTableConfig<TData> {
  tableLayout?: 'fixed' | 'auto';
  title?: string;
  subtitle?: string;
  reportType: TrackReportExportConfig['report'];
}

const DailyStatsTableCard = <TData extends BaseTableData>(
  props: DailyStatsTableCardProps<TData>,
): React.ReactElement => {
  const {
    columns,
    dailyStats,
    defaultSort,
    exportArgs,
    tableLayout,
    title = DEFAULT_CARD_TITLE,
    subtitle,
    virtual,
    footer,
    reportType,
  } = props;

  const { trackReportExport } = useEventTracking();

  const { loading, tableInstance } = useDailyStatsTable({
    columns,
    dailyStats,
    defaultSort,
    exportArgs,
    virtual,
    footer,
  });

  const { exportData } = tableInstance;

  const handleExportButtonClick = () => {
    exportData('csv', true);
    trackReportExport({ report: reportType });
  };

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subtitle}
        action={
          <Button
            onClick={handleExportButtonClick}
            variant="outlined"
            startIcon={<FileDownloadIcon />}
          >
            Export
          </Button>
        }
      />

      <CardContent component={Simplebar}>
        <S.DailyStatsTable
          tableLayout={tableLayout}
          isLoading={loading}
          skeletonRows={3}
          table={tableInstance}
          virtual
          footer
        />
      </CardContent>
    </Card>
  );
};

export default DailyStatsTableCard;
