import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface PreWithProps {
  isMobile: boolean;
}
export const ExternalLink = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};
  &:hover {
    text-decoration: underline; 
  },
`;

export const ExternalLinkWithEmphasis = styled(ExternalLink)`
  text-decoration: underline;
`;

export const CodeBlock = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.grey[400]};
  color: ${({ theme }) => theme.palette.common.black};
`;

export const Container = styled(Box)`
  height: 400px;
  width: 100%;
`;

export const Item = styled('li')`
  margin: 8px 0;
`;

export const Pre = styled('pre')<PreWithProps>`
  overflow-x: ${({ isMobile }) => (isMobile ? 'scroll' : 'inherit')};
`;
