import TableCells from 'components/TableCells';
import useColumnSum from 'hooks/useColumnSum';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import { CellProps, Column } from 'react-table';
import { parseDate } from 'utils/date';
import { ParsedPlatformBreakoutData } from '../types';

export const defaultSort = [{ id: 'date', desc: true }];

export const columns: ReadonlyArray<Column<ParsedPlatformBreakoutData>> = [
  {
    Footer: 'Totals',
    Header: 'Date',
    accessor: 'date',
    sortType: (rowA, rowB) => {
      const dateA = parseDate(rowA.values.date);
      const dateB = parseDate(rowB.values.date);
      return dateA > dateB ? 1 : -1;
    },
  },
  {
    Footer: '',
    Header: 'Platform',
    accessor: 'platform',
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell columnName="load" tableData={info} />
    ),
    Header: <TableCells.HeaderCell accessor="load" />,
    accessor: 'load',
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell columnName="view" tableData={info} />
    ),
    Header: <TableCells.HeaderCell accessor="view" />,
    accessor: 'view',
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell columnName="play" tableData={info} />
    ),
    Header: <TableCells.HeaderCell accessor="play" />,
    accessor: 'play',
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell
        columnName="clickthrough"
        tableData={info}
      />
    ),
    Header: <TableCells.HeaderCell accessor="clickthrough" />,
    accessor: 'clickthrough',
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell columnName="engage" tableData={info} />
    ),
    Header: <TableCells.HeaderCell accessor="engage" />,
    accessor: 'engage',
  },
  {
    Cell: ({
      value,
    }: CellProps<
      ParsedPlatformBreakoutData,
      ParsedPlatformBreakoutData['ctr']
    >) => <TableCells.NumericCell type="percent" value={value} />,
    Footer: (info) => {
      const totalView = useColumnSum({ accessor: 'view', tableData: info });
      const totalEngage = useColumnSum({ accessor: 'engage', tableData: info });
      const value =
        totalView === 0 || !isNumber(totalView) || !isNumber(totalEngage)
          ? 0
          : round((totalEngage / totalView) * 100, 2);

      return <TableCells.NumericCell type="percent" value={value} />;
    },
    Header: <TableCells.HeaderCell accessor="ctr" />,
    accessor: 'ctr',
    sortType: (rowA, rowB) => {
      const ctrA = parseFloat(rowA.values.ctr);
      const ctrB = parseFloat(rowB.values.ctr);
      return ctrA > ctrB ? 1 : -1;
    },
  },
];
