import { Button, ButtonProps, Search } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export type PodcastEpisodeEmptyInputProps = Pick<ButtonProps, 'onPress'>;

const PodcastEpisodeEmptyInput = React.forwardRef<
  HTMLButtonElement,
  PodcastEpisodeEmptyInputProps
>(({ onPress }, ref) => (
  <S.Button as={Button} startIcon={<Search />} onPress={onPress} ref={ref}>
    add an episode
  </S.Button>
));

export default PodcastEpisodeEmptyInput;
