import { MIXPANEL_API_HOST, MIXPANEL_DEBUG, MIXPANEL_TOKEN } from 'config';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EventTrackingContextType,
  TrackCreatePromoCheckout,
  TrackCreatePromoOutcome,
  TrackDashboardPageLoad,
  TrackDownloadFollowers,
  TrackPodcastPromoPurchaseNext,
  TrackPodcastPromoPurchaseNextConfig,
  TrackUpdateDiscoEpisodeDestinationUrl,
  TrackPopulatedWidgetContactSupport,
  TrackReportAdjust,
  TrackReportExport,
  TrackSelectNewClip,
  TrackShowInWidgetToggleAll,
  TrackShowInWidgetToggleClip,
  TrackStudioTabEmptyStateCtaClick,
  TrackStudioTabSeePostClick,
  TrackThumbnailEdit,
  TrackUserLogin,
  TrackUserSignup,
  TrackWidgetCreation,
  WidgetCreationFlowState,
} from '../types';
import { EVENTS } from './constants';
import { track, setUserTrackingInfo, clearUserTrackingInfo } from './utils';

if (!MIXPANEL_TOKEN) {
  throw new Error('missing mixpanel token');
}

mixpanel.init(MIXPANEL_TOKEN, {
  api_host: MIXPANEL_API_HOST,
  batch_requests: false,
  debug: MIXPANEL_DEBUG,
});

export default function useMixpanelEventTracking(): EventTrackingContextType {
  const dashboardPageLoadTracked = useRef(false);
  const location = useLocation();
  useEffect(
    () =>
      // any page tracking event can only fire once per location.  when location changes,
      // reset this ref variable so that the new page load can be tracked
      () => {
        dashboardPageLoadTracked.current = false;
      },
    [location],
  );

  const trackDashboardPageLoad: TrackDashboardPageLoad = useCallback(
    (properties) => {
      if (dashboardPageLoadTracked.current) {
        return;
      }
      dashboardPageLoadTracked.current = true;
      track(EVENTS.dashboardPageLoaded, properties);
    },
    [],
  );

  const trackUserSignup: TrackUserSignup = useCallback((email) => {
    track(EVENTS.signup);
    mixpanel.alias(email);
  }, []);

  const trackUserLogin: TrackUserLogin = useCallback(
    () => track(EVENTS.login),
    [],
  );

  const trackPodcastPromoPurchaseNext: TrackPodcastPromoPurchaseNext =
    useCallback(
      (props: TrackPodcastPromoPurchaseNextConfig) =>
        track(EVENTS.podcastPromoPurchaseNext, props),
      [],
    );

  const trackDownloadFollowers: TrackDownloadFollowers = useCallback(
    () => track(EVENTS.downloadFollowers),
    [],
  );

  const trackCreatePromoCheckout: TrackCreatePromoCheckout = useCallback(
    (purchaseComplete) =>
      track(EVENTS.createPromoCheckout, { purchaseComplete }),
    [],
  );

  const trackUpdateDiscoEpisodeDestinationUrl: TrackUpdateDiscoEpisodeDestinationUrl =
    useCallback(() => track(EVENTS.updateDiscoEpisodeDestinationUrl), []);
  const trackReportAdjust: TrackReportAdjust = useCallback(
    (props) => track(EVENTS.reportAdjust, props),
    [],
  );

  const trackReportExport: TrackReportExport = useCallback(
    (props) => track(EVENTS.reportExport, props),
    [],
  );

  const trackThumbnailEdit: TrackThumbnailEdit = useCallback((val) => {
    if (val === 'crop') {
      track(EVENTS.thumbnailCrop);
    } else {
      track(EVENTS.thumbnailReplace);
    }
  }, []);

  const trackSelectNewClip: TrackSelectNewClip = useCallback(
    () => track(EVENTS.selectNewClip),
    [],
  );

  const trackShowInWidgetToggleAll: TrackShowInWidgetToggleAll = useCallback(
    (val) => {
      if (val === 'on') {
        track(EVENTS.showInWidgetToggleAllOn);
      } else {
        track(EVENTS.showInWidgetToggleAllOff);
      }
    },
    [],
  );

  const trackShowInWidgetToggleClip: TrackShowInWidgetToggleClip = useCallback(
    (val) => {
      if (val === 'on') {
        track(EVENTS.showInWidgetToggleClipOn);
      } else {
        track(EVENTS.showInWidgetToggleClipOff);
      }
    },
    [],
  );

  const trackWidgetCreation: TrackWidgetCreation = useCallback((val) => {
    const widgetCreationEvents: Record<WidgetCreationFlowState, string> = {
      contactSupport: EVENTS.contactUsToGetStarted,
      createWidget: EVENTS.createYourWidget,
      createWidgetSuccess: EVENTS.createYourWidgetSuccess,
      createWidgetError: EVENTS.createYourWidgetError,
    };

    track(widgetCreationEvents[val]);
  }, []);

  const trackPopulatedWidgetContactSupport: TrackPopulatedWidgetContactSupport =
    useCallback(() => track(EVENTS.contactSupport), []);

  const trackCreatePromoOutcome: TrackCreatePromoOutcome = useCallback(
    (val) => {
      if (val === 'success') {
        track(EVENTS.createPromoSuccess);
      } else {
        track(EVENTS.createPromoError);
      }
    },
    [],
  );

  const trackStudioTabEmptyStateCtaClick: TrackStudioTabEmptyStateCtaClick =
    useCallback(() => track(EVENTS.emptyStateCTA), []);

  const trackStudioTabSeePostClick: TrackStudioTabSeePostClick = useCallback(
    () => track(EVENTS.seePost),
    [],
  );

  return useMemo(
    () => ({
      setUserTrackingInfo,
      clearUserTrackingInfo,
      trackDashboardPageLoad,
      trackUserSignup,
      trackUserLogin,
      trackPodcastPromoPurchaseNext,
      trackDownloadFollowers,
      trackCreatePromoCheckout,
      trackUpdateDiscoEpisodeDestinationUrl,
      trackReportAdjust,
      trackReportExport,
      trackThumbnailEdit,
      trackSelectNewClip,
      trackShowInWidgetToggleAll,
      trackShowInWidgetToggleClip,
      trackWidgetCreation,
      trackPopulatedWidgetContactSupport,
      trackCreatePromoOutcome,
      trackStudioTabEmptyStateCtaClick,
      trackStudioTabSeePostClick,
    }),
    [
      trackDashboardPageLoad,
      trackUserSignup,
      trackUserLogin,
      trackPodcastPromoPurchaseNext,
      trackDownloadFollowers,
      trackCreatePromoCheckout,
      trackUpdateDiscoEpisodeDestinationUrl,
      trackReportAdjust,
      trackReportExport,
      trackThumbnailEdit,
      trackSelectNewClip,
      trackShowInWidgetToggleAll,
      trackShowInWidgetToggleClip,
      trackWidgetCreation,
      trackPopulatedWidgetContactSupport,
      trackCreatePromoOutcome,
      trackStudioTabEmptyStateCtaClick,
      trackStudioTabSeePostClick,
    ],
  );
}
