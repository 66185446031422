import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface CreateAdStatusQueryParams {
  checkoutResponse: string | null;
}
export default function useCreateAdStatusQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState<CreateAdStatusQueryParams>({
    checkoutResponse: null,
  });

  useEffect(() => {
    if (searchParams.has('checkoutResponse')) {
      const checkoutResponse = searchParams.get('checkoutResponse');

      setParams({ checkoutResponse });

      searchParams.delete('checkoutResponse');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return params;
}
