import { LoginProvider, UserAuthResult } from '@sparemin/auth';
import { getUserProfile } from 'api/services';
import { useNotification } from 'components/Notification';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useAuth } from 'state/auth';
import { useEventTracking } from 'state/eventTracking';
import { userProfileQueries } from 'state/user';

export default function useAuthSuccess(authType: 'register' | 'login') {
  const { login } = useAuth();
  const queryClient = useQueryClient();
  const { setUserTrackingInfo, trackUserLogin, trackUserSignup } =
    useEventTracking();
  const { showNotification } = useNotification();

  const onAuthSuccess = useCallback(
    async (_: LoginProvider, result: UserAuthResult) => {
      const { spareminToken, userId } = result;
      try {
        const profile = await getUserProfile({ userId, token: spareminToken });
        queryClient.setQueryData(userProfileQueries.user(userId), profile);

        if (profile && profile.email) {
          setUserTrackingInfo(profile.email, userId);
          if (authType === 'login') {
            trackUserLogin();
          } else {
            trackUserSignup(profile.email);
          }
        }

        login({
          accessToken: result.spareminToken,
          userId: result.userId,
        });
      } catch (err) {
        showNotification({
          message: 'Something went wrong. Please try again.',
          severity: 'error',
        });
      }
    },
    [
      login,
      queryClient,
      setUserTrackingInfo,
      trackUserLogin,
      trackUserSignup,
      authType,
      showNotification,
    ],
  );

  return { onAuthSuccess };
}
