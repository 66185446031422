import Logout from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import UserAvatar from 'components/UserAvatar';
import React, { useState } from 'react';
import { useAuth } from 'state/auth';
import { useProfile } from 'state/user';
import * as S from './styles';

export interface AvatarMenuProps {}

const MENU_ID = 'account-menu';

const AvatarMenu: React.FC<AvatarMenuProps> = () => {
  const profile = useProfile();
  const { logout } = useAuth();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const open = Boolean(anchor);

  return (
    <>
      <IconButton
        aria-controls={open ? MENU_ID : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="user avatar"
        onClick={handleClick}
      >
        <UserAvatar />
      </IconButton>
      <S.AccountMenu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={MENU_ID}
        onClick={handleClose}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <S.UserInfoContainer>
          <Typography variant="body2">
            <strong>{profile.data?.email}</strong>
          </Typography>
        </S.UserInfoContainer>
        <Divider />
        <S.MenuContainer>
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            Logout
          </MenuItem>
        </S.MenuContainer>
      </S.AccountMenu>
    </>
  );
};

export default AvatarMenu;
