import { WidgetVariantId } from 'api/services';
import { useNotification } from 'components/Notification';
import useUpdateWidgetOptions from 'pages/Widget/state/useUpdateSelfServeWidgetOptions';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useModalControls } from 'state/modals';
import { userDataQueries } from 'state/user';
import { CONTACT_SUPPORT_ERROR_MESSAGE } from 'utils/constants';
import StyleWidgetSelector from './StyleWidgetSelector';

export type StyleWidgetModalContentsProps = {
  widgetId: string;
  onVariantUpdate?: (val: WidgetVariantId) => void;
  isMobile: boolean;
};

const StyleWidgetModalContents: React.FC<StyleWidgetModalContentsProps> = ({
  widgetId,
  onVariantUpdate,
  isMobile,
}) => {
  const { showError } = useNotification();
  const { close } = useModalControls();
  const updateWidget = useUpdateWidgetOptions();
  const queryClient = useQueryClient();

  const handleClick = (val: WidgetVariantId) => {
    updateWidget.mutateAsync(
      {
        widgetId,
        displayVariantId: val,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            userDataQueries.publisher({ isSelfServe: true }),
          );
          onVariantUpdate?.(val);
          close();
        },
        onError: () => {
          showError(CONTACT_SUPPORT_ERROR_MESSAGE);
        },
      },
    );
  };

  return <StyleWidgetSelector onClick={handleClick} isMobile={isMobile} />;
};
export default StyleWidgetModalContents;
