import { useHasFollowers } from 'pages/Play/state/useFeedFollowers';
import React from 'react';
import EmptyState from './EmptyState';
import FollowersList from './FollowersList';
import LoadingState from './LoadingState';

type PodcastFollowersProps = {};

const PodcastFollowers: React.FC<PodcastFollowersProps> = () => {
  const { data: hasFollowers, isLoading } = useHasFollowers();

  if (isLoading) {
    return <LoadingState />;
  }

  if (hasFollowers) {
    return <FollowersList />;
  }

  return <EmptyState />;
};

export default PodcastFollowers;
