import Stepper from 'components/Stepper';
import React from 'react';
import { steps } from './constants';
import { CreateWidgetStep } from './types';

type WidgetStepperProps = {
  activeStep: CreateWidgetStep;
};

const WidgetStepper: React.FC<WidgetStepperProps> = ({ activeStep }) => (
  <Stepper steps={steps} activeStep={steps.indexOf(activeStep)} />
);
export default WidgetStepper;
