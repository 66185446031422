import {
  FadingContainer,
  Heading,
  Spacer,
  Text,
  getThemeColor,
} from '@sparemin/blockhead';
import styled from 'styled-components';
import { ActionItemRowContainerStyleProps } from './types';

export const Root = styled(FadingContainer)`
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px solid ${getThemeColor('l4')};
  border-radius: 24px;
  overflow: hidden;
`;

export const Contents = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-bottom: 24px;
  overflow: auto;
`;

export const ActionItemRowContainer = styled(Spacer).attrs({
  space: '16px',
  align: 'center',
  justify: 'space-between',
})<ActionItemRowContainerStyleProps>`
  margin: 0 24px;
  padding-top: 24px;

  & + & {
    border-top: 1px solid ${getThemeColor('l4')};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const HeadingContainer = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'flex-start',
  justify: 'flex-start',
  space: '8px',
})`
  width: 100%;
`;

export const Title = styled(Heading).attrs({
  level: 3,
})`
  font-size: 16px;
`;

export const Description = styled(Text).attrs({
  variant: 'adornment',
})`
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 2px;
`;
