import TablePageContainer from 'components/TablePageContainer';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import Disclaimer from 'pages/headliner/components/Disclaimer';
import SocialStatsSummary from 'pages/headliner/SocialStatsSummary';
import { useGetSocialShareStatsByEpisode } from 'pages/headliner/state/useGetSocialShareStatistics';
import { useGetPodcastSummaryStats } from 'pages/headliner/state/useGetSocialShareStatsByPodcast';
import { useCallback, useState } from 'react';
import { useGetSocialStatsSummary } from '../../state';
import TableCard from './TableCard';
import TablePageTitle from './TablePageTitle';

type TableContainerProps = {};

const TableContainer: React.FC<TableContainerProps> = () => {
  useTrackPageLoad({ section: 'Studio', page: 'Podcasts', state: 'Populated' });

  const [podcastId, setPodcastId] = useState<string>();
  const [episodeId, setEpisodeId] = useState<string>();

  const handleBackClick = useCallback(() => {
    if (episodeId) {
      setEpisodeId(undefined);
    } else if (podcastId) {
      setPodcastId(undefined);
    }
  }, [podcastId, episodeId]);

  const { data: podcastStats } = useGetPodcastSummaryStats(podcastId);
  const { data: allPodcastsStats } = useGetSocialStatsSummary();
  const { data: episodeStats } = useGetSocialShareStatsByEpisode(
    episodeId as string,
  );

  const getSummaryStats = () => {
    if (!podcastId && allPodcastsStats)
      return <SocialStatsSummary stats={allPodcastsStats} />;
    if (!episodeId && podcastStats)
      return <SocialStatsSummary stats={podcastStats} />;
    if (episodeStats) return <SocialStatsSummary stats={episodeStats} />;
    return null;
  };

  return (
    <TablePageContainer>
      <TablePageTitle
        {...{ podcastId, episodeId }}
        onBackClick={handleBackClick}
      />
      {getSummaryStats()}
      <TableCard
        podcastId={podcastId}
        episodeId={episodeId}
        onSelectEpisode={setEpisodeId}
        onSelectPodcast={setPodcastId}
      />
      <Disclaimer />
    </TablePageContainer>
  );
};
export default TableContainer;
