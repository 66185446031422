import { changePassword, ChangePasswordArgs } from 'api/services';
import { useMutation, UseMutationOptions } from 'react-query';

export default function useChangePassword(
  config?: Omit<
    UseMutationOptions<void, unknown, ChangePasswordArgs, unknown>,
    'mutationFn'
  >,
) {
  return useMutation<void, unknown, ChangePasswordArgs>(
    (args) => changePassword(args),
    config,
  );
}
