import Skeleton from '@mui/material/Skeleton';
import Avatar, { AvatarIcon } from 'components/Avatar';
import { useProfile } from 'state/user';

export interface UserAvatarProps {
  className?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ className }) => {
  const profile = useProfile();

  if (profile.isError) {
    return null;
  }

  if (profile.isLoading) {
    return (
      <Skeleton variant="circular">
        <AvatarIcon />
      </Skeleton>
    );
  }

  return <Avatar className={className} name={profile.data?.email} />;
};

export default UserAvatar;
