import { SeriesOption } from 'components/DailyStatsChartCard';
import { ParsedDailyStatsData } from '../types';

export const CHART_STATS_SELECTOR_OPTIONS: SeriesOption<ParsedDailyStatsData>[] =
  [
    {
      value: 'load',
      label: 'Widget Load',
    },
    {
      value: 'view',
      label: 'Widget View',
    },
    {
      value: 'play',
      label: 'Preview Play',
    },
    {
      value: 'clickthrough',
      label: 'Link Click',
    },
    {
      value: 'engage',
      label: 'Engagement',
    },
    {
      isPercentage: true,
      value: 'ctr',
      label: 'Viewable Engagement CTR',
    },
  ];
