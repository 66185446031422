import { LinkProps } from '@mui/material/Link';
import { createTheme } from '@mui/material/styles';
import LinkBehavior from 'components/LinkBehavior/LinkBehavior';

export default createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(3),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.label.fontSize,
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          marginBottom: theme.spacing(0.5),
          color: theme.palette.grey[800],

          '&.Mui-selected': {
            color: theme.palette.primary.dark,
          },

          '&.Mui-selected .MuiListItemIcon-root': {
            color: theme.palette.primary.dark,
          },
        }),
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'label',
        },
        secondaryTypographyProps: {
          variant: 'body1',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[800],
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.label.fontSize,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          if (
            ownerState.variant === 'head' ||
            ownerState.variant === 'footer'
          ) {
            return {
              backgroundColor: theme.palette.grey[200],
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',

              '&:first-of-type': {
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
              },

              '&:last-of-type': {
                borderTopRightRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
              },
            };
          }

          return {
            fontSize: '14px',
          };
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: () => ({
          borderBottom: '1px solid #e3e3d',
          '&:last-child td': { border: 'none' },
        }),
      },
    },
  },

  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#019be3',
      main: '#19b5fe',
    },
    supporting1: {
      main: '#6e6ede',
    },
    supporting2: {
      main: '#f29e5e',
    },
    supporting3: {
      main: '#e77ac0',
    },
    grey: {
      500: '#dce1eb',
      700: '#646e82',
      800: '#464e5e',
    },
    warning: {
      main: '#c82828',
    },
    text: {
      primary: '#000000',
      dark: '#14191e',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    jumbo: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '40px',
      color: '#000000',
    },
    // Primary headline
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '24px',
      letterSpacing: 0,
      color: '#000000',
    },
    // Primary subheadline
    h2: {
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: 0.5,
      lineHeight: 1.5,
      color: '#000000',
    },
    // Secondary headline
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '18px',
      letterSpacing: 0,
    },
    // Secondary subheadline
    h4: {
      fontWeight: 500,
      fontSize: '12px',
      letterSpacing: 0.5,
    },
    body1: {
      fontWeight: 400,
      fontSize: '12px',
      letterSpacing: 0,
    },
    body2: {
      fontWeight: 400,
      fontSize: '10px',
      letterSpacing: 0,
    },
    label: {
      fontWeight: 400,
      fontSize: '16px',
      letterSpacing: 0,
    },
  },
});
