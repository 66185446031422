import {
  UNSUPPORTED_LANG_ERROR_MESSAGE,
  DUPLICATE_RSS_ERROR_MESSAGE,
  WIDGET_LIMIT_ERROR_MESSAGE,
  WIDGET_CREATION_ERROR_MESSAGE,
} from './constants';

export const createWidgetErrorMessages = {
  unsupportedLanguage: UNSUPPORTED_LANG_ERROR_MESSAGE,
  duplicateRssUrl: DUPLICATE_RSS_ERROR_MESSAGE,
  widgetLimitReached: WIDGET_LIMIT_ERROR_MESSAGE,
  createWidgetError: WIDGET_CREATION_ERROR_MESSAGE,
};
