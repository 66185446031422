import { useTheme } from '@mui/material';
import { Visibility, Heart, Comment } from '@sparemin/blockhead';
import { SocialShareStatInfo } from 'api/services';
import DetailCard from 'components/DetailCard';
import React from 'react';
import Stat from './Stat';

type SocialStatsSummaryProps = {
  stats: Omit<SocialShareStatInfo, 'isViewCountStale'>;
};

const SocialStatsSummary: React.FC<SocialStatsSummaryProps> = ({ stats }) => {
  const width = '33.3%';
  const theme = useTheme();
  return (
    <DetailCard>
      <Stat
        icon={
          <Visibility
            color={theme.palette.supporting1.main}
            height="16px"
            width="23px"
          />
        }
        numberOf={stats.viewCount}
        statsType="views"
        width={width}
      />

      <Stat
        icon={
          <Heart
            height="14px"
            width="16px"
            color={theme.palette.warning.main}
          />
        }
        numberOf={stats.likeCount}
        statsType="likes"
        width={width}
      />

      <Stat
        icon={
          <Comment
            height="16px"
            width="16px"
            color={theme.palette.supporting3.main}
          />
        }
        numberOf={stats.commentCount}
        statsType="comments"
        width={width}
      />
    </DetailCard>
  );
};

export default SocialStatsSummary;
