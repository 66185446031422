import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import { useMemo, PropsWithChildren } from 'react';
import { TableInstance } from 'react-table';

interface UseColumnSumConfig<TData extends object> {
  accessor: string;
  tableData: PropsWithChildren<TableInstance<TData>>;
}

const useColumnSum = <TData extends Object>(
  config: UseColumnSumConfig<TData>,
) => {
  const { accessor, tableData } = config;

  return useMemo(
    () =>
      tableData.rows.reduce((sum, row) => {
        const rawRowValue = row.values[accessor];
        const rowValue =
          isNumber(rawRowValue) && !isNaN(rawRowValue)
            ? row.values[accessor]
            : 0;
        return sum + rowValue;
      }, 0),
    [accessor, tableData.rows],
  );
};

export default useColumnSum;
