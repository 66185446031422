import {
  getEpisodeSegments,
  EpisodeSegmentsArgs,
  EpisodeSegmentsResponse,
  Segment,
} from 'api/services';
import { UseQueryOptions, useQuery } from 'react-query';
import { podcastQueries } from './queryKeys';
import {
  activeSegmentSelector,
  episodeSegmentAudioUrlSelector,
  segmentsSelector,
  sortedEpisodeSelector,
} from './selectors';

type QueryKey = ReturnType<typeof podcastQueries.segments>;

function useGetEpisodeSegments<TData = EpisodeSegmentsResponse>(
  args: EpisodeSegmentsArgs,
  opts?: Omit<
    UseQueryOptions<EpisodeSegmentsResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<EpisodeSegmentsResponse, unknown, TData, QueryKey>(
    podcastQueries.segments(args),
    () => getEpisodeSegments(args),
    { enabled: !!args.internalEpisodeId, ...opts },
  );
}

export function useEpisodeSegments(internalEpisodeId: number) {
  return useGetEpisodeSegments(
    { internalEpisodeId },
    { select: segmentsSelector },
  );
}

export function useActiveEpisodeSegment(
  episodeId: number,
  activeSegmentId?: number,
) {
  return useGetEpisodeSegments(
    { internalEpisodeId: episodeId },
    { select: (response) => activeSegmentSelector(response, activeSegmentId) },
  );
}

export function useSortedSegments(args: EpisodeSegmentsArgs) {
  return useGetEpisodeSegments<Segment[]>(args, {
    select: sortedEpisodeSelector,
  });
}

export function useSegmentAudioUrl(episodeId: number) {
  return useGetEpisodeSegments(
    { internalEpisodeId: episodeId },
    {
      select: episodeSegmentAudioUrlSelector,
    },
  );
}
