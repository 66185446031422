import { Stack, Typography } from '@mui/material';
import { WidgetVariantId } from 'api/services';
import React from 'react';

import OptionsList from './OptionsList';

type CustomizeTabProps = {
  widgetId: string;
  onVariantUpdate: (val: WidgetVariantId) => void;
};

const CustomizeTab: React.FC<CustomizeTabProps> = (props) => (
  <Stack spacing={2} marginTop="22px">
    <Typography variant="h1" paddingLeft="4px">
      Customize your widget
    </Typography>
    <Typography variant="h2" paddingLeft="4px">
      Changes will instantly be applied to your widget on any site it is live
      on.
    </Typography>
    <OptionsList {...props} />
  </Stack>
);
export default CustomizeTab;
