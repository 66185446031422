import {
  Button as MuiButton,
  FormHelperText,
  Typography as MuiTypograpy,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

export const Button = styled(MuiButton)`
  border-width: 2px;
  border-radius: 25px;
  height: 50px;
  padding: 0 26px;
`;

export const Form = styled('form')`
  width: 100%;
  padding: 0 24px;
`;

export const SubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  border-width: 2px;
  border-radius: 25px;
  height: 50px;
`;

export const ErrorText = styled(withAttrs(FormHelperText, { error: true }))`
  text-align: center;
`;

export const Typography = styled(MuiTypograpy)`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const Container = styled('div')`
  width: 450px;
  padding: 15px 0;
`;
