import { Typography, Stack } from '@mui/material';
import ImageCard, { ImageCardProps } from 'components/ImageCard';
import React from 'react';

export interface ImageCardWithCenteredContentsProps
  extends Omit<ImageCardProps, 'children'> {
  heading?: string;
  description?: string;
  children?: React.ReactNode;
}

const ImageCardWithCenteredContents: React.FC<
  ImageCardWithCenteredContentsProps
> = ({ heading, description, children, height, ...props }) => (
  <ImageCard {...props} height="320px">
    <Stack justifyContent="center" alignItems="center" spacing={3}>
      {heading && (
        <Typography variant="h1" textAlign="center">
          {heading}
        </Typography>
      )}

      {description && (
        <Typography variant="h2" textAlign="center">
          {description}
        </Typography>
      )}
      {children}
    </Stack>
  </ImageCard>
);
export default ImageCardWithCenteredContents;
