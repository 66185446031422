import { Stack, Typography } from '@mui/material';
import CodeBlock from './CodeBlock';
import {
  WIDGET_WORDPRESS_SETUP_LINK,
  WIDGET_WORDPRESS_PLUGIN_DOWNLOAD,
  WORDPRESS_PLUGIN_INSTRUCTIONS,
} from './constants';
import InstructionsHeader from './InstructionsHeader';
import * as S from './styles';

type WordpressInstructionsProps = { widgetId: string };

const WordpressInstructions: React.FC<WordpressInstructionsProps> = ({
  widgetId,
}) => (
  <Stack spacing={1}>
    <InstructionsHeader
      header="Wordpress Installation"
      href={WIDGET_WORDPRESS_SETUP_LINK}
    />
    <ol>
      <S.Item>
        <Stack direction="row">
          <Typography>
            {WORDPRESS_PLUGIN_INSTRUCTIONS[0]}
            <S.ExternalLinkWithEmphasis
              href={WIDGET_WORDPRESS_PLUGIN_DOWNLOAD}
              target="_blank"
              rel="noreferrer"
            >
              here
            </S.ExternalLinkWithEmphasis>
          </Typography>
        </Stack>
      </S.Item>
      <S.Item>
        <Typography>{WORDPRESS_PLUGIN_INSTRUCTIONS[1]}</Typography>
      </S.Item>
    </ol>
    <CodeBlock copyIcon>{widgetId}</CodeBlock>
  </Stack>
);
export default WordpressInstructions;
