import SelectAllMultiSelect, {
  SelectAllMultiSelectProps,
} from 'components/SelectAllMultiSelect';
import { get, find } from 'lodash';
import { useEventTracking } from 'state/eventTracking';
import { useDomainWidgetNames, EnhancedWidget } from 'state/user';

export interface WidgetIdSelectorProps
  extends Pick<
    SelectAllMultiSelectProps<string>,
    'onClose' | 'value' | 'onChange'
  > {
  widgetIds: string[];
}

const WidgetIdSelector: React.FC<WidgetIdSelectorProps> = ({
  onChange,
  onClose,
  value,
  widgetIds,
}) => {
  const { trackReportAdjust } = useEventTracking();

  const data = useDomainWidgetNames();
  const widgetData: EnhancedWidget[] = data.isSuccess ? data.data : [];

  const getDomainName = (val: string) => {
    const widget = find(widgetData, ['widgetSid', val]);
    return get(widget, 'domain', '');
  };

  const getWidgetName = (val: string) => {
    const widget = find(widgetData, ['widgetSid', val]);
    return get(widget, 'widgetName', val);
  };

  const widgetOptions = widgetIds.map((widget) => ({
    itemValue: widget,
    primaryItemLabel: getWidgetName(widget),
    secondaryItemLabel: getDomainName(widget),
  }));

  const handleClose = () => {
    if (value.length === 0) {
      onChange(widgetIds);
    }
    onClose?.();
    trackReportAdjust({ dropdown: 'Widget' });
  };

  return (
    <SelectAllMultiSelect
      selectLabel="Widget"
      value={value}
      options={widgetOptions}
      onClose={handleClose}
      onChange={onChange}
    />
  );
};

export default WidgetIdSelector;
