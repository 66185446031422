import AllVideosTable from 'pages/headliner/AllVideos';

import * as S from './styles';

type VideoPageCardProps = {};

const VideoPageCard: React.FC<VideoPageCardProps> = () => (
  <S.ContentCard>
    <AllVideosTable />
  </S.ContentCard>
);
export default VideoPageCard;
