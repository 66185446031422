import Grid from '@mui/material/Grid';
import { RoleName } from 'api/services';
import ScopeCheckContainer from 'components/ScopeCheckContainer';
import StatsPeriodSelector, {
  getDefaultPeriodArgs,
} from 'components/StatsPeriodSelector';
import WidgetIdSelector, {
  SelectedWidgetIds,
} from 'components/WidgetIdSelector';
import { DashboardContent, DashboardContentTitle } from 'pages/Dashboard';
import DashboardPageFilters from 'pages/Dashboard/DashboardPageFilters';
import NoDataPage from 'pages/NoDataPage';
import { useState } from 'react';
import { useWidgetIds } from 'state/user';
import DailyStatsCard from './DailyStatsCard';
import DailyStatsChartCard from './DailyStatsChartCard';
import PublisherDashboardProvider from './PublisherDashboardContext';

export interface PublisherDashboardProps {}

const PublisherDashboard: React.FC<PublisherDashboardProps> = () => {
  const [selectedWids, setSelectedWids] = useState<
    SelectedWidgetIds | undefined
  >(undefined);
  const [draftWids, setDraftWids] = useState<SelectedWidgetIds | undefined>(
    selectedWids,
  );
  const data = useWidgetIds(setDraftWids);

  const widgetIds = data.isSuccess ? data.data : [];

  const [periodArgs, setPeriodArgs] = useState(getDefaultPeriodArgs());

  const handleClose = () => {
    setSelectedWids(draftWids);
  };

  return (
    <ScopeCheckContainer
      requiredScopes={[RoleName.publisher]}
      fallback={
        <NoDataPage
          trackingProps={{
            section: 'DiscoWidget',
            page: 'DailyStats',
            state: 'Empty',
          }}
        />
      }
    >
      <PublisherDashboardProvider
        periodArgs={periodArgs}
        widgetIds={selectedWids || []}
      >
        <DashboardContent
          maxWidth="xl"
          title={
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12}>
                <DashboardContentTitle>Daily Stats</DashboardContentTitle>
              </Grid>
              <DashboardPageFilters
                filters={[
                  <WidgetIdSelector
                    onChange={setDraftWids}
                    onClose={handleClose}
                    value={draftWids || []}
                    widgetIds={widgetIds}
                  />,
                  <StatsPeriodSelector onSelectPeriod={setPeriodArgs} />,
                ]}
              />
            </Grid>
          }
        >
          <Grid container spacing={2} marginBottom={5}>
            <Grid item xs={12} md={12}>
              <DailyStatsChartCard />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <DailyStatsCard />
            </Grid>
          </Grid>
        </DashboardContent>
      </PublisherDashboardProvider>
    </ScopeCheckContainer>
  );
};

export default PublisherDashboard;
