import { Divider, Stack } from '@mui/material';
import { MobileDetailCard } from 'components/MobileDetailCard';
import TableCells from 'components/TableCells';
import MobileDetailCardContents from '../MobileDetailCardContents';
import { useSocialVideoInfo } from '../state';
import { socialPlatformInfoLookup } from './socialPlatformLogoLookup';

type AllVideosMobileListProps = {
  episodeId?: string;
};

const AllVideosMobileList = ({ episodeId }: AllVideosMobileListProps) => {
  const { data: socialVideoData } = useSocialVideoInfo({ episodeId });

  return (
    <Stack
      divider={<Divider orientation="horizontal" variant="fullWidth" />}
      spacing={0}
    >
      {socialVideoData?.map((video) => {
        const {
          sharePostId,
          sharePostTitle = '',
          projectName = '',
          sharePostUrl,
          createdAtMillis,
          previewThumbnailImageUrl,
          statistics,
          platform,
        } = video;
        const { likeCount, commentCount, viewCount, isViewCountStale } =
          statistics;
        const contents = (
          <TableCells.SocialVideoCell
            videoName={sharePostTitle}
            projectName={projectName}
            datePosted={createdAtMillis}
            videoUrl={sharePostUrl}
            imgUrl={previewThumbnailImageUrl}
            socialPlatform={platform}
          />
        );

        return (
          <MobileDetailCard>
            <MobileDetailCardContents
              details={contents}
              likeCount={likeCount}
              viewCount={viewCount}
              commentCount={commentCount}
              platform={socialPlatformInfoLookup[platform].icon}
              key={sharePostId}
              isViewCountStale={isViewCountStale}
            />
          </MobileDetailCard>
        );
      })}
    </Stack>
  );
};

export default AllVideosMobileList;
