import { CampaignCategories } from 'api';
import { useCampaignCreation } from 'pages/AdCampaignPage/CampaignCreationContext';
import { CATEGORIES_OPTIONS } from './constants';
import TargetingSelectionMultiSelect from './TargetingSelectionMultiSelect';

export interface CategorySelectorProps {}

const CategorySelector: React.FC<CategorySelectorProps> = () => {
  const {
    state: {
      values: { targetCategories },
    },
    dispatch,
  } = useCampaignCreation();

  const handleClose = () => {
    if (targetCategories?.length === 0) {
      dispatch({
        type: 'CHANGE_VALUES',
        payload: {
          targetCategories: [CATEGORIES_OPTIONS[0].itemValue],
        },
      });
    }
  };

  return (
    <TargetingSelectionMultiSelect
      selectLabel="Select a category"
      options={CATEGORIES_OPTIONS}
      value={targetCategories ?? []}
      onChange={(newValues: CampaignCategories[]) =>
        dispatch({
          type: 'CHANGE_VALUES',
          payload: {
            targetCategories: newValues,
          },
        })
      }
      onClose={handleClose}
    />
  );
};

export default CategorySelector;
