import {
  getByDayWidgetMetrics,
  ByDayWidgetResponse,
  GetByDayWidgetMetricsArgs,
} from 'api/services';
import { UseQueryOptions, useQuery } from 'react-query';
import { discoMetricsQueries } from './queryKeys';
import { dailyStatsDataSelector } from './selectors';

type QueryKey = ReturnType<typeof discoMetricsQueries.daily>;

function useByDayWidgetMetrics<TData = unknown>(
  args: GetByDayWidgetMetricsArgs,
  opts?: Omit<
    UseQueryOptions<ByDayWidgetResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<ByDayWidgetResponse, unknown, TData, QueryKey>(
    discoMetricsQueries.daily(args),
    () => getByDayWidgetMetrics(args),
    opts,
  );
}

export function useDailyStatsData(args: GetByDayWidgetMetricsArgs) {
  return useByDayWidgetMetrics(args, {
    keepPreviousData: true,
    select: dailyStatsDataSelector,
  });
}
