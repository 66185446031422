import DialogBox from 'components/DialogBox';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { useConnectedModal } from 'state/modals';
import StyleWidgetModalContents from './StyleWidgetModalContents';

export interface StyleWidgetModalProps {}

const StyleWidgetModal: React.FC<StyleWidgetModalProps> = () => {
  const name = 'StyleWidget';
  const isMobile = useMobileBreakpoint('lg');
  const { params } = useConnectedModal({ name });
  return (
    <DialogBox name={name} maxWidth="lg" fullWidth fullScreen={isMobile}>
      <StyleWidgetModalContents isMobile={isMobile} {...params} />
    </DialogBox>
  );
};
export default StyleWidgetModal;
