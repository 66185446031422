import { Button, HeadlinerGradientSquare, Link } from '@sparemin/blockhead';
import InfoCard from 'components/InfoCard';
import React from 'react';
import * as S from './styles';

export interface HeadlinerCardProps {
  onPress: () => void;
}

const HeadlinerCard: React.FC<HeadlinerCardProps> = ({ onPress }) => (
  <S.Card>
    <S.HeadlinerCardContainer>
      <InfoCard.Image>
        <HeadlinerGradientSquare width="80px" height="80px" />
      </InfoCard.Image>

      <InfoCard.Contents
        badge="Lower price"
        heading="Within Headliner’s app"
        subheading={
          <>
            A great value for podcasts with general genres, such as True Crime
            or Comedy, because our apps provide great exposure at a lower price
            (
            <Link
              href="https://learn.headliner.app/hc/en-us/articles/22688870315031-Podcast-Promo-Internal-Widget"
              target="_blank"
            >
              learn more
            </Link>
            ).
          </>
        }
      >
        <S.ButtonContainer>
          <Button fluid onPress={onPress}>
            Select this option
          </Button>
        </S.ButtonContainer>
      </InfoCard.Contents>
    </S.HeadlinerCardContainer>
  </S.Card>
);

export default HeadlinerCard;
