import { RoleName } from 'api/services';
import Spinner from 'components/Spinner';
import React, { useMemo } from 'react';
import { useUserRoles } from 'state/user';
import { ScopesState } from './types';
import { mapUserRoles } from './utils';

const ScopesContext = React.createContext<ScopesState>({
  availableScopes: [],
});

export const useScopesContext = () => {
  const { availableScopes } = React.useContext(ScopesContext);

  return { availableScopes };
};

export const ScopesProvider: React.FunctionComponent = (props) => {
  const { children } = props;

  const {
    data: userRoles = [],
    isLoading,
    remove,
  } = useUserRoles<RoleName[]>({
    select: (response) => mapUserRoles(response),
  });

  React.useEffect(
    () => () => {
      remove();
    },
    [remove],
  );

  return (
    <ScopesContext.Provider
      value={useMemo(() => ({ availableScopes: userRoles }), [userRoles])}
    >
      {isLoading ? <Spinner /> : children}
    </ScopesContext.Provider>
  );
};
