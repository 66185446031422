import React, { useCallback } from 'react';
import * as S from './styles';

export interface PlayBackButtonProps {
  onTogglePlayback?: (playing: boolean) => void;
  loading?: boolean;
  playing: boolean;
  disabled?: boolean;
}

const PlayBackButton: React.FC<PlayBackButtonProps> = ({
  loading,
  onTogglePlayback,
  playing,
  disabled = false,
}) => {
  const handleClick = useCallback(() => {
    onTogglePlayback?.(!playing);
  }, [onTogglePlayback, playing]);
  return (
    <S.PlaybackButton
      className={disabled ? 'disabled' : ''}
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
    >
      {playing ? <S.PauseIcon /> : <S.PlayIcon />}
    </S.PlaybackButton>
  );
};

export default PlayBackButton;
