import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'state/auth';
import { setRedirectPath } from './utils';

export interface RequireAuthProps {
  children?: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={setRedirectPath(location)} replace />;
  }

  return <>{children}</>;
};

export default RequireAuth;
