import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

export const RoundedButton = styled(
  withAttrs(Button, {
    component: 'a',
    textDecoration: 'none',
    target: '_blank',
  }),
)`
  border-radius: 25px;
  height: 50px;
`;
