import { Link, getThemeColor, whileHovering } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled(Link).attrs({
  forwardedAs: 'button',
  elementType: 'button',
  typography: 'text',
  variant: 'button',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getThemeColor('actionPrimary')};
  outline: none;

  ${whileHovering`
    color:  ${({ theme }) => theme.palette.actionPrimary.dark};
  `};
`;

export const StartAdornment = styled.span`
  display: inherit;
  margin-right: 8px;
`;

export const EndAdornment = styled.span`
  display: inherit;
  margin-left: 8px;
`;
