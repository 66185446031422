import ImageCardWithCenteredContents from 'components/ImageCardWithCenteredContents';
import { DashboardContent } from 'pages/Dashboard';
import { MOBILE_FALLBACK_PAGE_DESCRIPTION } from 'utils/constants';

type MobileFallbackProps = {
  heading: 'Disco' | 'Podcast Promo';
  srcSet?: string;
  src: string;
};

const MobileFallback: React.FC<MobileFallbackProps> = ({
  heading,
  src,
  srcSet,
}) => (
  <DashboardContent>
    <ImageCardWithCenteredContents
      heading={heading}
      description={MOBILE_FALLBACK_PAGE_DESCRIPTION}
      src={src}
      srcSet={srcSet}
    />
  </DashboardContent>
);
export default MobileFallback;
