import { useTheme } from '@mui/material';

import RSPulseLoader from 'react-spinners/PulseLoader';

export interface PulseLoaderProps
  extends React.ComponentPropsWithoutRef<typeof RSPulseLoader> {}

const PulseLoader: React.FC<PulseLoaderProps> = (props) => {
  const theme = useTheme();

  return <RSPulseLoader color={theme.palette.primary.main} {...props} />;
};

export default PulseLoader;
