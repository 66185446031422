import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';
import WidgetPageLayout from 'components/WidgetPageLayout';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { DashboardContent } from 'pages/Dashboard';
import {
  WIDGET_ERROR_EMAIL_SUBJECT,
  WIDGET_ERROR_HEADER,
} from 'pages/Widget/constants';
import React from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useGetSelfServeWidgetInfo } from 'state/user';
import SetupWidget from './SetupWidget';
import WidgetInProgress from './WidgetInProgress';

type SelfServePageProps = {
  id: number;
};

const SelfServePage: React.FC<SelfServePageProps> = ({ id }) => {
  const { trackWidgetCreation } = useEventTracking();

  const { data: widgetData } = useGetSelfServeWidgetInfo({
    selfServeRequestId: id,
  });

  useTrackPageLoad({
    section: 'DiscoWidget',
    page: 'WidgetCode',
    state: 'Populated',
  });
  const { status, widgetId, variantId, podcastName, websiteUrl } =
    widgetData ?? {};

  if (!widgetData) return <WidgetPageLayout content={<Spinner />} />;

  if (status === 'completed' && widgetId) {
    trackWidgetCreation('createWidgetSuccess');

    return (
      <WidgetPageLayout
        title={`${podcastName} - ${websiteUrl}`}
        content={
          <SetupWidget widgetId={widgetId} widgetVariantId={variantId} />
        }
      />
    );
  }

  if (status === 'processing')
    return (
      <DashboardContent maxWidth="md">
        <WidgetInProgress />
      </DashboardContent>
    );

  trackWidgetCreation('createWidgetError');

  return (
    <EmptyState
      header={WIDGET_ERROR_HEADER}
      subject={WIDGET_ERROR_EMAIL_SUBJECT}
    />
  );
};

export default SelfServePage;
