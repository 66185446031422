import React, { useContext, useMemo } from 'react';
import {
  CampaignCreationContextType,
  CampaignCreationProviderProps,
} from './types';

export const CampaignCreationContext = React.createContext<
  CampaignCreationContextType | undefined
>(undefined);

export const CampaignCreationProvider: React.FC<
  CampaignCreationProviderProps
> = ({ state, dispatch, children }) => (
  <CampaignCreationContext.Provider
    value={useMemo(
      () => ({
        state,
        dispatch,
      }),
      [state, dispatch],
    )}
  >
    {children}
  </CampaignCreationContext.Provider>
);

export function useCampaignCreation() {
  const context = useContext(CampaignCreationContext);

  if (context === undefined) {
    throw new Error(
      'useCampaignCreation must be used within CampaignCreationProvider',
    );
  }

  return context;
}
