import { FormHelperText, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Spacer } from '@sparemin/blockhead';
import { withAttrs } from 'utils/react';

export const OptionsContainer = styled(RadioGroup)`
  width: 100%;
  gap: 14px;
`;

export const BudgetContainer = styled(
  withAttrs(Spacer, { orientation: 'vertical', space: '8px', align: 'center' }),
)`
  width: 100%;
`;

export const ErrorText = styled(withAttrs(FormHelperText, { error: true }))`
  text-align: center;
`;
