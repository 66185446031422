import { Stack, Typography } from '@mui/material';
import { DISCO_WIDGET_REQUEST_URL } from 'config/env';
import CodeBlock from './CodeBlock';
import { WIDGET_MANUAL_SETUP_LINK } from './constants';
import InstructionsHeader from './InstructionsHeader';

type ManualInstructionsProps = { widgetId: string };

const ManualInstructions: React.FC<ManualInstructionsProps> = ({
  widgetId,
}) => {
  const DISCO_SCRIPT_TAG = `<script defer src="${DISCO_WIDGET_REQUEST_URL}"></script>`;
  const DISCO_DIV_TAG = `<div class="disco-widget" data-widget-id="${widgetId}"></div>`;
  return (
    <Stack spacing={1}>
      <InstructionsHeader
        header="Manual Installation"
        href={WIDGET_MANUAL_SETUP_LINK}
      />
      <Typography variant="body1">
        Script Tag (place in head section of html):
      </Typography>
      <CodeBlock copyIcon>{DISCO_SCRIPT_TAG}</CodeBlock>
      <Typography variant="body1">
        Placeholder text (add to your html where you want to inject the widget):
      </Typography>
      <CodeBlock copyIcon>{DISCO_DIV_TAG}</CodeBlock>
    </Stack>
  );
};
export default ManualInstructions;
