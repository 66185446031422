import {
  GetSelfServeWidgetsResponse,
  Publisher,
  PublisherDomain,
  Widget,
} from 'api';
import flatMap from 'lodash/flatMap';
import flow from 'lodash/flow';
import map from 'lodash/map';
import partialRight from 'lodash/partialRight';

export function domainWidgetNamesSelector(d: Publisher) {
  return d.domains.flatMap((domain) =>
    domain.widgets.map((widget) => ({
      ...widget,
      domain: domain.domain,
    })),
  );
}

export function widgetIdsSelector(d: Publisher) {
  return flow(
    partialRight<PublisherDomain[], string, Widget[]>(flatMap, 'widgets'),
    partialRight<Widget[], string, string[]>(map, 'widgetSid'),
  )(d.domains);
}

export function widgetOptionsSelector(d: Publisher) {
  return {
    displayStyleVariant:
      d.domains[0].widgets[0].displayVariants[0].displayVariantId,
    audioPlaybackMethod: d.domains[0].widgets[0].audioPlaybackMethod,
  };
}

export function selfServeWidgetIdSelector(d: Publisher) {
  return d.domains.length > 0 ? d.domains[0].selfServeRequestId : undefined;
}

export function widgetInfoSelector(d: GetSelfServeWidgetsResponse) {
  const info = d.domains[0].widgets[0];
  return {
    status: info.widgetProcessingStatus,
    widgetId: info.widgetSid,
    variantId: info.displayVariants[0].displayVariantId,
    podcastName: info.widgetName,
    websiteUrl: d.domains[0].domain,
  };
}
