import CardImageMedia, { CardImageMediaProps } from 'components/CardImageMedia';
import * as S from './styles';

export interface ImageCardProps extends CardImageMediaProps {
  children: React.ReactNode;
}

const ImageCard: React.FC<ImageCardProps> = ({ children, ...props }) => (
  <S.Card>
    <CardImageMedia {...props} />
    <S.Content>{children}</S.Content>
  </S.Card>
);
export default ImageCard;
