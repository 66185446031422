import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

export const IconContainer = styled('div')`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 0 160px 32px;
  padding: 34px 30px;
  background-image: linear-gradient(135deg, #5dbefc, #dd92fe);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RoundedButton = styled(
  withAttrs(Button, {
    component: 'a',
    textDecoration: 'none',
    target: '_blank',
    variant: 'contained',
    size: 'large',
    color: 'primary',
    fullWidth: true,
  }),
)`
  border-radius: 25px;
  height: 50px;
`;

export const Container = styled('div')`
  max-width: 420px;
  margin: 100px 0;
`;
