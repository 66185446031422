import { Button } from '@sparemin/blockhead';
import network from 'assets/adsImagery/network/network.png';
import network2x from 'assets/adsImagery/network/network@2x.png';
import network3x from 'assets/adsImagery/network/network@3x.png';
import podcaster from 'assets/adsImagery/podcaster/podcaster.png';
import podcaster2x from 'assets/adsImagery/podcaster/podcaster@2x.png';
import podcaster3x from 'assets/adsImagery/podcaster/podcaster@3x.png';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { DISCO_ADS_DESCRIPTION } from 'utils/constants';
import PageHeading from '../PageHeading';
import IntroViewCard from './IntroViewCard';
import * as S from './styles';

export interface CreateNewCampaignProps {
  onCreateNewCampaign: () => void;
}

const CreateNewCampaign: React.FC<CreateNewCampaignProps> = ({
  onCreateNewCampaign,
}) => {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();

  const handleCreateNewCampaign = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'Intro',
      view: 'NewPurchaser',
      buttonClick: 'CreateNew',
    });

    onCreateNewCampaign();
  }, [onCreateNewCampaign, trackPodcastPromoPurchaseNext]);

  return (
    <S.Root>
      <PageHeading
        title="Transparently grow your show"
        description={DISCO_ADS_DESCRIPTION}
      />

      <S.CardsContainer>
        <IntroViewCard
          title="We’ve helped grow thousands of podcasts."
          srcSet={`${podcaster} 1x, ${podcaster2x} 2x, ${podcaster3x} 3x`}
          alt="A row of portraits of different people. The portraits are arranged horizontally on a gray background."
        />

        <IntroViewCard
          title="We’ve built a network of trusted publishers."
          srcSet={`${network} 1x, ${network2x} 2x, ${network3x} 3x`}
          alt="A row of logos for various news outlets. The logos are arranged horizontally on a gray background."
        />
      </S.CardsContainer>

      <Button fluid onPress={handleCreateNewCampaign}>
        create new campaign
      </Button>
    </S.Root>
  );
};

export default CreateNewCampaign;
