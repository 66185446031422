import { Stack, useTheme } from '@mui/material';
import { Visibility, Heart, Comment } from '@sparemin/blockhead';
import TableCells from 'components/TableCells';
import { Column, CellProps } from 'react-table';
import { SocialShareVideos } from '../types';
import { socialPlatformInfoLookup } from './socialPlatformLogoLookup';
import * as S from './styles';

const columns: ReadonlyArray<Column<SocialShareVideos>> = [
  {
    Header: () => <S.PlatformIcon />,
    id: 'app',
    Cell: ({ row }: CellProps<SocialShareVideos>) => (
      <Stack height="100%" justifyContent="center">
        {socialPlatformInfoLookup[row.original.platform].icon}
      </Stack>
    ),
    width: 50,
  },
  {
    Header: 'Video Name',
    id: 'video',
    Cell: ({ row }: CellProps<SocialShareVideos>) => (
      <TableCells.SocialVideoCell
        videoName={row.original.sharePostTitle ?? 'Untitled'}
        projectName={row.original.projectName ?? 'Untitled'}
        datePosted={row.original.createdAtMillis}
        imgUrl={row.original.previewThumbnailImageUrl}
        videoUrl={row.original.sharePostUrl}
        socialPlatform={
          socialPlatformInfoLookup[row.original.platform].brandName
        }
      />
    ),
    disableSortBy: true,
    width: 800,
  },
  {
    Header: () => {
      const theme = useTheme();
      return (
        <TableCells.HeaderCell
          text={
            <Visibility
              width="23px"
              height="16px"
              color={theme.palette.grey[700]}
            />
          }
        />
      );
    },
    id: 'views',
    Cell: ({ row }: CellProps<SocialShareVideos>) => (
      <TableCells.NumericCell
        value={row.original.statistics.viewCount}
        isValueStale={row.original.statistics.isViewCountStale}
      />
    ),
    disableSortBy: true,
    width: 100,
  },
  {
    Header: () => {
      const theme = useTheme();
      return (
        <TableCells.HeaderCell
          text={
            <Heart width="16px" height="14px" color={theme.palette.grey[700]} />
          }
        />
      );
    },

    id: 'likes',
    Cell: ({ row }: CellProps<SocialShareVideos>) => (
      <TableCells.NumericCell value={row.original.statistics.likeCount} />
    ),
    disableSortBy: true,
    width: 100,
  },
  {
    Header: () => {
      const theme = useTheme();
      return (
        <TableCells.HeaderCell
          text={
            <Comment
              width="16px"
              height="16px"
              color={theme.palette.grey[700]}
            />
          }
        />
      );
    },
    id: 'comments',
    Cell: ({ row }: CellProps<SocialShareVideos>) => (
      <TableCells.NumericCell value={row.original.statistics.commentCount} />
    ),
    disableSortBy: true,
    width: 100,
  },
];

export default columns;
