import {
  updateWidgetOptions,
  UpdateWidgetStyleResponse,
  UpdateWidgetOptionsArgs,
} from 'api/services';
import { useMutation, UseMutationOptions } from 'react-query';

export default function useUpdateWidgetOptions(
  config?: Omit<
    UseMutationOptions<
      UpdateWidgetStyleResponse,
      unknown,
      UpdateWidgetOptionsArgs,
      unknown
    >,
    'mutationFn'
  >,
) {
  return useMutation<
    UpdateWidgetStyleResponse,
    unknown,
    UpdateWidgetOptionsArgs
  >((args) => updateWidgetOptions(args), config);
}
