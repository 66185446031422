import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

export const StatIconContainer = styled(
  withAttrs(Stack, {
    direction: 'row',
    spacing: 1,
  }),
)``;
