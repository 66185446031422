import { Typography, Button } from '@mui/material';
import { Download } from '@sparemin/blockhead';
import { useNotification } from 'components/Notification/NotificationContext';
import { useFollowersFormattedForCsv } from 'pages/Play/state/useFeedFollowers';
import React from 'react';
import { useEventTracking } from 'state/eventTracking';
import { downloadBlob } from 'utils/download';
import * as S from './styles';
import { exportDataToCsv } from './utils';

type Follower = {
  email: string;
  followDate: string;
};

type CTAProps = {};

const CTA: React.FC<CTAProps> = () => {
  const { data: exportData = [] } = useFollowersFormattedForCsv();

  const { trackDownloadFollowers } = useEventTracking();

  const { showError } = useNotification();

  const handleCsvDownload = () => {
    trackDownloadFollowers();
    try {
      const blob = exportDataToCsv<Follower>(exportData);
      downloadBlob('Followers', blob);
    } catch (err) {
      showError('Something went wrong. Please try again.');
    }
  };

  return (
    <S.Container>
      <Button
        startIcon={<Download width="12px" height="12px" />}
        onClick={handleCsvDownload}
      >
        <Typography
          component="span"
          variant="h2"
          textTransform="none"
          color="inherit"
        >
          Download full list as a CSV
        </Typography>
      </Button>
    </S.Container>
  );
};
export default CTA;
