import Spinner from 'components/Spinner';
import React from 'react';
import EmptyState from './EmptyState';
import { useHasClaimedPodcasts } from './state';
import TableContainer from './TableContainer';

type PlayProps = {};

const Play: React.FC<PlayProps> = () => {
  const { data: hasPodcasts, isLoading } = useHasClaimedPodcasts();

  if (isLoading) {
    return <Spinner />;
  }
  if (hasPodcasts) {
    return <TableContainer />;
  }
  return <EmptyState />;
};

export default Play;
