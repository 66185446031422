import { Stack, Typography } from '@mui/material';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import React from 'react';

export type StatProps = {
  icon: React.ReactElement;
  numberOf: number;
  statsType: 'views' | 'likes' | 'comments';
  width: string;
};

const Stat: React.FC<StatProps> = ({ icon, numberOf, statsType, width }) => {
  const isMobile = useMobileBreakpoint('md');

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      margin={isMobile ? '24px 0' : '48px 0'}
      width={width}
    >
      {icon}
      <Typography margin="16px 0" variant={isMobile ? 'h1' : 'jumbo'}>
        {numberOf}
      </Typography>
      <Typography variant="h2" textTransform="uppercase">
        {statsType}
      </Typography>
    </Stack>
  );
};
export default Stat;
