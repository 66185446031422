import { TableHead } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import MuiTableRow from '@mui/material/TableRow';

export const NoDataTableCell = styled(TableCell)`
  text-align: center;
`;

export const StickyFooter = styled(TableFooter)`
  bottom: 0;
  position: sticky;
`;

export const HeaderCellContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
`;

export const Header = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableRow = styled(MuiTableRow)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
