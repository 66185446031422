import {
  getSocialShareStatsRequestInfo,
  GetRequestStatusArgs,
  GetRequestStatusResponse,
} from 'api/services';
import { embedVideoQueries } from 'pages/headliner/state';
import { UseQueryOptions, useQuery } from 'react-query';

type QueryKey = ReturnType<typeof embedVideoQueries.request>;

export default function useGetSocialShareStatsRequestStatus<
  TData = GetRequestStatusResponse,
>(
  args: Partial<GetRequestStatusArgs>,
  opts?: Omit<
    UseQueryOptions<GetRequestStatusResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<GetRequestStatusResponse, unknown, TData, QueryKey>(
    embedVideoQueries.request({ requestId: args.requestId as number }),
    () =>
      getSocialShareStatsRequestInfo({ requestId: args.requestId as number }),
    {
      enabled: !!args.requestId,
      ...opts,
    },
  );
}
