import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentCard = styled(Card)`
  margin-top: 32px;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const Container = styled('div')`
  width: 250px;
  height: 250px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
