import PlayBackButton from 'components/PlayBackButton';
import { useEpisodeAudioUrl } from 'pages/ContentManagementPage/state';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { seekToAudioTimeCode } from 'utils/audio';
import { secToMillis } from 'utils/time';

type AudioPlayerProps = {
  episodeId: number;
  clipStart: number;
  clipEnd: number;
  audio: HTMLAudioElement | null;
  onAudioLoad: Dispatch<SetStateAction<HTMLAudioElement | null>>;
  error: boolean;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  episodeId,
  clipStart,
  clipEnd,
  audio,
  onAudioLoad,
  error,
}) => {
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  const { data: audioUrl } = useEpisodeAudioUrl(episodeId, {
    onSuccess: () => setLoading(false),
  });

  const handlePause = useCallback(() => {
    if (audio) {
      if (!audio.paused) {
        audio.pause();
        setPlaying(false);
      }
    }
  }, [audio]);

  const handlePlay = useCallback(async () => {
    if (!audio) return undefined;

    if (audio.paused) {
      setPlaying(true);
      return audio.play();
    }
    return undefined;
  }, [audio]);

  const handleTogglePlayback = () => {
    if (!playing) {
      handlePlay();
      return undefined;
    }
    if (audio) handlePause();

    return undefined;
  };

  const handleTimeUpdate = useCallback(() => {
    if (audio) {
      if (secToMillis(audio.currentTime) >= clipEnd) {
        setPlaying(false);
        audio.pause();
        seekToAudioTimeCode(clipStart, audio);
      }
    }
  }, [clipEnd, audio, clipStart]);

  useEffect(() => {
    const handlePlaying = () => setLoading(false);
    const handleWaiting = () => setLoading(true);
    if (audio) {
      audio.addEventListener('playing', handlePlaying);
      audio.addEventListener('waiting', handleWaiting);
      audio.addEventListener('timeupdate', handleTimeUpdate);
    }

    return () => {
      if (audio) {
        handlePause();
        audio.removeEventListener('playing', handlePlaying);
        audio.removeEventListener('waiting', handleWaiting);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [audio, clipEnd, handleTimeUpdate, clipStart, handlePause]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={onAudioLoad} src={audioUrl} />
      <PlayBackButton
        loading={loading}
        playing={playing}
        disabled={error}
        onTogglePlayback={() => {
          handleTogglePlayback();
        }}
      />
    </>
  );
};
export default AudioPlayer;
