import DialogBox from 'components/DialogBox';
import { useConnectedModal, useModalControls } from 'state/modals';
import { AUDIO_CLIP_MODAL_TITLE } from '../constants';
import { AUDIO_CLIP_MODAL_SUBTITLE } from './constants';
import NewEpisodeSegmentForm from './NewEpisodeSegmentForm';

export type NewEpisodeSegmentModalProps = {};

const NewEpisodeSegmentModal: React.FC<NewEpisodeSegmentModalProps> = () => {
  const name = 'NewEpisodeSegment';
  const { close } = useModalControls();
  const { params } = useConnectedModal({ name });

  return (
    <DialogBox
      title={AUDIO_CLIP_MODAL_TITLE}
      subtitle={AUDIO_CLIP_MODAL_SUBTITLE}
      name={name}
    >
      <NewEpisodeSegmentForm {...params} onClose={close} />
    </DialogBox>
  );
};
export default NewEpisodeSegmentModal;
