// import { SortByFn } from "react-table";
import { IdType, Row } from 'react-table';
import { parseDate } from '../date';

/**
 * Simple sort by date custom sorter for table columns.
 * @param {string} accesor - Key of table data object to be
 * compared. This accesor should point to a valid date data
 * member.
 * @returns {number} 1 or -1 depending on the result of the
 * compared values
 */
export const sortByDate =
  <TData extends object>(accesor: IdType<keyof TData>) =>
  (rowA: Row<TData>, rowB: Row<TData>) => {
    const dateA = parseDate(rowA.values[accesor]);
    const dateB = parseDate(rowB.values[accesor]);
    return dateA > dateB ? 1 : -1;
  };
