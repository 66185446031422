/* eslint-disable array-callback-return */
import { EpisodeSegmentsResponse } from 'api/services';
import { useNotification } from 'components/Notification/NotificationContext';
import {
  useUpdateEpisodeSegments,
  podcastQueries,
} from 'pages/ContentManagementPage/state';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useEventTracking } from 'state/eventTracking';

type UpdateAllEpisodeSegmentsFormData = {
  showInWidget: boolean;
};

export default function useToggleAllClipsForm(
  episodeId: number,
  onClose: () => void,
) {
  const { trackShowInWidgetToggleAll } = useEventTracking();
  const { showError } = useNotification();
  const queryClient = useQueryClient();

  const { handleSubmit, ...form } = useForm<UpdateAllEpisodeSegmentsFormData>();

  const changeEpisodeSegmentsState = useUpdateEpisodeSegments({
    onMutate: async ({ isEnabled }: { isEnabled: boolean }) => {
      await queryClient.cancelQueries(
        podcastQueries.segments({ internalEpisodeId: episodeId }),
      );
      const previousValues = queryClient.getQueryData(
        podcastQueries.segments({ internalEpisodeId: episodeId }),
      ) as EpisodeSegmentsResponse;
      const newSegments = previousValues.segments.map((segment) => {
        if (segment.isEnabled !== isEnabled) {
          return { ...segment, isEnabled };
        }
        return { ...segment };
      });
      const newEpisodeData = { ...previousValues, segments: [...newSegments] };
      queryClient.setQueryData(
        podcastQueries.segments({ internalEpisodeId: episodeId }),
        newEpisodeData,
      );
      onClose();
      return { previousValues };
    },
    onError: (data, args, context) => {
      showError('Something went wrong');

      queryClient.setQueriesData(
        podcastQueries.segments({ internalEpisodeId: episodeId }),
        context?.previousValues,
      );
    },
    onSuccess: (_, { isEnabled }) => {
      trackShowInWidgetToggleAll(isEnabled ? 'on' : 'off');
    },
  });

  const onSubmit = ({ showInWidget }: UpdateAllEpisodeSegmentsFormData) => {
    if (!episodeId) {
      showError('Something went wrong');
    } else {
      changeEpisodeSegmentsState.mutate({
        internalEpisodeId: episodeId,
        isEnabled: showInWidget,
      });
    }
  };

  return {
    ...form,

    onSubmit: handleSubmit(onSubmit),
  };
}
