import {
  AllPodcastContentResponse,
  FeedFollowersResponse,
  GetEpisodeMetricsResponse,
} from 'api';
import { InfiniteData } from 'react-query';
import { displayDate } from 'utils/date';

export function podcastDataSelector(d: AllPodcastContentResponse) {
  return d.podcasts;
}

export function hasClaimedPodcastsSelector(d: AllPodcastContentResponse) {
  return d.podcasts.length > 0;
}

export function podcastFeedIdSelector(
  d: AllPodcastContentResponse,
  podcastId?: string,
): number | undefined {
  return d.podcasts.find((podcast) => podcast.podcastId === podcastId)
    ?.podcastFeedId;
}

export function episodeListenDataSelector(
  d?: InfiniteData<GetEpisodeMetricsResponse>,
) {
  return {
    ...d,
    pages: d?.pages.flatMap((page) => page.episodes),
  };
}

export function podcastStatsSelector(
  d?: InfiniteData<GetEpisodeMetricsResponse>,
) {
  return {
    playCount: d?.pages[0].playCount,
    followCount: d?.pages[0].followCount,
    revenue: d?.pages[0].revenueShareMilliCent,
  };
}

export function podcastTitleSelector(
  d: AllPodcastContentResponse,
  podcastId?: string,
) {
  return d.podcasts.find((podcast) => podcast.podcastId === podcastId)?.title;
}

export function followersListSelector(d: FeedFollowersResponse) {
  return d.followers;
}

export function followersFormattedForCsvSelector(d: FeedFollowersResponse) {
  return d.followers.map((follower) => ({
    email: follower.email,
    followDate: displayDate(follower.followStartedAt),
  }));
}

export function hasFollowersSelector(d: FeedFollowersResponse) {
  return followersFormattedForCsvSelector(d).length > 0;
}
