import axios from 'axios';

import {
  autoLogoutResponseInterceptor,
  tokenRenewalRequestInterceptor,
} from './interceptors';

let authHeaderInterceptor: number;
let autoLogoutInterceptor: number;
let tokenRenewalInterceptor: number;

const axiosInstance = axios.create();

interface SetApiTokenConfig {
  token: string | undefined;
  onTokenRenewed: (token: string) => void;
}

export function setApiToken({ onTokenRenewed }: SetApiTokenConfig) {
  [authHeaderInterceptor, tokenRenewalInterceptor].forEach((interceptor) => {
    if (interceptor !== undefined) {
      axiosInstance.interceptors.request.eject(interceptor);
    }
  });

  tokenRenewalInterceptor = axiosInstance.interceptors.request.use(
    ...tokenRenewalRequestInterceptor({ onTokenRenewed }),
  );
}

export function configure(logout: () => void) {
  if (autoLogoutInterceptor !== undefined) {
    axiosInstance.interceptors.response.eject(autoLogoutInterceptor);
  }

  autoLogoutInterceptor = axiosInstance.interceptors.response.use(
    ...autoLogoutResponseInterceptor(logout),
  );
}

export { axiosInstance as request };
