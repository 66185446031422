import { Location } from 'react-router-dom';

interface AuthRedirectLocationState {
  from: Location;
}

export function setRedirectPath(location: Location): AuthRedirectLocationState {
  return { from: location };
}

export function getRedirectPath(location: Location) {
  const state = location.state as AuthRedirectLocationState;
  return state?.from || '/';
}
