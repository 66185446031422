import { Typography, Grid } from '@mui/material';
import ImageCardLink from 'components/ImageCardLink';
import PageContainer from 'components/PageContainer';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import React from 'react';
import { cardArray } from './cardInfo';
import { HOME_TITLE, HOME_SUBTITLE } from './constants';
import * as S from './styles';

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  useTrackPageLoad({ section: 'Home', page: 'Home' });

  return (
    <PageContainer>
      <S.Grid container spacing={6}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h1" gutterBottom>
              {HOME_TITLE}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              {HOME_SUBTITLE}
            </Typography>
          </Grid>
        </Grid>
        {cardArray.map((card) => (
          <Grid item xs={12} md={6} key={card.alt}>
            <ImageCardLink
              height={200}
              src={card.src}
              alt={card.alt}
              srcSet={card.srcSet}
              href={card.href}
            >
              <Typography variant="h3" gutterBottom>
                {card.title}
              </Typography>
              <Typography variant="body1">{card.text}</Typography>
            </ImageCardLink>
          </Grid>
        ))}
      </S.Grid>
    </PageContainer>
  );
};
export default Home;
