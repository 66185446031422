import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import { AlignedCellProps } from './types';

export const TextCell = styled('div')`
  text-align: left;
`;

export const AlignedCell = styled('div')<AlignedCellProps>`
  text-align: ${({ textAlign = 'left' }) => textAlign};
`;

export const HeaderCellWrapper = styled(AlignedCell)`
  display: flex;
  align-items: center;
`;

export const InfoIcon = styled(InfoOutlinedIcon)`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 1rem;
  padding-left: ${({ theme }) => theme.spacing(0.2)};
`;

export const Img = styled('img')`
  border-radius: 4px;
  display: block;
  max-height: 50px;
  max-width: 50px;
`;
