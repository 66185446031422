import { DataTableProps } from 'components/DataTable/DataTable';
import * as S from './styles';
import { PodcastContentTableConfig } from './types';
import usePodcastContentTable from './usePodcastContentTable';

type PickedDataTableProps<TData extends object> = Pick<
  DataTableProps<TData>,
  'hasMore' | 'infinite' | 'onLoadMore' | 'tableLayout'
>;

export interface PodcastContentTableProps<TData extends object>
  extends PickedDataTableProps<TData>,
    PodcastContentTableConfig<TData> {
  onRowClick?: (val: TData) => void;
}
const PodcastContentTable = <TData extends object>(
  props: PodcastContentTableProps<TData>,
): React.ReactElement => {
  const {
    columns,
    podcastData,
    tableLayout,
    virtual,
    isLoading,
    onRowClick,
    getRowId,
    hasMore,
    infinite,
    onLoadMore,
  } = props;

  const { loading, tableInstance } = usePodcastContentTable({
    columns,
    podcastData,
    virtual,
    isLoading,
    getRowId,
  });

  return (
    <S.PodcastContentTable
      tableLayout={tableLayout}
      isLoading={loading}
      skeletonRows={3}
      table={tableInstance}
      onRowClick={onRowClick}
      infinite={infinite}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
    />
  );
};

export default PodcastContentTable;
