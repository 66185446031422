import { request } from 'api/http';
import { AxiosResponse } from 'axios';
import { serializeParamsArray } from 'utils/paramsSerializer';
import {
  DASHBOARD_V1_ADVERTISER_DATA_URL,
  DASHBOARD_V1_EPISODE_METRICS_URL,
  DASHBOARD_V1_METRICS_URL,
  DASHBOARD_V1_PUBLISHER_DATA_URL,
  DASHBOARD_V1_PUBLISHER_METRICS_URL,
  DASHBOARD_V1_SELF_SERVE_PUBLISHER_URL,
  DASHBOARD_V1_SPONSOR_URL,
  DASHBOARD_V1_USER_ROLE_URL,
} from './constants';
import {
  AdvertisersByDayWidgetResponse,
  ByDayWidgetResponse,
  GetAdvertisersByDayWidgetMetricsArgs,
  GetByDayWidgetMetricsArgs,
  GetPlatformByDayWidgetMetricsArgs,
  GetPlatformByDayWidgetMetricsResponse,
  GetUrlWidgetMetricsArgs,
  GetUrlWidgetMetricsResponse,
  MyAdvertisers,
  Publisher,
  CreateAdCampaignArgs,
  CreateAdCampaignResponse,
  CreateWidgetArgs,
  CreateWidgetResponse,
  GetSelfServeWidgetsResponse,
  UserRoles,
  UpdateWidgetStyleResponse,
  UpdateWidgetOptionsArgs,
  GetPublisherWidgetArgs,
  GetEpisodeMetricsArgs,
  GetEpisodeMetricsOpts,
  GetEpisodeMetricsResponse,
  GetAdCampaignResponse,
} from './types';

export async function getMyUserRoles() {
  const { data } = await request.get<UserRoles>(DASHBOARD_V1_USER_ROLE_URL);
  return data;
}

export async function getMyPublisherData(args?: GetPublisherWidgetArgs) {
  const { data } = await request.get<Publisher>(
    DASHBOARD_V1_PUBLISHER_DATA_URL,
    { params: { isSelfServe: args?.isSelfServe } },
  );
  return data;
}

export async function getMyAdvertisersData() {
  const { data } = await request.get<MyAdvertisers>(
    DASHBOARD_V1_ADVERTISER_DATA_URL,
  );

  return data;
}

export async function getByDayWidgetMetrics({
  endMillis,
  startMillis,
  widgetIds,
}: GetByDayWidgetMetricsArgs) {
  const { data } = await request.get<ByDayWidgetResponse>(
    `${DASHBOARD_V1_PUBLISHER_METRICS_URL}/by-day-widget-metrics`,
    {
      params: {
        endMillis,
        startMillis,
        widgetId: widgetIds,
      },
      paramsSerializer: serializeParamsArray('widgetId'),
    },
  );
  return data;
}

export async function getAdvertisersByDayMetrics({
  endMillis,
  startMillis,
  campaignSids,
}: GetAdvertisersByDayWidgetMetricsArgs) {
  const { data } = await request.get<AdvertisersByDayWidgetResponse>(
    `${DASHBOARD_V1_METRICS_URL}/sponsor/by-day-campaign-metrics`,
    {
      params: {
        endMillis,
        startMillis,
        campaignId: campaignSids,
      },
      paramsSerializer: serializeParamsArray('campaignId'),
    },
  );

  return data;
}

export async function getPlatformMetrics({
  endMillis,
  startMillis,
  widgetIds,
}: GetPlatformByDayWidgetMetricsArgs) {
  const { data } = await request.get<GetPlatformByDayWidgetMetricsResponse>(
    `${DASHBOARD_V1_PUBLISHER_METRICS_URL}/by-platform-day-widget-metrics`,
    {
      params: {
        endMillis,
        startMillis,
        widgetId: widgetIds,
      },
      paramsSerializer: serializeParamsArray('widgetId'),
    },
  );

  return data;
}

export async function getUrlMetrics({
  endMillis,
  startMillis,
  widgetIds,
}: GetUrlWidgetMetricsArgs) {
  const { data } = await request.get<GetUrlWidgetMetricsResponse>(
    `${DASHBOARD_V1_PUBLISHER_METRICS_URL}/by-url-widget-metrics`,
    {
      params: {
        endMillis,
        startMillis,
        widgetId: widgetIds,
      },
      paramsSerializer: serializeParamsArray('widgetId'),
    },
  );

  return data;
}

export async function createAdCampaign(args: CreateAdCampaignArgs) {
  const { data } = await request.post<CreateAdCampaignResponse>(
    `${DASHBOARD_V1_SPONSOR_URL}/self-serve-sponsor`,
    args,
  );
  return data;
}

export async function getAdCampaignInfo() {
  const { data } = await request.get<GetAdCampaignResponse>(
    `${DASHBOARD_V1_SPONSOR_URL}/self-serve-sponsor`,
  );

  return data;
}

export async function createWidget({
  rssUrl,
  websiteUrl,
  displayVariantId,
  audioPlaybackMethod,
}: CreateWidgetArgs) {
  const { data } = await request.post<
    CreateWidgetResponse,
    AxiosResponse<CreateWidgetResponse>,
    CreateWidgetArgs
  >(DASHBOARD_V1_SELF_SERVE_PUBLISHER_URL, {
    rssUrl,
    websiteUrl,
    displayVariantId,
    audioPlaybackMethod,
  });
  return data;
}

export async function getSelfServeWidgets(selfServeId: number) {
  const { data } = await request.get<GetSelfServeWidgetsResponse>(
    `${DASHBOARD_V1_SELF_SERVE_PUBLISHER_URL}/${selfServeId}`,
  );
  return data;
}

export async function updateWidgetOptions(args: UpdateWidgetOptionsArgs) {
  const { widgetId, displayVariantId, audioPlaybackMethod } = args;
  const { data } = await request.put<UpdateWidgetStyleResponse>(
    `${DASHBOARD_V1_SELF_SERVE_PUBLISHER_URL}/widget/${widgetId}`,
    {
      displayVariantId,
      audioPlaybackMethod,
    },
  );

  return data;
}

export async function getEpisodeMetrics(
  { podcastId }: GetEpisodeMetricsArgs,
  opts?: GetEpisodeMetricsOpts,
): Promise<GetEpisodeMetricsResponse> {
  const { data } = await request.get<GetEpisodeMetricsResponse>(
    DASHBOARD_V1_EPISODE_METRICS_URL,
    {
      params: {
        podcastId,
        nextEpisodePubAtMillis: opts?.nextEpisodePubAtMillis,
      },
    },
  );
  return data;
}
