import { Button, Typography } from '@mui/material';
import { useNotification } from 'components/Notification';
import { usePodcastAspectRatio } from 'pages/ContentManagementPage/state';
import { useModalControls } from 'state/modals';
import { MAX_FILE_SIZE, MAX_SIZE_ERROR_MESSAGE } from './constants';

type ImageUploadProps = {
  episodeId: number;
  podcastId: number;
};

const ImageUpload: React.FC<ImageUploadProps> = ({ episodeId, podcastId }) => {
  const { showNotification } = useNotification();

  const { open } = useModalControls();

  const { data: widgetAspectRatio } = usePodcastAspectRatio(podcastId);

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      const fileList = e.currentTarget.files;
      if (!fileList) {
        return;
      }

      const file = fileList[0];
      if (file.size >= MAX_FILE_SIZE) {
        showNotification({
          message: MAX_SIZE_ERROR_MESSAGE,
          severity: 'error',
        });
        return;
      }
      if (widgetAspectRatio) {
        open({
          name: 'ImageCrop',
          params: {
            src: file,
            episodeId,
            widgetAspectRatio,
            cropAfterUpload: true,
          },
        });
      }
    } finally {
      e.target.value = '';
    }
  };

  return (
    <Button size="small" variant="text" component="label">
      <Typography variant="body1">REPLACE</Typography>
      <input
        hidden
        onChange={uploadFile}
        type="file"
        accept="image/x-png,image/jpeg, image/jpg"
      />
    </Button>
  );
};

export default ImageUpload;
