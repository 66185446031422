import {
  Card as MuiCard,
  CardContent,
  CardActionArea,
  CardActionAreaProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IMAGE_CARD_MAX_WIDTH } from 'utils/constants';

export const Content = styled(CardContent)`
  &.MuiCardContent-root {
    padding: 16px 24px 0;
  }
`;

export const ActionArea = styled(CardActionArea)`
  height: 350px;
` as React.ComponentType<CardActionAreaProps<'link'>>;

export const Card = styled(MuiCard)`
  max-width: ${IMAGE_CARD_MAX_WIDTH};
`;
