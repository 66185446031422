import { RoleName } from 'api/services';
import React from 'react';
import { useScopesContext } from 'state/scopes';
import { checkRequiredScopes } from './utils';

interface ScopeCheckContainerProps {
  requiredScopes?: RoleName[];
  fallback?: React.ReactNode;
  children?: React.ReactNode;
}

export default function ScopeCheckContainer(props: ScopeCheckContainerProps) {
  const { children, requiredScopes = [], fallback = null } = props;

  const { availableScopes } = useScopesContext();

  const canUseFeature = checkRequiredScopes(requiredScopes, availableScopes);

  return <>{canUseFeature ? children : fallback} </>;
}
