import DailyStatsTableCard from 'components/DailyStatsTableCard';
import { usePlatformBreakoutDashboard } from '../PlatformBreakoutDashboardContext';
import { columns, defaultSort } from './utils';

const PlatformBreakoutTableCard: React.FunctionComponent = () => {
  const { dailyStats } = usePlatformBreakoutDashboard();

  return (
    <DailyStatsTableCard
      columns={columns}
      dailyStats={dailyStats}
      defaultSort={defaultSort}
      exportArgs={{
        fileName: 'Platform_breakout_daily_stats',
      }}
      virtual
      footer
      reportType="DiscoWidgetPlatformBreakout"
    />
  );
};

export default PlatformBreakoutTableCard;
