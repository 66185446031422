import { getThemeColor } from '@sparemin/blockhead';
import styled from 'styled-components';
import { EpisodeInputContainer } from '../styles';

export const Button = styled(EpisodeInputContainer)`
  border-style: dashed;
  color: ${getThemeColor('actionPrimary')};
  justify-content: center;

  &:active:not([disabled]) {
    box-shadow: none;
  }
`;
