import { Typography } from '@mui/material';
import { AudioPlaybackMethod } from 'api/services';
import InfoCard from 'components/InfoCard';
import React from 'react';
import * as S from './styles';

interface DualPlaybackCardProps {
  onClick: (val: AudioPlaybackMethod) => void;
}

const DualPlaybackCard: React.FC<DualPlaybackCardProps> = ({ onClick }) => (
  <InfoCard>
    <InfoCard.ActionArea<AudioPlaybackMethod>
      cardType="headlinerWithOriginal"
      onClick={onClick}
    >
      <InfoCard.Image>
        <>
          <S.RSSIcon />
          <Typography variant="h3">+</Typography>
          <S.HeadlinerIcon />
        </>
      </InfoCard.Image>

      <InfoCard.Contents
        badge="Recommend"
        heading="Your host + Headliner"
        subheading={
          <>
            Simultaneously streams audio from your host and Headliner to ensure
            the best performance.
            <strong>
              Widget plays count as listens in your host dashboard.
            </strong>
          </>
        }
      />
    </InfoCard.ActionArea>
  </InfoCard>
);
export default DualPlaybackCard;
