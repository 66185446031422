import { CardContent } from '@mui/material';
import PodcastContentTable from 'components/PodcastContentTable';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useSocialPodcastsInfo from 'pages/headliner/HeadlinerVideosByRSS/useSocialPodcastsInfo';
import Simplebar from 'simplebar-react';
import PodcastsMobileList from './PodcastsMobileList';
import { SocialSharePodcasts } from './types';
import columns from './utils';

type VideosByPodcastTableProps = {
  onRowClick?: (val: SocialSharePodcasts) => void;
};

const VideosByPodcastTable: React.FC<VideosByPodcastTableProps> = ({
  onRowClick,
}) => {
  const query = useSocialPodcastsInfo();
  const data = query.data ?? [];
  const isMobile = useMobileBreakpoint('md');

  return isMobile ? (
    <PodcastsMobileList onClick={onRowClick} />
  ) : (
    <CardContent component={Simplebar}>
      <PodcastContentTable<SocialSharePodcasts>
        columns={columns}
        podcastData={data}
        isLoading={query.isLoading}
        tableLayout="fixed"
        onRowClick={onRowClick}
      />
    </CardContent>
  );
};
export default VideosByPodcastTable;
