import { PulseLoader } from '@sparemin/blockhead';
import { isNil } from 'lodash';
import { useCallback, useState } from 'react';
import { useCampaignCreation } from '../CampaignCreationContext';
import { useHasAdCampaigns } from '../state/useGetAdCampaignInfo';
import CreateNewCampaign from './CreateNewCampaign';
import PreviousCampaigns from './PreviousCampaigns';
import { IntroViewSection } from './types';

export interface IntroViewProps {
  onCreateNewCampaign: () => void;
  onRunAgain: () => void;
}

const IntroView: React.FC<IntroViewProps> = ({
  onCreateNewCampaign,
  onRunAgain,
}) => {
  const [section, setSection] = useState<IntroViewSection>();

  const { data: hasAdCampaigns, isLoading } = useHasAdCampaigns();
  const { dispatch } = useCampaignCreation();

  const handleCreateNewCampaign = useCallback((): void => {
    setSection('campaignCreation');
    dispatch({
      type: 'GO_TO_STEP',
      payload: {
        step: 'episode',
      },
    });
  }, [dispatch]);

  if (!section && !isLoading && !isNil(hasAdCampaigns)) {
    setSection(hasAdCampaigns ? 'previousCampaigns' : 'campaignCreation');
  }

  if (isLoading || !section) {
    return <PulseLoader />;
  }

  if (section === 'campaignCreation') {
    return <CreateNewCampaign onCreateNewCampaign={onCreateNewCampaign} />;
  }

  return (
    <PreviousCampaigns
      onCreateNewCampaign={handleCreateNewCampaign}
      onRunAgain={onRunAgain}
    />
  );
};

export default IntroView;
