import useColumnSum from 'hooks/useColumnSum';
import { PropsWithChildren } from 'react';
import { TableInstance } from 'react-table';

import NumericCell from './NumericCell';
import { NumericCellFormats } from './types';
import { formatNumericValue } from './utils';

type SummedNumericCellProps<TData extends object> = {
  columnName: string;
  tableData: PropsWithChildren<TableInstance<TData>>;
  type?: NumericCellFormats;
};

const SummedNumericCell = <TData extends object>(
  props: SummedNumericCellProps<TData>,
): React.ReactElement => {
  const { columnName, tableData, type } = props;
  const value = useColumnSum({ accessor: columnName, tableData });
  return <NumericCell value={formatNumericValue(value, type)} />;
};

export default SummedNumericCell;
