import { ReactElement } from 'react';
import { TableInstance } from 'react-table';
import DataTableBody, { DataTableBodyProps } from './DataTableBody';
import EmptyTableBody from './EmptyTableBody';
import SkeletonTableBody, { SkeletonTableBodyProps } from './SkeletonTableBody';
import VirtualTableBody, { VirtualTableBodyProps } from './VirtualTableBody';

type PickedSkeletonProps = Partial<
  Pick<SkeletonTableBodyProps, 'skeletonRows'>
>;
type PickedVirtualProps = Pick<
  VirtualTableBodyProps,
  'rowHeight' | 'scrollElement'
>;

export interface DataTableBodyContainerProps<TData extends object>
  extends PickedSkeletonProps,
    PickedVirtualProps,
    DataTableBodyProps<TData> {
  isLoading?: boolean;
  table: TableInstance<TData>;
  virtual?: boolean;
  footer?: boolean;
}

function DataTableBodyContainer<TData extends object>({
  hasMore,
  infinite,
  isLoading,
  onLoadMore,
  rowHeight,
  scrollElement,
  skeletonRows,
  table,
  virtual,
  onRowClick,
}: DataTableBodyContainerProps<TData>): ReactElement<any, any> | null {
  const { rows, columns } = table;

  if (isLoading && skeletonRows !== undefined) {
    return (
      <SkeletonTableBody
        skeletonRows={skeletonRows}
        columnWidth={columns.length}
      />
    );
  }

  if (rows.length === 0) {
    return <EmptyTableBody columnWidth={columns.length} />;
  }

  if (virtual) {
    return (
      <VirtualTableBody
        table={table}
        scrollElement={scrollElement}
        rowHeight={rowHeight}
      />
    );
  }

  return (
    <DataTableBody
      container={scrollElement}
      {...{ onRowClick, hasMore, infinite, onLoadMore, table }}
    />
  );
}

export default DataTableBodyContainer;
