import { Text } from '@sparemin/blockhead';
import * as S from './styles';

export interface IntroViewCardProps {
  title: string;
  srcSet: string;
  alt: string;
}

const IntroViewCard: React.FC<IntroViewCardProps> = ({
  title,
  srcSet,
  alt,
}) => (
  <S.IntroViewCard>
    <img srcSet={srcSet} alt={alt} height="65px" />

    <Text variant="p">{title}</Text>
  </S.IntroViewCard>
);

export default IntroViewCard;
