import { useMediaQuery, Stack, useTheme } from '@mui/material';
import { WidgetVariantId, AudioPlaybackMethod } from 'api/services';
import React, { useState } from 'react';
import { PlaybackSelector } from '../PlaybackModal';
import { StyleWidgetSelector } from '../StyleWidgetModal';
import { steps } from './constants';
import CreateWidgetModalFeed from './CreateWidgetModalFeed';
import * as S from './styles';
import { CreateWidgetStep } from './types';
import WidgetStepper from './WidgetStepper';

type CreateWidgetModalContentsProps = {};

const CreateWidgetModalContents: React.FC<
  CreateWidgetModalContentsProps
> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [createWidgetStep, setCreateWidgetStep] = useState<CreateWidgetStep>(
    steps[0],
  );
  const [styleVariant, setStyleVariant] = useState<
    WidgetVariantId | undefined
  >();
  const [playbackSource, setPlaybackSource] = useState<
    AudioPlaybackMethod | undefined
  >();

  const handleStyleSelect = (val: WidgetVariantId) => {
    setCreateWidgetStep(steps[1]);
    setStyleVariant(val);
  };

  const handlePlaybackSelect = (val: AudioPlaybackMethod) => {
    setPlaybackSource(val);
    setCreateWidgetStep(steps[2]);
  };

  return (
    <Stack height="575px" alignItems="center">
      <S.Container>
        <WidgetStepper activeStep={createWidgetStep} />
      </S.Container>
      {createWidgetStep === steps[0] && (
        <StyleWidgetSelector isMobile={isMobile} onClick={handleStyleSelect} />
      )}
      {createWidgetStep === steps[1] && (
        <PlaybackSelector onClick={handlePlaybackSelect} />
      )}
      {createWidgetStep === steps[2] && playbackSource && styleVariant && (
        <CreateWidgetModalFeed
          audioPlaybackMethod={playbackSource}
          widgetStyleVariant={styleVariant}
        />
      )}
    </Stack>
  );
};
export default CreateWidgetModalContents;
