import PodcastSearchModal from 'components/PodcastSearchModal';
import {
  NewEpisodeSegmentModal,
  ToggleAllClipsModal,
  ImageCropModal,
} from 'pages/ContentManagementPage';
import {
  CreateWidgetModal,
  WidgetMarketingModal,
  StyleWidgetModal,
  PlaybackModal,
} from 'pages/Widget';

export interface ModalProps {}

const Modals: React.FC<ModalProps> = () => (
  <>
    <ImageCropModal />
    <CreateWidgetModal />
    <WidgetMarketingModal />
    <ToggleAllClipsModal />
    <NewEpisodeSegmentModal />
    <StyleWidgetModal />
    <PlaybackModal />
    <PodcastSearchModal />
  </>
);

export default Modals;
