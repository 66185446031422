import {
  Button as MuiButton,
  Stack,
  Typography as MuiTypograpy,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Button = styled(MuiButton)`
  border-width: 2px;
  border-radius: 25px;
  height: 50px;
  padding: 0 26px;
`;

export const Body = styled(Stack)`
  padding: 28px 24px 0;
`;

export const Footer = styled(Stack)`
  padding: 0 40px 40px;
`;

export const Typography = styled(MuiTypograpy)`
  color: ${({ theme }) => theme.palette.text.dark};
`;
