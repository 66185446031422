import {
  getSocialShareStatsByPodcast,
  SocialShareStatsByPodcastResponse,
} from 'api/services';
import { useCallback } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { embedVideoQueries } from './queryKeys';
import { podcastSummarySocialShareStatsSelector } from './selectors';

type QueryKey = ReturnType<typeof embedVideoQueries.podcasts>;

export default function useGetSocialShareStatsByPodcast<
  TData = SocialShareStatsByPodcastResponse,
>(
  opts?: Omit<
    UseQueryOptions<
      SocialShareStatsByPodcastResponse,
      unknown,
      TData,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<SocialShareStatsByPodcastResponse, unknown, TData, QueryKey>(
    embedVideoQueries.podcasts(),
    () => getSocialShareStatsByPodcast(),
    opts,
  );
}

export function useGetPodcastSummaryStats(podcastId?: string) {
  const selector = useCallback(
    (d: SocialShareStatsByPodcastResponse) =>
      podcastSummarySocialShareStatsSelector(d, podcastId),
    [podcastId],
  );
  return useGetSocialShareStatsByPodcast({
    select: selector,
    enabled: !!podcastId,
  });
}
