import PageHeaderWithPodcastNavigation, {
  PageHeaderWithPodcastNavigationProps,
} from 'components/PageHeaderWithPodcastNavigation';
import DefaultSubtitle from 'pages/headliner/components/DefaultSubtitle';
import useSocialPodcastsInfo from 'pages/headliner/HeadlinerVideosByRSS/useSocialPodcastsInfo';
import { useGetSocialShareStatsByEpisode } from 'pages/headliner/state';
import { ALL_PODCASTS_TABLE_PAGE_HEADER } from './constants';

interface TablePageTitleProps
  extends Omit<
    PageHeaderWithPodcastNavigationProps,
    'podcastTitle' | 'episodeTitle' | 'defaultPageTitle' | 'defaultPageSubtitle'
  > {}

const TablePageTitle: React.FC<TablePageTitleProps> = ({
  podcastId,
  episodeId,
  ...props
}) => {
  const data = useSocialPodcastsInfo();

  const podcasts = data.isSuccess ? data.data : undefined;

  const podcastTitle = podcasts?.find(
    (podcast) => podcast.podcastId === podcastId,
  )?.title;

  const episodeData = useGetSocialShareStatsByEpisode({
    podcastId: String(podcastId),
  });

  const episodes = episodeData.isSuccess
    ? episodeData.data.episodes
    : undefined;

  const episodeTitle = episodes?.find(
    (episode) => episode.episodeId === episodeId,
  )?.title;

  return (
    <PageHeaderWithPodcastNavigation
      podcastTitle={podcastTitle}
      episodeTitle={episodeTitle}
      defaultPageTitle={ALL_PODCASTS_TABLE_PAGE_HEADER}
      defaultPageSubtitle={<DefaultSubtitle />}
      podcastId={podcastId}
      episodeId={episodeId}
      {...props}
    />
  );
};
export default TablePageTitle;
