export const STYLE_WIDGET_MODAL_TITLE = 'Select your widget style';

export const EPISODE_ART_HEADING = 'Episode art focused';

export const EPISODE_ART_SUBHEADING =
  'Great if you use a unique image for each episode.';

export const EPISODE_TITLE_HEADING = 'Episode title focused';

export const EPISODE_TITLE_SUBHEADING =
  'Recommended if each episode has the same cover image.';
