import discoWidget from 'assets/widgetImagery/imagery-widget.png';
import discoWidget2x from 'assets/widgetImagery/imagery-widget@2x.png';
import discoWidget3x from 'assets/widgetImagery/imagery-widget@3x.png';
import MobileFallback from 'components/MobileFallback';

type WidgetMobileFallbackProps = {};

const WidgetMobileFallback: React.FC<WidgetMobileFallbackProps> = () => {
  const srcSet = `${discoWidget} 1x, ${discoWidget2x} 2x, ${discoWidget3x} 3x`;
  return <MobileFallback heading="Disco" src={discoWidget} srcSet={srcSet} />;
};
export default WidgetMobileFallback;
