import { LinkProps } from '@sparemin/blockhead';
import * as S from './styles';

export type TextButtonProps = LinkProps & {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

const TextButton: React.FC<TextButtonProps> = ({
  startAdornment,
  endAdornment,
  children,
  ...props
}) => (
  <S.Root {...props}>
    {startAdornment && <S.StartAdornment>{startAdornment}</S.StartAdornment>}

    {children}

    {endAdornment && <S.EndAdornment>{endAdornment}</S.EndAdornment>}
  </S.Root>
);

export default TextButton;
