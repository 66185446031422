import { InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

export const WidgetIdFormControl = styled(FormControl)`
  width: 285px;
`;

export const SelectInputLabel = styled(InputLabel)`
  background-color: ${({ theme }) => theme.palette.common.white};
`;
