import React from 'react';
import * as S from './styles';
import { SocialPlatformIconProps } from './types';

const SocialPlatformIcon: React.FC<SocialPlatformIconProps> = ({
  children,
  backgroundColor,
}) => (
  <S.IconContainer backgroundColor={backgroundColor}>
    {children}
  </S.IconContainer>
);
export default SocialPlatformIcon;
