import { TextField } from '@mui/material';
import { useState } from 'react';
import { RequestStatus } from 'types';
import { getFormErrorMessage } from 'utils/form';
import { WEBSITE_URL_LABEL } from './constants';
import * as S from './styles';
import useCreateWidgetForm, {
  UseCreateWidgetFormConfig,
} from './useCreateWidgetForm';

export interface CreateWidgetFormProps
  extends Pick<
    UseCreateWidgetFormConfig,
    'onClose' | 'widgetStyleVariant' | 'audioPlaybackMethod'
  > {
  onRequestStatusChange: (val: RequestStatus) => void;
}

const CreateWidgetForm: React.FC<CreateWidgetFormProps> = ({
  onClose,
  widgetStyleVariant,
  onRequestStatusChange,
  audioPlaybackMethod,
}) => {
  const [disabled, setDisabled] = useState(false);

  const { onSubmit, errors, register, errorFromServer } = useCreateWidgetForm({
    onClose: () => {
      onClose?.();
    },
    widgetStyleVariant,
    audioPlaybackMethod,

    onSuccess: () => {
      onRequestStatusChange('success');
    },
    onErrorFromServer: () => {
      setDisabled(false);
      onRequestStatusChange('failure');
    },
    onSubmit: () => {
      setDisabled(true);
      onRequestStatusChange('request');
    },
  });
  const error = getFormErrorMessage(errors, errorFromServer);

  return (
    <S.Form onSubmit={onSubmit}>
      <TextField
        fullWidth
        id="feedUrl"
        label="Podcast RSS Feed"
        margin="normal"
        disabled={disabled}
        {...register('feedUrl')}
      />

      <TextField
        fullWidth
        id="websiteUrl"
        margin="normal"
        label={WEBSITE_URL_LABEL}
        disabled={disabled}
        {...register('websiteUrl')}
      />
      <S.ErrorText>{error}</S.ErrorText>
      <S.SubmitButton
        type="submit"
        disabled={disabled}
        fullWidth
        variant="contained"
      >
        Create Your Widget
      </S.SubmitButton>
    </S.Form>
  );
};

export default CreateWidgetForm;
