import { Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import {
  adjustDateToStartOfDayMillis,
  adjustInputUtcMillis,
  adjustTimestampMillisToInput,
} from 'utils/date';
import * as S from './styles';
import { PeriodArgs } from './types';
import {
  getDefaultPeriodArgs,
  getEndDateRange,
  getStartDateRange,
  getMinValidDate,
} from './utils';

interface StatsPeriodSelectorCustomPickerProps {
  currRange?: PeriodArgs;
  onClose: () => void;
  onConfirm: (startMillis: number, endMillis: number) => void;
  visible: boolean;
}

const DATE_PICKER_FORMAT = 'MM/DD/YYYY';

const defaultPeriodArgs = getDefaultPeriodArgs();

const StatsPeriodSelectorCustomPicker: React.FunctionComponent<
  StatsPeriodSelectorCustomPickerProps
> = (props) => {
  const { currRange, onClose, onConfirm, visible } = props;

  const [startDate, setStartDate] = useState(defaultPeriodArgs.startMillis);
  const [endDate, setEndDate] = useState(defaultPeriodArgs.endMillis);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (visible && currRange) {
      const { startMillis, endMillis } = currRange;
      setStartDate(adjustTimestampMillisToInput(startMillis));
      setEndDate(adjustTimestampMillisToInput(endMillis));
    }
  }, [currRange, visible]);

  const handlePickDate = useCallback(
    (key: 'startDate' | 'endDate') => (date: Dayjs | null) => {
      if (!date) {
        return;
      }
      if (key === 'startDate') {
        setStartDate(adjustDateToStartOfDayMillis(date));
      } else {
        setEndDate(adjustDateToStartOfDayMillis(date));
      }
    },
    [],
  );
  const handleConfirmDate = useCallback(() => {
    onConfirm(adjustInputUtcMillis(startDate), adjustInputUtcMillis(endDate));
  }, [endDate, onConfirm, startDate]);

  const startDateRange = getStartDateRange(endDate);
  const endDateRange = getEndDateRange(startDate);

  const handleCancel = () => {
    setStartDate(defaultPeriodArgs.startMillis);
    setEndDate(defaultPeriodArgs.endMillis);
    setError(false);
    onClose();
  };

  const handleClose = () => {
    if (
      startDate > endDate ||
      endDate > defaultPeriodArgs.endMillis ||
      startDate < getMinValidDate
    ) {
      setStartDate(defaultPeriodArgs.startMillis);
      setEndDate(defaultPeriodArgs.endMillis);
    }
    setError(false);
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <S.ModalComponent open={visible} onClose={handleClose}>
        <S.ModalBody>
          <Grid container>
            <S.ModalPickersContainer item xs={6}>
              <MobileDatePicker
                inputFormat={DATE_PICKER_FORMAT}
                label="Start date"
                minDate={startDateRange.min}
                maxDate={startDateRange.max}
                onChange={handlePickDate('startDate')}
                renderInput={(params) => <TextField {...params} />}
                value={startDate}
              />
            </S.ModalPickersContainer>
            <S.ModalPickersContainer item xs={6}>
              <MobileDatePicker
                inputFormat={DATE_PICKER_FORMAT}
                label="End date"
                minDate={endDateRange.min}
                maxDate={endDateRange.max}
                onChange={handlePickDate('endDate')}
                onError={(reason) => {
                  if (reason) setError(true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    helperText={error && 'Invalid date selection'}
                  />
                )}
                value={endDate}
              />
            </S.ModalPickersContainer>
          </Grid>
          <Grid container gap={2} justifyContent="flex-end">
            <Grid item>
              <Button onClick={handleCancel} variant="text">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleConfirmDate}
                variant="contained"
                disabled={
                  startDate > endDate ||
                  endDate > defaultPeriodArgs.endMillis ||
                  startDate < getMinValidDate
                }
              >
                Select
              </Button>
            </Grid>
          </Grid>
        </S.ModalBody>
      </S.ModalComponent>
    </LocalizationProvider>
  );
};

export default StatsPeriodSelectorCustomPicker;
