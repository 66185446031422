import { Platform } from 'api/services';
import { PeriodArgs } from 'components/StatsPeriodSelector';
import { SelectedWidgetIds } from 'components/WidgetIdSelector';
import React, { useContext, useMemo } from 'react';
import { usePlatformDailyStatsData } from '../state';
import { PlatformBreakoutDashboardContextType } from './types';

const PlatformBreakoutDashboardContext = React.createContext<
  PlatformBreakoutDashboardContextType | undefined
>(undefined);

export function usePlatformBreakoutDashboard() {
  const context = useContext(PlatformBreakoutDashboardContext);

  if (context === undefined) {
    throw new Error(
      'usePlatformBreakoutDashboard must be used within PlatformBreakoutDashboardProvider',
    );
  }

  return context;
}

interface PlatformBreakoutDashboardProviderProps {
  periodArgs: PeriodArgs;
  platforms: Platform[];
  widgetIds: SelectedWidgetIds;
}

const PlatformBreakoutDashboardProvider: React.FunctionComponent<
  PlatformBreakoutDashboardProviderProps
> = (props) => {
  const { children, periodArgs, platforms, widgetIds } = props;

  const {
    data: platformData = [],
    isError,
    isLoading,
  } = usePlatformDailyStatsData({
    endMillis: periodArgs.endMillis,
    startMillis: periodArgs.startMillis,
    widgetIds: widgetIds.includes('All') ? undefined : widgetIds,
  });
  const dailyStatsData = platformData?.filter((stat) =>
    platforms.includes(stat.platform),
  );

  return (
    <PlatformBreakoutDashboardContext.Provider
      value={useMemo(
        () => ({
          dailyStats: {
            data: dailyStatsData,
            error: isError,
            loading: isLoading,
          },
          periodArgs,
          platforms,
          widgetIds,
        }),
        [dailyStatsData, isError, isLoading, periodArgs, platforms, widgetIds],
      )}
    >
      {children}
    </PlatformBreakoutDashboardContext.Provider>
  );
};

export default PlatformBreakoutDashboardProvider;
