import { Button, Stack, Typography } from '@mui/material';
import { useModalControls } from 'state/modals';
import { AUDIO_CLIP_MODAL_TITLE } from './constants';
import EpisodeSegmentsTable from './EpisodeSegmentsTable';

type EpisodeSegmentsCardContentsProps = {
  episodeId: number;
};

const EpisodeSegmentsCardContents: React.FC<
  EpisodeSegmentsCardContentsProps
> = ({ episodeId }) => {
  const { open } = useModalControls();
  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Clips</Typography>
        <Button
          variant="outlined"
          size="medium"
          onClick={() =>
            open({
              name: 'NewEpisodeSegment',
              params: { episodeId },
            })
          }
        >
          {AUDIO_CLIP_MODAL_TITLE}
        </Button>
      </Stack>
      {episodeId && <EpisodeSegmentsTable episodeId={episodeId} />}
    </Stack>
  );
};
export default EpisodeSegmentsCardContents;
