import DailyStatsChartCardComponent from 'components/DailyStatsChartCard';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { usePlatformBreakoutDashboard } from '../PlatformBreakoutDashboardContext';
import { CHART_STATS_SELECTOR_OPTIONS } from './utils';

const PlatformBreakoutChartCard: React.FunctionComponent = () => {
  const {
    dailyStats: { data },
    platforms,
  } = usePlatformBreakoutDashboard();

  useTrackPageLoad({
    section: 'DiscoWidget',
    page: 'PlatformBreakout',
    state: 'Populated',
  });

  return (
    <DailyStatsChartCardComponent
      cardTitle="Platform Breakout Chart"
      initialSelectedSeries="ctr"
      seriesData={data}
      seriesFilter={{
        filters: platforms,
        targetKey: 'platform',
      }}
      showLegend
      showTotal={false}
      statOptions={CHART_STATS_SELECTOR_OPTIONS}
    />
  );
};

export default PlatformBreakoutChartCard;
