import PageHeaderWithPodcastNavigation from 'components/PageHeaderWithPodcastNavigation';
import { useClaimedPodcastTitle, usePodcast } from 'pages/Play/state';
import {
  OWN_PODCASTS_DEFAULT_TITLE,
  OWN_PODCASTS_DEFAULT_SUBTITLE,
} from './constants';

interface TableTitleProps {
  onBackClick?: () => void;
}

const TablePageTitle: React.FC<TableTitleProps> = ({ onBackClick }) => {
  const { podcastId } = usePodcast();

  const { data: podcastTitle } = useClaimedPodcastTitle(podcastId);

  return (
    <PageHeaderWithPodcastNavigation
      podcastTitle={podcastTitle}
      defaultPageTitle={OWN_PODCASTS_DEFAULT_TITLE}
      defaultPageSubtitle={OWN_PODCASTS_DEFAULT_SUBTITLE}
      podcastId={podcastId}
      onBackClick={onBackClick}
    />
  );
};
export default TablePageTitle;
