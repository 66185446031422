import {
  Typography,
  Stack,
  CardMedia,
  CardActions,
  Button,
} from '@mui/material';
import { Spacer } from '@sparemin/blockhead';
import Spinner from 'components/Spinner';
import {
  useDiscoImageUrls,
  useEpisodeDestinationUrlIsPlay,
  usePodcastAspectRatio,
} from 'pages/ContentManagementPage/state';
import { useModalControls } from 'state/modals';
import UpdateDestinationUrlForm from '../UpdateDestinationUrlForm';
import ImageUpload from './ImageUpload';
import * as S from './styles';

type ContentManagementPageImageCardProps = {
  podcastId: number;
  episodeId: number;
};

const ContentManagementPageImageCard: React.FC<
  ContentManagementPageImageCardProps
> = ({ episodeId, podcastId }) => {
  const { data: widgetAspectRatio } = usePodcastAspectRatio(podcastId);

  const { data, isLoading } = useDiscoImageUrls({
    internalEpisodeId: episodeId,
  });

  const { data: destinationIsPlay, isSuccess } =
    useEpisodeDestinationUrlIsPlay(episodeId);

  const { open } = useModalControls();

  const getImage = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (data?.currentThumbnailUrl) {
      return (
        <CardMedia
          component="img"
          sx={{ width: 450 }}
          image={data?.currentThumbnailUrl}
          alt="thumbnail for podcast episode"
          crossOrigin="anonymous"
        />
      );
    }
    return (
      <S.Container>
        <Typography variant="label">
          Episode Image Not Set
          <br />
          (will use cover art)
        </Typography>
      </S.Container>
    );
  };

  return (
    <S.ContentCard>
      <Spacer orientation="vertical" space={2}>
        <Stack spacing={2}>
          <Typography variant="h3">Thumbnail</Typography>
          {getImage()}

          <CardActions>
            {widgetAspectRatio && data?.currentThumbnailUrl && (
              <Button
                size="small"
                variant="text"
                onClick={() =>
                  open({
                    name: 'ImageCrop',
                    params: {
                      src: data?.originalImageUrl || '',
                      episodeId,
                      widgetAspectRatio,
                    },
                  })
                }
              >
                <Typography variant="body1">CROP</Typography>
              </Button>
            )}
            <ImageUpload episodeId={episodeId} podcastId={podcastId} />
          </CardActions>
        </Stack>
        {isSuccess && !destinationIsPlay && (
          <UpdateDestinationUrlForm episodeId={episodeId} />
        )}
      </Spacer>
    </S.ContentCard>
  );
};

export default ContentManagementPageImageCard;
