import episodeArt from 'assets/widgetEducationImagery/episodeArt/episode-art.png';
import episodeArt2x from 'assets/widgetEducationImagery/episodeArt/episode-art@2x.png';
import episodeArt3x from 'assets/widgetEducationImagery/episodeArt/episode-art@3x.png';
import episodeText from 'assets/widgetEducationImagery/episodeText/episode-text.png';
import episodeText2x from 'assets/widgetEducationImagery/episodeText/episode-text@2x.png';
import episodeText3x from 'assets/widgetEducationImagery/episodeText/episode-text@3x.png';
import { widgetVariantMap } from 'utils/widget';
import {
  EPISODE_ART_HEADING,
  EPISODE_ART_SUBHEADING,
  EPISODE_TITLE_HEADING,
  EPISODE_TITLE_SUBHEADING,
} from './constants';
import { WidgetStyleConfig } from './types';

export const widgetStyles: WidgetStyleConfig[] = [
  {
    src: episodeArt,
    srcSet: `${episodeArt} 1x, ${episodeArt2x} 2x, ${episodeArt3x} 3x`,
    alt: 'episode art focused widget',
    value: widgetVariantMap.episodeArtVariant,
    heading: EPISODE_ART_HEADING,
    subheading: EPISODE_ART_SUBHEADING,
  },
  {
    src: episodeText,
    srcSet: `${episodeText} 1x, ${episodeText2x} 2x, ${episodeText3x} 3x`,
    alt: 'episode text focused widget',
    value: widgetVariantMap.episodeTextVariant,
    heading: EPISODE_TITLE_HEADING,
    subheading: EPISODE_TITLE_SUBHEADING,
  },
];
