import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Stack, IconButton } from '@mui/material';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import React, { useRef } from 'react';
import { handleCopy } from 'utils/html';
import * as S from './styles';

type CodeBlockProps = {
  children: React.ReactNode;
  copyIcon?: React.ReactNode;
};

const CodeBlock: React.FC<CodeBlockProps> = ({
  children,
  copyIcon = false,
}) => {
  const copyRef = useRef<HTMLElement>(null);

  const isMobile = useMobileBreakpoint('lg');
  return (
    <S.CodeBlock>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <S.Pre isMobile={isMobile}>
          <code ref={copyRef}>{children}</code>
        </S.Pre>
        {copyIcon && (
          <IconButton
            onClick={() => {
              if (copyRef.current) handleCopy(copyRef.current);
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        )}
      </Stack>
    </S.CodeBlock>
  );
};
export default CodeBlock;
