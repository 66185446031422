import {
  AdvertisersByDayWidgetResponse,
  MyAdvertisers,
  Advertiser,
  Campaign,
} from 'api/services';
import flatMap from 'lodash/flatMap';
import flow from 'lodash/flow';
import map from 'lodash/map';
import partialRight from 'lodash/partialRight';
import { displayDate } from 'utils/date';

export function promoDailyStatsDataSelector(d: AdvertisersByDayWidgetResponse) {
  return d.data?.map((dailyStat) => ({
    date: displayDate(dailyStat.dayMillis),
    dateMillis: dailyStat.dayMillis,
    adPlayed: dailyStat.spResultPlayed,
    adClickThrough: dailyStat.spClickThrough,
    avgCpc: dailyStat.avgCpcMilliCent,
    spEngaged: dailyStat.spEngaged,
    total: dailyStat.totalCostMilliCent,
    unitLoad: dailyStat.unitLoad,
    unitInViewport: dailyStat.unitInViewport,
  }));
}

export function campaignIdsSelector(d: MyAdvertisers) {
  return flow(
    partialRight<Advertiser[], string, Campaign[]>(flatMap, 'campaigns'),
    partialRight<Campaign[], string, string[]>(map, 'campaignSid'),
  )(d.advertisers);
}

export function campaignsSelector(d: MyAdvertisers) {
  return flatMap(d.advertisers, 'campaigns');
}
