import { WidgetVariantId } from 'api/services';
import Spinner from 'components/Spinner';
import { DISCO_WIDGET_IFRAME_URL } from 'config/env';
import React from 'react';
import * as S from './styles';

type WidgetIframeProps = {
  widgetId: string;
  widgetVariantId?: WidgetVariantId;
  loading: boolean;
  onLoadingComplete: () => void;
};

const WidgetIframe: React.FC<WidgetIframeProps> = ({
  widgetId,
  widgetVariantId,
  loading,
  onLoadingComplete,
}) => {
  const widgetUrl = `${DISCO_WIDGET_IFRAME_URL}?widgetId=${widgetId}&variantId=${widgetVariantId}`;

  return (
    <S.Container>
      {loading ? <Spinner /> : null}
      <iframe
        key={widgetVariantId}
        frameBorder="0"
        height="400"
        width="100%"
        src={widgetUrl}
        onLoad={onLoadingComplete}
        title="Disco Demo"
      />
    </S.Container>
  );
};
export default WidgetIframe;
