import { Box, Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { DashboardContent, DashboardContentTitle } from 'pages/Dashboard';
import { useProfile } from 'state/user';
import {
  SUPPORT_EMAIL,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
} from 'utils/constants';
import AccountCard from './AccountCard';
import ChangePasswordForm from './ChangePasswordForm';
import * as S from './styles';

export interface AccountProps {}

const Account: React.FC<AccountProps> = () => {
  const profile = useProfile();
  useTrackPageLoad({ section: 'Account' });

  return (
    <DashboardContent
      maxWidth="md"
      title={<DashboardContentTitle>Account</DashboardContentTitle>}
    >
      <AccountCard title="Details">
        <Box>
          <Typography variant="h6">Email Address</Typography>
          <S.CardDescriptionText variant="body1">
            {profile.isLoading ? <Skeleton /> : profile.data?.email}
          </S.CardDescriptionText>
        </Box>
        <S.CardDescriptionDivider />
        <Box>
          <Typography variant="h6">Change Password</Typography>
          <S.CardDescriptionText variant="body1">
            Password must be at least 8 characters.
          </S.CardDescriptionText>
          <ChangePasswordForm />
        </Box>
        <S.CardDescriptionDivider />
        <Box>
          <Typography variant="h6">Contact</Typography>
          <S.CardDescriptionText variant="body1">
            {SUPPORT_EMAIL}
          </S.CardDescriptionText>
        </Box>
        <S.CardDescriptionDivider />
        <Stack direction="row" spacing={5}>
          <S.ExternalLink href={TERMS_OF_SERVICE}>
            <Typography variant="body1">Terms of Service</Typography>
          </S.ExternalLink>
          <S.ExternalLink href={PRIVACY_POLICY}>
            <Typography variant="body1"> Privacy</Typography>
          </S.ExternalLink>
        </Stack>
      </AccountCard>
    </DashboardContent>
  );
};

export default Account;
