import { SignUpCard } from '@sparemin/auth';

import useAuthSuccess from 'hooks/useAuthSuccess';

type RegisterCardProps = {};

const RegisterCard: React.FC<RegisterCardProps> = () => {
  const { onAuthSuccess } = useAuthSuccess('register');

  return <SignUpCard onAuthenticateSuccess={onAuthSuccess} />;
};
export default RegisterCard;
