import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { isFinite } from 'lodash';

dayjs.extend(duration);

/**
 * Simple formatter for displaying a timestamp
 * @param {number} millis = Number in milliseconds
 * @returns {string} Timestamp formatted to string
 */

export function formatTimeValue(millis: number): string {
  return dayjs.duration(millis).format('H:mm:ss');
}

export function millisToSec(value: number): number;
export function millisToSec(value: number | undefined): number | undefined;
export function millisToSec(value: any): number | undefined {
  if (!isFinite(value)) {
    return undefined;
  }

  return (value as number) / 1000;
}

export function secToMillis(value: number): number {
  return value * 1000;
}
