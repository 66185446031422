import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import EmptyState from 'components/EmptyState';
import { NO_DATA_HEADER, NO_DATA_SUBHEADER } from '../constants';
import * as S from '../styles';

interface EmptyTableBodyProps {
  columnWidth: number;
}
const EmptyTableBody: React.FC<EmptyTableBodyProps> = ({ columnWidth }) => (
  <TableBody>
    <TableRow>
      <S.NoDataTableCell colSpan={columnWidth}>
        <EmptyState header={NO_DATA_HEADER} subHeader={NO_DATA_SUBHEADER} />
      </S.NoDataTableCell>
    </TableRow>
  </TableBody>
);

export default EmptyTableBody;
