import * as S from './styles';

interface ImageProps {
  children: React.ReactNode;
}

const Image: React.FC<ImageProps> = ({ children }) => (
  <S.Root
    spacing={2}
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </S.Root>
);

export default Image;
