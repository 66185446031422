import { getThemeColor } from '@sparemin/blockhead';
import styled from 'styled-components';

export const EpisodeInputContainer = styled.div`
  align-items: center;
  border-color: ${getThemeColor('l4')};
  border-radius: 24px;
  border-width: 2px;
  display: flex;
  height: 93px;
  padding: 20px;
  width: 100%;

  &,
  &[data-hovered='true'] {
    background-color: inherit;
  }
`;
