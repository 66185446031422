import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

export const Link = styled(
  withAttrs('a', {
    target: '_blank',
    rel: 'noreferrer',
  }),
)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
