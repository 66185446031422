import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import useToggle from 'hooks/useToggle';
import React from 'react';

const PasswordField = React.forwardRef<any, TextFieldProps>(
  ({ label, ...props }, ref) => {
    const { value: visible, toggle: toggleVisibility } = useToggle(false);

    const handleVisibilityClick = () => toggleVisibility();

    return (
      <TextField
        label={label}
        margin="normal"
        ref={ref}
        fullWidth
        required
        type={visible ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleVisibilityClick}
                edge="end"
              >
                {visible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    );
  },
);

export default PasswordField;
