import theme from './theme';
import { ChartsTheme } from './types';

const chartsTheme: ChartsTheme = {
  lineChart: {
    area: {
      margin: { top: 5, right: 20, left: 10, bottom: 20 },
    },
    legend: {
      verticalAlign: 'top',
      height: 36,
    },
    line: {
      stroke: theme.palette.primary.main,
    },
    linePallete: [
      '#33cc33',
      '#6600cc',
      '#33cccc',
      '#ff9933',
      '#e6e600',
      '#e60073',
      '#99ccff',
      '#999966',
      '#003300',
      '#0000cc',
      '#999966',
    ],
    grid: {
      stroke: '#f5f5f5',
    },
    xAxis: {
      tick: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
      },
    },
    xLabel: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(12),
      offset: 5,
      position: 'bottom',
    },
    yAxis: {
      tick: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
      },
    },
    yLabel: {
      dx: -30,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(12),
      position: 'center',
    },
  },
};

export default chartsTheme;
