import { SeriesOption } from 'components/DailyStatsChartCard';
import { formatMiliCentsValue } from 'utils/table/currency';
import { ParsedAdvertisersDailyStatsData } from '../types';

export const CHART_STATS_SELECTOR_OPTIONS: SeriesOption<ParsedAdvertisersDailyStatsData>[] =
  [
    { label: 'Impressions', value: 'unitLoad' },
    { label: 'Viewable Impressions', value: 'unitInViewport' },
    {
      label: 'Promo Played',
      value: 'adPlayed',
    },
    {
      label: 'Promo Click Through',
      value: 'adClickThrough',
    },
    {
      label: 'Unique Engagement',
      value: 'spEngaged',
    },
    {
      allowDecimals: true,
      formatter: formatMiliCentsValue,
      label: 'Avg CPE',
      value: 'avgCpc',
    },
    {
      allowDecimals: true,
      formatter: formatMiliCentsValue,
      label: 'Total $',
      value: 'total',
    },
  ];
