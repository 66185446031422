import { Stack } from '@mui/material';
import PulseLoader from 'components/PulseLoader';
import React from 'react';

type LoadingRowProps = {};

const LoadingRow = React.forwardRef<HTMLDivElement, LoadingRowProps>(
  (__, ref) => (
    <Stack
      direction="row"
      ref={ref}
      justifyContent="center"
      alignContent="center"
      padding="40px 0"
    >
      <PulseLoader />
    </Stack>
  ),
);
export default LoadingRow;
