import dayjs from 'dayjs';

export const isInputComplete = (input: string, completeLength: number) =>
  input.length === completeLength;

export const timeCodeInMillis = (time: string) => {
  const [hours, minutes, seconds] = time.split(':');
  return dayjs
    .duration({
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: Number(seconds),
    })
    .asMilliseconds();
};
