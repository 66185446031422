import MultiSelect, { MultiSelectProps, Option } from 'components/MultiSelect';
import { difference } from 'lodash';
import { useMemo } from 'react';

export type TargetingSelectionMultiSelectProps<T extends string> = Omit<
  MultiSelectProps<T>,
  'getInputValue' | 'onChange'
> & {
  onChange: (val: T[]) => void;
};

const TargetingSelectionMultiSelect = <T extends string>(
  props: TargetingSelectionMultiSelectProps<T>,
) => {
  const { options, onChange, onClose, value, selectLabel } = props;

  const defaultValue = useMemo(() => options[0].itemValue, [options]);

  const handleChange = (newValues: T[]) => {
    const modified =
      difference(value, newValues)[0] ?? difference(newValues, value)[0];
    const checked = newValues.includes(modified);
    if (modified === defaultValue) {
      if (checked) {
        onChange([defaultValue]);
      } else onChange([]);
    } else onChange(newValues.filter((val) => val !== defaultValue));
  };

  const getInputValue = (selected: string[]) => {
    if (selected.length === 0) return 'None selected';

    const selectedOptions: Option<T>[] = options.filter((option: Option<T>) =>
      selected.includes(option.itemValue),
    );

    return selectedOptions
      .map((opt) => opt.primaryItemLabel ?? opt.itemValue)
      .join(', ');
  };

  return (
    <MultiSelect
      getInputValue={getInputValue}
      options={options}
      onChange={handleChange}
      onClose={onClose}
      value={value}
      selectLabel={selectLabel}
    />
  );
};
export default TargetingSelectionMultiSelect;
