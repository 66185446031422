import * as S from './styles';

interface TextCellProps {
  value: string;
}

const TextCell: React.FC<TextCellProps> = ({ value }) => (
  <S.TextCell>{value}</S.TextCell>
);

export default TextCell;
