import { round } from 'lodash';

export function millicentsToDollars(valueInMillicents: number) {
  return round(valueInMillicents / 100000, 2);
}
export function dollarsToString(valueInDollars: number) {
  return valueInDollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}
