import { Stack, Typography, useTheme } from '@mui/material';
import { PlayCircle } from '@sparemin/blockhead';
import { EpisodeMetrics } from 'api';
import TableCells from 'components/TableCells';
import React from 'react';

export interface MobileDetailCardContentsProps extends EpisodeMetrics {}

const MobileDetailCardContents: React.FC<MobileDetailCardContentsProps> = ({
  playCount,
  title,
  thumbnailUrl,
  publishedAtMillis,
}) => {
  const theme = useTheme();
  return (
    <Stack>
      <TableCells.PodcastEpisodeCell
        episodeTitle={title}
        thumbnailUrl={thumbnailUrl}
        pubDateInMillis={publishedAtMillis}
      />
      <Stack direction="row" spacing={1} alignItems="center" marginTop="24px">
        <div>
          <PlayCircle
            color={theme.palette.grey[700]}
            height="16px"
            width="16px"
          />
        </div>
        <Typography variant="h2">{`${playCount} plays`}</Typography>
      </Stack>
    </Stack>
  );
};
export default MobileDetailCardContents;
