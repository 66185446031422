import { difference } from 'lodash';
import { useMemo } from 'react';
import MultiSelect, { MultiSelectProps, Option } from '../MultiSelect';
import { ALL_KEY, ALL_LABEL, NONE_LABEL } from './constants';

export type SelectAllMultiSelectProps<T extends string> = Omit<
  MultiSelectProps<T>,
  'getInputValue' | 'onChange'
> & {
  onChange: (val: T[]) => void;
};

const SelectAllMultiSelect = <T extends string>(
  props: SelectAllMultiSelectProps<T>,
) => {
  const { options, onChange, onClose, value, selectLabel } = props;
  const allValues = useMemo(
    () => options.map((opt) => opt.itemValue),
    [options],
  );

  const selectAllValue: Array<T | typeof ALL_KEY> =
    value.length === options.length ? [ALL_KEY, ...value] : value;

  const selectAllOptions: Option<T | typeof ALL_KEY>[] = [
    {
      itemValue: ALL_KEY,
      primaryItemLabel: ALL_LABEL,
      indeterminate: options.length !== value.length,
    },
    ...options,
  ];

  const handleOnChange = (newValues: Array<T | typeof ALL_KEY>) => {
    const modified =
      difference(selectAllValue, newValues)[0] ??
      difference(newValues, selectAllValue)[0];
    const checked = newValues.includes(modified);

    if (modified === ALL_KEY) {
      if (checked) {
        onChange(allValues);
      } else {
        onChange([]);
      }
    } else {
      onChange(newValues.filter((val) => val !== ALL_KEY) as Array<T>);
    }
  };

  const getInputValue = (selected: string[]) => {
    if (selected.length === options.length) return ALL_LABEL;
    if (selected.length === 0) return NONE_LABEL;
    const selectedOptions: Option<T>[] = options.filter((option) =>
      selected.includes(option.itemValue),
    );

    return selectedOptions
      .map((opt) => opt.primaryItemLabel ?? opt.itemValue)
      .join(', ');
  };

  return (
    <MultiSelect<T | typeof ALL_KEY>
      getInputValue={getInputValue}
      options={selectAllOptions}
      onChange={handleOnChange}
      onClose={onClose}
      value={selectAllValue}
      selectLabel={selectLabel}
    />
  );
};

export default SelectAllMultiSelect;
