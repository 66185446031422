import React from 'react';
import {
  HEADLINER_STUDIO_VIDEO_TABLES_SUBHEAD,
  LEARNING_CENTER_LINK,
} from './constants';
import * as S from './styles';

type DefaultSubtitleProps = {};

const DefaultSubtitle: React.FC<DefaultSubtitleProps> = () => (
  <>
    {`${HEADLINER_STUDIO_VIDEO_TABLES_SUBHEAD} (`}
    <S.Link href={LEARNING_CENTER_LINK}>learn more about this data</S.Link>
    ).
  </>
);
export default DefaultSubtitle;
