import Skeleton from '@mui/material/Skeleton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import range from 'lodash/range';

export interface SkeletonTableBodyProps {
  skeletonRows: number;
  columnWidth: number;
}
const SkeletonTableBody: React.FC<SkeletonTableBodyProps> = ({
  skeletonRows,
  columnWidth,
}) => (
  <TableBody>
    {range(skeletonRows).map((i) => (
      <TableRow key={i}>
        <TableCell colSpan={columnWidth}>
          <Skeleton variant="rectangular" />
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

export default SkeletonTableBody;
