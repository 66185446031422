import { Stack } from '@mui/material';
import { WidgetVariantId } from 'api/services';
import { CardImageMediaProps } from 'components/CardImageMedia';
import React from 'react';
import * as S from './styles';

interface WidgetStyleExampleProps extends Omit<CardImageMediaProps, 'onClick'> {
  heading: string;
  subheading: string;
  onClick: (val: WidgetVariantId) => void;
  value: WidgetVariantId;
}

const WidgetStyleExample: React.FC<WidgetStyleExampleProps> = ({
  heading,
  subheading,
  onClick,
  value,
  ...imageProps
}) => (
  <Stack spacing={3}>
    <S.ButtonCard onClick={() => onClick(value)}>
      <S.Image {...imageProps} />
    </S.ButtonCard>
    <Stack spacing={2}>
      <S.Heading variant="h1">{heading}</S.Heading>
      <S.Subheading>{subheading}</S.Subheading>
    </Stack>
  </Stack>
);
export default WidgetStyleExample;
