import { Stack, Typography } from '@mui/material';
import EmptyState from 'components/EmptyState';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { usePodcast } from 'pages/Play/state/PodcastContext';
import React from 'react';
import FollowersGradientIcon from './FollowersGradientIcon';

import * as S from './styles';
import { createPodcastPageRedirectLink } from './utils';

type EmptyStateProps = {};

const FollowersEmptyState: React.FC<EmptyStateProps> = () => {
  const { podcastId } = usePodcast();

  useTrackPageLoad({
    section: 'Play',
    page: 'FollowersPage',
    state: 'Empty',
  });

  return (
    <Stack justifyContent="center" alignItems="center">
      <S.Container>
        <EmptyState
          header="No followers yet"
          subHeader="Get the word out about your podcast to gain your first followers by sharing a link to your podcast page."
          icon={<FollowersGradientIcon />}
          cta={
            <S.RoundedButton href={createPodcastPageRedirectLink(podcastId)}>
              <Typography variant="button">visit your podcast page</Typography>
            </S.RoundedButton>
          }
        />
      </S.Container>
    </Stack>
  );
};

export default FollowersEmptyState;
