import { request } from 'api/http';
import { SOCIAL_SHARE_STATS_URL } from './constants';
import {
  SocialShareStatsByEpisodeResponse,
  SocialShareStatsByPodcastResponse,
  GetRequestStatusArgs,
  GetRequestStatusResponse,
  GetSocialStatsByEpisodeArgs,
  SocialShareStatsResponse,
  SocialShareStatsArgs,
} from './types';

export async function getSocialShareStats(args?: SocialShareStatsArgs) {
  const { data } = await request.get<SocialShareStatsResponse>(
    SOCIAL_SHARE_STATS_URL,
    {
      params: {
        episodeId: args?.episodeId,
      },
    },
  );
  return data;
}

export async function getSocialShareStatsByPodcast() {
  const { data } = await request.get<SocialShareStatsByPodcastResponse>(
    `${SOCIAL_SHARE_STATS_URL}/podcasts`,
  );

  return data;
}

export async function getSocialShareStatsByEpisode(
  args: GetSocialStatsByEpisodeArgs,
) {
  const { data } = await request.get<SocialShareStatsByEpisodeResponse>(
    `${SOCIAL_SHARE_STATS_URL}/podcasts/${args.podcastId}`,
  );

  return data;
}

export async function getSocialShareStatsRequestInfo(
  args: GetRequestStatusArgs,
) {
  const { data } = await request.get<GetRequestStatusResponse>(
    `${SOCIAL_SHARE_STATS_URL}/request/${args.requestId}`,
  );

  return data;
}

export async function requestSocialShareStats() {
  const { data } = await request.post<GetRequestStatusResponse>(
    `${SOCIAL_SHARE_STATS_URL}/request`,
  );

  return data;
}
