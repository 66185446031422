import dayjs from 'dayjs';

import { formatTimeValue } from 'utils/time';

const getFriendlyTimeValue = (val: number) =>
  dayjs.duration(val).format('H:mm:ss');

export const AUDIO_CLIP_MODAL_SUBTITLE =
  'You can select up to two minutes from any part of the episode';

export const DEFAULT_CLIP_START = '0:00:00';

export const DEFAULT_CLIP_END = '0:01:00';

export const TIMESTAMP_FORMAT = '#:##:##';

export const MAX_CLIP_DURATION_MILLIS: number = 120000;

export const INVALID_CLIP_ERROR = 'Invalid clip duration';

export const CLIP_TOO_LONG_ERROR = `Clip cannot exceed ${getFriendlyTimeValue(
  MAX_CLIP_DURATION_MILLIS,
)}`;

export const SEGMENT_ADDED_SUCCESS = 'Segment added!';

export const SEGMENT_ADDED_ERROR = 'Error adding segment';

export const getClipOutsideEpisodeError = (
  episodeLengthInMilliseconds: number,
) =>
  `Clip exceeds episode length. Episode length is ${formatTimeValue(
    episodeLengthInMilliseconds,
  )}`;
