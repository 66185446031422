import Menu from '@mui/icons-material/Menu';
import { ReactComponent as HeadyIcon } from 'assets/heady-gradient.svg';
import AvatarMenu from './AvatarMenu';
import * as S from './styles';
import { MenuOpen, Mobile } from './types';

export interface HeaderProps extends MenuOpen, Mobile {
  onToggleMenu: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isMenuOpen,
  isMobile,
  onToggleMenu,
}) => (
  <S.HeaderAppBar {...{ isMenuOpen, isMobile }} position="absolute">
    <S.HeaderToolbar>
      <S.ToolbarLeft {...{ isMenuOpen, isMobile }}>
        <S.CondensedLogo alt="logo">
          <HeadyIcon />
        </S.CondensedLogo>
        <S.MenuButton
          aria-label="open drawer"
          color="inherit"
          edge="start"
          onClick={onToggleMenu}
        >
          <Menu />
        </S.MenuButton>
      </S.ToolbarLeft>
      <S.ToolbarRight>
        <AvatarMenu />
      </S.ToolbarRight>
    </S.HeaderToolbar>
  </S.HeaderAppBar>
);

export default Header;
