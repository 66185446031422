import { updateEpisodeSegment, SegmentUpdateArgs } from 'api/services';
import { useMutation, UseMutationOptions } from 'react-query';

export default function useUpdateEpisodeSegments<TContext = unknown>(
  config: Omit<
    UseMutationOptions<void, unknown, SegmentUpdateArgs, TContext>,
    'mutationFn'
  >,
) {
  return useMutation<void, unknown, SegmentUpdateArgs, TContext>(
    (args) => updateEpisodeSegment(args),
    config,
  );
}
