import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React from 'react';
import * as S from './styles';
import useToggleAllClipsForm from './useToggleAllClipsForm';

export type ToggleAllClipsFormProps = {
  episodeId: number;
  onClose: () => void;
};

const ToggleAllClipsForm: React.FC<ToggleAllClipsFormProps> = ({
  episodeId,
  onClose,
}) => {
  const { onSubmit, register } = useToggleAllClipsForm(episodeId, onClose);

  return (
    <Box component="form" onSubmit={onSubmit}>
      <S.Background>
        <FormControlLabel
          control={<Switch defaultChecked {...register('showInWidget')} />}
          label="Show in widget"
          labelPlacement="start"
        />
      </S.Background>
      <Stack justifyContent="flex-end" direction="row" spacing={2}>
        <Button
          size="small"
          onClick={() => {
            onClose();
          }}
        >
          <Typography variant="body1">CANCEL</Typography>
        </Button>
        <Button size="small" type="submit" onClick={onSubmit}>
          <Typography variant="body1">SAVE</Typography>
        </Button>
      </Stack>
    </Box>
  );
};
export default ToggleAllClipsForm;
