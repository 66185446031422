import {
  createAdCampaign,
  CreateAdCampaignArgs,
  CreateAdCampaignResponse,
} from 'api/services';
import { useMutation, UseMutationOptions } from 'react-query';

export default function useCreateAdCampaign(
  config?: Omit<
    UseMutationOptions<
      CreateAdCampaignResponse,
      unknown,
      CreateAdCampaignArgs,
      unknown
    >,
    'mutationFn'
  >,
) {
  return useMutation<CreateAdCampaignResponse, unknown, CreateAdCampaignArgs>(
    (args) => createAdCampaign(args),
    config,
  );
}
