import { SocialShareStatsByEpisodeResponse } from 'api/services';
import { useGetSocialShareStatsByEpisode } from 'pages/headliner/state';
import { SocialSharePodcastEpisode } from './types';

export default function useSocialEpisodesInfo(podcastId: string) {
  return useGetSocialShareStatsByEpisode<SocialSharePodcastEpisode[]>(
    { podcastId },
    {
      select: (data: SocialShareStatsByEpisodeResponse) =>
        data.episodes.map((episode) => ({
          episodeId: episode.episodeId,
          episodeTitle: episode.title ?? 'unknown',
          thumbnailUrl: episode.thumbnailUrl ?? '',
          statistics: { ...episode.statistics },
        })),
    },
  );
}
