import discoAds from 'assets/adsImagery/imagery-ads.png';
import discoAds2x from 'assets/adsImagery/imagery-ads@2x.png';
import discoAds3x from 'assets/adsImagery/imagery-ads@3x.png';
import ImageCardWithCenteredContents from 'components/ImageCardWithCenteredContents';
import { DashboardContent } from 'pages/Dashboard';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import CampaignCreationProvider, {
  useCampaignCreationState,
} from './CampaignCreationContext';
import CampaignCreationView from './CampaignCreationView';
import ConfirmationView from './ConfirmationView';
import IntroView from './IntroView';
import ReviewView from './ReviewView';
import { adCampaignsQueries } from './state/queryKeys';
import { AdCampaignContentView } from './types';
import useCheckCreateAdStatus from './useCheckCreateAdStatus';
import useTrackAdCampaignPage from './useTrackAdCampaignPage';

export interface ADCampaignPageProps {}

const ADCampaignPage: React.FC<ADCampaignPageProps> = () => {
  const [view, setView] = useState<AdCampaignContentView>('intro');

  const queryClient = useQueryClient();
  const [state, dispatch] = useCampaignCreationState();

  useCheckCreateAdStatus({
    onSuccess: () => {
      setView('confirmation');
      queryClient.invalidateQueries(adCampaignsQueries.adCampaignInfo());
      dispatch({
        type: 'RESET_VALUES',
      });
    },
  });
  useTrackAdCampaignPage();

  const srcSet = `${discoAds} 1x, ${discoAds2x} 2x, ${discoAds3x} 3x`;

  const createHandleGoToView = useCallback(
    (nextView: AdCampaignContentView) => () => {
      setView(nextView);
    },
    [],
  );

  const handleCancel = useCallback((): void => {
    setView('intro');
    dispatch({
      type: 'RESET_VALUES',
    });
    dispatch({
      type: 'GO_TO_STEP',
      payload: {
        step: 'episode',
      },
    });
  }, [dispatch]);

  return (
    <DashboardContent maxWidth="md">
      <ImageCardWithCenteredContents src={discoAds} srcSet={srcSet}>
        <CampaignCreationProvider {...{ state, dispatch }}>
          {view === 'intro' && (
            <IntroView
              onCreateNewCampaign={createHandleGoToView('campaign-creation')}
              onRunAgain={createHandleGoToView('review')}
            />
          )}

          {view === 'campaign-creation' && (
            <CampaignCreationView
              onCancel={handleCancel}
              onFinishCreation={createHandleGoToView('review')}
            />
          )}

          {view === 'review' && (
            <ReviewView
              onGoBack={createHandleGoToView('campaign-creation')}
              onCancel={handleCancel}
            />
          )}

          {view === 'confirmation' && (
            <ConfirmationView
              onCreateAnotherCampaign={createHandleGoToView(
                'campaign-creation',
              )}
              onCancel={handleCancel}
            />
          )}
        </CampaignCreationProvider>
      </ImageCardWithCenteredContents>
    </DashboardContent>
  );
};

export default ADCampaignPage;
