import { useChartTheme } from 'components/Theme';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import {
  BaseSeriesData,
  ChartData,
  FilterKey,
  SeriesFilter,
  SeriesOption,
} from './types';
import {
  getSelectedStatOption,
  getYAxisFormatter,
  getYAxisLabel,
  shouldAllowDecimals,
} from './utils';

interface DailyStatsChartProps<
  TData extends BaseSeriesData,
  TFilterKey extends FilterKey<TData>,
> {
  selectedAxisData: ChartData[];
  selectedStat: keyof TData;
  seriesFilter?: SeriesFilter<TData, TFilterKey>;
  showLegend?: boolean;
  showTotal?: boolean;
  statOptions: SeriesOption<TData>[];
}

const DailyStatsChart = <
  TData extends BaseSeriesData,
  TFilterKey extends FilterKey<TData>,
>(
  props: DailyStatsChartProps<TData, TFilterKey>,
) => {
  const {
    selectedAxisData,
    selectedStat,
    seriesFilter,
    showLegend,
    showTotal,
    statOptions,
  } = props;

  const { lineChart } = useChartTheme();

  const selectedStatOption = getSelectedStatOption(selectedStat, statOptions);
  const allowDecimals = shouldAllowDecimals(selectedStatOption);
  const yAxisLabel = getYAxisLabel(selectedStatOption);

  return (
    <ResponsiveContainer height={400}>
      <LineChart {...lineChart.chartArea} data={selectedAxisData}>
        <XAxis {...lineChart.xAxis} dataKey="date">
          <Label {...lineChart.xLabel} value="Date" />
        </XAxis>
        <YAxis
          {...lineChart.yAxis}
          id="0"
          allowDecimals={allowDecimals}
          tickFormatter={getYAxisFormatter(selectedStatOption)}
        >
          <Label {...lineChart.yLabel} value={yAxisLabel} angle={-90} />
        </YAxis>
        <CartesianGrid {...lineChart.grid} />
        {showTotal && (
          <Line
            {...lineChart.line}
            type="monotone"
            dataKey="total"
            dot={false}
            yAxisId={0}
          />
        )}
        {seriesFilter?.filters.map((filterKey, index) => (
          <Line
            {...lineChart.line}
            stroke={lineChart.linePallete[index]}
            key={filterKey}
            type="monotone"
            dataKey={filterKey}
            dot={false}
            yAxisId={0}
          />
        ))}
        {showLegend && <Legend {...lineChart.legend} />}
        <Tooltip<number, string>
          itemSorter={(item) => (item?.value ?? 0) * -1}
          formatter={getYAxisFormatter(selectedStatOption)}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DailyStatsChart;
