import { useMediaQuery, useTheme } from '@mui/material';
import DialogBox from 'components/DialogBox';
import CreateWidgetModalContents from './CreateWidgetModalContents';

interface CreateWidgetModalProps {}
const CreateWidgetModal: React.FC<CreateWidgetModalProps> = () => {
  const name = 'CreateWidget';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <DialogBox name={name} maxWidth="lg" fullWidth fullScreen={isMobile}>
      <CreateWidgetModalContents />
    </DialogBox>
  );
};
export default CreateWidgetModal;
