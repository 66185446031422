import { ListItem, ListItemText, Grid, Skeleton } from '@mui/material';
import { WidgetVariantId } from 'api/services';
import { Fragment } from 'react';
import { useModalControls } from 'state/modals';
import { useGetOptionsForSelfServeWidgets } from 'state/user';
import { customizeWidgetPlayback, customizeWidgetVariant } from './constants';
import * as S from './styles';

type OptionsListProps = {
  widgetId: string;
  onVariantUpdate: (val: WidgetVariantId) => void;
};

const OptionsList: React.FC<OptionsListProps> = ({
  widgetId,
  onVariantUpdate,
}) => {
  const data = useGetOptionsForSelfServeWidgets();
  const displayStyleVariant = data?.data?.displayStyleVariant;
  const audioPlaybackMethod = data?.data?.audioPlaybackMethod;

  const { open } = useModalControls();
  const handleEditStyleClick = () => {
    open({ name: 'StyleWidget', params: { widgetId, onVariantUpdate } });
  };

  const handleEditPlaybackClick = () => {
    open({ name: 'Playback', params: { widgetId } });
  };

  const styleOptions = [
    {
      type: 'style',
      icon: <S.StyleIcon />,
      action: handleEditStyleClick,
      value: displayStyleVariant
        ? customizeWidgetVariant[displayStyleVariant]
        : undefined,
    },
    {
      type: 'playback',
      icon: <S.AudioIcon />,
      action: handleEditPlaybackClick,
      value: audioPlaybackMethod
        ? customizeWidgetPlayback[audioPlaybackMethod]
        : undefined,
    },
  ];

  const lastItemIndex = styleOptions.length - 1;

  return (
    <S.List>
      {styleOptions.map((option, i) => (
        <Fragment key={option.type}>
          <ListItem disablePadding>
            <S.Button onClick={option.action}>
              <Grid
                container
                direction="row"
                justifyContent="spaceBetween"
                flexWrap="nowrap"
              >
                <Grid item container direction="row">
                  <Grid item>
                    <S.Badge>{option.icon}</S.Badge>
                  </Grid>
                  <Grid item paddingLeft="16px">
                    {displayStyleVariant && audioPlaybackMethod ? (
                      <ListItemText
                        primary={<S.Label>{option.type}</S.Label>}
                        secondary={
                          <S.UserSelectionText>
                            {option.value}
                          </S.UserSelectionText>
                        }
                      />
                    ) : (
                      <Skeleton variant="rectangular" />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <S.Edit />
                    </Grid>
                    <Grid item>
                      <S.EditText>edit</S.EditText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </S.Button>
          </ListItem>
          {i !== lastItemIndex && (
            <S.Divider orientation="horizontal" variant="fullWidth" />
          )}
        </Fragment>
      ))}
    </S.List>
  );
};
export default OptionsList;
