import Spinner from 'components/Spinner';
import MakeEmptyState from '../MakeEmptyState';
import { useCheckSocialShareStatistics } from '../state';
import { useFetchNewSocialStats } from '../useFetchNewSocialStats';
import {
  VIDEOS_EMPTY_STATE_DESCRIPTION,
  VIDEOS_EMPTY_STATE_TITLE,
} from './constants';
import TableContainer from './TableContainer';

type HeadlinerVideosProps = {};

const HeadlinerVideos: React.FC<HeadlinerVideosProps> = () => {
  const { requestStatus } = useFetchNewSocialStats();

  const { data, isSuccess } = useCheckSocialShareStatistics(requestStatus);

  if (data === undefined) return <Spinner />;

  if (isSuccess && data.length > 0) {
    return <TableContainer />;
  }

  return (
    <MakeEmptyState
      title={VIDEOS_EMPTY_STATE_TITLE}
      description={VIDEOS_EMPTY_STATE_DESCRIPTION}
      cta="return to your videos"
    />
  );
};
export default HeadlinerVideos;
