import {
  requestSocialShareStats,
  GetRequestStatusResponse,
} from 'api/services';
import { useMutation, UseMutationOptions } from 'react-query';

export default function useRequestSocialShareStats(
  config?: Omit<
    UseMutationOptions<GetRequestStatusResponse, unknown, unknown>,
    'mutationFn'
  >,
) {
  return useMutation<GetRequestStatusResponse, unknown>(
    () => requestSocialShareStats(),
    config,
  );
}
