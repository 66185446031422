import { isFinite } from 'lodash';
import { useEffect } from 'react';
import { TIMEOUT_FREQUENCY } from 'utils/constants';
import {
  useGetSocialShareStatsRequestStatus,
  useRequestSocialShareStats,
} from './state';

export const useFetchNewSocialStats = () => {
  const { data, mutate } = useRequestSocialShareStats();

  useEffect(() => mutate(), [mutate]);

  // if the post request to fetch new social data isn't resolved, poll the request status endpoint until it is resolved.
  const checkRequestStatus = useGetSocialShareStatsRequestStatus(
    { requestId: data?.requestId },
    {
      enabled: isFinite(data?.requestId) && !data?.isResolved,
      refetchInterval: TIMEOUT_FREQUENCY,
    },
  );

  return { requestStatus: checkRequestStatus.data?.isResolved };
};
