import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(CardActionArea)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.25)
    ),
    linear-gradient(117deg, #5dbefc, #dd92fe);

  .MuiCardActionArea-focusHighlight {
    background: transparent;
  }
`;
