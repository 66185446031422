import { styled } from '@mui/material/styles';
import { HeadlinerGradientSquare, RSSSquare } from '@sparemin/blockhead';

export const HeadlinerIcon = styled(HeadlinerGradientSquare)`
  width: 70px;
  height: 70px;
`;

export const RSSIcon = styled(RSSSquare)`
  width: 70px;
  height: 70px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
