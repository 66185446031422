import { PODCAST_SERVICE_URL } from 'config';

export const PODCAST_V1_URL = `${PODCAST_SERVICE_URL}/api/v1/podcast`;

export const PODCAST_V1_ALL_PODCAST_URL = `${PODCAST_V1_URL}/podcasts`;

export const PODCAST_V1_PODCAST_FEED_URL = `${PODCAST_V1_ALL_PODCAST_URL}/feed`;

export const PODCAST_V1_PODCAST_EPISODE_URL = `${PODCAST_V1_URL}/episode`;

export const PODCAST_V1_PODCAST_FOLLOWS_URL = `${PODCAST_V1_URL}/podcasts/follows`;

export const PODCAST_V1_DISCO_EPISODE_URL = `${PODCAST_V1_URL}/disco-episode`;
