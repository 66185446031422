import CardImageMedia, { CardImageMediaProps } from 'components/CardImageMedia';
import * as S from './styles';

interface ImageCardLinkProps extends CardImageMediaProps {
  href: string;
  children?: React.ReactNode;
}

const ImageCardLink: React.FC<ImageCardLinkProps> = ({
  href,
  children,
  ...props
}) => (
  <S.Card>
    <S.ActionArea href={href}>
      <CardImageMedia {...props} />
      <S.Content>{children}</S.Content>
    </S.ActionArea>
  </S.Card>
);
export default ImageCardLink;
