import { AudioPlaybackMethod } from 'api/services';
import { useNotification } from 'components/Notification';
import { useUpdateWidgetOptions } from 'pages/Widget/state';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useModalControls } from 'state/modals';
import { userDataQueries } from 'state/user';
import { CONTACT_SUPPORT_ERROR_MESSAGE } from 'utils/constants';
import PlaybackSelector from './PlaybackSelector';

export type PlaybackModalContentsProps = {
  widgetId: string;
};

const StyleWidgetModalContents: React.FC<PlaybackModalContentsProps> = ({
  widgetId,
}) => {
  const updateWidget = useUpdateWidgetOptions();
  const { close } = useModalControls();
  const { showError } = useNotification();
  const queryClient = useQueryClient();

  const handleClick = (playbackVal: AudioPlaybackMethod) => {
    updateWidget.mutateAsync(
      {
        widgetId,
        audioPlaybackMethod: playbackVal,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            userDataQueries.publisher({ isSelfServe: true }),
          );
          close();
        },
        onError: () => {
          showError(CONTACT_SUPPORT_ERROR_MESSAGE);
        },
      },
    );
  };
  return <PlaybackSelector onClick={handleClick} />;
};
export default StyleWidgetModalContents;
