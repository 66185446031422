import { getUserProfile, UserProfile } from 'api/services';
import { useQuery } from 'react-query';
import { useAuth } from 'state/auth';
import { userProfileQueries } from './queryKeys';

type QueryKey = ReturnType<typeof userProfileQueries.user>;

export default function useProfile<TData = UserProfile>() {
  const { userId, accessToken } = useAuth();

  return useQuery<UserProfile, unknown, TData, QueryKey>(
    userProfileQueries.user(userId as number),

    () => getUserProfile({ token: accessToken, userId: userId as number }),
    {
      enabled: !!userId,
    },
  );
}
