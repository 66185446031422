import {
  ByDayWidgetMetric,
  GetPlatformByDayWidgetMetric,
  GetUrlWidgetMetric,
} from 'api';
import round from 'lodash/round';
import { displayDate } from 'utils/date';
import { ParsedPlatformBreakoutData } from '../PlaformBreakoutDashboard/types';
import { ParsedDailyStatsData } from '../PublisherDashboard/types';
import { ParsedUrlStatsData } from '../UrlBreakoutDashboard/types';

export const calculateEngagement = (
  stat: ByDayWidgetMetric | GetUrlWidgetMetric,
): number => stat.resultPlayed + stat.resultClickThrough;

export const calculateCtr = (
  stat: ByDayWidgetMetric | GetUrlWidgetMetric,
): number =>
  stat.widgetInViewport === 0
    ? 0
    : round((calculateEngagement(stat) / stat.widgetInViewport) * 100, 2);

export const mapPlatformStatsData = (
  dailyStatsData?: GetPlatformByDayWidgetMetric[],
): ParsedPlatformBreakoutData[] => {
  if (!dailyStatsData) {
    return [];
  }

  return dailyStatsData.map((stat) => {
    const engagement = calculateEngagement(stat);

    return {
      dateMillis: stat.dayMillis,
      date: displayDate(stat.dayMillis),
      load: stat.widgetLoad,
      view: stat.widgetInViewport,
      play: stat.resultPlayed,
      clickthrough: stat.resultClickThrough,
      engage: engagement,
      ctr: calculateCtr(stat),
      platform: stat.platform,
    };
  });
};

export const mapDailyStatsData = (
  dailyStatsData?: ByDayWidgetMetric[],
): ParsedDailyStatsData[] => {
  if (!dailyStatsData) {
    return [];
  }

  return dailyStatsData.map((stat) => {
    const engagement = calculateEngagement(stat);
    return {
      dateMillis: stat.dayMillis,
      date: displayDate(stat.dayMillis),
      load: stat.widgetLoad,
      view: stat.widgetInViewport,
      play: stat.resultPlayed,
      clickthrough: stat.resultClickThrough,
      engage: engagement,
      ctr: calculateCtr(stat),
    };
  });
};

export const mapUrlStatsData = (
  dailyStatsData?: GetUrlWidgetMetric[],
): ParsedUrlStatsData[] => {
  if (!dailyStatsData) {
    return [];
  }

  return dailyStatsData.map((stat) => {
    const engagement = calculateEngagement(stat);
    return {
      url: stat.url,
      load: stat.widgetLoad,
      view: stat.widgetInViewport,
      play: stat.resultPlayed,
      clickthrough: stat.resultClickThrough,
      engage: engagement,
      ctr: calculateCtr(stat),
    };
  });
};
