import Stepper from 'components/Stepper';
import TextButton from 'components/TextButton';
import { useCallback } from 'react';
import {
  CampaignCreationViewStep,
  GoToStepAction,
  PlacementSubstep,
  useCampaignCreation,
} from '../CampaignCreationContext';
import { CAMPAIGN_CREATION_VIEW_STEPS } from './constants';
import EpisodeStep from './EpisodeStep';
import ObjectiveStep from './ObjectiveStep';
import PlacementStep from './PlacementStep';
import * as S from './styles';

export interface CampaignCreationViewProps {
  onFinishCreation: () => void;
  onCancel: () => void;
}

const CampaignCreationView: React.FC<CampaignCreationViewProps> = ({
  onFinishCreation,
  onCancel,
}) => {
  const {
    state: { step },
    dispatch,
  } = useCampaignCreation();

  const createHandleGoToStep = useCallback(
    (nextStep: CampaignCreationViewStep, nextSubstep?: PlacementSubstep) =>
      () => {
        dispatch({
          type: 'GO_TO_STEP',
          payload: {
            step: nextStep,
            subStep: nextSubstep,
          } as GoToStepAction['payload'],
        });
      },
    [dispatch],
  );

  return (
    <>
      <S.SteperContainer>
        <Stepper
          steps={CAMPAIGN_CREATION_VIEW_STEPS}
          activeStep={CAMPAIGN_CREATION_VIEW_STEPS.indexOf(step)}
        />
      </S.SteperContainer>

      <S.StepsContainer>
        {step === 'episode' && (
          <EpisodeStep
            onContinue={createHandleGoToStep('placement', 'initial')}
          />
        )}

        {step === 'placement' && (
          <PlacementStep onContinue={createHandleGoToStep('objective')} />
        )}

        {step === 'objective' && (
          <ObjectiveStep onContinue={onFinishCreation} />
        )}

        <TextButton onPress={onCancel}>cancel</TextButton>
      </S.StepsContainer>
    </>
  );
};

export default CampaignCreationView;
