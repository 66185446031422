import { CardMediaProps, CardMedia } from '@mui/material';
import React from 'react';

export interface CardImageMediaProps extends CardMediaProps<'img'> {}

const CardImageMedia: React.FC<CardImageMediaProps> = ({
  alt = '',
  ...props
}) => <CardMedia component="img" alt={alt} {...props} />;
export default CardImageMedia;
