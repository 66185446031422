import { WidgetVariantId, AudioPlaybackMethod } from 'api/services';

export const customizeWidgetVariant: Record<WidgetVariantId, string> = {
  DV_ckr3u0go30000dip199xf5kd5: 'Episode art focused',
  DV_cldn7q2lg00010tqfeqgu26bi: 'Episode title focused',
};

export const customizeWidgetPlayback: Record<AudioPlaybackMethod, string> = {
  headlinerWithOriginal: 'Play audio from Headliner and host',
  headlinerOnly: 'Play audio from Headliner only',
  originalOnly: 'Play audio from host only',
};
