import {
  getUrlMetrics,
  GetUrlWidgetMetricsArgs,
  GetUrlWidgetMetricsResponse,
} from 'api/services';
import { UseQueryOptions, useQuery } from 'react-query';
import { discoMetricsQueries } from './queryKeys';
import { urlStatsDataSelector } from './selectors';

type QueryKey = ReturnType<typeof discoMetricsQueries.url>;

function useUrlWidgetMetrics<TData = unknown>(
  args: GetUrlWidgetMetricsArgs,
  opts?: Omit<
    UseQueryOptions<GetUrlWidgetMetricsResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<GetUrlWidgetMetricsResponse, unknown, TData, QueryKey>(
    discoMetricsQueries.url(args),
    () => getUrlMetrics(args),
    opts,
  );
}

export function useParsedUrlWidgetMetrics(args: GetUrlWidgetMetricsArgs) {
  return useUrlWidgetMetrics(args, {
    keepPreviousData: true,
    select: urlStatsDataSelector,
  });
}
