import { Tooltip, Typography, Stack } from '@mui/material';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import React from 'react';

export type StatProps = {
  icon: React.ReactElement;
  numberOf: number | string;
  statsType: 'plays' | 'followers' | 'revenue';
  width: string;
  tooltip?: string;
  // className is needed to pass down additional styles to the Stack component
  className?: string;
};

const Stat: React.FC<StatProps> = (props) => {
  const isMobile = useMobileBreakpoint('md');
  const { width, icon, numberOf, statsType, tooltip, className } = props;

  return (
    <Tooltip
      title={tooltip}
      placement="top"
      disableHoverListener={!tooltip}
      arrow
    >
      <Stack
        className={className}
        justifyContent="center"
        alignItems="center"
        margin={isMobile ? '24px 0' : '48px 0'}
        width={width}
      >
        {icon}
        <Typography margin="16px 0" variant={isMobile ? 'h1' : 'jumbo'}>
          {numberOf}
        </Typography>
        <Typography variant="h2" textTransform="uppercase" textAlign="center">
          {statsType}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
export default Stat;
