const routes = {
  account: { path: '/dashboard/account' },
  discoAdsDashboard: { path: '/dashboard/disco-ads' },
  discoAdsDailyStats: { path: '/dashboard/disco-ads/daily-stats' },
  dashboard: { path: '/dashboard' },
  home: { path: '/dashboard/home' },
  login: { path: '/login' },
  platformBreakoutDashboard: {
    path: '/dashboard/disco-widget/platform-breakout',
  },
  discoWidgetDailyStats: { path: '/dashboard/disco-widget/daily-stats' },
  discoWidgetDashboard: { path: '/dashboard/disco-widget' },
  register: { path: '/register' },
  resetPassword: { path: '/reset-password' },
  adCampaign: { path: '/dashboard/disco-ads/create-ad' },
  urlBreakoutDashboard: {
    path: '/dashboard/disco-widget/url-breakout',
  },
  contentManagement: {
    path: '/dashboard/disco-widget/content-management',
  },
  discoWidget: {
    path: '/dashboard/disco-widget/widget',
  },
  studioDashboard: {
    path: '/dashboard/headliner-studio',
  },
  allVideos: {
    path: '/dashboard/headliner-studio/all-videos',
  },
  podcasts: {
    path: '/dashboard/headliner-studio/podcasts',
  },
  playAnalytics: {
    path: '/dashboard/play/podcasts',
  },
};

export default routes;
