import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

// https://mui.com/guides/routing/#LinkRouterWithTheme.tsx
const LinkBehavior = React.forwardRef<
  any,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>(({ href, ...props }, ref) => (
  <Link data-testid="custom-link" ref={ref} to={href} {...props} />
));

export default LinkBehavior;
