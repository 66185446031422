import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import SegmentAudioPlayer from 'pages/ContentManagementPage/episodeSegments/SegmentAudioPlayer';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { seekToAudioTimeCode } from 'utils/audio';
import { getFormErrorMessage } from 'utils/form';
import { formatTimeValue } from 'utils/time';
import {
  DEFAULT_CLIP_START,
  DEFAULT_CLIP_END,
  TIMESTAMP_FORMAT,
} from './constants';
import * as S from './styles';
import useNewEpisodeSegmentForm from './useNewEpisodeSegmentForm';
import { isInputComplete, timeCodeInMillis } from './utils';

export type NewEpisodeSegmentFormProps = {
  episodeId: number;
  onClose: () => void;
};

const NewEpisodeSegmentForm: React.FC<NewEpisodeSegmentFormProps> = ({
  episodeId,
  onClose,
}) => {
  const {
    onSubmit,
    control,
    errors,

    isError,
    clearErrors,
  } = useNewEpisodeSegmentForm(episodeId, onClose);

  const [clipDuration, setClipDuration] = useState<number>(
    timeCodeInMillis(DEFAULT_CLIP_END),
  );

  const [startTime, setStartTime] = useState<string>(DEFAULT_CLIP_START);
  const startTimeInMillis = timeCodeInMillis(startTime);

  const [endTime, setEndTime] = useState<string>(DEFAULT_CLIP_END);
  const endTimeInMillis = timeCodeInMillis(endTime);

  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(
    null,
  );

  const handleStartChange = (val: string, onChange: (val: number) => void) => {
    // react-number-format inserts spaces as placeholders,
    // so the string will always have the same length unless spaces are removed
    if (isInputComplete(val.trim(), 7)) {
      clearErrors();
      setStartTime(val);
      onChange(timeCodeInMillis(val));
      const duration = endTimeInMillis - timeCodeInMillis(val);
      setClipDuration(duration);
    }
  };

  const handleEndChange = (val: string, onChange: (val: number) => void) => {
    // react-number-format inserts spaces as placeholders,
    // so the string will always have the same length unless spaces are removed
    if (isInputComplete(val.trim(), 7)) {
      clearErrors();
      setEndTime(val);
      onChange(timeCodeInMillis(val));
      const duration = timeCodeInMillis(val) - startTimeInMillis;
      setClipDuration(duration);
    }
  };

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Stack spacing={2} direction="column">
        <Stack spacing={3} direction="row">
          <SegmentAudioPlayer
            episodeId={episodeId}
            clipStart={startTimeInMillis}
            audio={audioElement}
            onAudioLoad={setAudioElement}
            clipEnd={endTimeInMillis}
            error={isError}
          />
          <Controller
            render={({ field: { ref, onChange } }) => (
              <PatternFormat
                customInput={TextField}
                format={TIMESTAMP_FORMAT}
                InputLabelProps={{ shrink: true }}
                getInputRef={ref}
                id="start-time"
                label="Clip Start"
                value={startTime}
                onValueChange={({ formattedValue }) => {
                  handleStartChange(formattedValue, onChange);
                  seekToAudioTimeCode(
                    timeCodeInMillis(formattedValue),
                    audioElement,
                  );
                }}
              />
            )}
            name="startTime"
            control={control}
          />

          <Controller
            render={({ field: { ref, onChange } }) => (
              <PatternFormat
                customInput={TextField}
                format={TIMESTAMP_FORMAT}
                InputLabelProps={{ shrink: true }}
                id="end-time"
                getInputRef={ref}
                label="Clip End"
                value={endTime}
                onValueChange={({ formattedValue }) => {
                  handleEndChange(formattedValue, onChange);
                }}
              />
            )}
            name="endTime"
            control={control}
          />

          <TextField
            InputLabelProps={{ shrink: true }}
            id="duration"
            label="Duration"
            error={isError}
            inputProps={{ readOnly: true }}
            value={formatTimeValue(clipDuration)}
          />
        </Stack>
        <div>
          <S.ErrorText>{getFormErrorMessage(errors)}</S.ErrorText>
        </div>
        <Stack justifyContent="flex-end" direction="row" spacing={2}>
          <Button
            size="small"
            onClick={() => {
              onClose();
            }}
          >
            <Typography variant="body1">CANCEL</Typography>
          </Button>
          <Button
            size="small"
            type="submit"
            onClick={onSubmit}
            disabled={isError}
          >
            <Typography variant="body1">SELECT THIS CLIP</Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
export default NewEpisodeSegmentForm;
