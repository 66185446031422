import {
  EpisodeDetailResponse,
  updateDiscoEpisode,
  UpdateDiscoEpisodeArgs,
} from 'api';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { podcastQueries } from './queryKeys';

type DiscoEpisodeUpdateContext = {
  previousEpisodeData: EpisodeDetailResponse | undefined;
};
interface UseUpdateDiscoEpisodeUrlArgs
  extends Omit<UpdateDiscoEpisodeArgs, 'isEnabled'> {}

export default function useUpdateDiscoEpisodeDestinationUrl(
  config?: Omit<
    UseMutationOptions<
      void,
      unknown,
      UseUpdateDiscoEpisodeUrlArgs,
      DiscoEpisodeUpdateContext
    >,
    'mutationFn'
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    UseUpdateDiscoEpisodeUrlArgs,
    DiscoEpisodeUpdateContext
  >({
    mutationFn: updateDiscoEpisode,
    onMutate: async ({ episodeId, urlOverride }) => {
      const queryKey = podcastQueries.episode({
        internalEpisodeId: episodeId,
      });
      await queryClient.cancelQueries(queryKey);
      const previousEpisodeData: EpisodeDetailResponse | undefined =
        queryClient.getQueryData(queryKey);

      if (!previousEpisodeData) {
        return undefined;
      }

      const newData = {
        ...previousEpisodeData,
        discoDetail: {
          ...previousEpisodeData.discoDetail,
          destUrl: urlOverride,
        },
      };

      queryClient.setQueryData(queryKey, newData);

      return { previousEpisodeData };
    },
    onError: (err, variables, context) => {
      const queryKey = podcastQueries.episode({
        internalEpisodeId: variables.episodeId,
      });
      queryClient.setQueryData(queryKey, context?.previousEpisodeData);
      config?.onError?.(err, variables, context);
    },
    onSuccess: (data, variables, context) =>
      config?.onSuccess?.(data, variables, context),
    onSettled: (data, error, variables, context) => {
      const queryKey = podcastQueries.episode({
        internalEpisodeId: variables.episodeId,
      });
      queryClient.invalidateQueries(queryKey);
      config?.onSettled?.(data, error, variables, context);
    },
  });
}
