import {
  parseAuthHeader,
  shouldRenewToken,
  replaceHeaderToken,
} from '@sparemin/auth';
import { renewToken } from 'api/services/user';
import { AxiosRequestConfig } from 'axios';
import identity from 'lodash/identity';
import merge from 'lodash/merge';
import { TOKEN_CHECK_INTERVAL_MILLIS } from './constants';

type Interceptor<V = any, T = V> = [
  (value: V) => T | Promise<T>,
  (error: any) => any,
];

export function autoLogoutResponseInterceptor(logout: () => void): Interceptor {
  return [
    identity,
    (error: any) => {
      if (error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    },
  ];
}

interface TokenRenewalRequestInterceptorConfig {
  onTokenRenewed?: (token: string) => void;
}

export function tokenRenewalRequestInterceptor(
  interceptorConfig: TokenRenewalRequestInterceptorConfig,
): Interceptor {
  const { onTokenRenewed } = interceptorConfig ?? {};

  let checkToken = true;
  let renewingToken = false;

  const scheduleNextTokenCheck = () => {
    checkToken = false;
    window.setTimeout(() => {
      checkToken = true;
    }, TOKEN_CHECK_INTERVAL_MILLIS);
  };

  return [
    // eslint-disable-next-line consistent-return
    async (config: AxiosRequestConfig) => {
      if (!checkToken || renewingToken) {
        // NB: do not call "bail" here.  renewingToken should only be
        // set to false by the function call that set it to true
        return config;
      }

      renewingToken = true;

      const bail = () => {
        renewingToken = false;
        return config;
      };

      if (!config.headers) {
        return bail();
      }

      const authHeader = config.headers.Authorization;
      if (!authHeader) {
        return bail();
      }

      const token = parseAuthHeader(authHeader);
      if (!token || !shouldRenewToken(token)) {
        return bail();
      }

      try {
        const { spareminToken } = await renewToken();
        if (spareminToken) {
          onTokenRenewed?.(spareminToken);
          scheduleNextTokenCheck();
          // patch new token into header in case current token has expired
          // while fetching the new one
          return merge(config, {
            headers: {
              Authorization: replaceHeaderToken(
                config.headers.Authorization,
                spareminToken,
              ),
            },
          });
        }
      } catch {
        return config;
      } finally {
        renewingToken = false;
      }
    },
    Promise.reject,
  ];
}
