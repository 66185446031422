import {
  ByDayWidgetResponse,
  GetPlatformByDayWidgetMetricsResponse,
  GetUrlWidgetMetricsResponse,
} from 'api/services';
import {
  mapPlatformStatsData,
  mapDailyStatsData,
  mapUrlStatsData,
} from './utils';

export function dailyStatsDataSelector(d: ByDayWidgetResponse) {
  return mapDailyStatsData(d.data);
}

export function platformStatsDataSelector(
  d: GetPlatformByDayWidgetMetricsResponse,
) {
  return mapPlatformStatsData(d.data);
}

export function urlStatsDataSelector(d: GetUrlWidgetMetricsResponse) {
  return mapUrlStatsData(d.data);
}
