import { CircularProgress, Stack } from '@mui/material';
import React from 'react';
import * as S from './styles';

type LoadingStateProps = {};

const LoadingState: React.FC<LoadingStateProps> = () => (
  <Stack justifyContent="center" alignItems="center">
    <S.Container>
      <CircularProgress />
    </S.Container>
  </Stack>
);

export default LoadingState;
