import { mapKeys } from 'lodash';
import { PascalCasedProperties } from 'type-fest';
import { pascalCase } from './string';

type AnyObject = { [key: string]: any };

type PascalKeysResult<
  T extends AnyObject,
  K extends keyof T,
> = PascalCasedProperties<Omit<T, K>> & Pick<T, K>;

/**
 * Converts all top-level keys of an object to PascalCase.  Any keys in the
 * `exclude` array are left untouched.
 */
export function pascalKeys<T extends AnyObject, K extends keyof T = never>(
  obj: T,
  exclude: Array<K> = [],
) {
  return mapKeys(obj, (_, key) => {
    if (exclude.includes(key as any)) {
      return key;
    }
    return pascalCase(key);
  }) as PascalKeysResult<T, K>;
}
