import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import React from 'react';
import { MODAL_NAME } from './constants';
import PodcastSearchModalContents from './PodcastSearchModalContents';
import * as S from './styles';
import { PodcastSearchModalProps } from './types';

const PodcastSearchModal: React.FC<PodcastSearchModalProps> = () => {
  const isMobile = useMobileBreakpoint('md');

  return (
    <S.DialogBox
      aria-label="search for a podcast episode"
      maxWidth={false}
      name={MODAL_NAME}
      closeButton
      fullHeight
      fullScreen={isMobile}
      fullWidth
      $isMobile={isMobile}
    >
      <PodcastSearchModalContents isMobile={isMobile} />
    </S.DialogBox>
  );
};

export default PodcastSearchModal;
