import { useEffect } from 'react';
import {
  TrackDashboardPageLoadProperties,
  useEventTracking,
} from 'state/eventTracking';

export default function useTrackPageLoad(
  props: TrackDashboardPageLoadProperties | undefined,
) {
  const { trackDashboardPageLoad } = useEventTracking();
  useEffect(() => {
    if (props !== undefined) {
      trackDashboardPageLoad(props);
    }
  }, [trackDashboardPageLoad, props]);
}
