import { Divider, Stack } from '@mui/material';
import { EpisodeMetrics } from 'api';
import { MobileDetailCard } from 'components/MobileDetailCard';
import MobileDetailCardContents from 'pages/Play/MobileDetailCardContents';
import { useInView } from 'react-intersection-observer';
import LoadingRow from './LoadingRow';

type PodcastEpisodesMobileListProps = {
  hasMore?: boolean;
  onLoadMore: () => void;
  threshold?: number;
  data: EpisodeMetrics[];
};

const PodcastEpisodesMobileList = ({
  hasMore = false,
  onLoadMore,
  threshold = 25,
  data,
}: PodcastEpisodesMobileListProps) => {
  const { ref: loaderRef } = useInView({
    rootMargin: `0px 0px ${threshold}px 0px`,
    onChange(inView) {
      if (inView) {
        onLoadMore?.();
      }
    },
  });

  return (
    <Stack
      divider={<Divider orientation="horizontal" variant="fullWidth" />}
      spacing={0}
    >
      {data?.map((episode) => {
        const { title, thumbnailUrl, publishedAtMillis, playCount } = episode;

        return (
          <MobileDetailCard key={title}>
            <MobileDetailCardContents
              title={title}
              thumbnailUrl={thumbnailUrl}
              publishedAtMillis={publishedAtMillis}
              playCount={playCount}
            />
          </MobileDetailCard>
        );
      })}
      {hasMore && <LoadingRow ref={loaderRef} />}
    </Stack>
  );
};

export default PodcastEpisodesMobileList;
