interface FullstoryIdentifyProps {
  userId: number;
  email: string;
}

export function getCurrentSessionURL(now?: boolean): string | null {
  return window.FS?.getCurrentSessionURL?.(now) ?? null;
}

export function fullstoryIdentify({
  userId,
  email,
}: FullstoryIdentifyProps): void {
  return window.FS?.identify?.(userId, { email });
}

export function fullstoryAnonymize(): void {
  return window.FS?.anonymize?.();
}
