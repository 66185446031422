import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { TextField } from '@sparemin/blockhead';
import { useNotification } from 'components/Notification';
import { useEpisodeDestinationUrl } from 'pages/ContentManagementPage/state';
import useUpdateDiscoEpisodeDestinationUrl from 'pages/ContentManagementPage/state/useUpdateDiscoEpisodeDestinationUrl';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useEventTracking } from 'state/eventTracking';
import { object, SchemaOf, string } from 'yup';
import * as S from './styles';

type UpdateDestinationUrlModalContentsProps = {
  episodeId: number;
};

type UpdateDestinationUrlData = { newUrl: string };

const urlSchema: SchemaOf<UpdateDestinationUrlData> = object({
  newUrl: string().url('Please enter a valid url.').required(),
});

const UpdateDestinationUrlModalContents: React.FC<
  UpdateDestinationUrlModalContentsProps
> = ({ episodeId }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<UpdateDestinationUrlData>({
    resolver: yupResolver(urlSchema),
  });

  const { showSuccess, showError } = useNotification();

  const { data: destinationUrl, isLoading } =
    useEpisodeDestinationUrl(episodeId);

  const { trackUpdateDiscoEpisodeDestinationUrl } = useEventTracking();

  const { mutate: updateUrl } = useUpdateDiscoEpisodeDestinationUrl({
    onSettled: () => reset(),
    onSuccess: () => {
      showSuccess('Destination url updated');
      trackUpdateDiscoEpisodeDestinationUrl();
    },
    onError: () => showError('Error updating url. Please try again.'),
  });

  const onSubmit = ({ newUrl }: UpdateDestinationUrlData) => {
    updateUrl({ episodeId, urlOverride: newUrl });
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.TextContainer>
        <TextField
          aria-label="Disco Destination Url"
          placeholder={destinationUrl}
          disabled={isLoading}
          errorMessage={errors.newUrl?.message}
          id="new-url"
          {...register('newUrl')}
        />
      </S.TextContainer>

      <Button
        disabled={isLoading}
        type="submit"
        variant="contained"
        size="medium"
      >
        save
      </Button>
    </S.Form>
  );
};
export default UpdateDestinationUrlModalContents;
