import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { omitProps } from 'utils/props';
import { NAV_PANEL_WIDTH } from './constants';
import { WithMenuOpenAndMobile } from './types';

export const Body = styled(Box)`
  display: flex;
`;

export const Main = styled(Box)`
  min-height: 100vh;
  padding-bottom: 128px;
  width: 100%;
`;

export const MenuButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.action.active};
  margin-right: ${({ theme }) => theme.spacing(4.5)};
`;

export const CondensedLogo = styled(Avatar)`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 36px;
`;

export const HeaderToolbar = styled(Toolbar)`
  overflow: hidden;
`;

export const HeaderSpacer = styled(Toolbar)`
  margin-bottom: 64px;
`;

export const HeaderAppBar = styled(AppBar, {
  shouldForwardProp: omitProps(['isMobile', 'isMenuOpen']),
})<WithMenuOpenAndMobile<AppBarProps>>(({ theme, isMenuOpen, isMobile }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(!isMobile && {
    zIndex: theme.zIndex.drawer + 1,
  }),
  ...(isMenuOpen &&
    !isMobile && {
      marginLeft: `${NAV_PANEL_WIDTH}px`,
      width: `calc(100% - ${NAV_PANEL_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
}));

export const ToolbarLeft = styled(Box, {
  shouldForwardProp: omitProps(['isMobile', 'isMenuOpen']),
})<WithMenuOpenAndMobile<BoxProps>>(({ theme, isMenuOpen, isMobile }) => ({
  alignItems: 'center',
  display: 'flex',
  transform: 'translateX(0px)',
  transition: theme.transitions.create(['transform'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(isMenuOpen &&
    !isMobile && {
      transform: 'translateX(-65px)',
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
}));

export const ToolbarRight = styled(Box)`
  margin-left: auto;
`;

export const DashboardContentTitleContainer = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const DashboardContentInstructionsContainer = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
