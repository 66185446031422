import { Tooltip } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';
import * as S from './styles';

interface OverflowTooltipProps {
  text: string;
}

const OverflowTooltip: React.FC<OverflowTooltipProps> = ({ text }) => {
  const textElementRef = useRef<HTMLDivElement>(null);

  const [isOverflowed, setIsOverflowed] = useState(false);

  useLayoutEffect(() => {
    if (textElementRef.current) {
      setIsOverflowed(
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
      );
    }
  }, []);

  return (
    <Tooltip title={text} placement="top" disableHoverListener={!isOverflowed}>
      <S.TextWithEllipsis ref={textElementRef}>{text}</S.TextWithEllipsis>
    </Tooltip>
  );
};
export default OverflowTooltip;
