import { currency } from 'utils/table';
import { NumericCellFormats } from './types';

export const formatNumericValue = (
  value: string | number,
  format: NumericCellFormats = 'default',
): string => {
  switch (format) {
    case 'mili-cents':
      return currency.formatMiliCentsValue(value);
    case 'percent':
      return `${value}%`;

    default:
      return String(value);
  }
};
