import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ReactNode } from 'react';
import * as S from './styles';

export type Option<T> = {
  itemValue: T;
  primaryItemLabel?: ReactNode;
  secondaryItemLabel?: ReactNode;
  indeterminate?: boolean;
};

export type MultiSelectProps<T extends string> = {
  selectLabel: string;
  value: Array<T>;
  onChange: (val: T[]) => void;
  onClose?: () => void;
  options: Option<T>[];
  getInputValue: (val: string[]) => string;
};

const multiSelectLabel = 'multiselect-label';

const MultiSelect = <T extends string>({
  selectLabel,
  value,
  onChange,
  onClose,
  options,
  getInputValue,
}: MultiSelectProps<T>) => {
  const handleChange = (event: SelectChangeEvent<Array<T>>) => {
    const {
      target: { value: newValue },
    } = event;

    const parsedValue: Array<T> =
      typeof newValue === 'string'
        ? (newValue.split(',') as Array<T>)
        : newValue;

    onChange(parsedValue);
  };

  const labelId = `${selectLabel}-${multiSelectLabel}`;

  return (
    <S.WidgetIdFormControl>
      <S.SelectInputLabel shrink id={labelId}>
        {selectLabel}
      </S.SelectInputLabel>
      <Select
        displayEmpty
        id={`${selectLabel}-id-multiselect`}
        input={<OutlinedInput label={selectLabel} />}
        labelId={labelId}
        multiple
        onChange={handleChange}
        onClose={onClose}
        renderValue={getInputValue}
        value={value}
      >
        {options?.map((option) => {
          const {
            itemValue,
            primaryItemLabel,
            secondaryItemLabel,

            indeterminate,
          } = option;

          return (
            <MenuItem key={itemValue} value={itemValue}>
              <Checkbox
                checked={value.includes(itemValue)}
                indeterminate={indeterminate}
              />
              <ListItemText
                primary={primaryItemLabel || itemValue}
                secondary={secondaryItemLabel}
              />
            </MenuItem>
          );
        })}
      </Select>
    </S.WidgetIdFormControl>
  );
};
export default MultiSelect;
