/* eslint-disable array-callback-return */
import { EpisodeSegmentsResponse, SegmentUpdateArgs } from 'api/services';
import { useNotification } from 'components/Notification';
import TableCells from 'components/TableCells';
import {
  podcastQueries,
  useUpdateEpisodeSegment,
  useActiveEpisodeSegment,
  useDiscoEpisodeEnabled,
} from 'pages/ContentManagementPage/state';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { CellProps, Column } from 'react-table';
import { useEventTracking } from 'state/eventTracking';
import { useModalControls } from 'state/modals';
import { UPDATE_SEGMENT_ERROR } from './constants';
import { usePlayback } from './PlaybackContext';
import * as S from './styles';
import { SegmentDetails } from './types';

export const columns: ReadonlyArray<Column<SegmentDetails>> = [
  {
    Header: 'Time Codes',

    id: 'time codes',
    Cell: ({ row }: CellProps<SegmentDetails>) => (
      <TableCells.TimeSegmentsCell
        startTime={row.original.startMillis}
        endTime={row.original.endMillis}
      />
    ),
  },
  {
    Header: 'Episode Preview',
    id: 'episode preview',
    disableSortBy: true,

    Cell: ({ row }: CellProps<SegmentDetails>) => {
      const { activeSegmentId, loading, pause, playing, playSegment } =
        usePlayback();

      const isPlayingSegment = activeSegmentId === row.original.id && playing;
      const isLoadingSegment = activeSegmentId === row.original.id && loading;

      const handleTogglePlayback = useCallback(() => {
        if (!isPlayingSegment) {
          playSegment(row.original.id);
        } else {
          pause();
        }
      }, [isPlayingSegment, pause, playSegment, row.original.id]);

      return (
        <TableCells.AudioCell
          onTogglePlayback={handleTogglePlayback}
          loading={isLoadingSegment}
          playing={isPlayingSegment}
          transcriptText={row.original.transcriptText}
        />
      );
    },
    width: 650,
  },
  {
    Header: 'Clipping Method',
    id: 'method',
    disableSortBy: true,
    Cell: ({ row }: CellProps<SegmentDetails>) => (
      <TableCells.TextCell
        value={
          row.original.creationMethod === 'internal' ? 'Automatic' : 'Manual'
        }
      />
    ),
    width: 150,
  },

  {
    Header: () => {
      const { internalEpisodeId } = usePlayback();
      const { data: isEpisodeEnabled } =
        useDiscoEpisodeEnabled(internalEpisodeId);
      const { open } = useModalControls();
      return (
        <TableCells.HeaderCellWithButton
          text="Show in Widget"
          button={
            <S.TextButton
              variant="text"
              size="small"
              disabled={!isEpisodeEnabled}
              onClick={() =>
                open({
                  name: 'ToggleAllClips',
                  params: { episodeId: internalEpisodeId },
                })
              }
            >
              (toggle all)
            </S.TextButton>
          }
        />
      );
    },
    id: 'setting',
    disableSortBy: true,

    Cell: ({ row }: CellProps<SegmentDetails>) => {
      const { showError } = useNotification();
      const { trackShowInWidgetToggleClip } = useEventTracking();

      // TODO: should this value come from a different context?
      const { internalEpisodeId } = usePlayback();

      const { data: isEpisodeEnabled } =
        useDiscoEpisodeEnabled(internalEpisodeId);

      const queryClient = useQueryClient();
      const { data: segment } = useActiveEpisodeSegment(
        internalEpisodeId,
        row.original.id,
      );

      const toggleStateFromApi = segment?.isEnabled;

      const [toggleState, setToggleState] = useState(toggleStateFromApi);
      if (toggleState !== toggleStateFromApi) {
        setToggleState(toggleStateFromApi);
      }

      const changeEpisodeSegmentState = useUpdateEpisodeSegment({
        onMutate: async (newValues: SegmentUpdateArgs) => {
          await queryClient.cancelQueries(
            podcastQueries.segments({ internalEpisodeId }),
          );
          const previousData = queryClient.getQueryData(
            podcastQueries.segments({ internalEpisodeId }),
          ) as EpisodeSegmentsResponse;
          const newSegments = previousData.segments.map((oldSegment) => {
            if (oldSegment.id === newValues.segmentId) {
              return { ...oldSegment, isEnabled: newValues.isEnabled };
            }
            return { ...oldSegment };
          });
          const newEpisodeData = {
            ...previousData,
            segments: newSegments,
          };
          queryClient.setQueryData(
            podcastQueries.segments({ internalEpisodeId }),
            newEpisodeData,
          );
          return { previousData };
        },

        onError: (err, variables, context) => {
          showError(UPDATE_SEGMENT_ERROR);
          queryClient.setQueryData(
            podcastQueries.segments({ internalEpisodeId }),
            context?.previousData,
          );
        },
        onSuccess: (_, { isEnabled }) => {
          trackShowInWidgetToggleClip(isEnabled ? 'on' : 'off');
        },
      });

      const handleChange = () => {
        changeEpisodeSegmentState.mutate({
          internalEpisodeId,
          segmentId: row.original.id,
          isEnabled: !toggleState,
        });
      };

      return (
        <TableCells.SwitchCell
          textAlign="center"
          checked={toggleState}
          onChange={handleChange}
          disabled={!isEpisodeEnabled}
        />
      );
    },
    width: 225,
  },
];
