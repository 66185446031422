import { createAuthHeader, TokenResponse } from '@sparemin/auth';
import { SOURCE_APPLICATION_NAME } from 'api/constants';
import { request } from 'api/http';
import { createFormattedAuthHeader } from 'api/utils';
import { AUTH_V1_URL, USER_V1_URL } from './constants';
import { GetUserProfileArgs, UserProfile } from './types';

export async function getUserProfile(args: GetUserProfileArgs) {
  const { token, userId } = args;

  const { data } = await request.get<UserProfile>(
    `${USER_V1_URL}/${userId}/profile`,
    {
      disableAuthHeaderInterceptor: true,
      ...(!token
        ? undefined
        : createFormattedAuthHeader(createAuthHeader(token, false))),
    },
  );

  return data;
}

export async function renewToken() {
  const { data } = await request.post<TokenResponse>(
    `${AUTH_V1_URL}/token`,
    {
      application: SOURCE_APPLICATION_NAME,
    },
    {
      omitBearerPrefix: true,
    },
  );
  return data;
}
