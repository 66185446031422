function boolean(val: string | undefined, fallback: boolean) {
  if (val === undefined) {
    return fallback;
  }

  const lowercaseVal = val.toLowerCase();
  return lowercaseVal === 'true';
}

// API -------------------------------------------------------------------------
export const AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;
export const DASHBOARD_SERVICE_URL =
  process.env.REACT_APP_DASHBOARD_SERVICE_URL;
export const USER_SERVICE_URL = process.env.REACT_APP_USER_SERVICE_URL;
export const THIRD_PARTY_AUTH_SERVICE_URL =
  process.env.REACT_APP_THIRD_PARTY_AUTH_SERVICE_URL;
export const PODCAST_SERVICE_URL = process.env.REACT_APP_PODCAST_SERVICE_URL;
export const EMBED_VIDEO_SERVICE_URL =
  process.env.REACT_APP_EMBED_VIDEO_SERVICE_URL;
// GA --------------------------------------------------------------------------
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

// MISC ------------------------------------------------------------------------
export const RUNTIME_ENV = process.env.REACT_APP_RUNTIME_ENV;

// DISCO WIDGET ----------------------------------------------------------------
export const DISCO_WIDGET_IFRAME_URL =
  process.env.REACT_APP_DISCO_WIDGET_IFRAME_URL;
export const DISCO_WIDGET_REQUEST_URL =
  process.env.REACT_APP_DISCO_WIDGET_REQUEST_URL;

// Apple -----------------------------------------------------------------------
export const APPLE_AUTH_REDIRECT_URI =
  process.env.REACT_APP_APPLE_AUTH_REDIRECT_URI;

// Headliner -------------------------------------------------------------------
export const HEADLINER_REDIRECT_URL = process.env.REACT_APP_HEADLINER_URL;

// Play -------------------------------------------------------------------------
export const PLAY_URL = process.env.REACT_APP_PLAY_URL;

// mixpanel
export const MIXPANEL_API_HOST = process.env.REACT_APP_MIXPANEL_API_HOST;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const MIXPANEL_DEBUG = boolean(
  process.env.REACT_APP_MIXPANEL_DEBUG,
  false,
);
