import { PeriodArgs } from 'components/StatsPeriodSelector';
import { SelectedWidgetIds } from 'components/WidgetIdSelector';
import React, { useContext, useMemo } from 'react';
import { useDailyStatsData } from '../state';
import { PublisherDashboardContextType } from './types';

const PublisherDashboardContext = React.createContext<
  PublisherDashboardContextType | undefined
>(undefined);

export function usePublisherDashboard() {
  const context = useContext(PublisherDashboardContext);

  if (context === undefined) {
    throw new Error(
      'usePublisherDashboard must be used within PublisherDashboardProvider',
    );
  }

  return context;
}

interface PublisherDashboardProviderProps {
  periodArgs: PeriodArgs;
  widgetIds: SelectedWidgetIds;
}

const PublisherDashboardProvider: React.FunctionComponent<
  PublisherDashboardProviderProps
> = (props) => {
  const { children, periodArgs, widgetIds } = props;

  const {
    data: dailyStatsData = [],
    isError,
    isLoading,
  } = useDailyStatsData({
    endMillis: periodArgs.endMillis,
    startMillis: periodArgs.startMillis,
    widgetIds: widgetIds.includes('All') ? undefined : widgetIds,
  });

  return (
    <PublisherDashboardContext.Provider
      value={useMemo(
        () => ({
          dailyStats: {
            data: dailyStatsData,
            error: isError,
            loading: isLoading,
          },
          periodArgs,
          widgetIds,
        }),
        [dailyStatsData, isError, isLoading, periodArgs, widgetIds],
      )}
    >
      {children}
    </PublisherDashboardContext.Provider>
  );
};

export default PublisherDashboardProvider;
