import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  height: 56px;
  margin: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
