import { Platform } from 'api/services';
import { MultiSelectProps } from 'components/MultiSelect';
import SelectAllMultiSelect from 'components/SelectAllMultiSelect';
import { useEventTracking } from 'state/eventTracking';

export interface PlatformBreakoutProps
  extends Pick<MultiSelectProps<Platform>, 'value' | 'onChange'> {
  platformOptions: Platform[];
}

const PlatformBreakoutFilter: React.FunctionComponent<PlatformBreakoutProps> = (
  props,
) => {
  const { trackReportAdjust } = useEventTracking();

  const { platformOptions, onChange, value } = props;

  const formattedPlatformOptions = platformOptions.map((option) => ({
    itemValue: option,
  }));

  const handleClose = () => {
    if (value.length === 0) {
      onChange(platformOptions);
    }
    trackReportAdjust({ dropdown: 'Platform' });
  };

  return (
    <SelectAllMultiSelect<Platform>
      selectLabel="Platform"
      value={value}
      options={formattedPlatformOptions}
      onChange={onChange}
      onClose={handleClose}
    />
  );
};

export default PlatformBreakoutFilter;
