import Dashboard from './Dashboard';
import DashboardContent from './DashboardContent';
import DashboardContentInstructions from './DashboardContentInstructions';
import DashboardContentTitle from './DashboardContentTitle';

export default Dashboard;
export {
  DashboardContent,
  DashboardContentTitle,
  DashboardContentInstructions,
};
