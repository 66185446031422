/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';

export function useStaticCallback<Params extends Array<unknown>, Result>(
  callback: (...args: Params) => Result,
): (...args: Params) => Result;
export function useStaticCallback<Params extends Array<unknown>, Result>(
  callback: ((...args: Params) => Result) | undefined,
): (...args: Params) => Result | undefined;
export function useStaticCallback<Params extends Array<unknown>, Result>(
  callback: ((...args: Params) => Result) | undefined,
): (...args: Params) => Result | undefined {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useCallback((...args) => callbackRef.current?.(...args), []);
}
