import { Box } from '@mui/material';
import styled from 'styled-components';

export const Form = styled(Box).attrs({ component: 'form' })`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  column-gap: 15px;
`;

export const TextContainer = styled('div')`
  flex-grow: 2;
  width: fit-content;
`;
