import { Button, Typography, Stack } from '@mui/material';
import ImageCropper from 'components/ImageCropper';
import { useNotification } from 'components/Notification';
import Spinner from 'components/Spinner';
import { useUpdateDiscoImage } from 'pages/ContentManagementPage/state';
import { useEffect, useRef, useState } from 'react';
import { ReactCropperElement } from 'react-cropper';
import { useEventTracking } from 'state/eventTracking';
import { useModalControls } from 'state/modals';
import { RequestStatus } from 'types';
import { CONTACT_SUPPORT_ERROR_MESSAGE } from 'utils/constants';
import { getFileFromUrl } from './utils';

export type ImageCropModalContentsProps = {
  src: string | File;
  widgetAspectRatio?: number;
  episodeId: number;
  onLoading: () => void;
  onLoadingComplete: (val: RequestStatus) => void;
  cropAfterUpload?: boolean;
};

const ImageCropModalContents: React.FC<ImageCropModalContentsProps> = ({
  src,
  widgetAspectRatio,
  episodeId,
  cropAfterUpload = false,
}) => {
  const { trackThumbnailEdit } = useEventTracking();

  const [requestStatus, updateRequestStatus] = useState<RequestStatus>('idle');

  const isSrcFileInstance = src instanceof File;

  const getOriginalImageFile = () => {
    if (isSrcFileInstance) {
      return src;
    }
    const file = getFileFromUrl(src);
    return file;
  };

  const { showNotification } = useNotification();

  const cropperRef = useRef<ReactCropperElement>(null);

  const imageUrlState = useUpdateDiscoImage({
    onMutate: () => updateRequestStatus('request'),
  });
  const { close } = useModalControls();

  const [imgSource, setImageSource] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!isSrcFileInstance) {
      setImageSource(src);
    } else {
      const imgUrl = URL.createObjectURL(src);
      setImageSource(imgUrl);
      return () => {
        URL.revokeObjectURL(imgUrl);
      };
    }
    return undefined;
  }, [isSrcFileInstance, src]);

  const handleClick = async () => {
    const croppedImage = cropperRef.current?.cropper
      .getCroppedCanvas()
      .toDataURL();

    const originalImageFile = await getOriginalImageFile();

    try {
      imageUrlState.mutate(
        {
          internalEpisodeId: episodeId,
          croppedImage,
          originalImageFile,
        },
        {
          onSuccess: () => {
            updateRequestStatus('success');
            close();
            trackThumbnailEdit(cropAfterUpload ? 'replace' : 'crop');
          },
          onError: () => {
            showNotification({
              message: CONTACT_SUPPORT_ERROR_MESSAGE,
              severity: 'error',
            });
            updateRequestStatus('failure');
            close();
          },
        },
      );
    } catch (err) {
      showNotification({
        message: CONTACT_SUPPORT_ERROR_MESSAGE,
        severity: 'error',
      });
    }
  };

  return (
    <Stack>
      {requestStatus === 'request' && <Spinner />}
      <ImageCropper
        src={imgSource}
        aspectRatio={widgetAspectRatio}
        className="cropper-body"
        ref={cropperRef}
      />
      <Stack direction="row">
        <Button size="small" onClick={close}>
          <Typography variant="body1">CANCEL</Typography>
        </Button>
        <Button size="small" onClick={handleClick}>
          <Typography variant="body1">SAVE</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};
export default ImageCropModalContents;
