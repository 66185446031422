import { Stepper as MuiStepper, Step } from '@mui/material';
import React from 'react';
import * as S from './styles';

export interface StepperProps {
  steps: string[];
  activeStep: number;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStep }) => (
  <S.Container>
    <MuiStepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, i) => (
        <Step key={label} completed={i < activeStep}>
          <S.Label>{label}</S.Label>
        </Step>
      ))}
    </MuiStepper>
  </S.Container>
);
export default Stepper;
