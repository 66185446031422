import PodcastContentTable from 'components/PodcastContentTable';
import {
  useSegmentAudioUrl,
  useSortedSegments,
} from 'pages/ContentManagementPage/state';
import { useState } from 'react';
import { PlaybackProvider } from './PlaybackContext';
import { SegmentDetails } from './types';
import { columns } from './utils';

type EpisodeDetailsTableProps = {
  episodeId: number;
};

const EpisodeSegmentsTable: React.FC<EpisodeDetailsTableProps> = ({
  episodeId,
}) => {
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(
    null,
  );

  const { data = [], isLoading } = useSortedSegments({
    internalEpisodeId: episodeId,
  });
  const { data: audioUrl = '' } = useSegmentAudioUrl(episodeId);
  return (
    <PlaybackProvider element={audioElement} internalEpisodeId={episodeId}>
      <PodcastContentTable<SegmentDetails>
        columns={columns}
        isLoading={isLoading}
        podcastData={data}
        tableLayout="fixed"
      />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={setAudioElement} src={audioUrl} />
    </PlaybackProvider>
  );
};
export default EpisodeSegmentsTable;
