import React from 'react';
import * as S from './styles';

type PageContainerProps = {
  children: React.ReactNode;
};

const PageContainer: React.FC<PageContainerProps> = ({ children }) => (
  <S.Page maxWidth="xl">{children}</S.Page>
);
export default PageContainer;
