import { Segment } from 'api';
import { useStaticCallback } from 'hooks/useStaticCallback';
import { useEpisodeSegments } from 'pages/ContentManagementPage/state';
import { useCallback, useEffect, useState } from 'react';
import { millisToSec, secToMillis } from 'utils/time';
import { UseSegmentPlaybackConfig, UseSegmentPlaybackResult } from './types';

function getSegment(
  segments: Segment[] | undefined,
  segmentId: number | undefined,
) {
  return segments?.find((seg) => seg.id === segmentId);
}

export default function useSegmentPlayback({
  element,
  internalEpisodeId,
}: UseSegmentPlaybackConfig): UseSegmentPlaybackResult {
  const [activeSegmentId, setActiveSegmentId] = useState<number>();

  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  const { data: segments } = useEpisodeSegments(internalEpisodeId);

  const segment = getSegment(segments, activeSegmentId);

  const seekToSegmentStart = useCallback(
    (segmentId?: number) => {
      const seg =
        segmentId !== undefined ? getSegment(segments, segmentId) : segment;
      if (seg) {
        const startSec = millisToSec(seg.startMillis);

        if (element && startSec !== undefined) {
          // eslint-disable-next-line no-param-reassign
          element.currentTime = startSec;
        }
      }
    },
    [segments, element, segment],
  );

  const handlePause = useCallback(() => {
    if (element) {
      if (!element.paused) {
        element.pause();
        setPlaying(false);
      }
    }
  }, [element]);

  const staticHandleTimeupdate = useStaticCallback(() => {
    if (element && segment) {
      const { endMillis } = segment;
      if (secToMillis(element.currentTime) >= endMillis) {
        handlePause();
        seekToSegmentStart();
      }
    }
  });

  const handlePlaySegment = useCallback(
    async (segmentId: number) => {
      if (!element) {
        return undefined;
      }

      if (segmentId !== activeSegmentId) {
        handlePause();
        setActiveSegmentId(segmentId);
        seekToSegmentStart(segmentId);
      }

      if (element.paused) {
        setPlaying(true);
        return element.play();
      }

      return undefined;
    },
    [activeSegmentId, element, handlePause, seekToSegmentStart],
  );

  useEffect(() => {
    const handlePlaying = () => setLoading(false);
    const handleWaiting = () => setLoading(true);

    if (element) {
      element.addEventListener('playing', handlePlaying);
      element.addEventListener('waiting', handleWaiting);
      element.addEventListener('timeupdate', staticHandleTimeupdate);
    }

    return () => {
      if (element) {
        handlePause();
        element.removeEventListener('playing', handlePlaying);
        element.removeEventListener('waiting', handleWaiting);
        element.removeEventListener('timeupdate', staticHandleTimeupdate);
      }
    };
  }, [element, handlePause, staticHandleTimeupdate]);

  return {
    activeSegmentId,
    internalEpisodeId,
    loading,
    playing,
    pause: handlePause,
    playSegment: handlePlaySegment,
  };
}
