import {
  getMyPublisherData,
  GetPublisherWidgetArgs,
  Publisher,
} from 'api/services';
import { useQuery, UseQueryOptions } from 'react-query';
import { userDataQueries } from 'state/user';
import {
  domainWidgetNamesSelector,
  selfServeWidgetIdSelector,
  widgetIdsSelector,
  widgetOptionsSelector,
} from './selectors';

type QueryKey = ReturnType<typeof userDataQueries.publisher>;

function usePublisherData<TData = Publisher>(
  args?: GetPublisherWidgetArgs,
  opts?: Omit<
    UseQueryOptions<Publisher, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery(
    userDataQueries.publisher(args),
    () => getMyPublisherData(args),
    opts,
  );
}

export function useDomainWidgetNames() {
  return usePublisherData(
    {},
    {
      select: domainWidgetNamesSelector,
    },
  );
}

export function useWidgetIds(successHandler: (data: string[]) => void) {
  return usePublisherData<string[]>(
    {},
    {
      select: widgetIdsSelector,
      onSuccess: successHandler,
    },
  );
}

export function useGetOptionsForSelfServeWidgets() {
  return usePublisherData(
    {
      isSelfServe: true,
    },
    {
      select: widgetOptionsSelector,
    },
  );
}

export function useIsSelfServePublisher() {
  return usePublisherData(
    {
      isSelfServe: true,
    },
    {
      select: selfServeWidgetIdSelector,
    },
  );
}
