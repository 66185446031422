import React, { useCallback, useContext, useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useEventTracking } from 'state/eventTracking';
import { AuthContextType, AuthProviderProps, AuthState } from './types';
import {
  createAuthState,
  deleteToken,
  persistToken,
  rehydrateToken,
} from './utils';

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { clearUserTrackingInfo } = useEventTracking();
  const queryClient = useQueryClient();

  const [state, setState] = useState<AuthState | undefined>(() => {
    const storedToken = rehydrateToken();

    if (!storedToken) {
      return undefined;
    }

    return createAuthState(storedToken);
  });

  const login = useCallback(
    (data: AuthState) => {
      setState(createAuthState(data.accessToken, data.userId));
      queryClient.invalidateQueries();
      persistToken(data.accessToken);
    },
    [queryClient],
  );

  const handleLogout = useCallback(() => {
    setState(undefined);
    queryClient.clear();
    deleteToken();
    clearUserTrackingInfo();
  }, [queryClient, clearUserTrackingInfo]);

  const handleReplaceToken = useCallback((newToken: string) => {
    setState(createAuthState(newToken));
    persistToken(newToken);
  }, []);

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          login,
          accessToken: state?.accessToken,
          isAuthenticated: state !== undefined,
          logout: handleLogout,
          replaceToken: handleReplaceToken,
          userId: state?.userId,
        }),
        [handleLogout, handleReplaceToken, login, state],
      )}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}
