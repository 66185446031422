import React from 'react';
import { EventTrackingContext } from './EventTrackingContext';
import useMixpanelEventTracking from './useMixpanelEventTracking';

export interface EventTrackingProviderProps {}

const EventTrackingProvider: React.FC<EventTrackingProviderProps> = ({
  children,
}) => {
  const contextValue = useMixpanelEventTracking();

  return (
    <EventTrackingContext.Provider value={contextValue}>
      {children}
    </EventTrackingContext.Provider>
  );
};

export default EventTrackingProvider;
