import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const CardDescriptionText = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing()};
`;

export const CardDescriptionDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(3)} 0px;
`;

export const ChangePasswordButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const ExternalLink = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    text-decoration: underline; 
  },
`;
