import { useEffect, useRef, forwardRef } from 'react';
import SimpleBarReact from 'simplebar-react';

export interface SimplebarProps extends SimpleBarReact.Props {}

const Simplebar = forwardRef<SimpleBarReact, SimplebarProps>((props, ref) => {
  const simplebarRef = useRef<SimpleBarReact>(null);

  useEffect(() => {
    if (typeof ref === 'function') {
      ref(simplebarRef.current);
    } else if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = simplebarRef.current;
    }
  }, [ref]);

  return <SimpleBarReact {...props} ref={simplebarRef} />;
});

export default Simplebar;
