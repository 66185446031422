import {
  getDiscoImage,
  DiscoImageArgs,
  DiscoImageResponse,
} from 'api/services';
import { podcastQueries } from 'pages/ContentManagementPage/state';
import { UseQueryOptions, useQuery } from 'react-query';
import { discoImageUrlSelector } from './selectors';

type QueryKey = ReturnType<typeof podcastQueries.discoImage>;

function useDiscoImage<TData = DiscoImageResponse>(
  args: DiscoImageArgs,
  opts?: Omit<
    UseQueryOptions<DiscoImageResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<DiscoImageResponse, unknown, TData, QueryKey>(
    podcastQueries.discoImage(args),
    () => getDiscoImage(args),
    { enabled: !!args.internalEpisodeId, ...opts },
  );
}

export function useDiscoImageUrls(args: DiscoImageArgs) {
  return useDiscoImage(args, {
    select: discoImageUrlSelector,
  });
}
