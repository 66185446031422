import { HEADLINER_REDIRECT_URL } from 'config';

// urls

export const PRIVACY_POLICY =
  'https://www.headliner.app/docs/privacy-policy.html';

export const TERMS_OF_SERVICE =
  'https://www.headliner.app/docs/terms-of-service.html';

export const LINK_TO_HEADLINER = HEADLINER_REDIRECT_URL;

export const WIDGET_INSTALL_INSTRUCTIONS =
  'https://learn.headliner.app/hc/en-us/articles/8717757411607-How-to-Install-The-Self-Serve-Disco-Widget';

// string
export const SUPPORT_EMAIL = 'support@headliner.app';

export const DISCO_ADS_DESCRIPTION =
  'We know how hard it is to get your podcast out there. That’s why we created a platform that allows you to promote your podcast in Headliner and across the Internet. It’s as easy as 1, 2, 3!';

export const DISCO_WIDGET_DESCRIPTION =
  'Our widget combines eye-catching audiograms with the power of relevant recommendations to get your podcast in front of website visitors at the right time, right place.';

export const HEADLINER_STUDIO_DESCRIPTION =
  'Make helps you create and share from anywhere! From full-length, high-resolution videos to simple waveforms, Make drives discoverability and listenership, taking you from audio to audience.';

export const PLAY_ANALYTICS_DESCRIPTION =
  'The podcast player designed for discovery! See how many people are listening and following your podcast on Headliner.';

export const CONTACT_SUPPORT_ERROR_MESSAGE = `Something went wrong. Try again or email ${SUPPORT_EMAIL}`;

export const MOBILE_FALLBACK_PAGE_DESCRIPTION =
  'Sorry, this page isn’t available on screens this small.  Please visit it on a larger screen to view your data.';
// misc

export const TIMEOUT_FREQUENCY = 5000;

export const IMAGE_CARD_MAX_WIDTH = '850px';

// ------------------- colors ----------------

export const TWITTER_BACKGROUND_COLOR = '#38a1f3';

export const LINKEDIN_BACKGROUND_COLOR = '#2867b2';

export const TIKTOK_BACKGROUND_COLOR = '#000';

export const FACEBOOK_BACKGROUND_COLOR = '#3b5998';
