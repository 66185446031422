export const WIDGET_INSTALLATION_INSTRUCTIONS =
  'We currently support WordPress via a plugin and also have manual installation instructions for other platforms.';

export const WORDPRESS_PLUGIN_INSTRUCTIONS = [
  `Download the plugin `,
  'Take the following widgetID and add it the plugin settings in your WordPress dashboard.',
];

export const WIDGET_MANUAL_SETUP_LINK =
  'https://sparemin.atlassian.net/wiki/spaces/HEAD/pages/2312601601/Disco#Add-the-Disco-widget';

export const WIDGET_WORDPRESS_SETUP_LINK =
  'https://learn.headliner.app/hc/en-us/articles/13268376887959';

export const WIDGET_WORDPRESS_PLUGIN_DOWNLOAD =
  'https://wordpress.org/plugins/headliner-disco-free/';
