import {
  AllEpisodesResponse,
  AllPodcastContentResponse,
  DiscoImageResponse,
  EpisodeDetailResponse,
  EpisodeSegmentsResponse,
  PodcastEpisode,
} from 'api';
import { pick } from 'lodash';
import memoize from 'memoizee';
import { InfiniteData } from 'react-query';

export function sortedEpisodeSelector(d: EpisodeSegmentsResponse) {
  return [...d.segments].sort((a, b) => a.startMillis - b.startMillis);
}

export function segmentsSelector(d: EpisodeSegmentsResponse) {
  return d.segments;
}

export function activeSegmentSelector(
  d: EpisodeSegmentsResponse,
  activeSegment?: number,
) {
  return d.segments.find((seg) => seg.id === activeSegment);
}

export function episodeAudioUrlSelector(d: EpisodeDetailResponse): string {
  return d.audioUrl;
}

export function episodeSegmentAudioUrlSelector(d: EpisodeSegmentsResponse) {
  return d.episodeAudioUrl;
}

export function episodeDataSelector(d: InfiniteData<AllEpisodesResponse>) {
  return {
    ...d,
    pages: d.pages
      .flatMap((page) => page.episodes)
      .map((episode) =>
        pick(
          episode,
          'internalEpisodeId',
          'publishedAtMillis',
          'title',
          'thumbnailUrl',
        ),
      ),
  };
}

export function podcastAspectRatioSelector(
  d: AllPodcastContentResponse,
  podcastId: number,
) {
  const selectedPodcast = d?.podcasts.find(
    (podcast) => podcast.podcastFeedId === podcastId,
  );
  const height = selectedPodcast?.configuration.discoImageAspectRatioHeight;
  const width = selectedPodcast?.configuration.discoImageAspectRatioWidth;
  if (height && width) {
    return height / width;
  }
  return undefined;
}

export function podcastDataSelector(d: AllPodcastContentResponse) {
  return d.podcasts;
}

export function podcastTitleSelector(
  d: AllPodcastContentResponse,
  podcastId?: number,
) {
  return d.podcasts.find((podcast) => podcast.podcastFeedId === podcastId)
    ?.title;
}

export function episodeTitleSelector(d: EpisodeDetailResponse) {
  return d.title;
}

export function discoImageUrlSelector(d: DiscoImageResponse) {
  return {
    currentThumbnailUrl: d.currentThumbnailUrl,
    originalImageUrl: d.originalImageUrl,
  };
}

export function audioDurationSelector(d: EpisodeDetailResponse) {
  return d.audioDurationMillis;
}

export const episodesByIdSelector = memoize(
  (d: InfiniteData<AllEpisodesResponse>) => {
    const dictionary = d.pages.reduce((acc, page) => {
      page.episodes.forEach((episode) => {
        acc[episode.internalEpisodeId] = episode;
      });
      return acc;
    }, {} as Record<number, PodcastEpisode>);
    return { pages: [dictionary], pageParams: d.pageParams };
  },
  { max: 1 },
);

export function discoEpisodeEnabledSelector(
  d: InfiniteData<AllEpisodesResponse>,
  episodeId: number,
) {
  const episodes = episodesByIdSelector(d).pages[0];
  return {
    pages: [episodes[episodeId].discoDetail.isEnabled],
    pageParams: d.pageParams,
  };
}

export function episodeDestinationSelector(d: EpisodeDetailResponse) {
  return d.discoDetail.usePlayAppDestUrl;
}

export function episodeDestinationUrlSelector(d: EpisodeDetailResponse) {
  return d.discoDetail.destUrl;
}
