import { millisToSec } from 'utils/time';

export const seekToAudioTimeCode = (
  time: number,
  element: HTMLAudioElement | null,
) => {
  const startSec = millisToSec(time);
  if (element && startSec !== undefined) {
    // eslint-disable-next-line no-param-reassign
    element.currentTime = startSec;
  }
};
