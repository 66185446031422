import { GetAdCampaignResponse, getAdCampaignInfo } from 'api';
import { UseQueryOptions, useQuery } from 'react-query';
import { adCampaignsQueries } from './queryKeys';
import {
  hasAdCampaignsSelector,
  previousAdCampaignsSelector,
} from './selectors';

type QueryKey = ReturnType<typeof adCampaignsQueries.adCampaignInfo>;

function useGetAdCampaignInfo<TData = GetAdCampaignResponse>(
  opts?: Omit<
    UseQueryOptions<GetAdCampaignResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery(
    adCampaignsQueries.adCampaignInfo(),
    () => getAdCampaignInfo(),
    opts,
  );
}

export const useHasAdCampaigns = () =>
  useGetAdCampaignInfo({ select: hasAdCampaignsSelector });

export const usePreviousAdCampaigns = () =>
  useGetAdCampaignInfo({ select: previousAdCampaignsSelector });
