import DiscoWidgetMarketingCard from 'components/DiscoWidgetMarketingCard';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { DashboardContent } from 'pages/Dashboard';
import React from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useModalControls } from 'state/modals';
import { SUPPORT_EMAIL } from 'utils/constants';
import { WIDGET_DESCRIPTION, WIDGET_HEADER } from './constants';
import * as S from './styles';

type WidgetMarketingPageProps = {};

const WidgetMarketingPage: React.FC<WidgetMarketingPageProps> = () => {
  const { trackWidgetCreation } = useEventTracking();
  const isMobile = useMobileBreakpoint('lg');
  const modalName = 'WidgetMarketing';
  const { open } = useModalControls();

  useTrackPageLoad({
    section: 'DiscoWidget',
    page: 'EmptyState',
    state: 'Empty',
  });

  const handleClick = () => {
    open({ name: modalName });
    trackWidgetCreation('createWidget');
  };

  const handleContactSupport = () => {
    trackWidgetCreation('contactSupport');
  };

  return (
    <DashboardContent maxWidth="md">
      <DiscoWidgetMarketingCard
        heading={WIDGET_HEADER}
        description={WIDGET_DESCRIPTION}
      >
        <S.Container
          direction={isMobile ? 'column-reverse' : 'row'}
          spacing={2}
        >
          <S.MailToButton
            subject="Help setting up widget"
            email={SUPPORT_EMAIL}
            label="get enterprise support"
            fullWidth
            variant="outlined"
            onClick={handleContactSupport}
          />
          <S.Button variant="contained" fullWidth onClick={handleClick}>
            set up your widget now
          </S.Button>
        </S.Container>
      </DiscoWidgetMarketingCard>
    </DashboardContent>
  );
};
export default WidgetMarketingPage;
