import { Spacer, breakpointDown } from '@sparemin/blockhead';
import styled from 'styled-components';

export const Root = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  space: '32px',
})`
  width: 100%;
`;

export const FieldsContainer = styled(Spacer).attrs({
  orientation: 'vertical',
  align: 'center',
  space: '32px',
})`
  width: 100%;
`;

export const VerticalContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 44px;
  align-items: center;

  ${breakpointDown('md')`
    gap: 24px;
    flex-direction: column;
  `}
`;
