import Grid from '@mui/material/Grid';
import { RoleName } from 'api/services';
import ScopeCheckContainer from 'components/ScopeCheckContainer';
import StatsPeriodSelector, {
  getDefaultPeriodArgs,
} from 'components/StatsPeriodSelector';
import { DashboardContent, DashboardContentTitle } from 'pages/Dashboard';
import DashboardPageFilters from 'pages/Dashboard/DashboardPageFilters';
import NoDataPage from 'pages/NoDataPage';
import React, { useState } from 'react';
import AdvertisersDashboardProvider from './AdvertisersDashboardContext';
import CampaignIdSelector from './CampaignIdSelector';
import DailyStatsCard from './DailyStatsCard';
import DailyStatsChartCard from './DailyStatsChartCard';
import { useCampaignIds } from './state';
import { SelectedCampaignSids } from './types';

const AdvertisersDashboard: React.FunctionComponent = () => {
  const [periodArgs, setPeriodArgs] = useState(getDefaultPeriodArgs());
  const [selectedCids, setSelectedCids] = useState<
    SelectedCampaignSids | undefined
  >(undefined);
  const [draftCids, setDraftCids] = useState<SelectedCampaignSids | undefined>(
    selectedCids,
  );
  const data = useCampaignIds(setDraftCids);

  const campaignIds = data.isSuccess ? data.data : [];

  const handleClose = () => {
    setSelectedCids(draftCids);
  };

  return (
    <ScopeCheckContainer
      requiredScopes={[RoleName.advertiser]}
      fallback={
        <NoDataPage
          trackingProps={{
            section: 'DiscoPromo',
            page: 'DailyStats',
            state: 'Empty',
          }}
        />
      }
    >
      <AdvertisersDashboardProvider
        campaignIds={selectedCids || []}
        periodArgs={periodArgs}
      >
        <DashboardContent
          maxWidth="xl"
          title={
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <DashboardContentTitle>Daily Stats</DashboardContentTitle>
              </Grid>
              <DashboardPageFilters
                filters={[
                  <CampaignIdSelector
                    onChange={setDraftCids}
                    onClose={handleClose}
                    value={draftCids || []}
                    campaignIds={campaignIds}
                  />,
                  <StatsPeriodSelector onSelectPeriod={setPeriodArgs} />,
                ]}
              />
            </Grid>
          }
        >
          <Grid container spacing={2} marginBottom={5}>
            <Grid item xs={12} md={12}>
              <DailyStatsChartCard />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <DailyStatsCard />
            </Grid>
          </Grid>
        </DashboardContent>
      </AdvertisersDashboardProvider>
    </ScopeCheckContainer>
  );
};

export default AdvertisersDashboard;
