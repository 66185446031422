import { useMemo } from 'react';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { useExportData } from 'react-table-plugins';
import { reports } from 'utils/table';
import { BaseTableData, DailyStatsTableConfig } from './types';

const useDailyStatsTable = <TData extends BaseTableData>(
  config: DailyStatsTableConfig<TData>,
) => {
  const { columns, dailyStats, defaultSort, exportArgs } = config;

  const data = useMemo(() => dailyStats.data, [dailyStats.data]);
  const tableInstance = useTable(
    {
      columns,
      data,
      getExportFileBlob:
        exportArgs && reports.exportTableToCsv(exportArgs?.formatter),
      getExportFileName: exportArgs && (() => exportArgs.fileName),
      initialState: {
        sortBy: defaultSort,
      },
    },
    useSortBy,
    useExportData,
    useFlexLayout,
  );

  return { loading: dailyStats.loading, tableInstance };
};

export default useDailyStatsTable;
