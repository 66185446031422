import { columnInfoLookup } from 'components/TableCells';
import Papa from 'papaparse';
import { ExportBlobArguments } from 'react-table-plugins';

/**
 * Basic params formatter. It received a data row formatter and
 * applies it to each row's flattened info when formatting the output.
 * @param {Function} dataRowFormatter - Row formatter, it will be
 * called for each row processed in order to format the information
 * @returns {Function} Formatter to be provided to table getExportFileBlob
 * function
 */
export const exportTableToCsv =
  <TInput extends unknown[], TOutput extends unknown[]>(
    dataRowFormatter?: (data: TInput) => TOutput,
  ) =>
  (exportData: ExportBlobArguments<TInput>) => {
    const { columns, data } = exportData;
    const headerNames = columns.map((col) => {
      const key = col.exportValue;
      if (key in columnInfoLookup) {
        const result = columnInfoLookup[key as keyof typeof columnInfoLookup];
        return result.text;
      }
      return col.exportValue;
    });
    const csvString = Papa.unparse({
      fields: headerNames,
      data: data?.map?.((rowData) =>
        dataRowFormatter ? dataRowFormatter(rowData) : rowData,
      ),
    });
    return new Blob([csvString], { type: 'text/csv' });
  };
