import discoWidget from 'assets/widgetImagery/imagery-widget.png';
import discoWidget2x from 'assets/widgetImagery/imagery-widget@2x.png';
import discoWidget3x from 'assets/widgetImagery/imagery-widget@3x.png';
import ImageCardWithCenteredContents, {
  ImageCardWithCenteredContentsProps,
} from 'components/ImageCardWithCenteredContents';

interface DiscoWidgetMarketingCardProps
  extends Omit<ImageCardWithCenteredContentsProps, 'src' | 'srcSet'> {}

const DiscoWidgetMarketingCard: React.FC<DiscoWidgetMarketingCardProps> = ({
  children,
  ...props
}) => {
  const srcSet = `${discoWidget} 1x, ${discoWidget2x} 2x, ${discoWidget3x} 3x`;

  return (
    <ImageCardWithCenteredContents src={discoWidget} srcSet={srcSet} {...props}>
      {children}
    </ImageCardWithCenteredContents>
  );
};
export default DiscoWidgetMarketingCard;
