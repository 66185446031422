import widget from 'assets/widgetEducationImagery/self-serve-image.png';
import widget2x from 'assets/widgetEducationImagery/self-serve-image@2x.png';
import widget3x from 'assets/widgetEducationImagery/self-serve-image@3x.png';
import {
  WIDGET_MARKETING_MODAL_TITLE,
  WIDGET_MARKETING_MODAL_DESCRIPTION,
} from './constants';
import * as S from './styles';

type WidgetMarketingModalContentsProps = {};

const WidgetMarketingModalContents: React.FC<
  WidgetMarketingModalContentsProps
> = () => {
  const srcSet = `${widget} 1x, ${widget2x} 2x, ${widget3x} 3x`;

  return (
    <S.Body spacing={3}>
      <S.Typography variant="h1" textAlign="center">
        {WIDGET_MARKETING_MODAL_TITLE}
      </S.Typography>
      <S.Typography variant="h2" textAlign="center">
        {WIDGET_MARKETING_MODAL_DESCRIPTION}
      </S.Typography>
      <img src={widget} srcSet={srcSet} alt="" />
    </S.Body>
  );
};
export default WidgetMarketingModalContents;
