import { Button } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import CategorySelector from './CategorySelector';
import * as S from './styles';
import TargetCountrySelector from './TargetCountrySelector';

export interface TargetingSelectionProps {
  onContinue: () => void;
}

const TargetingSelection: React.FC<TargetingSelectionProps> = ({
  onContinue,
}) => {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();

  const handleContinue = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'Targeting',
    });
    onContinue();
  }, [onContinue, trackPodcastPromoPurchaseNext]);

  return (
    <S.Root>
      <S.FieldsContainer>
        <S.VerticalContainer>
          <CategorySelector />
          <TargetCountrySelector />
        </S.VerticalContainer>
      </S.FieldsContainer>

      <Button fluid onPress={handleContinue}>
        continue with these targets
      </Button>
    </S.Root>
  );
};

export default TargetingSelection;
