import React, { useContext, useMemo } from 'react';
import { PodcastContextType, PodcastProviderProps } from './types';

const PodcastContext = React.createContext<PodcastContextType | undefined>(
  undefined,
);

export const PodcastProvider: React.FC<PodcastProviderProps> = ({
  children,
  podcastId,
}) => (
  <PodcastContext.Provider value={useMemo(() => ({ podcastId }), [podcastId])}>
    {children}
  </PodcastContext.Provider>
);

export function usePodcast() {
  const context = useContext(PodcastContext);

  if (context === undefined) {
    throw new Error('usePodcast must be used within PodcastProvider');
  }

  return context;
}
