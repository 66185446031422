import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

const playIconSize = '0.75em';

export const PlaybackButton = styled(
  withAttrs(LoadingButton, {
    color: 'primary',
    variant: 'contained',
  }),
)`
  border-radius: 100%;
  flex-shrink: 0;
  height: 30px;
  min-width: 0;
  padding: 0;
  width: 30px;
`;

export const PlayIcon = styled(PlayArrow)`
  height: ${playIconSize};
  width: auto;
`;

export const PauseIcon = styled(Pause)`
  height: ${playIconSize};
  width: auto;
`;
