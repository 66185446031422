import { ButtonProps, Typography } from '@mui/material';
import * as S from './styles';

export interface MailToProps extends ButtonProps {
  email?: string;
  subject?: string;
  label?: string;
  onClick?: () => void;
}

const MailTo: React.FC<MailToProps> = ({
  email,
  subject,
  label,
  variant = 'contained',
  size = 'large',
  color = 'primary',
  fullWidth,
  onClick,
}) => {
  const params = subject ? `?subject=${encodeURIComponent(subject)}` : '';
  return (
    <S.RoundedButton
      href={`mailto:${email}${params}`}
      variant={variant}
      size={size}
      color={color}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      <Typography variant="button">{label}</Typography>
    </S.RoundedButton>
  );
};
export default MailTo;
