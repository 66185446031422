import { breakpointDown, getThemeColor } from '@sparemin/blockhead';
import InfoCard from 'components/InfoCard';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;

  ${breakpointDown('md')`
    flex-direction: column;
    gap: 20px;
  `}
`;

export const Card = styled(InfoCard)`
  width: 100%;
  height: 345px;
  position: relative;
  border-width: 2px;
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeadlinerCardContainer = styled(CardContainer)`
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.25)
    ),
    linear-gradient(117deg, rgb(93, 190, 252), rgb(221, 146, 254));
`;

export const PublisherCardContainer = styled(CardContainer)`
  background-color: ${getThemeColor('l3')};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  padding: 0 24px;
  padding-bottom: 24px;
`;
