import { yupResolver } from '@hookform/resolvers/yup';
import { AudioPlaybackMethod, WidgetVariantId } from 'api/services';
import { useNotification } from 'components/Notification';
import { useCreateWidget } from 'pages/Widget/state';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { userDataQueries } from 'state/user';
import routes from 'utils/routes';
import { object, string, SchemaOf } from 'yup';
import { WIDGET_CREATION_ERROR_MESSAGE } from './constants';
import { CreateWidgetErrorTypes } from './types';
import { createWidgetErrorMessages } from './utils';

export type CreateWidgetFormData = {
  feedUrl: string;
  websiteUrl: string;
};

export type UseCreateWidgetFormConfig = {
  onClose?: () => void;
  onErrorFromServer?: () => void;
  onSuccess?: () => void;
  onSubmit?: () => void;
  widgetStyleVariant: WidgetVariantId;
  audioPlaybackMethod: AudioPlaybackMethod;
};

const schema: SchemaOf<CreateWidgetFormData> = object()
  .shape({
    feedUrl: string()
      .url('Incorrect RSS Feed Format.')
      .required('RSS Feed is required.'),
    websiteUrl: string()
      .url('Incorrect format for a url.')
      .required('Website url is required.'),
  })
  .required();

export default function useCreateWidgetForm(config: UseCreateWidgetFormConfig) {
  const {
    onClose,
    onErrorFromServer,
    onSubmit,
    onSuccess,
    widgetStyleVariant,
    audioPlaybackMethod,
  } = config;
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    ...form
  } = useForm<CreateWidgetFormData>({
    resolver: yupResolver(schema),
  });

  const { showError } = useNotification();

  const queryClient = useQueryClient();
  const widgetState = useCreateWidget({
    onSuccess: () => {
      queryClient.invalidateQueries(userDataQueries.all);
    },
  });

  const getWidgetFormError = (): CreateWidgetErrorTypes | undefined => {
    if (widgetState.isError) return 'createWidgetError';
    if (widgetState.isSuccess) return widgetState.data.creationStatus;
    return undefined;
  };

  const formError = getWidgetFormError();
  const errorFromServer =
    formError && formError !== 'success'
      ? createWidgetErrorMessages[formError]
      : undefined;

  const onHandleSubmit = ({ feedUrl, websiteUrl }: CreateWidgetFormData) => {
    onSubmit?.();
    widgetState.mutateAsync(
      {
        rssUrl: feedUrl,
        websiteUrl,
        displayVariantId: widgetStyleVariant,
        audioPlaybackMethod,
      },
      {
        onSuccess: (data) => {
          if (data.creationStatus === 'success') {
            onClose?.();
            onSuccess?.();
            navigate(routes.discoWidget.path, { replace: true });
          }
          onErrorFromServer?.();
        },
        onError: () => {
          showError(WIDGET_CREATION_ERROR_MESSAGE);
          onErrorFromServer?.();
        },
      },
    );
  };

  return {
    ...form,
    errors,
    errorFromServer,
    onSubmit: handleSubmit(onHandleSubmit),
  };
}
