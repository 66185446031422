import { Stack, Typography, useTheme } from '@mui/material';
import { Visibility, Heart, Comment } from '@sparemin/blockhead';
import React from 'react';
import * as S from './styles';

export type SocialStatsRowProps = {
  likeCount: number;
  viewCount: number;
  commentCount: number;
  platform?: React.ReactNode;
  isViewCountStale?: boolean;
};

const SocialStatsRow: React.FC<SocialStatsRowProps> = ({
  likeCount,
  viewCount,
  commentCount,
  platform,
  isViewCountStale,
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-end"
      marginTop="24px"
    >
      {platform}
      <S.StatIconContainer>
        <Visibility
          width="23px"
          height="16px"
          color={theme.palette.grey[700]}
        />
        <Typography variant="h2">
          <Stack direction="row">
            {viewCount}
            {isViewCountStale && <span>*</span>}
          </Stack>
        </Typography>
      </S.StatIconContainer>
      <S.StatIconContainer>
        <Heart width="16px" height="16px" color={theme.palette.grey[700]} />

        <Typography variant="h2">{likeCount}</Typography>
      </S.StatIconContainer>
      <S.StatIconContainer>
        <Comment width="16px" height="16px" color={theme.palette.grey[700]} />

        <Typography variant="h2">{commentCount}</Typography>
      </S.StatIconContainer>
    </Stack>
  );
};
export default SocialStatsRow;
