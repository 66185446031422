import { getRedirectPath } from 'components/RequireAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'state/auth';

export interface RequireUnauthProps {
  children?: React.ReactNode;
}

const RequireUnauth: React.FC<RequireUnauthProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  if (isAuthenticated) {
    return <Navigate to={getRedirectPath(location)} replace />;
  }

  return <>{children}</>;
};

export default RequireUnauth;
