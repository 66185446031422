import { Button, Link } from '@sparemin/blockhead';
import network from 'assets/adsImagery/network/network.png';
import network2x from 'assets/adsImagery/network/network@2x.png';
import network3x from 'assets/adsImagery/network/network@3x.png';
import InfoCard from 'components/InfoCard';
import React from 'react';
import * as S from './styles';

export interface PublisherNetworkCardProps {
  onPress: () => void;
}

const PublisherNetworkCard: React.FC<PublisherNetworkCardProps> = ({
  onPress,
}) => (
  <S.Card>
    <S.PublisherCardContainer>
      <InfoCard.Image>
        <img
          srcSet={`${network} 1x, ${network2x} 2x, ${network3x} 3x`}
          alt="A row of logos for various news outlets. The logos are arranged horizontally on a gray background."
          height="65px"
        />
      </InfoCard.Image>

      <InfoCard.Contents
        badge="Better targeting"
        heading="Across our Publisher Network"
        subheading={
          <>
            While this option is a bit more expensive, it’s ideal for more niche
            podcasts because you can take advantage of world-class targeting
            capabilities (
            <Link
              href="https://learn.headliner.app/hc/en-us/articles/22045858630679-What-is-Podcast-Promo"
              target="_blank"
            >
              learn more
            </Link>
            ).
          </>
        }
      >
        <S.ButtonContainer>
          <Button fluid onPress={onPress}>
            Select this option
          </Button>
        </S.ButtonContainer>
      </InfoCard.Contents>
    </S.PublisherCardContainer>
  </S.Card>
);

export default PublisherNetworkCard;
