import { CampaignCreationViewStep } from '../CampaignCreationContext/types';

export const CAMPAIGN_CREATION_VIEW_STEPS: CampaignCreationViewStep[] = [
  'episode',
  'placement',
  'objective',
];

export const STEP_TITLES: Record<CampaignCreationViewStep, string> = {
  episode: 'Select an episode to promote',
  placement: 'Where would you like to promote?',
  targeting: 'Target Category and Location',
  objective: 'Select your objective',
};
