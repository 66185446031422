import MailTo, { MailToProps } from 'components/MailTo';
import { SUPPORT_EMAIL } from 'utils/constants';
import { DEFAULT_LABEL, DEFAULT_SUBJECT } from './constants';

const MailToSupport: React.FC<MailToProps> = ({
  email = SUPPORT_EMAIL,
  subject = DEFAULT_SUBJECT,
  label = DEFAULT_LABEL,
  onClick,
}) => (
  <MailTo email={email} subject={subject} label={label} onClick={onClick} />
);
export default MailToSupport;
