import { Box, Tab, Tabs as MuiTabs } from '@mui/material';
import theme from 'components/Theme/theme';
import React from 'react';
import * as S from './styles';
import TabPanel from './TabPanel';

type TabContent = {
  label: string;
  content: React.ReactNode;
  icon?: React.ReactElement;
};

export interface TabProps {
  content: TabContent[];
}

const Tabs: React.FC<TabProps> = ({ content }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <S.Container>
      <Box borderBottom={2} borderColor={theme.palette.divider}>
        <MuiTabs value={value} onChange={handleChange} variant="fullWidth">
          {content.map((tab) => (
            <Tab
              icon={tab.icon}
              iconPosition="start"
              label={tab.label}
              key={tab.label}
            />
          ))}
        </MuiTabs>
      </Box>
      {content.map((tab, index) => (
        <TabPanel value={value} index={index} key={tab.label}>
          {tab.content}
        </TabPanel>
      ))}
    </S.Container>
  );
};

export default Tabs;
