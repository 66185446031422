import { Mic, Followers } from '@sparemin/blockhead';
import Tabs from 'components/Tabs';
import React from 'react';
import PodcastEpisodesTable from './PodcastEpisodesTable';
import PodcastFollowers from './PodcastFollowers';

type PodcastEpisodesViewProps = {};

const PodcastEpisodesView: React.FC<PodcastEpisodesViewProps> = () => (
  <Tabs
    content={[
      { label: 'episodes', content: <PodcastEpisodesTable />, icon: <Mic /> },
      {
        label: 'followers',
        content: <PodcastFollowers />,
        icon: <Followers />,
      },
    ]}
  />
);
export default PodcastEpisodesView;
