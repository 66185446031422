import { Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextButton = styled(Button)`
  text-transform: none;
`;

export const Background = styled(Box)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  margin-bottom: 10px;
`;
