import { updateDiscoImage, UpdateDiscoImageArgs } from 'api/services';

import { podcastQueries } from 'pages/ContentManagementPage/state';
import { useQueryClient, useMutation, UseMutationOptions } from 'react-query';

export default function useUpdateDiscoImage(
  config?: Omit<
    UseMutationOptions<void, unknown, UpdateDiscoImageArgs, unknown>,
    'mutationFn'
  >,
) {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, UpdateDiscoImageArgs>(
    (args: UpdateDiscoImageArgs) => updateDiscoImage(args),
    {
      ...config,
      onSuccess: (data, args, context) => {
        const id = { internalEpisodeId: args.internalEpisodeId };
        queryClient.invalidateQueries(podcastQueries.discoImage(id));
        if (config?.onSuccess) {
          config?.onSuccess(data, args, context);
        }
      },
    },
  );
}
