import {
  getPodcastEpisodes,
  AllEpisodesArgs,
  AllEpisodesResponse,
} from 'api/services';
import { useCallback } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryOptions } from 'types';
import { usePodcastFeed } from './PodcastFeedContext';
import { podcastQueries } from './queryKeys';
import { discoEpisodeEnabledSelector, episodeDataSelector } from './selectors';

type QueryKey = ReturnType<typeof podcastQueries.feed>;

type Options<TData = AllEpisodesResponse> = UseInfiniteQueryOptions<
  AllEpisodesResponse,
  QueryKey,
  unknown,
  TData
>;

function useAllEpisodes<TData = AllEpisodesResponse>(
  args: AllEpisodesArgs,
  opts?: Options<TData>,
) {
  return useInfiniteQuery(
    podcastQueries.feed(args),
    ({ pageParam }) =>
      getPodcastEpisodes(args, { nextEpisodePubAtMillis: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.nextEpisodePubAtMillis ?? undefined,
      enabled: !!args.podcastFeedId,
      ...opts,
    },
  );
}

export function useEpisodeTableData(
  podcastId: number | undefined,
  opts?: Options<{
    internalEpisodeId: number;
    publishedAtMillis: number;
    title: string;
    thumbnailUrl: string;
  }>,
) {
  return useAllEpisodes(
    { podcastFeedId: podcastId },
    {
      select: episodeDataSelector,
      ...opts,
    },
  );
}

export function useDiscoEpisodeEnabled(episodeId: number) {
  const { podcastId } = usePodcastFeed();
  const { data, ...rest } = useAllEpisodes(
    { podcastFeedId: podcastId },
    {
      select: useCallback(
        (d: InfiniteData<AllEpisodesResponse>) =>
          discoEpisodeEnabledSelector(d, episodeId),
        [episodeId],
      ),
    },
  );
  return { data: data?.pages[0], ...rest };
}
