import {
  AuthProvider as AuthRoutesProvider,
  AuthPageLayout,
} from '@sparemin/auth';
import AuthLink from 'components/AuthLink';
import RequireUnauth from 'components/RequireUnauth';
import { Outlet } from 'react-router-dom';
import routes from 'utils/routes';

type UserAuthenticationRouteProps = {};

const UserAuthenticationRoute: React.FC<UserAuthenticationRouteProps> = () => (
  <AuthRoutesProvider
    navigation={{
      routes: {
        login: routes.login.path,
        'sign-up': routes.register.path,
      },
      linkComponent: AuthLink,
    }}
  >
    <RequireUnauth>
      <AuthPageLayout>
        <Outlet />
      </AuthPageLayout>
    </RequireUnauth>
  </AuthRoutesProvider>
);
export default UserAuthenticationRoute;
