import { forwardRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';

interface ImageCropperProps {
  src?: string;
  aspectRatio?: number;
  className?: string;
}

const ImageCropper = forwardRef<
  HTMLImageElement | ReactCropperElement,
  ImageCropperProps
>((props, ref) => {
  const { src, aspectRatio, className } = props;

  return (
    <Cropper
      src={src}
      className={className} // Cropper.js options
      aspectRatio={aspectRatio}
      minCropBoxHeight={10}
      minCropBoxWidth={10}
      guides
      background={false}
      viewMode={2}
      ref={ref}
    />
  );
});

export default ImageCropper;
