import { RoleName } from 'api/services';
import ScopeCheckContainer from 'components/ScopeCheckContainer';
import React from 'react';
import WidgetMarketingPage from './WidgetMarketingPage';
import WidgetPage from './WidgetPage';

type WidgetProps = {};

const Widget: React.FC<WidgetProps> = () => (
  <ScopeCheckContainer
    requiredScopes={[RoleName.publisher]}
    fallback={<WidgetMarketingPage />}
  >
    <WidgetPage />
  </ScopeCheckContainer>
);
export default Widget;
