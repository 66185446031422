import {
  AllEpisodesArgs,
  AllPodcastsArgs,
  DiscoImageArgs,
  EpisodeDetailArgs,
  EpisodeSegmentsArgs,
} from 'api/services';

export const podcastQueries = {
  all: ['podcasts'] as const,
  publisher: (args: AllPodcastsArgs) => [...podcastQueries.all, args],
  feed: (args: AllEpisodesArgs) => [...podcastQueries.all, 'feed', args],
  episode: (args: EpisodeDetailArgs) => [
    ...podcastQueries.all,
    'episode',
    args,
  ],
  segments: (args: EpisodeSegmentsArgs) => [
    ...podcastQueries.episode({ internalEpisodeId: args.internalEpisodeId }),
    'segments',
  ],

  discoImage: (args: DiscoImageArgs) => [...podcastQueries.all, 'image', args],
};
