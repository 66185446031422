import { groupBy, isNumber } from 'lodash';
import { displayDate } from 'utils/date';
import {
  BaseSeriesData,
  ChartData,
  FilterKey,
  SeriesCollector,
  SeriesFilter,
  SeriesOption,
} from './types';

export const getSelectedStatOption = <TData extends BaseSeriesData>(
  selectedStatOption: keyof TData,
  statOptions: SeriesOption<TData>[],
): SeriesOption<TData> | undefined =>
  statOptions.find((option) => option.value === selectedStatOption);

export const isPercentDataset = <TData extends BaseSeriesData>(
  selectedStatOption?: SeriesOption<TData>,
): boolean => !!selectedStatOption?.isPercentage;

export const getYAxisFormatter =
  <TData extends BaseSeriesData>(
    selectedStatOption?: SeriesOption<TData>,
  ): ((value: string | number) => string) =>
  (value: string | number): string => {
    const isPercent = isPercentDataset(selectedStatOption);
    const formattedValue = selectedStatOption?.formatter?.(value) ?? value;

    return isPercent ? `${formattedValue}%` : ` ${formattedValue}`;
  };

export const getYAxisLabel = <TData extends BaseSeriesData>(
  selectedStatOption?: SeriesOption<TData>,
): string => selectedStatOption?.label ?? '';

export const shouldAllowDecimals = <TData extends BaseSeriesData>(
  selectedStatOption?: SeriesOption<TData>,
): boolean => !!selectedStatOption?.allowDecimals;

/**
 * Creates a model of the dataset object. By default it will always contain total property.
 * @param {Object} [seriesFilter] - Contains an object that should point to a particular prop from input data and an
 * array of values for grouping registries into a single entry
 * @returns Series collector object, it always contains the "total" key and alternatively it
 * add additional series for the grouped values.
 */
export const buildSeriesCollector = <
  TData extends BaseSeriesData,
  TFilterKey extends FilterKey<TData>,
>(
  seriesFilter?: SeriesFilter<TData, TFilterKey>,
): SeriesCollector<TData> => {
  // If provided, one entry is added to the object with each of the target group keys
  const filters = seriesFilter?.filters ?? [];
  return filters.reduce(
    (acc, seriesKey) => {
      acc[seriesKey] = 0;
      return acc;
    },
    { total: 0 } as SeriesCollector<TData>,
  );
};

/**
 * Iterates a date grouped set of data entries and maps the values to a series collector object. It
 * assigns every value to total and individually assings it to the matching grouping entry to which
 * it belongs
 * @param {string} selectedStat - Selected data attribute to be plotted. It should belong to series data
 * @param {string} [targetGroupingProp] - an optional reference to the data object grouping property
 * @returns - A populated series collector object
 */
export const processDatasetEntry =
  <TData extends BaseSeriesData>(
    selectedStat: keyof TData,
    targetGroupingProp?: keyof TData,
  ) =>
  (
    // keeping series collector var name as acc as this is an eslint rule allowed name for prop param
    // mutation (it is the acc var from a reduce funtion)
    acc: SeriesCollector<TData>,
    stat: TData,
  ): SeriesCollector<TData> => {
    // gets the particular stats raw value and parses it
    const statRawValue = stat[selectedStat];
    const statValue = isNumber(statRawValue) ? statRawValue : 0;

    // for each key in the series collector its name is checked. If it is total, value is accumulated,
    // if not value is only added if the grouping key matches
    Object.keys(acc).forEach((filterKey) => {
      const accFieldKey = filterKey as
        | TData[Exclude<keyof TData, 'date'>]
        | 'total';
      if (accFieldKey === 'total') {
        acc.total += statValue;
      } else if (targetGroupingProp) {
        acc[accFieldKey] +=
          stat[targetGroupingProp] === accFieldKey ? statValue : 0;
      }
    });
    return acc;
  };

/**
 * Maps a composed data group for a single date into a single seriesCollector or dataset object
 * @param {string} selectedStat - Selected data attribute to be plotted. It should belong to series data
 * @param {Object} seriesCollector - Object representing a chart point dataset object.
 * @param {string} [targetGroupingProp] - an optional reference to the data object grouping property
 * @returns - A full dataset entry object. This contains the formatted date entry for the x axis and
 * each accumulated entry of the series collector merged into a single object
 */
export const mapDataset =
  <TData extends BaseSeriesData>(
    selectedStat: keyof TData,
    seriesCollector: SeriesCollector<TData>,
    targetGroupingProp?: keyof TData,
  ) =>
  ([, stats]: [unknown, TData[]]): ChartData => {
    // process all the date-grouped data entries for populating the series collector object
    const valueSet = stats.reduce(
      processDatasetEntry(selectedStat, targetGroupingProp),
      { ...seriesCollector },
    );

    // parses the first element (it can be assumed that it will always exist) for setting the date attrubute
    const dateMillis = stats[0]?.dateMillis ?? 0;

    // finally dataset entry is created by merging date with the series collector object
    return {
      date: displayDate(dateMillis),
      ...valueSet,
    };
  };

/**
 * Builds chart's datasets array.
 * @param {Array} seriesData - Input data, it should be an array of elements containing the field 'dateMillis'
 * @param {string} selectedStat - Selected data attribute to be plotted. It should belong to series data
 * @param {Object} [seriesFilter] - Contains an object that should point to a particular prop from input data and an
 * array of values for grouping registries into a single entry
 * @returns An array of dataset object entry that always contains "total" entry. Additionally it will contain
 * an entry per filter value at the series filter. Each key will have a numeric value associated to it.
 */
export const buildDatasets = <
  TData extends BaseSeriesData,
  TFilterKey extends FilterKey<TData>,
>(
  seriesData: TData[],
  selectedStat: keyof TData,
  seriesFilter?: SeriesFilter<TData, TFilterKey>,
): ChartData[] => {
  // First it builds the series collector object for collecting each dataset entry.
  const seriesCollector = buildSeriesCollector(seriesFilter);
  // Then it selects the target grouping key if provided
  const targetGroupingProp = seriesFilter?.targetKey;
  // group by creates single entries for each date, by doing so each entry will contain
  // an array with all the entries matching a single date. That entry should be then
  // mapped to a single seriesCollector object or dataset entry.
  return (
    Object.entries(groupBy(seriesData, 'dateMillis'))
      .map(mapDataset(selectedStat, seriesCollector, targetGroupingProp))
      // As BE returns data in descending order, it is required to reverse the
      // datasets array for plotting the information in the correct order
      .reverse()
  );
};
