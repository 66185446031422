import React from 'react';

/**
 * A workaround for styled-component's `.attrs` function, for use with MUI's
 * `styled` utility.
 */
export function withAttrs<
  C extends React.ElementType,
  // MUI has an issue where React.ComponentProps<MUIComponent> does not report
  // the `component` prop (used for polymorphism) as one of the props.  This
  // "any object" intersection supports adding the `component` prop in the set
  // of attributes.  This intersection also exists in styled-components signature
  // for the `.attrs` function
  A extends Partial<React.ComponentProps<C>> & { [others: string]: any },
>(Component: C, attrs: A) {
  return (props: React.ComponentProps<C>) => (
    // TODO: see if there's a workaround for this type error
    // TS reports an error here that attrs doesn't conform to LibraryManagedAttributes.
    // @ts-ignore
    <Component {...attrs} {...props} />
  );
}
