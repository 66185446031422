import { CardActionAreaProps } from '@mui/material';
import React from 'react';
import * as S from './styles';

interface CardButtonProps extends CardActionAreaProps {}

const CardButton: React.FC<CardButtonProps> = ({ onClick, children }) => (
  <S.Card variant="outlined">
    <S.Button onClick={onClick}>{children}</S.Button>
  </S.Card>
);
export default CardButton;
