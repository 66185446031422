import { Stack, Typography } from '@mui/material';
import React from 'react';
import { WIDGET_INSTALLATION_INSTRUCTIONS } from './constants';
import ManualInstructions from './ManualInstructions';
import WordpressInstructions from './WordpressInstructions';

type InstructionsTabProps = {
  widgetId: string;
};

const InstructionsTab: React.FC<InstructionsTabProps> = ({ widgetId }) => (
  <Stack marginTop={3} spacing={1} marginBottom="24px">
    <Stack spacing={1}>
      <Typography variant="h3">Installation Instructions: </Typography>
      <Typography variant="body1">
        {WIDGET_INSTALLATION_INSTRUCTIONS}
      </Typography>
    </Stack>
    <WordpressInstructions widgetId={widgetId} />
    <ManualInstructions widgetId={widgetId} />
  </Stack>
);
export default InstructionsTab;
