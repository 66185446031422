import { CircularProgress } from '@mui/material';
import * as S from './styles';

export type SpinnerPropTypes = {};

export default function Spinner() {
  return (
    <S.LoadingBackdrop open>
      <CircularProgress />
    </S.LoadingBackdrop>
  );
}
