import { getAllPodcastContent, AllPodcastContentResponse } from 'api/services';
import { useCallback } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { ownedPodcastQueries } from './queryKeys';
import {
  hasClaimedPodcastsSelector,
  podcastDataSelector,
  podcastFeedIdSelector,
  podcastTitleSelector,
} from './selectors';

type QueryKey = typeof ownedPodcastQueries.all;

function useClaimedPodcasts<TData = AllPodcastContentResponse>(
  opts?: Omit<
    UseQueryOptions<AllPodcastContentResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<AllPodcastContentResponse, unknown, TData, QueryKey>(
    ownedPodcastQueries.all,
    () => getAllPodcastContent({ ownedPodcastsOnly: true }),
    opts,
  );
}

export function useClaimedPodcastData() {
  return useClaimedPodcasts({ select: podcastDataSelector });
}

export function useFeedIdFromPodcastId(podcastId?: string) {
  const selector = useCallback(
    (d: AllPodcastContentResponse) => podcastFeedIdSelector(d, podcastId),
    [podcastId],
  );

  return useClaimedPodcasts({ select: selector });
}

export function useHasClaimedPodcasts() {
  return useClaimedPodcasts({ select: hasClaimedPodcastsSelector });
}

export function useClaimedPodcastTitle(podcastId?: string) {
  const selector = useCallback(
    (d: AllPodcastContentResponse) => podcastTitleSelector(d, podcastId),
    [podcastId],
  );
  return useClaimedPodcasts({ select: selector });
}
