import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Simplebar from 'components/Simplebar';
import { useState, ReactElement } from 'react';
import { TableInstance } from 'react-table';
import SimpleBar from 'simplebar-react';
import DataTableBodyContainer, {
  DataTableBodyContainerProps,
} from './DataTableBody/DataTableBodyContainer';
import DataTableFooter from './DataTableFooter';
import * as S from './styles';
import 'simplebar/dist/simplebar.min.css';

export interface DataTableProps<TData extends object>
  extends DataTableBodyContainerProps<TData> {
  className?: string;
  table: TableInstance<TData>;
  tableLayout?: 'auto' | 'fixed';
}

// https://github.com/TanStack/react-table/blob/76a4a861ee56b782404ef91987c3b5691ecf2ebc/examples/material-UI-components/src/App.js
function DataTable<TData extends object>({
  className,
  onRowClick,
  hasMore,
  infinite,
  isLoading,
  onLoadMore,
  skeletonRows,
  table,

  tableLayout = 'fixed',
  virtual = false,
  footer = false,
}: DataTableProps<TData>): ReactElement<any, any> | null {
  const { headerGroups } = table;

  const [simplebar, setSimplebar] = useState<SimpleBar | null>(null);

  return (
    <TableContainer
      className={className}
      ref={setSimplebar}
      component={Simplebar}
    >
      <Table
        aria-label="daily widget stats"
        stickyHeader
        style={{ tableLayout }}
        {...table.getTableProps()}
      >
        <S.Header>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  width={column.width}
                  // setting the title to undefined turns off the "native" column tooltip so that it doesn't collide with ours.
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined }),
                  )}
                >
                  <S.HeaderCellContainer>
                    {column.render('Header')}

                    {column.canSort && (
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    )}
                  </S.HeaderCellContainer>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </S.Header>

        <DataTableBodyContainer
          scrollElement={simplebar?.getScrollElement()}
          {...{
            onRowClick,
            hasMore,
            infinite,
            isLoading,
            onLoadMore,
            skeletonRows,
            table,
            virtual,
          }}
        />
        {footer && <DataTableFooter table={table} />}
      </Table>
    </TableContainer>
  );
}

export default DataTable;
