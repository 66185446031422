import {
  updateAllEpisodeSegments,
  UpdateAllEpisodeSegmentsArgs,
} from 'api/services';
import { useMutation, UseMutationOptions } from 'react-query';

export default function useUpdateEpisodeSegments<TContext = unknown>(
  config: Omit<
    UseMutationOptions<void, unknown, UpdateAllEpisodeSegmentsArgs, TContext>,
    'mutationFn'
  >,
) {
  return useMutation<void, unknown, UpdateAllEpisodeSegmentsArgs, TContext>(
    (args) => updateAllEpisodeSegments(args),
    config,
  );
}
