import DailyStatsTableCard from 'components/DailyStatsTableCard';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { useUrlBreakoutDashboard } from '../UrlBreakoutDashboardContext';
import { columns, defaultSort } from './utils';

const UrlBreakoutTableCard: React.FunctionComponent = () => {
  const { urlStats } = useUrlBreakoutDashboard();

  useTrackPageLoad({
    section: 'DiscoWidget',
    page: 'URLBreakout',
    state: 'Populated',
  });

  return (
    <DailyStatsTableCard
      columns={columns}
      dailyStats={urlStats}
      defaultSort={defaultSort}
      exportArgs={{
        fileName: 'URL_breakout_daily_stats',
      }}
      tableLayout="fixed"
      title="Stats by URL"
      footer
      reportType="DiscoWidgetURLBreakout"
    />
  );
};

export default UrlBreakoutTableCard;
