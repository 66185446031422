import { DASHBOARD_SERVICE_URL } from 'config';

const DASHBOARD_V1_URL = `${DASHBOARD_SERVICE_URL}/api/v1`;

export const DASHBOARD_V1_USER_ROLE_URL = `${DASHBOARD_V1_URL}/access/me`;

export const DASHBOARD_V1_PUBLISHER_DATA_URL = `${DASHBOARD_V1_URL}/publisher/me`;

export const DASHBOARD_V1_ADVERTISER_DATA_URL = `${DASHBOARD_V1_URL}/sponsor/me`;

export const DASHBOARD_V1_METRICS_URL = `${DASHBOARD_V1_URL}/metrics`;

export const DASHBOARD_V1_PUBLISHER_METRICS_URL = `${DASHBOARD_V1_URL}/metrics/publisher`;

export const DASHBOARD_V1_SPONSOR_URL = `${DASHBOARD_V1_URL}/sponsor`;

export const DASHBOARD_V1_SELF_SERVE_PUBLISHER_URL = `${DASHBOARD_V1_URL}/publisher/self-serve-publisher`;

export const DASHBOARD_V1_EPISODE_METRICS_URL = `${DASHBOARD_V1_URL}/metrics/podcast/by-episode-metrics`;
