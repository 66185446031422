import { Typography } from '@mui/material';
import { Heading, Spacer } from '@sparemin/blockhead';

export interface PageHeadingProps {
  title?: React.ReactNode;
  titleId?: string;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

const PageHeading: React.FC<PageHeadingProps> = ({
  title,
  titleId,
  description,
  children,
}) => (
  <Spacer orientation="vertical" align="center" space="18px">
    {title && (
      <Heading id={titleId} level={1} align="center">
        {title}
      </Heading>
    )}

    {description && (
      <Typography variant="h2" textAlign="center">
        {description}
      </Typography>
    )}

    {children}
  </Spacer>
);

export default PageHeading;
