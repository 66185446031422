import TextButton from 'components/TextButton';
import { useCampaignCreation } from 'pages/AdCampaignPage/CampaignCreationContext';
import PageHeading from 'pages/AdCampaignPage/PageHeading';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { STEP_TITLES } from '../constants';
import HeadlinerCard from './HeadlinerCard';
import PublisherNetworkCard from './PublisherNetworkCard';
import * as S from './styles';
import TargetingSelection from './TargetingSelection';

export interface PlacementStepProps {
  onContinue: () => void;
}

const PlacementStep: React.FC<PlacementStepProps> = ({ onContinue }) => {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();

  const {
    state: { subStep },
    dispatch,
  } = useCampaignCreation();

  const switchSubstep = useCallback((): void => {
    dispatch({
      type: 'GO_TO_STEP',
      payload: {
        step: 'placement',
        subStep: subStep === 'initial' ? 'targetSelection' : 'initial',
      },
    });
  }, [dispatch, subStep]);

  const handleHeadlinerCardSelection = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'Placement',
      selection: 'Headliner',
    });
    dispatch({
      type: 'CHANGE_PLACEMENT',
      payload: 'headlinerApp',
    });
    onContinue();
  }, [dispatch, onContinue, trackPodcastPromoPurchaseNext]);

  const handlePublisherNetworkCardSelection = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'Placement',
      selection: 'PubNet',
    });
    dispatch({
      type: 'CHANGE_PLACEMENT',
      payload: 'publisherNetwork',
    });
    switchSubstep();
  }, [dispatch, switchSubstep, trackPodcastPromoPurchaseNext]);

  return (
    <>
      <PageHeading
        title={STEP_TITLES[subStep === 'initial' ? 'placement' : 'targeting']}
      >
        {subStep === 'targetSelection' && (
          <TextButton onPress={switchSubstep}>
            ← return to placement selection
          </TextButton>
        )}
      </PageHeading>

      {subStep === 'initial' && (
        <S.Root>
          <HeadlinerCard onPress={handleHeadlinerCardSelection} />

          <PublisherNetworkCard onPress={handlePublisherNetworkCardSelection} />
        </S.Root>
      )}

      {subStep === 'targetSelection' && (
        <TargetingSelection onContinue={onContinue} />
      )}
    </>
  );
};

export default PlacementStep;
