import React from 'react';
import AvatarIcon from './AvatarIcon';
import * as S from './styles';
import { AvatarProps } from './types';

const Avatar: React.FC<AvatarProps> = ({
  className,
  name,
  fontSize,
  fontWeight,
  size,
}) => (
  <AvatarIcon name={name} className={className} size={size}>
    <S.AvatarTypography $fontSize={fontSize} $fontWeight={fontWeight}>
      {!name ? '?' : name.charAt(0).toUpperCase()}
    </S.AvatarTypography>
  </AvatarIcon>
);
export default Avatar;
