import { AdCampaignRequest } from 'api';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useCampaignCreation } from '../CampaignCreationContext';
import { usePreviousAdCampaigns } from '../state/useGetAdCampaignInfo';
import PreviousCampaignsListItem from './PreviousCampaignsListItem';
import * as S from './styles';

export interface PreviousCampaignsListProps {
  onRunAgain: () => void;
}

const PreviousCampaignsList: React.FC<PreviousCampaignsListProps> = ({
  onRunAgain,
}) => {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();
  const { dispatch } = useCampaignCreation();
  const { data: previousAdCampaigns = [] } = usePreviousAdCampaigns();

  const handleRunAgain = useCallback(
    (campaign: AdCampaignRequest): void => {
      trackPodcastPromoPurchaseNext({
        step: 'Intro',
        view: 'ReturningPurchaser',
        buttonClick: 'ReRun',
      });

      dispatch({
        type: 'RE_RUN_CAMPAIGN',
        payload: campaign,
      });

      onRunAgain();
    },
    [dispatch, onRunAgain, trackPodcastPromoPurchaseNext],
  );

  return (
    <S.PreviousCampaignsListRoot>
      {previousAdCampaigns.map((campaign) => (
        <PreviousCampaignsListItem
          campaign={campaign}
          onRunAgain={handleRunAgain}
          key={campaign.createdAt}
        />
      ))}
    </S.PreviousCampaignsListRoot>
  );
};

export default PreviousCampaignsList;
