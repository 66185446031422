export const WIDGET_SETUP_LINK =
  'https://sparemin.atlassian.net/wiki/spaces/HEAD/pages/2312601601/Disco#Add-the-Disco-widget';

export const WIDGET_API_URL = 'https://disco.headliner.link/d/web/js/widget.js';

export const WIDGET_ERROR_HEADER = 'There was an error processing your podcast';

export const WIDGET_ERROR_EMAIL_SUBJECT = 'Self Serve Disco widget setup error';

export const PUBLISHER_WIDGET_CODE_HEADER = 'Your code is safe with us';

export const PUBLISHER_WIDGET_CODE_MESSAGE =
  'Please get in touch so we can assist with your widget';
