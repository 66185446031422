import dayjs from 'dayjs';
import { adjustInputUtcMillis } from 'utils/date';
import {
  DateRangeLimits,
  PeriodArgs,
  PeriodOption,
  PeriodOptionKey,
} from './types';

export const DEFAULT_PERIOD_OPTION: PeriodOptionKey = 'last_30_days';

export const PERIOD_OPTIONS: PeriodOption[] = [
  {
    isCustom: false,
    label: 'Last 7 days',
    startMillisArgs: {
      amount: 6, // as "today" is included, instead of 7, 6 days are subtracted
      key: 'day',
    },
    value: 'last_7_days',
  },
  {
    isCustom: false,
    label: 'Last 30 days',
    startMillisArgs: {
      amount: 29, // as "today" is included, instead of 30, 29 days are subtracted
      key: 'day',
    },
    value: 'last_30_days',
  },
  {
    isCustom: false,
    label: 'Last year',
    startMillisArgs: {
      amount: 1,
      key: 'year',
    },
    value: 'last_year',
  },
  {
    isCustom: true,
    label: 'Custom range',
    startMillisArgs: {
      amount: 0,
      key: 'day',
    },
    value: 'custom_range',
  },
];

export const calculateMillis = (
  args: PeriodOption['startMillisArgs'],
): PeriodArgs => {
  const { amount, key } = args;

  return {
    startMillis: adjustInputUtcMillis(
      dayjs().startOf('day').subtract(amount, key).valueOf(),
    ),
    endMillis: adjustInputUtcMillis(dayjs().startOf('day').valueOf()),
  };
};

export const getDefaultPeriodArgs = (): PeriodArgs => ({
  startMillis: adjustInputUtcMillis(
    dayjs().startOf('day').subtract(30, 'day').valueOf(),
  ),
  endMillis: adjustInputUtcMillis(dayjs().endOf('day').valueOf()),
});

export const getEndDateRange = (startDate: number): DateRangeLimits => ({
  min: dayjs(startDate).endOf('day'),
  max: dayjs().endOf('day'),
});

export const getStartDateRange = (endDate: number): DateRangeLimits => ({
  min: dayjs().startOf('day').subtract(1, 'year'),
  max: dayjs(endDate).startOf('day'),
});

export const getMinValidDate = dayjs().subtract(1, 'year').valueOf();
