import { AdCampaignRequest } from 'api';
import { ActionItemRow } from 'components/ActionItemsList';
import { useCallback } from 'react';
import { displayDate } from 'utils/date';
import {
  HEADLINER_PRICES,
  PUBNET_PRICES,
} from '../CampaignCreationView/ObjectiveStep/constants';
import { CAMPAIGN_PLACEMENT_LABEL } from '../constants';
import * as S from './styles';

export interface PreviousCampaignsListItemProps {
  campaign: AdCampaignRequest;
  onRunAgain: (campaign: AdCampaignRequest) => void;
}

const PreviousCampaignsListItem: React.FC<PreviousCampaignsListItemProps> = ({
  campaign,
  onRunAgain,
}) => {
  const {
    contentDetail: {
      episodeThumbnailUrl,
      podcastThumbnailUrl,
      podcastTitle,
      episodeTitle,
    },
    createdAt,
    sponsorPlacement,
    campaignBudgetMilliCent,
  } = campaign;

  const createHandleRunAgain = useCallback(
    (campaignToRunAgain: AdCampaignRequest) => () => {
      onRunAgain(campaignToRunAgain);
    },
    [onRunAgain],
  );

  return (
    <ActionItemRow key={createdAt}>
      <S.Thumbnail
        src={episodeThumbnailUrl || podcastThumbnailUrl}
        alt="podcast artwork"
      />

      <ActionItemRow.HeadingContainer>
        <ActionItemRow.Title>
          {`${podcastTitle} ${episodeTitle ? `(${episodeTitle})` : ''}`}
        </ActionItemRow.Title>

        <ActionItemRow.Description>
          {displayDate(createdAt, 'MMM DD, YYYY')}
          {' • '}
          {CAMPAIGN_PLACEMENT_LABEL[sponsorPlacement || 'headlinerApp']}
          {' • '}
          {
            (sponsorPlacement === 'headlinerApp'
              ? HEADLINER_PRICES
              : PUBNET_PRICES
            ).find(({ value }) => value === campaignBudgetMilliCent)
              ?.description
          }{' '}
          (${Number(campaignBudgetMilliCent) / 100000})
        </ActionItemRow.Description>
      </ActionItemRow.HeadingContainer>

      <S.RunAgainButton onPress={createHandleRunAgain(campaign)}>
        run again
      </S.RunAgainButton>
    </ActionItemRow>
  );
};

export default PreviousCampaignsListItem;
