import { useCampaignCreation } from 'pages/AdCampaignPage/CampaignCreationContext';
import PageHeading from 'pages/AdCampaignPage/PageHeading';
import React, { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { STEP_TITLES } from '../constants';
import PodcastEpisodeInput, {
  PodcastEpisodeInputValue,
} from './PodcastEpisodeInput';
import * as S from './styles';

export interface EpisodeStepProps {
  onContinue: () => void;
}

const EpisodeStep: React.FC<EpisodeStepProps> = ({ onContinue }) => {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();

  const {
    state: {
      values: { episode },
    },
    dispatch,
  } = useCampaignCreation();

  const handleChange = useCallback(
    (newEpisode: PodcastEpisodeInputValue): void => {
      dispatch({
        type: 'CHANGE_VALUES',
        payload: {
          episode: newEpisode,
        },
      });
    },
    [dispatch],
  );

  const handleSubmit = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'PodcastEpisode',
    });
    onContinue();
  }, [onContinue, trackPodcastPromoPurchaseNext]);

  return (
    <>
      <PageHeading title={STEP_TITLES.episode} />

      <S.Form component="form" onSubmit={handleSubmit}>
        <PodcastEpisodeInput episode={episode} onChange={handleChange} />

        <S.SubmitCampaignButton fluid type="submit" disabled={!episode}>
          continue with this episode
        </S.SubmitCampaignButton>
      </S.Form>
    </>
  );
};

export default EpisodeStep;
