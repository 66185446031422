import { AudioPlaybackMethod } from 'api/services';
import InfoCard from 'components/InfoCard';
import React from 'react';
import * as S from './styles';

interface HostPlaybackCardProps {
  onClick: (val: AudioPlaybackMethod) => void;
}

const HostPlaybackCard: React.FC<HostPlaybackCardProps> = ({ onClick }) => (
  <InfoCard>
    <InfoCard.ActionArea<AudioPlaybackMethod>
      cardType="originalOnly"
      onClick={onClick}
    >
      <InfoCard.Image>
        <S.RSSIcon />
      </InfoCard.Image>

      <InfoCard.Contents
        heading="Your host only"
        subheading={
          <>
            If your feed is reliable enough without a backup audio source, but
            there is a risk of worse performance.
            <strong>
              {' '}
              Widget plays count as listens in your host dashboard.
            </strong>
          </>
        }
      />
    </InfoCard.ActionArea>
  </InfoCard>
);
export default HostPlaybackCard;
