import TableCells from 'components/TableCells';
import { Column, CellProps } from 'react-table';
import { displayDate } from 'utils/date';
import { useDiscoEpisodeEnabled } from '../state';
import useUpdateDiscoEpisodeEnabled from '../state/useUpdateDiscoEpisodeEnabled';
import * as S from './styles';

import { PodcastEpisodeTableData } from './types';

const columns: ReadonlyArray<Column<PodcastEpisodeTableData>> = [
  {
    Header: 'Episode',
    id: 'episode',
    Cell: ({ row }: CellProps<PodcastEpisodeTableData>) => (
      <TableCells.PodcastEpisodeCell
        episodeTitle={row.original.title}
        thumbnailUrl={row.original.thumbnailUrl}
      />
    ),
    width: 600,
    disableSortBy: true,
  },
  {
    Header: 'Show in Widget',
    id: 'showInWidget',
    Cell: ({ row }: CellProps<PodcastEpisodeTableData>) => {
      const { data: isEnabled } = useDiscoEpisodeEnabled(
        row.original.internalEpisodeId,
      );

      const { mutate } = useUpdateDiscoEpisodeEnabled();

      const handleChange = () => {
        mutate({
          episodeId: row.original.internalEpisodeId,
          isEnabled: !isEnabled,
        });
      };
      return (
        <TableCells.SwitchCell
          textAlign="center"
          checked={isEnabled}
          onChange={handleChange}
        />
      );
    },
    width: 100,
    disableSortBy: true,
  },
  {
    Header: 'Publish Date',
    accessor: (d) => displayDate(d.publishedAtMillis),
    Cell: ({ value }: CellProps<PodcastEpisodeTableData, string>) => (
      <S.Stack alignItems="center" direction="row">
        <strong>{value}</strong>
      </S.Stack>
    ),
    disableSortBy: true,
  },
];

export function getRowId(data: PodcastEpisodeTableData) {
  return String(data.internalEpisodeId);
}

export default columns;
