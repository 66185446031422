import React from 'react';
import { DATA_DISCLAIMER } from './constants';
import * as S from './styles';

type DisclaimerProps = {};

const Disclaimer: React.FC<DisclaimerProps> = () => (
  <S.Disclaimer variant="label">{DATA_DISCLAIMER} </S.Disclaimer>
);
export default Disclaimer;
