import {
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import * as S from './styles';
import { BaseSeriesData, SeriesOption } from './types';

interface DailyStatsChartStatPickerProps<TData extends BaseSeriesData> {
  onChangeSelectedStat: (option: keyof TData) => void;
  selectedStat: keyof TData;
  statOptions: SeriesOption<TData>[];
}

const DailyStatsChartStatPicker = <TData extends BaseSeriesData>(
  props: DailyStatsChartStatPickerProps<TData>,
): React.ReactElement => {
  const { trackReportAdjust } = useEventTracking();

  const { onChangeSelectedStat, selectedStat, statOptions } = props;

  const handleChangeSelectedStat = useCallback(
    (evt: SelectChangeEvent<keyof TData>) => {
      onChangeSelectedStat(evt.target.value as keyof TData);
      trackReportAdjust({ dropdown: 'Stat' });
    },
    [onChangeSelectedStat, trackReportAdjust],
  );

  const renderValue = useCallback(
    (value: keyof TData) =>
      statOptions.find((option) => option.value === value)?.label,
    [statOptions],
  );

  const options = statOptions.map((option) => {
    const value = String(option.value);
    return (
      <MenuItem key={value} value={value}>
        <ListItemText primary={option.label} />
      </MenuItem>
    );
  });

  return (
    <S.StatsPickerFormControl size="small">
      <InputLabel id="publisher-daily-stats-chart-stat-selector-input-label">
        Stat
      </InputLabel>
      <Select
        id="publisher-daily-stats-chart-stat-selector"
        input={<OutlinedInput label="Stat" />}
        labelId="publisher-daily-stats-chart-stat-label"
        onChange={handleChangeSelectedStat}
        renderValue={renderValue}
        value={selectedStat}
      >
        {options}
      </Select>
    </S.StatsPickerFormControl>
  );
};

export default DailyStatsChartStatPicker;
