import React, { useContext, useMemo } from 'react';
import { PodcastFeedContextType, PodcastFeedProviderProps } from './types';

const PodcastFeedContext = React.createContext<
  PodcastFeedContextType | undefined
>(undefined);

export const PodcastFeedProvider: React.FC<PodcastFeedProviderProps> = ({
  children,
  podcastId,
}) => (
  <PodcastFeedContext.Provider
    value={useMemo(() => ({ podcastId }), [podcastId])}
  >
    {children}
  </PodcastFeedContext.Provider>
);

export function usePodcastFeed() {
  const context = useContext(PodcastFeedContext);

  if (context === undefined) {
    throw new Error('usePodcastFeed must be used within PodcastFeedProvider');
  }

  return context;
}
