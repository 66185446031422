import { Stack } from '@mui/material';
import { AudioPlaybackMethod } from 'api/services';
import React from 'react';
import DualPlaybackCard from './DualPlaybackCard';
import HeadlinerPlaybackCard from './HeadlinerPlaybackCard';
import HostPlaybackCard from './HostPlaybackCard';

interface WidgetPlaybackExampleProps {
  onClick: (val: AudioPlaybackMethod) => void;
}

const WidgetPlaybackExample: React.FC<WidgetPlaybackExampleProps> = ({
  onClick,
}) => (
  <Stack direction="row" spacing={4}>
    <DualPlaybackCard onClick={onClick} />
    <HostPlaybackCard onClick={onClick} />
    <HeadlinerPlaybackCard onClick={onClick} />
  </Stack>
);
export default WidgetPlaybackExample;
