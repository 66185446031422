import {
  Typography,
  ListItem as MuiListItem,
  ListItemAvatar as MuiAvatar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

export const ListItem = styled(MuiListItem)`
  &.MuiListItem-root {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

export const ListItemAvatar = styled(MuiAvatar)`
  padding: 0;
  margin-right: 16px;
  &.MuiListItemAvatar-root {
    min-width: unset;
  }
`;

export const Email = styled('div')`
  font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.text.dark};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const FollowDate = styled(withAttrs(Typography, { variant: 'body1' }))`
  color: ${({ theme }) => theme.palette.text.dark};
`;
