import { Stack } from '@mui/material';
import { WidgetVariantId } from 'api/services';
import React from 'react';
import { STYLE_WIDGET_MODAL_TITLE } from './constants';
import * as S from './styles';
import { widgetStyles } from './widgets';
import WidgetStyleExample from './WidgetStyleExample';

interface StyleWidgetSelectorProps {
  onClick: (val: WidgetVariantId) => void;
  isMobile?: boolean;
}

const StyleWidgetSelector: React.FC<StyleWidgetSelectorProps> = ({
  onClick,
  isMobile,
}) => (
  <S.Body spacing={4} alignItems="center">
    <S.Typography variant="h1" textAlign="center">
      {STYLE_WIDGET_MODAL_TITLE}
    </S.Typography>
    <Stack direction={isMobile ? 'column' : 'row'} spacing={3}>
      {widgetStyles.map((widget) => (
        <WidgetStyleExample {...widget} onClick={onClick} key={widget.value} />
      ))}
    </Stack>
  </S.Body>
);
export default StyleWidgetSelector;
