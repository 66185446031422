import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { stringToColor } from 'utils/colors';
import { AvatarStyleProps, AvatarIconStyleProps } from './types';

export const StringAvatar = styled(Avatar)<AvatarIconStyleProps>`
  background-color: ${({ $name }) => (!$name ? 'white' : stringToColor($name))};

  ${({ $size = '30px' }) => `
    height: ${$size}; 
    width: ${$size}
    `};
`;

export const AvatarTypography = styled('div')<AvatarStyleProps>`
  font-family: Montserrat;
  ${({ $fontWeight = 800 }) => `
    font-weight: ${$fontWeight}
  `};

  ${({ $fontSize = '15px' }) => `
    font-size: ${$fontSize}
  `};
`;
