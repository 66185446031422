import { ListItemText } from '@mui/material';
import Avatar from 'components/Avatar';
import React from 'react';
import { displayDate } from 'utils/date';
import * as S from './styles';

type ListItemProps = {
  email: string;
  followDate: number;
};

const ListItem: React.FC<ListItemProps> = ({ email, followDate }) => (
  <S.ListItem>
    <S.ListItemAvatar>
      <Avatar name={email} fontSize="25px" fontWeight={700} size="50px" />
    </S.ListItemAvatar>
    <ListItemText
      primary={<S.Email>{email}</S.Email>}
      secondary={
        <S.FollowDate>{`began following on ${displayDate(
          followDate,
          'MM.DD.YYYY',
        )}`}</S.FollowDate>
      }
    />
  </S.ListItem>
);
export default ListItem;
