import TextOverflowTooltip from 'components/TextOverflowTooltip';

interface UrlCellProps {
  text: string;
}

const UrlCell: React.FunctionComponent<UrlCellProps> = (props) => {
  const { text } = props;

  return <TextOverflowTooltip text={text} />;
};

export default UrlCell;
