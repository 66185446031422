import {
  getEpisodeDetails,
  EpisodeDetailArgs,
  EpisodeDetailResponse,
} from 'api/services';
import { UseQueryOptions, useQuery } from 'react-query';
import { podcastQueries } from './queryKeys';
import {
  audioDurationSelector,
  episodeAudioUrlSelector,
  episodeDestinationSelector,
  episodeDestinationUrlSelector,
  episodeTitleSelector,
} from './selectors';

type QueryKey = ReturnType<typeof podcastQueries.episode>;

type Options<TData> = Omit<
  UseQueryOptions<EpisodeDetailResponse, unknown, TData, QueryKey>,
  'queryKey' | 'queryFn'
>;

function useGetEpisodeDetails<TData = EpisodeDetailResponse>(
  args: EpisodeDetailArgs,
  opts?: Options<TData>,
) {
  return useQuery<EpisodeDetailResponse, unknown, TData, QueryKey>(
    podcastQueries.episode(args),
    () => getEpisodeDetails(args),
    { enabled: !!args.internalEpisodeId, ...opts },
  );
}

export function useEpisodeTitle(episodeId?: number) {
  return useGetEpisodeDetails(
    { internalEpisodeId: episodeId as number },
    {
      select: episodeTitleSelector,
    },
  );
}

export function useEpisodeAudioDuration(episodeId?: number) {
  return useGetEpisodeDetails(
    { internalEpisodeId: episodeId as number },
    {
      select: audioDurationSelector,
    },
  );
}

export function useEpisodeAudioUrl(episodeId?: number, opts?: Options<string>) {
  return useGetEpisodeDetails(
    { internalEpisodeId: episodeId as number },
    {
      select: episodeAudioUrlSelector,
      ...opts,
    },
  );
}

export function useEpisodeDestinationUrlIsPlay(episodeId?: number) {
  return useGetEpisodeDetails(
    { internalEpisodeId: episodeId as number },
    {
      select: episodeDestinationSelector,
    },
  );
}

export function useEpisodeDestinationUrl(episodeId?: number) {
  return useGetEpisodeDetails(
    { internalEpisodeId: episodeId as number },
    {
      select: episodeDestinationUrlSelector,
    },
  );
}
