import { useTheme } from '@mui/material';
import { PlayCircle, Followers } from '@sparemin/blockhead';
import DetailCard from 'components/DetailCard';
import React from 'react';
import { usePodcastListenData } from '../state';
import RevenueStat from './RevenueStat';
import Stat from './Stat';

type SocialStatsSummaryProps = {};

const SocialStatsSummary: React.FC<SocialStatsSummaryProps> = () => {
  const width = '33%';
  const theme = useTheme();
  const {
    data: { playCount, followCount, revenue },
    isSuccess,
  } = usePodcastListenData();

  if (isSuccess) {
    return (
      <DetailCard>
        <Stat
          icon={
            <PlayCircle
              color={theme.palette.supporting1.main}
              height="16px"
              width="16px"
            />
          }
          numberOf={playCount ?? 0}
          statsType="plays"
          width={width}
        />

        <Stat
          icon={
            <Followers
              height="16px"
              width="20px"
              color={theme.palette.supporting3.main}
            />
          }
          numberOf={followCount ?? 0}
          statsType="followers"
          width={width}
        />

        <RevenueStat revenue={revenue} width={width} />
      </DetailCard>
    );
  }
  return null;
};

export default SocialStatsSummary;
