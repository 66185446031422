import AudioCell from './AudioCell';
import AveragedTotalsNumericCells from './AveragedTotalsNumericCell';
import { columnInfoLookup } from './columnInfo';
import HeaderCell from './HeaderCell';
import HeaderCellWithButton from './HeaderCellWithButton';
import NumericCell from './NumericCell';
import PodcastEpisodeCell from './PodcastEpisodeCell';
import PodcastInfoCell, { PodcastInfoCellProps } from './PodcastInfoCell';
import SocialVideoCell, { SocialVideoCellProps } from './SocialVideoCell';
import SummedNumericCell from './SummedNumericCell';
import SwitchCell from './SwitchCell';
import TextCell from './TextCell';
import TimeSegmentsCell from './TimeSegmentsCell';
import UrlCell from './UrlCell';

const TableCells = {
  AudioCell,
  HeaderCell,
  NumericCell,
  SocialVideoCell,
  SummedNumericCell,
  UrlCell,
  PodcastEpisodeCell,
  PodcastInfoCell,
  TimeSegmentsCell,
  SwitchCell,
  HeaderCellWithButton,
  TextCell,
  AveragedTotalsNumericCells,
};

export default TableCells;
export { columnInfoLookup };
export type { SocialVideoCellProps, PodcastInfoCellProps };
