import PodcastContentTable from 'components/PodcastContentTable';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import { useEpisodeListenData } from 'pages/Play/state/useGetEpisodeMetrics';
import React from 'react';
import PodcastEpisodesMobileList from './PodcastEpisodesMobileList';
import columns from './utils';

type PodcastEpisodesTableProps = {};

const PodcastEpisodesTable: React.FC<PodcastEpisodesTableProps> = () => {
  const {
    fetchNextPage,
    hasNextPage = false,
    isLoading,
    data,
  } = useEpisodeListenData();

  const isMobile = useMobileBreakpoint('md');

  return isMobile ? (
    <PodcastEpisodesMobileList
      data={data?.pages || []}
      hasMore={hasNextPage}
      onLoadMore={fetchNextPage}
    />
  ) : (
    <PodcastContentTable
      columns={columns}
      podcastData={data?.pages || []}
      isLoading={isLoading}
      tableLayout="fixed"
      hasMore={hasNextPage}
      infinite
      onLoadMore={fetchNextPage}
    />
  );
};
export default PodcastEpisodesTable;
