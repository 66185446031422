import { useEffect, useRef } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useProfile } from 'state/user';

const useOnAppLoad = () => {
  const { setUserTrackingInfo } = useEventTracking();

  const { data: userData } = useProfile();

  const ref = useRef<number | undefined>(undefined);
  useEffect(() => {
    if (
      userData?.userId &&
      userData?.email &&
      ref.current !== userData?.userId
    ) {
      const { userId, email } = userData;
      ref.current = userId;
      setUserTrackingInfo(email, userId);
    }
  }, [userData, setUserTrackingInfo]);
};

export default useOnAppLoad;
