import {
  SocialShareStatsByPodcastResponse,
  SocialShareStatsResponse,
} from 'api';

export function socialVideoInfoSelector(d: SocialShareStatsResponse) {
  return d.data.map((video) => ({
    platform: video.platform,
    sharePostId: video.socialShareId,
    sharePostUrl: video.sharePostUrl,
    sharePostTitle: video.sharePostTitle,
    createdAtMillis: video.createdAtMillis,
    statistics: { ...video.statistics },
    previewThumbnailImageUrl: video.embedVideo.previewThumbnailImageUrl,
    projectName: video.embedVideo.project?.projectConfig?.projectName,
  }));
}

export function socialStatsSummarySelector(d: SocialShareStatsResponse) {
  return d.data.reduce(
    (acc, current) => ({
      likeCount: acc.likeCount + current.statistics.likeCount,
      viewCount: acc.viewCount + current.statistics.viewCount,
      commentCount: acc.commentCount + current.statistics.commentCount,
    }),

    { likeCount: 0, viewCount: 0, commentCount: 0 },
  );
}

export function podcastSummarySocialShareStatsSelector(
  d: SocialShareStatsByPodcastResponse,
  podcastId?: string,
) {
  return d.podcasts
    .filter((podcast) => podcast.podcastId === podcastId)
    .reduce(
      (acc, current) => ({
        likeCount: acc.likeCount + current.statistics.likeCount,
        viewCount: acc.viewCount + current.statistics.viewCount,
        commentCount: acc.commentCount + current.statistics.commentCount,
      }),
      { likeCount: 0, viewCount: 0, commentCount: 0 },
    );
}
