import React from 'react';
import * as S from './styles';
import { AvatarIconProps } from './types';

const AvatarIcon: React.FC<AvatarIconProps> = ({
  name,
  className,
  children,
  size,
}) => (
  <S.StringAvatar className={className} $name={name} $size={size}>
    {children}
  </S.StringAvatar>
);
export default AvatarIcon;
