import { Skeleton, TableCell, TableRow } from '@mui/material';
import { last, range } from 'lodash';
import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { TableInstance } from 'react-table';

export interface InfiniteLoaderProps {
  container?: HTMLElement;
  hasMore?: boolean;
  onLoadMore?: () => void;
  table: TableInstance<any>;
}

const InfiniteLoader: React.FC<InfiniteLoaderProps> = ({
  container,
  hasMore,
  onLoadMore,
  table,
}) => {
  const { rows } = table;

  const { ref } = useInView({
    root: container,
    rootMargin: '300px',
    skip: !hasMore,
    threshold: 0,
    onChange: useCallback(
      (inView) => {
        if (inView) {
          onLoadMore?.();
        }
      },
      [onLoadMore],
    ),
  });

  // the rows could theoretically have different column layouts, so have the loader
  // match the last row
  const lastRow = last(rows);

  return !hasMore ? null : (
    <>
      {range(2).map((rowIndex) => (
        // we technically don't know what getRowProps does, but in case it adds a key,
        // overwrite it with a different one so that React doesn't wind up replacing
        // a row of actual data with this placeholder
        <TableRow
          {...lastRow?.getRowProps()}
          key={`placeholder_${rowIndex}`}
          ref={rowIndex === 0 ? ref : null}
        >
          {lastRow?.cells.map((cell) => (
            <TableCell width={cell.column.width} {...cell.getCellProps()}>
              <Skeleton variant="rectangular" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default InfiniteLoader;
