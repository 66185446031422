import { useMemo, useState } from 'react';
import { BaseSeriesData, SeriesFilter, FilterKey } from './types';
import { buildDatasets } from './utils';

interface UseDailyStatsChartConfig<TData extends BaseSeriesData> {
  initialSelectedSeries: keyof Omit<TData, 'date'>;
  seriesData: TData[];
  seriesFilter?: SeriesFilter<TData, FilterKey<TData>>;
}

const useDailyStatsChart = <TData extends BaseSeriesData>(
  config: UseDailyStatsChartConfig<TData>,
) => {
  const { initialSelectedSeries, seriesData, seriesFilter } = config;

  const [selectedStat, setSelectedStat] = useState<keyof TData>(
    initialSelectedSeries,
  );

  const datasets = useMemo(
    () => buildDatasets(seriesData, selectedStat, seriesFilter),
    [seriesData, selectedStat, seriesFilter],
  );

  return {
    data: datasets,
    selectedStat,
    setSelectedStat,
  };
};

export default useDailyStatsChart;
