import { ActionItem } from 'components/ActionItemsList';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useCampaignCreation } from '../CampaignCreationContext';
import {
  CampaignCreationViewStep,
  GoToStepAction,
  PlacementSubstep,
} from '../CampaignCreationContext/types';
import { placementLabelByValue } from './constants';

export interface UseReviewItemsConfig {
  onGoBack: () => void;
}

export type UseReviewItemsResult = ActionItem[];

export default function useReviewItems({
  onGoBack,
}: UseReviewItemsConfig): UseReviewItemsResult {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();

  const {
    state: {
      pricesList,
      isCustomBudget,
      values: {
        episode,
        sponsorPlacement,
        targetCategories,
        targetCountryCodes,
        campaignBudgetMilliCent,
      },
    },
    dispatch,
  } = useCampaignCreation();

  const { title, podcast } = episode || {};

  const episodeTitle = `${podcast?.title} ${
    title ? `(${episode?.title})` : ''
  }`;

  const targetingTitle = [targetCategories, targetCountryCodes]
    .map((prop) => prop.join(', '))
    .join(', ');

  const price = pricesList.find(
    ({ value }) => value === campaignBudgetMilliCent,
  );

  const objectiveTitle = isCustomBudget
    ? `Custom budget ($${campaignBudgetMilliCent})`
    : `${price?.description} (${price?.label.split(' budget')[0]})`;

  const createHandleGoToViewStep = useCallback(
    (step: CampaignCreationViewStep, subStep?: PlacementSubstep) => () => {
      trackPodcastPromoPurchaseNext({
        step: 'Review',
        buttonClick: 'EditRowItem',
      });

      dispatch({
        type: 'GO_TO_STEP',
        payload: {
          step,
          subStep,
        } as GoToStepAction['payload'],
      });
      onGoBack();
    },
    [dispatch, onGoBack, trackPodcastPromoPurchaseNext],
  );

  return [
    {
      id: 'episode',
      title: episodeTitle,
      description: 'podcast episode',
      onEdit: createHandleGoToViewStep('episode'),
    },
    {
      id: 'placement',
      title: sponsorPlacement && placementLabelByValue[sponsorPlacement],
      description: 'placement',
      onEdit: createHandleGoToViewStep('placement', 'initial'),
    },
    ...(sponsorPlacement === 'publisherNetwork'
      ? [
          {
            id: 'targeting',
            title: targetingTitle,
            description: 'targeting',
            onEdit: createHandleGoToViewStep('placement', 'targetSelection'),
          },
        ]
      : []),
    {
      id: 'objective',
      title: objectiveTitle,
      description: 'objective',
      onEdit: createHandleGoToViewStep('objective'),
    },
  ];
}
