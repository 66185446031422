import {
  LinkedIn,
  YouTube,
  TikTok,
  Twitter,
  Facebook,
  Instagram,
} from '@sparemin/blockhead';
import { SocialPlatform } from 'api';
import {
  FACEBOOK_BACKGROUND_COLOR,
  LINKEDIN_BACKGROUND_COLOR,
  TIKTOK_BACKGROUND_COLOR,
  TWITTER_BACKGROUND_COLOR,
} from 'utils/constants';
import SocialPlatformIcon from './SocialPlatformIcon';

type SocialPlatformInfo = {
  icon: React.ReactElement;
  brandName: string;
};

export const socialPlatformInfoLookup: Record<
  SocialPlatform,
  SocialPlatformInfo
> = {
  linkedin: {
    icon: (
      <SocialPlatformIcon backgroundColor={LINKEDIN_BACKGROUND_COLOR}>
        <LinkedIn width="16px" height="16px" color="#FFF" />
      </SocialPlatformIcon>
    ),
    brandName: 'LinkedIn',
  },

  youtube: {
    icon: (
      <SocialPlatformIcon>
        <YouTube width="13.2px" height="9px" />
      </SocialPlatformIcon>
    ),
    brandName: 'YouTube',
  },
  tiktok: {
    icon: (
      <SocialPlatformIcon backgroundColor={TIKTOK_BACKGROUND_COLOR}>
        <TikTok width="17px" height="18px" />
      </SocialPlatformIcon>
    ),
    brandName: 'TikTok',
  },
  twitter: {
    icon: (
      <SocialPlatformIcon backgroundColor={TWITTER_BACKGROUND_COLOR}>
        <Twitter width="12px" color="#FFF" />
      </SocialPlatformIcon>
    ),
    brandName: 'Twitter',
  },
  facebook: {
    icon: (
      <SocialPlatformIcon backgroundColor={FACEBOOK_BACKGROUND_COLOR}>
        <Facebook color="#FFF" width="35%" />
      </SocialPlatformIcon>
    ),
    brandName: 'Facebook',
  },
  instagram: {
    icon: <Instagram width="32px" height="32px" />,
    brandName: 'Instagram',
  },
};
