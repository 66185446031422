import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

export const UserInfoContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing()} 24px;
  text-align: center;
`;

export const MenuContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing()};
`;

export const AccountMenu = styled(Menu)`
  .MuiMenu-list {
    padding: 0;
  }
  .MuiPaper-root {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;
