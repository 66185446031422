import CollapsedIcon from '@mui/icons-material/ChevronRight';
import ExpandedIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { RoleName } from 'api/services';
import LinkBehavior from 'components/LinkBehavior/LinkBehavior';
import ScopeCheckContainer, {
  checkRequiredScopes,
} from 'components/ScopeCheckContainer';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useScopesContext } from 'state/scopes';
import * as S from './styles';

export interface NavigationLinkProps {
  icon?: React.ReactNode;
  nestedLinks?: Omit<NavigationLinkProps, 'icon' | 'nestedLinks'>[];
  path: string;
  text: string;
  requiredScopes?: RoleName[];
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  icon,
  nestedLinks = [],
  path,
  text,
  requiredScopes,
}) => {
  const { availableScopes } = useScopesContext();

  const hasScopesForNestedLinks = nestedLinks.some((item) => {
    if (item.requiredScopes) {
      return checkRequiredScopes(item.requiredScopes, availableScopes);
    }
    return true;
  });

  const [expanded, setExpanded] = useState(false);
  const { pathname } = useLocation();
  const nestedLinksAmount = nestedLinks.length;
  const selected = pathname.startsWith(path);
  const showExpand = hasScopesForNestedLinks;

  useEffect(() => {
    if (nestedLinksAmount) {
      setExpanded(selected);
    }
  }, [nestedLinksAmount, selected]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (selected) {
        e.preventDefault();
      }
      if (nestedLinksAmount) {
        setExpanded(!expanded);
      }
    },
    [expanded, nestedLinksAmount, selected],
  );

  return (
    <ScopeCheckContainer requiredScopes={requiredScopes}>
      <ListItemButton
        component={LinkBehavior}
        href={path}
        onClick={handleClick}
        selected={selected}
      >
        {icon && <S.Icon>{icon}</S.Icon>}
        <ListItemText
          primaryTypographyProps={{ variant: 'h2' }}
          primary={text}
        />
        {showExpand && (expanded ? <ExpandedIcon /> : <CollapsedIcon />)}
      </ListItemButton>
      {nestedLinks && (
        <Collapse in={expanded}>
          <List component="div" disablePadding>
            {nestedLinks.map((item) => (
              <NavigationLink
                key={item.path}
                path={item.path}
                text={item.text}
                requiredScopes={item.requiredScopes}
              />
            ))}
          </List>
        </Collapse>
      )}
    </ScopeCheckContainer>
  );
};

export default NavigationLink;
