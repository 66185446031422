import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

export const Root = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
`;

export const Badge = styled('div')`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  left: 25px;
  padding: 3px 6px;
  border-radius: 4px;
  background-color: #6e6ede;
  color: white;
  font-size: 12px;
  font-weight: 500;
  z-index: 1000;
`;

export const Heading = styled(withAttrs(Typography, { variant: 'h3' }))`
  margin: 16px 24px 0;
  line-height: 1.44;
`;

export const Subheading = styled(withAttrs(Typography, { variant: 'body1' }))`
  margin: 4px 24px 12px;
`;
