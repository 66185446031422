import { Grid } from '@mui/material';
import { RoleName } from 'api/services';
import ScopeCheckContainer from 'components/ScopeCheckContainer';
import StatsPeriodSelector, {
  getDefaultPeriodArgs,
} from 'components/StatsPeriodSelector';
import WidgetIdSelector, {
  SelectedWidgetIds,
} from 'components/WidgetIdSelector';
import { DashboardContent, DashboardContentTitle } from 'pages/Dashboard';
import DashboardPageFilters from 'pages/Dashboard/DashboardPageFilters';
import NoDataPage from 'pages/NoDataPage';
import { useCallback, useState } from 'react';
import { useWidgetIds } from 'state/user';
import UrlBreakoutDashboardProvider from './UrlBreakoutDashboardContext';
import UrlBreakoutDisclaimer from './UrlBreakoutDisclaimer';
import UrlBreakoutTableCard from './UrlBreakoutTableCard';

const UrlBreakoutDashboard: React.FunctionComponent = () => {
  const [selectedWids, setSelectedWids] = useState<
    SelectedWidgetIds | undefined
  >(undefined);
  const [draftWids, setDraftWids] = useState<SelectedWidgetIds | undefined>(
    undefined,
  );
  const data = useWidgetIds(setDraftWids);

  const widgetIds = data.isSuccess ? data.data : [];

  const [periodArgs, setPeriodArgs] = useState(getDefaultPeriodArgs());

  const handleClose = useCallback(() => {
    setSelectedWids(draftWids);
  }, [draftWids]);

  return (
    <ScopeCheckContainer
      requiredScopes={[RoleName.publisher]}
      fallback={
        <NoDataPage
          trackingProps={{
            section: 'DiscoWidget',
            page: 'URLBreakout',
            state: 'Empty',
          }}
        />
      }
    >
      <UrlBreakoutDashboardProvider
        periodArgs={periodArgs}
        widgetIds={selectedWids || []}
      >
        <DashboardContent
          maxWidth="xl"
          title={
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12}>
                <DashboardContentTitle>URL Breakout</DashboardContentTitle>
              </Grid>
              <DashboardPageFilters
                filters={[
                  <WidgetIdSelector
                    onChange={setDraftWids}
                    onClose={handleClose}
                    value={draftWids || []}
                    widgetIds={widgetIds}
                  />,
                  <StatsPeriodSelector onSelectPeriod={setPeriodArgs} />,
                ]}
              />
            </Grid>
          }
        >
          <Grid container spacing={2} marginBottom={5}>
            <Grid item xs={12}>
              <UrlBreakoutDisclaimer />
            </Grid>
            <Grid item xs={12}>
              <UrlBreakoutTableCard />
            </Grid>
          </Grid>
        </DashboardContent>
      </UrlBreakoutDashboardProvider>
    </ScopeCheckContainer>
  );
};

export default UrlBreakoutDashboard;
