import { Edit } from '@sparemin/blockhead';
import { ActionItem, ActionItemRow } from 'components/ActionItemsList';
import TextButton from 'components/TextButton';

export type ReviewItemProps = ActionItem;

const ReviewItem: React.FC<ReviewItemProps> = ({
  title,
  description,
  isDisabled,
  onEdit,
}) => (
  <ActionItemRow isDisabled={isDisabled}>
    <ActionItemRow.HeadingContainer>
      <ActionItemRow.Description>{description}</ActionItemRow.Description>

      <ActionItemRow.Title>{title}</ActionItemRow.Title>
    </ActionItemRow.HeadingContainer>

    <TextButton startAdornment={<Edit />} onPress={onEdit}>
      edit
    </TextButton>
  </ActionItemRow>
);

export default ReviewItem;
