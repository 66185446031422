import { styled } from '@mui/material/styles';

export const ExternalLink = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    text-decoration: underline; 
  },
`;
