import { Button } from '@sparemin/blockhead';
import SpinningSparklyHeady from 'assets/gifs/spinning-sparkly-heady.gif';
import TextButton from 'components/TextButton';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import PageHeading from '../PageHeading';
import * as S from './styles';

export interface ReviewViewProps {
  onCreateAnotherCampaign: () => void;
  onCancel: () => void;
}

const ReviewView: React.FC<ReviewViewProps> = ({
  onCreateAnotherCampaign,
  onCancel,
}) => {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();

  const handleCreateAnotherCampaign = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'Confirmation',
      buttonClick: 'CreateAnotherCampaign',
    });

    onCreateAnotherCampaign();
  }, [onCreateAnotherCampaign, trackPodcastPromoPurchaseNext]);

  const handleCancel = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'Confirmation',
      buttonClick: 'NoThanks',
    });

    onCancel();
  }, [onCancel, trackPodcastPromoPurchaseNext]);

  return (
    <S.Root>
      <img src={SpinningSparklyHeady} alt="" width="134px" height="134px" />

      <PageHeading
        title="Your campaign has been submitted!"
        description="A member of our team will be in touch soon to confirm all the details
        before setting it live."
      />

      <Button fluid onPress={handleCreateAnotherCampaign}>
        create another campaign
      </Button>

      <TextButton onPress={() => handleCancel()}>no thanks</TextButton>
    </S.Root>
  );
};

export default ReviewView;
