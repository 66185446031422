import { PeriodArgs } from 'components/StatsPeriodSelector';
import { SelectedWidgetIds } from 'components/WidgetIdSelector';
import React, { useContext, useMemo } from 'react';
import { useParsedUrlWidgetMetrics } from '../state';
import { UrlBreakoutDashboardContextType } from './types';

const UrlBreakoutDashboardContext = React.createContext<
  UrlBreakoutDashboardContextType | undefined
>(undefined);

export function useUrlBreakoutDashboard() {
  const context = useContext(UrlBreakoutDashboardContext);

  if (context === undefined) {
    throw new Error(
      'useUrlBreakoutDashboard must be used within UrlBreakoutDashboardProvider',
    );
  }

  return context;
}

interface UrlBreakoutDashboardProviderProps {
  periodArgs: PeriodArgs;
  widgetIds: SelectedWidgetIds;
}

const UrlBreakoutDashboardProvider: React.FunctionComponent<
  UrlBreakoutDashboardProviderProps
> = (props) => {
  const { children, periodArgs, widgetIds } = props;

  const {
    data: urlStatsData = [],
    isError,
    isLoading,
  } = useParsedUrlWidgetMetrics({
    endMillis: periodArgs.endMillis,
    startMillis: periodArgs.startMillis,
    widgetIds: widgetIds.includes('All') ? undefined : widgetIds,
  });

  return (
    <UrlBreakoutDashboardContext.Provider
      value={useMemo(
        () => ({
          periodArgs,
          urlStats: {
            data: urlStatsData,
            error: isError,
            loading: isLoading,
          },
          widgetIds,
        }),
        [isError, isLoading, periodArgs, urlStatsData, widgetIds],
      )}
    >
      {children}
    </UrlBreakoutDashboardContext.Provider>
  );
};

export default UrlBreakoutDashboardProvider;
