import {
  Typography,
  List as MuiList,
  ListItemButton,
  Divider as MuiDivider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit as EditIcon, Visibility, Mic } from '@sparemin/blockhead';
import { withAttrs } from 'utils/react';

export const Edit = styled(EditIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  margin: 0;
`;

export const UserSelectionText = styled(
  withAttrs(Typography, { component: 'span' }),
)`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.dark};
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const EditText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};

  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`;

export const Badge = styled('div')`
  width: 45px;
  height: 45px;
  background-color: #6e6ede;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
`;

export const StyleIcon = styled(Visibility)`
  width: 22.5px;
  height: 15.75px;
  color: white;
`;

export const AudioIcon = styled(Mic)`
  width: 14.625px;
  height: 22.5px;
  color: white;
`;

export const List = styled(MuiList)`
  padding-left: 0;
  padding-right: 0;
`;

export const Button = styled(
  withAttrs(ListItemButton, {
    disableGutters: true,
    component: 'div',
  }),
)`
  position: relative;
  width: 100%;
  padding: 10px;
`;

export const Divider = styled(MuiDivider)`
  margin: 0 0 9px 0;
`;
