import { Stack, Typography } from '@mui/material';
import Logo from 'assets/heady-gradient.svg';
import { MailToProps } from 'components/MailTo';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { TrackDashboardPageLoadProperties } from 'state/eventTracking';
import MailToSupport from './MailToSupport';
import * as S from './styles';

export interface EmptyStateProps
  extends Pick<MailToProps, 'email' | 'subject' | 'label'> {
  header?: string;
  subHeader?: string;
  trackingProps?: TrackDashboardPageLoadProperties;
  icon?: React.ReactNode;
  cta?: React.ReactNode;
  onCtaClick?: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  header,
  subHeader,
  email,
  subject,
  label,
  trackingProps,
  icon,
  cta,
  onCtaClick,
}) => {
  useTrackPageLoad(trackingProps);

  return (
    <Stack alignItems="center" gap={2.5}>
      {icon ?? <S.Logo alt="Disco by Headliner logo" src={Logo} />}
      <Typography variant="h1">{header}</Typography>
      <Typography gutterBottom variant="h2">
        {subHeader}
      </Typography>
      {cta ?? (
        <MailToSupport
          email={email}
          subject={subject}
          label={label}
          onClick={onCtaClick}
        />
      )}
    </Stack>
  );
};
export default EmptyState;
