import { Button } from '@sparemin/blockhead';
import ActionItemsList from 'components/ActionItemsList';
import TextButton from 'components/TextButton';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useCampaignCreation } from '../CampaignCreationContext';
import PageHeading from '../PageHeading';
import { useCreateAdCampaign } from '../state';
import ReviewItem from './ReviewItem';
import * as S from './styles';
import useReviewItems from './useReviewItems';

export interface ReviewViewProps {
  onGoBack: () => void;
  onCancel: () => void;
}

const ReviewView: React.FC<ReviewViewProps> = ({ onGoBack, onCancel }) => {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();
  const { mutateAsync: createCampaign, isLoading } = useCreateAdCampaign();
  const {
    state: { values, isCustomBudget },
  } = useCampaignCreation();
  const reviewItems = useReviewItems({ onGoBack });

  const handleSubmit = useCallback(async (): Promise<void> => {
    trackPodcastPromoPurchaseNext({
      step: 'Review',
      buttonClick: 'ContinueToPurchase',
    });

    const { checkoutUrl } = await createCampaign({
      ...values,
      targetCategories: values?.targetCategories?.includes('General Interest')
        ? null
        : values.targetCategories,
      targetCountryCodes: values?.targetCountryCodes?.includes('Worldwide')
        ? null
        : values.targetCountryCodes,
      podcastId: values?.episode?.podcast?.podcastId,
      remoteEpisodeId: values?.episode?.episodeId,
      campaignBudgetMilliCent: isCustomBudget
        ? Number(values?.campaignBudgetMilliCent) * 100000
        : values.campaignBudgetMilliCent,
    });

    window.location.href = checkoutUrl;
  }, [createCampaign, isCustomBudget, trackPodcastPromoPurchaseNext, values]);

  return (
    <S.Root>
      <PageHeading title="Let’s do a quick review of your campaign!" />

      <ActionItemsList>
        {reviewItems.map((item) => (
          <ReviewItem key={item.id} isDisabled={isLoading} {...item} />
        ))}
      </ActionItemsList>

      <Button fluid isDisabled={isLoading} onPress={handleSubmit}>
        continue to purchase
      </Button>

      <TextButton onPress={onCancel}>cancel</TextButton>
    </S.Root>
  );
};

export default ReviewView;
