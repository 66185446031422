import { GetPublisherWidgetArgs, GetSelfServeWidgetArgs } from 'api/services';

export const userDataQueries = {
  all: ['userRoles' as const],
  publisher: (args?: GetPublisherWidgetArgs) => [
    ...userDataQueries.all,
    'publisher',
    args,
  ],
  selfServeWidget: (args: GetSelfServeWidgetArgs) => [
    ...userDataQueries.all,
    'selfServeWidget',
    args,
  ],
  advertiser: () => [...userDataQueries.all, 'advertiser'],
};

export const userProfileQueries = {
  all: ['profile'] as const,
  user: (userId: number) => [...userProfileQueries.all, userId],
};
