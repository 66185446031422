import * as S from './styles';

interface ActionAreaProps<T> {
  cardType: T;
  className?: string;
  children?: React.ReactNode;
  onClick?: (val: T) => void;
}

const ActionArea = <T,>({
  cardType,
  className,
  children,
  onClick,
}: ActionAreaProps<T>) => (
  <S.Root className={className} onClick={() => onClick?.(cardType)}>
    {children}
  </S.Root>
);

export default ActionArea;
