export type Platform = 'Mobile' | 'Other' | 'PC' | 'Tablet';

export type PlatformAll = Platform | 'All';

export type WidgetVariantId =
  | 'DV_ckr3u0go30000dip199xf5kd5'
  | 'DV_cldn7q2lg00010tqfeqgu26bi';

export type DisplayVariant = {
  displayVariantId: WidgetVariantId;
};

export type AudioPlaybackMethod =
  | 'headlinerWithOriginal'
  | 'headlinerOnly'
  | 'originalOnly';

export interface Widget {
  widgetId: number;
  widgetSid: string;
  widgetName: string;
  selfServeRequestId: number;
  displayVariants: DisplayVariant[];
  audioPlaybackMethod?: AudioPlaybackMethod;
}

export interface PublisherDomain {
  domainId: number;
  domainSid: string;
  domain: string;
  displayName: string;
  widgets: Widget[];
  selfServeRequestId?: number;
}

export interface Publisher {
  domains: PublisherDomain[];
}

export interface GetPublisherWidgetArgs {
  isSelfServe?: boolean;
}

export interface Campaign {
  id: number;
  campaignSid: string;
  name: string;
}

export interface Advertiser {
  id: number;
  name: string;
  campaigns: Campaign[];
}

export interface MyAdvertisers {
  advertisers: Advertiser[];
}

export interface ByDayWidgetMetric {
  dayMillis: number;
  widgetLoad: number;
  widgetInViewport: number;
  resultPlayed: number;
  resultClickThrough: number;
}

export interface ByDayWidgetResponse {
  data: ByDayWidgetMetric[];
}

export interface GetByDayWidgetMetricsArgs {
  startMillis: number;
  endMillis?: number;
  widgetIds?: string[];
}

export interface AdvertisersByDayWidgetMetric {
  dayMillis: number;
  spResultPlayed: number;
  spClickThrough: number;
  avgCpcMilliCent: number;
  spEngaged: number;
  totalCostMilliCent: number;
  unitLoad: number;
  unitInViewport: number;
}

export interface AdvertisersByDayWidgetResponse {
  data: AdvertisersByDayWidgetMetric[];
}

export interface GetAdvertisersByDayWidgetMetricsArgs {
  startMillis: number;
  endMillis?: number;
  campaignSids?: string[];
}

export interface GetPlatformByDayWidgetMetricsArgs {
  startMillis: number;
  endMillis?: number;
  widgetIds?: string[];
}

export interface GetPlatformByDayWidgetMetric {
  dayMillis: number;
  widgetLoad: number;
  widgetInViewport: number;
  resultPlayed: number;
  resultClickThrough: number;
  platform: Platform;
}

export interface GetPlatformByDayWidgetMetricsResponse {
  data: GetPlatformByDayWidgetMetric[];
}

export interface GetUrlWidgetMetricsArgs {
  startMillis: number;
  endMillis?: number;
  widgetIds?: string[];
}

export interface GetUrlWidgetMetric {
  resultClickThrough: number;
  resultPlayed: number;
  url: string;
  widgetInViewport: number;
  widgetLoad: number;
}

export interface GetUrlWidgetMetricsResponse {
  data: GetUrlWidgetMetric[];
}

export interface CreateWidgetArgs {
  rssUrl: string;
  websiteUrl: string;
  displayVariantId: string;
  audioPlaybackMethod: AudioPlaybackMethod;
}

export interface CreateWidgetResponse {
  creationStatus: WidgetCreationResponses;
}

export interface UpdateWidgetOptionsArgs {
  widgetId: string;
  displayVariantId?: string;
  audioPlaybackMethod?: AudioPlaybackMethod;
}
export interface UpdateWidgetStyleResponse {
  widgetId: string;
  isValid: boolean;
}
export type WidgetCreationResponses =
  | 'success'
  | 'unsupportedLanguage'
  | 'duplicateRssUrl'
  | 'widgetLimitReached';

export interface GetSelfServeWidgetArgs {
  selfServeRequestId: number;
}
export interface GetSelfServeWidgetsResponse {
  domains: SelfServePublisherDomain[];
}

export interface SelfServePublisherDomain
  extends Omit<PublisherDomain, 'widgets'> {
  widgets: SelfServeWidget[];
}

export interface SelfServeWidget extends Widget {
  widgetProcessingStatus: 'completed' | 'processing' | 'error';
}

export type SponsorPlacement = 'headlinerApp' | 'publisherNetwork';

export type CampaignCategories =
  | 'Arts & Entertainment'
  | 'Automotive'
  | 'Business'
  | 'Family & Parenting'
  | 'Food & Drink'
  | 'Health & Fitness'
  | 'Home & Garden'
  | 'Law, Gov’t & Politics'
  | 'Mature'
  | 'News'
  | 'Outdoor & Hunting'
  | 'Pets'
  | 'Science'
  | 'Shopping'
  | 'Sports'
  | 'Style & Fashion'
  | 'Technology & Computing'
  | 'Travel'
  | 'General Interest';

export interface AdCampaignInfo {
  podcastId?: string;
  remoteEpisodeId?: string;
  campaignBudgetMilliCent?: number;
  sponsorPlacement?: SponsorPlacement;
  campaignDuration?: string;
  targetCategories: CampaignCategories[] | null;
  targetCountryCodes: string[] | null;
  // The following categories have been removed from the UI but will still be returned by the api
  targetGenderGroups: string[] | null;
  targetDeviceTypes: string[] | null;
  targetAgeGroups: string[] | null;
}

export type CreateAdCampaignArgs = Omit<
  AdCampaignInfo,
  'targetGenderGroups' | 'targetDeviceTypes' | 'targetAgeGroups'
>;

export interface CreateAdCampaignResponse {
  requestId: number;
  checkoutUrl: string;
}

export interface AdCampaignContentDetail {
  podcastTitle: string;
  podcastThumbnailUrl: string;
  episodeTitle: string;
  episodeThumbnailUrl: string;
}

export interface AdCampaignRequest extends AdCampaignInfo {
  contentDetail: AdCampaignContentDetail;
  createdAt: number;
}

export interface GetAdCampaignResponse {
  requests: AdCampaignRequest[];
}

export enum RoleName {
  advertiser = 'advertiser',
  publisher = 'publisher',
}

export type Role = {
  name: RoleName;
};

export type UserRoles = {
  roles: Role[];
};

export interface GetEpisodeMetricsArgs {
  podcastId: string;
}

export interface GetEpisodeMetricsOpts {
  nextEpisodePubAtMillis?: number;
}

export interface EpisodeMetrics {
  title: string;
  thumbnailUrl: string;
  publishedAtMillis: number;
  playCount: number;
}

export interface GetEpisodeMetricsResponse {
  title: string;
  playCount: number;
  followCount: number;
  revenueShareMilliCent?: number;
  episodes: EpisodeMetrics[];
  nextEpisodePubAtMillis?: number;
}
