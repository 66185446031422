import { useCampaignCreation } from 'pages/AdCampaignPage/CampaignCreationContext';
import { COUNTRIES } from 'utils/countries';
import TargetingSelectionMultiSelect from './TargetingSelectionMultiSelect';

export interface TargetCountrySelectorProps {}

const TargetCountrySelector: React.FC<TargetCountrySelectorProps> = () => {
  const {
    state: {
      values: { targetCountryCodes },
    },
    dispatch,
  } = useCampaignCreation();

  const handleClose = () => {
    if (targetCountryCodes?.length === 0) {
      dispatch({
        type: 'CHANGE_VALUES',
        payload: {
          targetCountryCodes: [COUNTRIES[0].itemValue],
        },
      });
    }
  };

  return (
    <TargetingSelectionMultiSelect
      selectLabel="Target Country"
      options={COUNTRIES}
      value={targetCountryCodes ?? []}
      onChange={(newValues: string[]) =>
        dispatch({
          type: 'CHANGE_VALUES',
          payload: {
            targetCountryCodes: newValues,
          },
        })
      }
      onClose={handleClose}
    />
  );
};

export default TargetCountrySelector;
