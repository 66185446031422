import discoAds from 'assets/adsImagery/imagery-ads.png';
import discoAds2x from 'assets/adsImagery/imagery-ads@2x.png';
import discoAds3x from 'assets/adsImagery/imagery-ads@3x.png';
import play from 'assets/playImagery/imagery-play.png';
import play2x from 'assets/playImagery/imagery-play@2x.png';
import play3x from 'assets/playImagery/imagery-play@3x.png';
import studio from 'assets/studioImagery/imagery-studio.png';
import studio2x from 'assets/studioImagery/imagery-studio@2x.png';
import studio3x from 'assets/studioImagery/imagery-studio@3x.png';
import discoWidget from 'assets/widgetImagery/imagery-widget.png';
import discoWidget2x from 'assets/widgetImagery/imagery-widget@2x.png';
import discoWidget3x from 'assets/widgetImagery/imagery-widget@3x.png';

import {
  DISCO_ADS_DESCRIPTION,
  DISCO_WIDGET_DESCRIPTION,
  HEADLINER_STUDIO_DESCRIPTION,
  PLAY_ANALYTICS_DESCRIPTION,
} from 'utils/constants';
import routes from 'utils/routes';

export const cardArray = [
  {
    src: discoWidget,
    srcSet: `${discoWidget} 1x, ${discoWidget2x} 2x, ${discoWidget3x} 3x`,
    alt: 'disco',
    title: 'Disco',
    text: DISCO_WIDGET_DESCRIPTION,
    href: routes.discoWidgetDashboard.path,
  },
  {
    src: discoAds,
    srcSet: `${discoAds} 1x, ${discoAds2x} 2x, ${discoAds3x} 3x`,
    alt: 'podcast promo',
    title: 'Podcast Promo',
    text: DISCO_ADS_DESCRIPTION,
    href: routes.discoAdsDashboard.path,
  },
  {
    src: play,
    srcSset: `${play} 1x, ${play2x} 2x, ${play3x} 3x`,
    alt: 'play',
    title: 'Play',

    text: PLAY_ANALYTICS_DESCRIPTION,
    href: routes.playAnalytics.path,
  },
  {
    src: studio,
    srcSet: `${studio} 1x, ${studio2x} 2x, ${studio3x} 3x`,
    alt: 'make',
    title: 'Make',
    text: HEADLINER_STUDIO_DESCRIPTION,
    href: routes.studioDashboard.path,
  },
];
