import useColumnSum from 'hooks/useColumnSum';
import { PropsWithChildren } from 'react';
import { TableInstance } from 'react-table';

import NumericCell from './NumericCell';
import { ColumnHeaders, NumericCellFormats } from './types';
import { formatNumericValue } from './utils';

type AveragedNumericCellProps<TData extends object> = {
  totalColumn: ColumnHeaders;
  divisorColumn: ColumnHeaders;
  tableData: PropsWithChildren<TableInstance<TData>>;
  type?: NumericCellFormats;
};

const AveragedTotalsNumericCells = <TData extends object>(
  props: AveragedNumericCellProps<TData>,
): React.ReactElement => {
  const { totalColumn, divisorColumn, tableData, type } = props;
  const total = useColumnSum({ accessor: totalColumn, tableData });
  const divisor = useColumnSum({ accessor: divisorColumn, tableData });
  const average = divisor !== 0 ? total / divisor : 0;
  return <NumericCell value={formatNumericValue(average, type)} />;
};

export default AveragedTotalsNumericCells;
