import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { ReactElement } from 'react';
import { TableInstance } from 'react-table';
import * as S from '../styles';
import InfiniteLoader, { InfiniteLoaderProps } from './InfiniteLoader';

export interface DataTableBodyProps<TData extends object>
  extends Omit<InfiniteLoaderProps, 'table'> {
  onRowClick?: (data: TData) => void;
  infinite?: boolean;
  table: TableInstance<TData>;
}

function DataTableBody<TData extends object>({
  onRowClick,
  container,
  hasMore,
  infinite,
  onLoadMore,
  table,
}: DataTableBodyProps<TData>): ReactElement<any, any> | null {
  const { prepareRow, rows } = table;

  return (
    <TableBody>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <S.TableRow
            {...row.getRowProps()}
            onClick={!onRowClick ? undefined : () => onRowClick(row.original)}
          >
            {row.cells.map((cell) => (
              <TableCell width={cell.column.width} {...cell.getCellProps()}>
                {cell.render('Cell')}
              </TableCell>
            ))}
          </S.TableRow>
        );
      })}
      {infinite && (
        <InfiniteLoader {...{ container, hasMore, onLoadMore, table }} />
      )}
    </TableBody>
  );
}

export default DataTableBody;
