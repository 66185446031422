import { styled } from '@mui/material/styles';
import { SocialPlatformIconProps } from './types';

export const IconContainer = styled('div')<SocialPlatformIconProps>`
  background-color: ${(props) => props.backgroundColor};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: ${({ theme }) => `solid 0.3px ${theme.palette.grey[500]}`};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
`;
