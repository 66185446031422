import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Card)`
  width: 280px;
  height: 280px;

  &:hover {
    border-color: #019ce3;
  }
`;
