import DialogBox from 'components/DialogBox';
import { useConnectedModal } from 'state/modals';
import { IMAGE_CROPPER_TEXT } from './constants';
import ImageCropModalContents from './ImageCropModalContents';

export type ImageCropModalProps = {};

const ImageCropModal: React.FC<ImageCropModalProps> = () => {
  const name = 'ImageCrop';
  const { params } = useConnectedModal({ name });

  return (
    <DialogBox title="Crop Image" subtitle={IMAGE_CROPPER_TEXT} name={name}>
      <ImageCropModalContents {...params} />
    </DialogBox>
  );
};
export default ImageCropModal;
