import { Action, State } from './types';

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'hide': {
      if (state.length === 0) {
        return state;
      }

      const [first, ...rest] = state;
      return [
        {
          ...first,
          show: false,
        },
        ...rest,
      ];
    }

    case 'push': {
      const { payload: modal } = action;
      return [...state, { ...modal, show: true }];
    }

    case 'pop': {
      const [, ...rest] = state;
      return rest;
    }

    default:
      return state;
  }
}
