import DailyStatsTableCard from 'components/DailyStatsTableCard';
import { usePublisherDashboard } from '../PublisherDashboardContext';
import { columns, defaultSort } from './utils';

export interface DailyStatsCardProps {}

const DailyStatsCard: React.FC<DailyStatsCardProps> = () => {
  const { dailyStats } = usePublisherDashboard();

  return (
    <DailyStatsTableCard
      columns={columns}
      dailyStats={dailyStats}
      defaultSort={defaultSort}
      exportArgs={{
        fileName: 'Publisher_daily_stats',
      }}
      footer
      reportType="DiscoWidgetDailyStats"
    />
  );
};

export default DailyStatsCard;
