import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const MINUTES_TO_MILLIS_COEF = 60000;

/**
 * Simple formatter for displaying a date
 * @param {number} millis - Number in miliseconds
 * @returns {string} Date formatted to string
 */
export function displayDate(
  millis: number,
  format: string = 'YYYY-MM-DD',
): string {
  return dayjs.utc(millis).format(format);
}

/**
 * Convers a date string to its value in miliseconds
 * @param {string} date - date string to convert
 * @returns {number} Date converted to miliseconds
 */
export function parseDate(date: string): number {
  return dayjs.utc(date).valueOf();
}

/**
 * Adjust input changed value expressed in local TZ with the offset
 * to utc for sending values to API
 * @param {number} millis - datetime value in millis selected using local
 * tz based input.
 * @returns {number} - Adjusted time in millis (useful for being sent
 * to the backend)
 */
export function adjustInputUtcMillis(millis: number): number {
  return millis + dayjs().utcOffset() * MINUTES_TO_MILLIS_COEF;
}

/**
 * Adjust externally obtained value in utc to a local tz value
 * expressed in millis
 * @param {number} millis - datetime value in millis selected using utc
 * tz based input.
 * @returns {number} - Adjusted time in millis (useful for mapping
 * converted values or api obtained values to a input)
 */
export function adjustTimestampMillisToInput(millis: number) {
  return millis - dayjs().utcOffset() * MINUTES_TO_MILLIS_COEF;
}

/**
 * Gets the start of day millis value for a certain date
 * @param {Object} date - date from which millis value should be obtained
 * @returns {number} - Millis values for the start of day of the selected date
 */
export function adjustDateToStartOfDayMillis(date: Dayjs): number {
  return date.clone().startOf('day').valueOf();
}
