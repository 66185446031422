import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withAttrs } from 'utils/react';

export const Button = styled(
  withAttrs(MuiButton, {
    component: 'a',
    variant: 'contained',
    size: 'large',
  }),
)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  border-width: 2px;
  border-radius: 25px;
  height: 50px;
  padding: 0 26px;
  text-align: center;
`;
