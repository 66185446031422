import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Stack, Typography } from '@mui/material';
import { displayDate } from 'utils/date';
import * as TableStyles from '../styles';
import * as S from './styles';

interface PodcastEpisodeCellProps {
  thumbnailUrl: string;
  episodeTitle: string;
  pubDateInMillis?: number;
}

const PodcastEpisodeCell: React.FC<PodcastEpisodeCellProps> = ({
  thumbnailUrl,
  episodeTitle,
  pubDateInMillis,
}) => (
  <Stack
    alignItems="center"
    direction="row"
    spacing={2}
    justifyContent="flex-start"
  >
    {thumbnailUrl ? (
      <TableStyles.Img src={thumbnailUrl} alt="episode artwork" />
    ) : (
      <ImageNotSupportedIcon />
    )}
    {pubDateInMillis ? (
      <Stack direction="column">
        <S.Title>
          <strong>{episodeTitle}</strong>
        </S.Title>

        <Typography variant="body1">{`Published on ${displayDate(
          pubDateInMillis,
          'MM.DD.YYYY',
        )}`}</Typography>
      </Stack>
    ) : (
      <S.Title>
        <strong>{episodeTitle}</strong>
      </S.Title>
    )}
  </Stack>
);

export default PodcastEpisodeCell;
