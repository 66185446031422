import { Typography } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';

export interface NotificationProps
  extends Omit<SnackbarProps, 'children' | 'message' | 'onClose'>,
    Pick<AlertProps, 'severity'> {
  children?: React.ReactNode;
  onClose?: () => void;
}

const Notification: React.FC<NotificationProps> = ({
  children,
  onClose,
  severity,
  ...props
}) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    autoHideDuration={5000}
    onClose={onClose}
    {...props}
  >
    <Alert onClose={onClose} severity={severity}>
      <Typography variant="body1">{children}</Typography>
    </Alert>
  </Snackbar>
);

export default Notification;
