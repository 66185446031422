import {
  getSelfServeWidgets,
  GetSelfServeWidgetsResponse,
  GetSelfServeWidgetArgs,
} from 'api/services';
import { useQuery, UseQueryOptions } from 'react-query';
import { userDataQueries } from './queryKeys';
import { widgetInfoSelector } from './selectors';

type QueryKey = ReturnType<typeof userDataQueries.selfServeWidget>;

function useGetSelfServeWidgets<TData = GetSelfServeWidgetsResponse>(
  args: GetSelfServeWidgetArgs,
  opts?: Omit<
    UseQueryOptions<GetSelfServeWidgetsResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery(
    userDataQueries.selfServeWidget(args),
    () => getSelfServeWidgets(args.selfServeRequestId),
    { enabled: !!args.selfServeRequestId, ...opts },
  );
}

export function useGetSelfServeWidgetInfo(args: GetSelfServeWidgetArgs) {
  return useGetSelfServeWidgets(args, { select: widgetInfoSelector });
}
