const DEFAULT_DECIMAL_POSITIONS = 2;
const MILI_CENTS_TO_USD_RATE = 100000;

/**
 * Simple number formatter for converting a value expressed in mili cents
 * to a value in usd (pending to validate is usd will be the only currency)
 * @param {number} value - Value expressed in mili cents
 * @param {number} [decimalPositions=3] - Decimal positions for the output
 * formatted value (default=3)
 * @returns {string} Formatted output value converted to string
 */
export const formatMiliCentsValue = (
  value: string | number,
  decimalPositions = DEFAULT_DECIMAL_POSITIONS,
) => {
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  return (numericValue / MILI_CENTS_TO_USD_RATE).toFixed(decimalPositions);
};
