import { Option } from 'components/MultiSelect';

export const COUNTRIES: Option<string>[] = [
  { itemValue: 'Worldwide', primaryItemLabel: 'Worldwide' },
  { itemValue: 'AF', primaryItemLabel: 'Afghanistan' },
  { itemValue: 'AL', primaryItemLabel: 'Albania' },
  { itemValue: 'DZ', primaryItemLabel: 'Algeria' },
  { itemValue: 'AD', primaryItemLabel: 'Andorra' },
  { itemValue: 'AO', primaryItemLabel: 'Angola' },
  { itemValue: 'AG', primaryItemLabel: 'Antigua & Deps' },
  { itemValue: 'AR', primaryItemLabel: 'Argentina' },
  { itemValue: 'AM', primaryItemLabel: 'Armenia' },
  { itemValue: 'AU', primaryItemLabel: 'Australia' },
  { itemValue: 'AT', primaryItemLabel: 'Austria' },
  { itemValue: 'AZ', primaryItemLabel: 'Azerbaijan' },
  { itemValue: 'BS', primaryItemLabel: 'Bahamas' },
  { itemValue: 'BH', primaryItemLabel: 'Bahrain' },
  { itemValue: 'BD', primaryItemLabel: 'Bangladesh' },
  { itemValue: 'BB', primaryItemLabel: 'Barbados' },
  { itemValue: 'BY', primaryItemLabel: 'Belarus' },
  { itemValue: 'BE', primaryItemLabel: 'Belgium' },
  { itemValue: 'BZ', primaryItemLabel: 'Belize' },
  { itemValue: 'BJ', primaryItemLabel: 'Benin' },
  { itemValue: 'BT', primaryItemLabel: 'Bhutan' },
  { itemValue: 'BO', primaryItemLabel: 'Bolivia' },
  { itemValue: 'BA', primaryItemLabel: 'Bosnia Herzegovina' },
  { itemValue: 'BW', primaryItemLabel: 'Botswana' },
  { itemValue: 'BR', primaryItemLabel: 'Brazil' },
  { itemValue: 'BN', primaryItemLabel: 'Brunei' },
  { itemValue: 'BG', primaryItemLabel: 'Bulgaria' },
  { itemValue: 'BF', primaryItemLabel: 'Burkina' },
  { itemValue: 'BI', primaryItemLabel: 'Burundi' },
  { itemValue: 'KH', primaryItemLabel: 'Cambodia' },
  { itemValue: 'CM', primaryItemLabel: 'Cameroon' },
  { itemValue: 'CA', primaryItemLabel: 'Canada' },
  { itemValue: 'CV', primaryItemLabel: 'Cape Verde' },
  { itemValue: 'CF', primaryItemLabel: 'Central African Rep' },
  { itemValue: 'TD', primaryItemLabel: 'Chad' },
  { itemValue: 'CL', primaryItemLabel: 'Chile' },
  { itemValue: 'CN', primaryItemLabel: 'China' },
  { itemValue: 'CO', primaryItemLabel: 'Colombia' },
  { itemValue: 'KM', primaryItemLabel: 'Comoros' },
  { itemValue: 'CG', primaryItemLabel: 'Congo' },
  { itemValue: 'CD', primaryItemLabel: 'Congo {Democratic Rep}' },
  { itemValue: 'CR', primaryItemLabel: 'Costa Rica' },
  { itemValue: 'HR', primaryItemLabel: 'Croatia' },
  { itemValue: 'CU', primaryItemLabel: 'Cuba' },
  { itemValue: 'CY', primaryItemLabel: 'Cyprus' },
  { itemValue: 'CZ', primaryItemLabel: 'Czech Republic' },
  { itemValue: 'DK', primaryItemLabel: 'Denmark' },
  { itemValue: 'DJ', primaryItemLabel: 'Djibouti' },
  { itemValue: 'DM', primaryItemLabel: 'Dominica' },
  { itemValue: 'DO', primaryItemLabel: 'Dominican Republic' },
  { itemValue: 'TL', primaryItemLabel: 'East Timor' },
  { itemValue: 'EC', primaryItemLabel: 'Ecuador' },
  { itemValue: 'EG', primaryItemLabel: 'Egypt' },
  { itemValue: 'SV', primaryItemLabel: 'El Salvador' },
  { itemValue: 'GQ', primaryItemLabel: 'Equatorial Guinea' },
  { itemValue: 'ER', primaryItemLabel: 'Eritrea' },
  { itemValue: 'EE', primaryItemLabel: 'Estonia' },
  { itemValue: 'ET', primaryItemLabel: 'Ethiopia' },
  { itemValue: 'FJ', primaryItemLabel: 'Fiji' },
  { itemValue: 'FI', primaryItemLabel: 'Finland' },
  { itemValue: 'FR', primaryItemLabel: 'France' },
  { itemValue: 'GA', primaryItemLabel: 'Gabon' },
  { itemValue: 'GM', primaryItemLabel: 'Gambia' },
  { itemValue: 'GE', primaryItemLabel: 'Georgia' },
  { itemValue: 'DE', primaryItemLabel: 'Germany' },
  { itemValue: 'GH', primaryItemLabel: 'Ghana' },
  { itemValue: 'GR', primaryItemLabel: 'Greece' },
  { itemValue: 'GD', primaryItemLabel: 'Grenada' },
  { itemValue: 'GT', primaryItemLabel: 'Guatemala' },
  { itemValue: 'GN', primaryItemLabel: 'Guinea' },
  { itemValue: 'GW', primaryItemLabel: 'Guinea-Bissau' },
  { itemValue: 'GY', primaryItemLabel: 'Guyana' },
  { itemValue: 'HT', primaryItemLabel: 'Haiti' },
  { itemValue: 'HN', primaryItemLabel: 'Honduras' },
  { itemValue: 'HU', primaryItemLabel: 'Hungary' },
  { itemValue: 'IS', primaryItemLabel: 'Iceland' },
  { itemValue: 'IN', primaryItemLabel: 'India' },
  { itemValue: 'ID', primaryItemLabel: 'Indonesia' },
  { itemValue: 'IR', primaryItemLabel: 'Iran' },
  { itemValue: 'IQ', primaryItemLabel: 'Iraq' },
  { itemValue: 'IE', primaryItemLabel: 'Ireland {Republic}' },
  { itemValue: 'IL', primaryItemLabel: 'Israel' },
  { itemValue: 'IT', primaryItemLabel: 'Italy' },
  { itemValue: 'CI', primaryItemLabel: 'Ivory Coast' },
  { itemValue: 'JM', primaryItemLabel: 'Jamaica' },
  { itemValue: 'JP', primaryItemLabel: 'Japan' },
  { itemValue: 'JO', primaryItemLabel: 'Jordan' },
  { itemValue: 'KZ', primaryItemLabel: 'Kazakhstan' },
  { itemValue: 'KE', primaryItemLabel: 'Kenya' },
  { itemValue: 'KI', primaryItemLabel: 'Kiribati' },
  { itemValue: 'KP', primaryItemLabel: 'Korea North' },
  { itemValue: 'KR', primaryItemLabel: 'Korea South' },
  { itemValue: 'XK', primaryItemLabel: 'Kosovo' },
  { itemValue: 'KW', primaryItemLabel: 'Kuwait' },
  { itemValue: 'KG', primaryItemLabel: 'Kyrgyzstan' },
  { itemValue: 'LA', primaryItemLabel: 'Laos' },
  { itemValue: 'LV', primaryItemLabel: 'Latvia' },
  { itemValue: 'LB', primaryItemLabel: 'Lebanon' },
  { itemValue: 'LS', primaryItemLabel: 'Lesotho' },
  { itemValue: 'LR', primaryItemLabel: 'Liberia' },
  { itemValue: 'LY', primaryItemLabel: 'Libya' },
  { itemValue: 'LI', primaryItemLabel: 'Liechtenstein' },
  { itemValue: 'LT', primaryItemLabel: 'Lithuania' },
  { itemValue: 'LU', primaryItemLabel: 'Luxembourg' },
  { itemValue: 'MK', primaryItemLabel: 'Macedonia' },
  { itemValue: 'MG', primaryItemLabel: 'Madagascar' },
  { itemValue: 'MW', primaryItemLabel: 'Malawi' },
  { itemValue: 'MY', primaryItemLabel: 'Malaysia' },
  { itemValue: 'MV', primaryItemLabel: 'Maldives' },
  { itemValue: 'ML', primaryItemLabel: 'Mali' },
  { itemValue: 'MT', primaryItemLabel: 'Malta' },
  { itemValue: 'MH', primaryItemLabel: 'Marshall Islands' },
  { itemValue: 'MR', primaryItemLabel: 'Mauritania' },
  { itemValue: 'MU', primaryItemLabel: 'Mauritius' },
  { itemValue: 'MX', primaryItemLabel: 'Mexico' },
  { itemValue: 'FM', primaryItemLabel: 'Micronesia' },
  { itemValue: 'MD', primaryItemLabel: 'Moldova' },
  { itemValue: 'MC', primaryItemLabel: 'Monaco' },
  { itemValue: 'MN', primaryItemLabel: 'Mongolia' },
  { itemValue: 'ME', primaryItemLabel: 'Montenegro' },
  { itemValue: 'MA', primaryItemLabel: 'Morocco' },
  { itemValue: 'MZ', primaryItemLabel: 'Mozambique' },
  { itemValue: 'MM', primaryItemLabel: 'Myanmar, {Burma}' },
  { itemValue: 'NA', primaryItemLabel: 'Namibia' },
  { itemValue: 'NR', primaryItemLabel: 'Nauru' },
  { itemValue: 'NP', primaryItemLabel: 'Nepal' },
  { itemValue: 'NL', primaryItemLabel: 'Netherlands' },
  { itemValue: 'NZ', primaryItemLabel: 'New Zealand' },
  { itemValue: 'NI', primaryItemLabel: 'Nicaragua' },
  { itemValue: 'NE', primaryItemLabel: 'Niger' },
  { itemValue: 'NG', primaryItemLabel: 'Nigeria' },
  { itemValue: 'NO', primaryItemLabel: 'Norway' },
  { itemValue: 'OM', primaryItemLabel: 'Oman' },
  { itemValue: 'PK', primaryItemLabel: 'Pakistan' },
  { itemValue: 'PW', primaryItemLabel: 'Palau' },
  { itemValue: 'PA', primaryItemLabel: 'Panama' },
  { itemValue: 'PG', primaryItemLabel: 'Papua New Guinea' },
  { itemValue: 'PY', primaryItemLabel: 'Paraguay' },
  { itemValue: 'PE', primaryItemLabel: 'Peru' },
  { itemValue: 'PH', primaryItemLabel: 'Philippines' },
  { itemValue: 'PL', primaryItemLabel: 'Poland' },
  { itemValue: 'PT', primaryItemLabel: 'Portugal' },
  { itemValue: 'QA', primaryItemLabel: 'Qatar' },
  { itemValue: 'RO', primaryItemLabel: 'Romania' },
  { itemValue: 'RU', primaryItemLabel: 'Russian Federation' },
  { itemValue: 'RW', primaryItemLabel: 'Rwanda' },
  { itemValue: 'KN', primaryItemLabel: 'St Kitts & Nevis' },
  { itemValue: 'LC', primaryItemLabel: 'St Lucia' },
  { itemValue: 'VC', primaryItemLabel: 'Saint Vincent & the Grenadines' },
  { itemValue: 'WS', primaryItemLabel: 'Samoa' },
  { itemValue: 'SM', primaryItemLabel: 'San Marino' },
  { itemValue: 'ST', primaryItemLabel: 'Sao Tome & Principe' },
  { itemValue: 'SA', primaryItemLabel: 'Saudi Arabia' },
  { itemValue: 'SN', primaryItemLabel: 'Senegal' },
  { itemValue: 'RS', primaryItemLabel: 'Serbia' },
  { itemValue: 'SC', primaryItemLabel: 'Seychelles' },
  { itemValue: 'SL', primaryItemLabel: 'Sierra Leone' },
  { itemValue: 'SG', primaryItemLabel: 'Singapore' },
  { itemValue: 'SK', primaryItemLabel: 'Slovakia' },
  { itemValue: 'SI', primaryItemLabel: 'Slovenia' },
  { itemValue: 'SB', primaryItemLabel: 'Solomon Islands' },
  { itemValue: 'SO', primaryItemLabel: 'Somalia' },
  { itemValue: 'ZA', primaryItemLabel: 'South Africa' },
  { itemValue: 'SS', primaryItemLabel: 'South Sudan' },
  { itemValue: 'ES', primaryItemLabel: 'Spain' },
  { itemValue: 'LK', primaryItemLabel: 'Sri Lanka' },
  { itemValue: 'SD', primaryItemLabel: 'Sudan' },
  { itemValue: 'SR', primaryItemLabel: 'Suriname' },
  { itemValue: 'SZ', primaryItemLabel: 'Swaziland' },
  { itemValue: 'SE', primaryItemLabel: 'Sweden' },
  { itemValue: 'CH', primaryItemLabel: 'Switzerland' },
  { itemValue: 'SY', primaryItemLabel: 'Syria' },
  { itemValue: 'TW', primaryItemLabel: 'Taiwan' },
  { itemValue: 'TJ', primaryItemLabel: 'Tajikistan' },
  { itemValue: 'TZ', primaryItemLabel: 'Tanzania' },
  { itemValue: 'TH', primaryItemLabel: 'Thailand' },
  { itemValue: 'TG', primaryItemLabel: 'Togo' },
  { itemValue: 'TO', primaryItemLabel: 'Tonga' },
  { itemValue: 'TT', primaryItemLabel: 'Trinidad & Tobago' },
  { itemValue: 'TN', primaryItemLabel: 'Tunisia' },
  { itemValue: 'TR', primaryItemLabel: 'Turkey' },
  { itemValue: 'TM', primaryItemLabel: 'Turkmenistan' },
  { itemValue: 'TV', primaryItemLabel: 'Tuvalu' },
  { itemValue: 'UG', primaryItemLabel: 'Uganda' },
  { itemValue: 'UA', primaryItemLabel: 'Ukraine' },
  { itemValue: 'AE', primaryItemLabel: 'United Arab Emirates' },
  { itemValue: 'GB', primaryItemLabel: 'United Kingdom' },
  { itemValue: 'US', primaryItemLabel: 'United States' },
  { itemValue: 'UY', primaryItemLabel: 'Uruguay' },
  { itemValue: 'UZ', primaryItemLabel: 'Uzbekistan' },
  { itemValue: 'VU', primaryItemLabel: 'Vanuatu' },
  { itemValue: 'VA', primaryItemLabel: 'Vatican City' },
  { itemValue: 'VE', primaryItemLabel: 'Venezuela' },
  { itemValue: 'VN', primaryItemLabel: 'Vietnam' },
  { itemValue: 'YE', primaryItemLabel: 'Yemen' },
  { itemValue: 'ZM', primaryItemLabel: 'Zambia' },
  { itemValue: 'ZW', primaryItemLabel: 'Zimbabwe' },
];
