import { ButtonProps } from '@mui/material';
import DialogBox from 'components/DialogBox';
import { useModalControls } from 'state/modals';
import * as S from './styles';
import WidgetMarketingModalContents from './WidgetMarketingModalContents';

interface WidgetMarketingModalProps extends ButtonProps {}

const WidgetMarketingModal: React.FC<WidgetMarketingModalProps> = () => {
  const name = 'WidgetMarketing';
  const { open, close } = useModalControls();

  const handleChangeModals = () => {
    close();
    open({ name: 'CreateWidget' });
  };

  return (
    <DialogBox
      name={name}
      scroll="body"
      actions={
        <S.Footer
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <S.Button fullWidth variant="outlined" onClick={() => close()}>
            cancel
          </S.Button>
          <S.Button variant="contained" fullWidth onClick={handleChangeModals}>
            continue
          </S.Button>
        </S.Footer>
      }
    >
      <WidgetMarketingModalContents />
    </DialogBox>
  );
};
export default WidgetMarketingModal;
