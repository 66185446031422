import * as S from './styles';

export interface ActionItemsListProps {
  className?: string;
  children: React.ReactNode;
}

const ActionItemsList: React.FC<ActionItemsListProps> = ({
  className,
  children,
}) => (
  <S.Root axis="y" backgroundColor="white">
    <S.Contents className={className}>{children}</S.Contents>
  </S.Root>
);

export default ActionItemsList;
