import { CardContent } from '@mui/material';
import PodcastContentTable from 'components/PodcastContentTable';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import Simplebar from 'simplebar-react';
import { useSocialVideoInfo } from '../state';
import { SocialShareVideos } from '../types';
import AllVideosMobileList from './AllVideosMobileList';
import columns from './utils';

type AllVideosTableProps = {
  episodeId?: string | undefined;
};

const AllVideosTable: React.FC<AllVideosTableProps> = ({ episodeId }) => {
  const { isLoading, data: socialVideoData = [] } = useSocialVideoInfo({
    episodeId,
  });
  const isMobile = useMobileBreakpoint('md');

  return isMobile ? (
    <AllVideosMobileList episodeId={episodeId} />
  ) : (
    <CardContent component={Simplebar}>
      <PodcastContentTable<SocialShareVideos>
        columns={columns}
        podcastData={socialVideoData}
        isLoading={isLoading}
        tableLayout="fixed"
      />
    </CardContent>
  );
};
export default AllVideosTable;
