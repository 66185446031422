import AllVideosTable from 'pages/headliner/AllVideos';
import * as S from './styles';
import VideosByEpisodeTable, {
  SocialSharePodcastEpisode,
} from './VideosByEpisode';
import VideosByPodcastTable, { SocialSharePodcasts } from './VideosByPodcast';

type PodcastsPageCardProps = {
  podcastId: string | undefined;
  episodeId: string | undefined;
  onSelectPodcast?: (podcastId: string) => void;
  onSelectEpisode?: (episodeId: string) => void;
};

const VideosByPodcastPageCard: React.FC<PodcastsPageCardProps> = ({
  podcastId,
  episodeId,
  onSelectPodcast,
  onSelectEpisode,
}) => {
  const handleSelectPodcast = (val: SocialSharePodcasts): void => {
    onSelectPodcast?.(val.podcastId);
  };

  const handleSelectEpisode = (val: SocialSharePodcastEpisode): void => {
    onSelectEpisode?.(val.episodeId);
  };

  const getComponentFromState = () => {
    if (!podcastId) {
      return <VideosByPodcastTable onRowClick={handleSelectPodcast} />;
    }
    if (podcastId && !episodeId) {
      return (
        <VideosByEpisodeTable
          podcastId={podcastId}
          onRowClick={handleSelectEpisode}
        />
      );
    }
    return <AllVideosTable episodeId={episodeId} />;
  };

  return <S.ContentCard>{getComponentFromState()}</S.ContentCard>;
};
export default VideosByPodcastPageCard;
