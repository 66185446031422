import {
  GetRequestStatusArgs,
  GetSocialStatsByEpisodeArgs,
  SocialShareStatsArgs,
} from 'api/services';

export const embedVideoQueries = {
  videos: (args?: SocialShareStatsArgs) => ['videoStats', args],
  request: (args: GetRequestStatusArgs) => [
    ...embedVideoQueries.videos(),
    'request',
    args,
  ],
  podcasts: () => [...embedVideoQueries.videos(), 'podcasts'],
  podcast: (args: GetSocialStatsByEpisodeArgs) => [
    embedVideoQueries.podcasts(),
    args,
  ],
};
