import { Typography } from '@mui/material';
import TablePageContainer from 'components/TablePageContainer';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import DefaultSubtitle from 'pages/headliner/components/DefaultSubtitle';
import Disclaimer from 'pages/headliner/components/Disclaimer';
import StatsSummary from 'pages/headliner/SocialStatsSummary';
import { useGetSocialStatsSummary } from '../../state';
import { TABLE_PAGE_HEADER } from './constants';
import TableCard from './TableCard';

type TableContainerProps = {};

const TableContainer: React.FC<TableContainerProps> = () => {
  const { data: stats } = useGetSocialStatsSummary();

  useTrackPageLoad({
    section: 'Studio',
    page: 'AllVideos',
    state: 'Populated',
  });

  return (
    <TablePageContainer>
      <Typography variant="h1" gutterBottom>
        {TABLE_PAGE_HEADER}
      </Typography>
      <Typography variant="h2" gutterBottom>
        <DefaultSubtitle />
      </Typography>
      {stats && <StatsSummary stats={stats} />}
      <TableCard />
      <Disclaimer />
    </TablePageContainer>
  );
};
export default TableContainer;
