import React from 'react';
import { SUPPORT_EMAIL } from 'utils/constants';
import { millicentsToDollars, dollarsToString } from 'utils/money';
import * as S from './styles';

type RevenueStatProps = {
  revenue: number | undefined;
  width: string;
};

const RevenueStat: React.FC<RevenueStatProps> = ({ revenue, width }) => {
  const disabled = revenue === undefined;

  return (
    <S.RevenueStat
      icon={<S.MoneyIcon />}
      numberOf={revenue ? dollarsToString(millicentsToDollars(revenue)) : 'N/A'}
      statsType="revenue"
      width={width}
      tooltip={
        disabled
          ? `Contact ${SUPPORT_EMAIL} to turn monetization on`
          : undefined
      }
      $isDisabled={disabled}
    />
  );
};
export default RevenueStat;
