import { ListItemIcon } from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import {
  AccountCircle,
  Home,
  RadioTower,
  HeadlinerGradientLogo,
  Video,
  Megaphone,
  PlayCircle,
} from '@sparemin/blockhead';
import { omitProps } from 'utils/props';
import { NAV_PANEL_WIDTH } from '../constants';
import { WithMobile } from '../types';

const iconWidth = '20px';

export const HeadlinerLogo = styled(HeadlinerGradientLogo)`
  width: 160px;
  height: 35px;
`;

export const Icon = styled(ListItemIcon)`
  min-width: 32px;
`;

export const UserAccount = styled(AccountCircle)`
  width: ${iconWidth};
  height: auto;
`;

export const HomeIcon = styled(Home)`
  width: ${iconWidth};
  height: auto;
`;

export const MegaphoneIcon = styled(Megaphone)`
  width: ${iconWidth};
  height: auto;
`;

export const RadioIcon = styled(RadioTower)`
  width: ${iconWidth};
  height: auto;
`;

export const VideoIcon = styled(Video)`
  width: ${iconWidth};
  height: auto;
`;

export const PLayIcon = styled(PlayCircle)`
  width: ${iconWidth};
  height: auto;
`;

export const NavDrawer = styled(Drawer, {
  shouldForwardProp: omitProps(['isMobile']),
})<WithMobile<DrawerProps>>(({ isMobile, open, theme }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    whitespace: 'nowrap',

    width: NAV_PANEL_WIDTH,
    ...(!open &&
      !isMobile && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
      }),
  },
}));

export const NavDrawerContents = styled(List)<{ component: React.ElementType }>`
  width: ${NAV_PANEL_WIDTH}px;
`;
