import { getMyAdvertisersData, MyAdvertisers } from 'api/services';
import { useQuery, UseQueryOptions } from 'react-query';
import { userDataQueries } from 'state/user';
import { campaignIdsSelector, campaignsSelector } from './selectors';

type QueryKey = ReturnType<typeof userDataQueries.advertiser>;

function useCampaignData<TData = MyAdvertisers>(
  opts: Omit<
    UseQueryOptions<MyAdvertisers, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery(
    userDataQueries.advertiser(),
    () => getMyAdvertisersData(),
    opts,
  );
}

export function useCampaignIds(successHandler?: (data: string[]) => void) {
  return useCampaignData<string[]>({
    select: campaignIdsSelector,
    onSuccess: successHandler,
  });
}

export function useCampaigns() {
  return useCampaignData({
    select: campaignsSelector,
  });
}
