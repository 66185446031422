import { PriceCard } from './types';

export const HEADLINER_PRICES: PriceCard[] = [
  {
    label: '$50 budget',
    value: 5000000,
    description: '500 listens',
  },
  {
    label: '$100 budget',
    value: 10000000,
    description: '1000 listens',
  },
  {
    label: '$200 budget',
    value: 20000000,
    description: '2000 listens',
  },
];

export const PUBNET_PRICES: PriceCard[] = [
  {
    label: '$50 budget',
    value: 5000000,
    description: '200 listens',
  },
  {
    label: '$100 budget',
    value: 10000000,
    description: '400 listens',
  },
  {
    label: '$200 budget',
    value: 20000000,
    description: '800 listens',
  },
];
