import SelectAllMultiSelect, {
  SelectAllMultiSelectProps,
  Option,
} from 'components/SelectAllMultiSelect';
import { useEventTracking } from 'state/eventTracking';

import { useCampaigns } from '../state';

export interface CampaignIdSelectorProps
  extends Pick<
    SelectAllMultiSelectProps<string>,
    'onClose' | 'value' | 'onChange'
  > {
  campaignIds: string[];
}
const CampaignIdSelector: React.FC<CampaignIdSelectorProps> = ({
  onChange,
  onClose,
  value,
  campaignIds,
}) => {
  const { trackReportAdjust } = useEventTracking();

  const { data: campaigns = [] } = useCampaigns();

  const campaignOptions = campaigns.reduce((acc, item) => {
    if (campaignIds.includes(item.campaignSid)) {
      const newObj: Option<string> = {
        itemValue: item.campaignSid,
        primaryItemLabel: item.name,
      };
      return [...acc, newObj];
    }
    return [];
  }, [] as Option<string>[]);

  const handleClose = () => {
    if (value.length === 0) {
      onChange(campaignIds);
    }
    onClose?.();
    trackReportAdjust({ dropdown: 'Campaign' });
  };

  return (
    <SelectAllMultiSelect
      selectLabel="Campaign"
      options={campaignOptions}
      value={value}
      onClose={handleClose}
      onChange={onChange}
    />
  );
};

export default CampaignIdSelector;
