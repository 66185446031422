import { createContext, useContext, useMemo } from 'react';
import chartsTheme from './chartsTheme';

const ChartsThemeProviderContext = createContext(chartsTheme);

export const useChartTheme = () => {
  const theme = useContext(ChartsThemeProviderContext);

  return {
    lineChart: {
      chartArea: theme.lineChart.area,
      grid: theme.lineChart.grid,
      legend: theme.lineChart.legend,
      line: theme.lineChart.line,
      linePallete: theme.lineChart.linePallete,
      xAxis: theme.lineChart.xAxis,
      xLabel: theme.lineChart.xLabel,
      yAxis: theme.lineChart.yAxis,
      yLabel: theme.lineChart.yLabel,
    },
  };
};

const ChartsThemeProvider: React.FunctionComponent = ({ children }) => (
  <ChartsThemeProviderContext.Provider value={useMemo(() => chartsTheme, [])}>
    {children}
  </ChartsThemeProviderContext.Provider>
);

export default ChartsThemeProvider;
