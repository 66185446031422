import { useCallback } from 'react';
import { useModal, Modal } from 'state/modals';

export interface UseModalConfig {}

export default function useModalControls() {
  const { dispatch } = useModal();

  const close = useCallback(() => {
    dispatch({ type: 'hide' });
  }, [dispatch]);

  const open = useCallback(
    (modalArgs: Modal) => {
      dispatch({ type: 'push', payload: modalArgs });
    },

    [dispatch],
  );

  return { open, close };
}
