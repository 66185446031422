import { addEpisodeSegment, AddEpisodeSegmentArgs } from 'api/services';
import { useQueryClient, useMutation, UseMutationOptions } from 'react-query';
import { podcastQueries } from './queryKeys';

export default function useAddEpisodeSegment(
  config: Omit<
    UseMutationOptions<void, unknown, AddEpisodeSegmentArgs, unknown>,
    'mutationFn'
  >,
) {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, AddEpisodeSegmentArgs>(
    (args) => addEpisodeSegment(args),
    {
      ...config,
      onSuccess: (data, args, context) => {
        const id = { internalEpisodeId: args.internalEpisodeId };
        queryClient.invalidateQueries(podcastQueries.segments(id));
        if (config.onSuccess) {
          config.onSuccess(data, args, context);
        }
      },
    },
  );
}
