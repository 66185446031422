import {
  THEME_ID,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { BlockheadProvider } from '@sparemin/blockhead';
import theme from './theme';

export interface ThemeProviderProps {
  children?: React.ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => (
  <BlockheadProvider theme="light">
    <MuiThemeProvider theme={{ [THEME_ID]: theme }}>
      {children}
    </MuiThemeProvider>
  </BlockheadProvider>
);

export default ThemeProvider;
