import React from 'react';
import * as S from './styles';

export interface MobileDetailCardProps {
  children: React.ReactNode;
}

const MobileDetailCard: React.FC<MobileDetailCardProps> = ({ children }) => (
  <S.Content>{children}</S.Content>
);
export default MobileDetailCard;
