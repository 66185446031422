import TableCells from 'components/TableCells';
import { Column, CellProps } from 'react-table';
import { OwnPodcast } from './types';

const columns: ReadonlyArray<Column<OwnPodcast>> = [
  {
    Header: 'Podcast',
    id: 'podcast',
    Cell: ({ row }: CellProps<OwnPodcast>) => (
      <TableCells.PodcastInfoCell
        url={row.original.thumbnailUrl}
        title={row.original.title}
        episodeCount={row.original.totalEpisodes}
      />
    ),
    disableSortBy: true,
  },
];

export default columns;
