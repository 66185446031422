import {
  UpdateDiscoEpisodeArgs,
  updateDiscoEpisode,
  AllEpisodesResponse,
} from 'api/services';
import { useNotification } from 'components/Notification';
import { produce } from 'immer';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { usePodcastFeed } from './PodcastFeedContext';
import { podcastQueries } from './queryKeys';

type DiscoEpisodeUpdateContext = {
  previousEpisodeData: InfiniteData<AllEpisodesResponse> | undefined;
};

interface UpdateDiscoEpisodeEnabledProps
  extends Omit<UpdateDiscoEpisodeArgs, 'urlOverride'> {}

export default function useUpdateDiscoEpisodeEnabled() {
  const queryClient = useQueryClient();
  const { podcastId } = usePodcastFeed();
  const { showError } = useNotification();

  const queryKey = podcastQueries.feed({ podcastFeedId: podcastId });

  return useMutation<
    void,
    unknown,
    UpdateDiscoEpisodeEnabledProps,
    DiscoEpisodeUpdateContext
  >({
    mutationFn: updateDiscoEpisode,

    onMutate: async ({ episodeId, isEnabled }) => {
      await queryClient.cancelQueries(queryKey);

      const previousEpisodeData: InfiniteData<AllEpisodesResponse> | undefined =
        queryClient.getQueryData(queryKey);
      if (!previousEpisodeData) {
        return undefined;
      }
      const pageIndex = previousEpisodeData.pages.findIndex((page) =>
        page.episodes.some((e) => e.internalEpisodeId === episodeId),
      );

      if (pageIndex > -1) {
        queryClient.setQueryData(
          queryKey,

          produce(previousEpisodeData, (draft) => {
            const { episodes } = draft.pages[pageIndex];
            const index = episodes.findIndex(
              (episode) => episode.internalEpisodeId === episodeId,
            );
            if (index !== -1 && isEnabled) {
              // eslint-disable-next-line no-param-reassign
              draft.pages[pageIndex].episodes[index].discoDetail.isEnabled =
                isEnabled;
            }
          }),
        );
      }

      return { previousEpisodeData };
    },

    onError: (err, variables, context) => {
      showError('Error updating episode status. Please try again.');
      queryClient.setQueryData(queryKey, context?.previousEpisodeData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
}
