import { Button as MuiButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import MailTo from 'components/MailTo';

export const Button = styled(MuiButton)`
  border-width: 2px;
  border-radius: 25px;
  height: 50px;
  padding: 0 26px;
`;
export const Container = styled(Stack)`
  width: 100%;
`;

export const MailToButton = styled(MailTo)`
  width: 100%;
  padding: 0 26px;
  border-width: 2px;
`;
