import { PodcastEpisode } from 'api/services';
import { useNotification } from 'components/Notification';
import PodcastContentTable from 'components/PodcastContentTable';
import { PodcastContentTableProps } from 'components/PodcastContentTable/PodcastContentTable';
import { useEpisodeTableData } from 'pages/ContentManagementPage/state';
import { useCallback } from 'react';
import columns, { getRowId } from './utils';

type EpisodeData = Pick<
  PodcastEpisode,
  'internalEpisodeId' | 'publishedAtMillis' | 'title' | 'thumbnailUrl'
>;

type PickedTableProps = Pick<
  PodcastContentTableProps<EpisodeData>,
  'onRowClick'
>;

interface PodcastEpisodesTableProps extends PickedTableProps {
  podcastId?: number;
}

const PodcastEpisodesTable: React.FC<PodcastEpisodesTableProps> = ({
  onRowClick,
  podcastId,
}) => {
  const { showNotification } = useNotification();

  const {
    fetchNextPage,
    hasNextPage = true,
    isLoading,
    data,
  } = useEpisodeTableData(podcastId, {
    onError: useCallback(() => {
      showNotification({
        message: 'Error loading episodes',
        severity: 'error',
      });
    }, [showNotification]),
  });

  return (
    <PodcastContentTable
      columns={columns}
      podcastData={data?.pages || []}
      getRowId={getRowId}
      hasMore={hasNextPage}
      infinite
      isLoading={isLoading}
      onLoadMore={fetchNextPage}
      tableLayout="fixed"
      onRowClick={onRowClick}
    />
  );
};

export default PodcastEpisodesTable;
