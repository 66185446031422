import { Divider, Stack } from '@mui/material';
import { ClickableMobileDetailCard as MobileDetailCard } from 'components/MobileDetailCard';
import TableCells from 'components/TableCells';
import useSocialPodcastsInfo from 'pages/headliner/HeadlinerVideosByRSS/useSocialPodcastsInfo';
import MobileDetailCardContents from 'pages/headliner/MobileDetailCardContents';
import React from 'react';
import { SocialSharePodcasts } from './types';

interface PodcastsMobileListProps {
  onClick?: (val: SocialSharePodcasts) => void;
}

const PodcastsMobileList: React.FC<PodcastsMobileListProps> = ({ onClick }) => {
  const { data } = useSocialPodcastsInfo();
  return (
    <Stack divider={<Divider orientation="horizontal" variant="fullWidth" />}>
      {data?.map((video) => {
        const { title, thumbnailUrl, totalEpisodes, statistics } = video;

        const { likeCount, commentCount, viewCount, isViewCountStale } =
          statistics;

        const contents = (
          <TableCells.PodcastInfoCell
            title={title}
            url={thumbnailUrl}
            episodeCount={totalEpisodes}
          />
        );

        return (
          <MobileDetailCard
            onClick={() => {
              onClick?.(video);
            }}
          >
            <MobileDetailCardContents
              details={contents}
              likeCount={likeCount}
              viewCount={viewCount}
              commentCount={commentCount}
              key={video.podcastId}
              isViewCountStale={isViewCountStale}
            />
          </MobileDetailCard>
        );
      })}
    </Stack>
  );
};

export default PodcastsMobileList;
