import { Tooltip } from '@mui/material';
import { columnInfoLookup } from './columnInfo';

import * as S from './styles';

import { ColumnHeaders } from './types';

interface HeaderCellProps {
  accessor?: ColumnHeaders;
  text?: React.ReactNode;
  description?: NonNullable<React.ReactNode>;
  align?: React.CSSProperties['textAlign'];
}

const HeaderCell: React.FunctionComponent<HeaderCellProps> = ({
  accessor,
  text,
  description,
  align,
}) =>
  accessor ? (
    <S.HeaderCellWrapper textAlign={align}>
      {text || columnInfoLookup[accessor].text}
      <Tooltip title={description || columnInfoLookup[accessor].description}>
        <S.InfoIcon color="info" fontSize="small" />
      </Tooltip>
    </S.HeaderCellWrapper>
  ) : (
    <S.HeaderCellWrapper>{text}</S.HeaderCellWrapper>
  );
export default HeaderCell;
