import { GetEpisodeMetricsResponse } from 'api';
import { getEpisodeMetrics } from 'api/services/dashboard';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryOptions } from 'types';
import { usePodcast } from './PodcastContext';
import { ownedPodcastQueries } from './queryKeys';
import { episodeListenDataSelector, podcastStatsSelector } from './selectors';

type QueryKey = ReturnType<typeof ownedPodcastQueries.episodes>;

type Options<TData = GetEpisodeMetricsResponse> = UseInfiniteQueryOptions<
  GetEpisodeMetricsResponse,
  QueryKey,
  unknown,
  TData
>;

function useEpisodeMetrics<TData = GetEpisodeMetricsResponse>(
  opts?: Options<TData>,
) {
  const { podcastId } = usePodcast();
  return useInfiniteQuery(
    ownedPodcastQueries.episodes(podcastId as string),
    ({ pageParam, queryKey }) =>
      getEpisodeMetrics(
        { podcastId: queryKey[0].podcastId },
        { nextEpisodePubAtMillis: pageParam },
      ),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.nextEpisodePubAtMillis ?? undefined,
      enabled: !!podcastId,
      ...opts,
    },
  );
}

// there is a limitation for using the selectors at the infinite query
// https://github.com/TanStack/query/pull/3625

export function useEpisodeListenData() {
  const { data, ...rest } = useEpisodeMetrics();

  const episodeStats = useMemo(() => episodeListenDataSelector(data), [data]);
  return { data: episodeStats, ...rest };
}

export function usePodcastListenData() {
  const { data, ...rest } = useEpisodeMetrics();

  const podcastStats = useMemo(() => podcastStatsSelector(data), [data]);

  return { data: podcastStats, ...rest };
}
