import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Simplebar from 'simplebar-react';
import DailyStatsChart from './DailyStatsChart';
import DailyStatsChartStatPicker from './DailyStatsChartStatPicker';
import { BaseSeriesData, FilterKey, SeriesFilter, SeriesOption } from './types';
import useDailyStatsChart from './useDailyStatsChart';

interface DailyStatsChartCardProps<
  TSeriesData extends BaseSeriesData,
  TFilterKey extends FilterKey<TSeriesData>,
> {
  cardTitle: string;
  initialSelectedSeries: keyof Omit<TSeriesData, 'date'>;
  seriesData: TSeriesData[];
  seriesFilter?: SeriesFilter<TSeriesData, TFilterKey>;
  showLegend?: boolean;
  showTotal?: boolean;
  statOptions: SeriesOption<TSeriesData>[];
}

// Base card component for plotting a single stat over a line chart. It is intended
// for cronologica / daily time series. Whereas a single stat at a time can be plotted,
// this same series can be grouped for entries that share a common value for a particular
// property.
// * Multi stat is not allowed
// * It allows plotting the same stat splitted in grouped by the value of a particular
// data property.
const DailyStatsChartCard = <
  TData extends BaseSeriesData,
  TFilterKey extends FilterKey<TData>,
>(
  props: DailyStatsChartCardProps<TData, TFilterKey>,
): React.ReactElement => {
  const {
    cardTitle,
    initialSelectedSeries,
    seriesData,
    seriesFilter,
    showLegend,
    showTotal = true,
    statOptions,
  } = props;

  const { data, selectedStat, setSelectedStat } = useDailyStatsChart({
    initialSelectedSeries,
    seriesData,
    seriesFilter,
  });

  return (
    <Card>
      <CardHeader
        action={
          <DailyStatsChartStatPicker
            onChangeSelectedStat={setSelectedStat}
            selectedStat={selectedStat}
            statOptions={statOptions}
          />
        }
        title={cardTitle}
      />
      <CardContent component={Simplebar}>
        <DailyStatsChart
          selectedAxisData={data}
          selectedStat={selectedStat}
          seriesFilter={seriesFilter}
          showLegend={showLegend}
          showTotal={showTotal}
          statOptions={statOptions}
        />
      </CardContent>
    </Card>
  );
};

export default DailyStatsChartCard;
