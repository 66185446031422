import { CardContent } from '@mui/material';
import PodcastContentTable from 'components/PodcastContentTable';
import { useClaimedPodcastData } from 'pages/Play/state';
import React from 'react';
import Simplebar from 'simplebar-react';
import { OwnPodcast } from './types';
import columns from './utils';

type AllPodcastsTableProps = {
  onRowClick?: (val: OwnPodcast) => void;
};

const AllPodcastsTable: React.FC<AllPodcastsTableProps> = ({ onRowClick }) => {
  const { data = [], isLoading } = useClaimedPodcastData();

  return (
    <CardContent component={Simplebar}>
      <PodcastContentTable<OwnPodcast>
        columns={columns}
        podcastData={data}
        isLoading={isLoading}
        tableLayout="fixed"
        onRowClick={onRowClick}
      />
    </CardContent>
  );
};
export default AllPodcastsTable;
