import { Heading, IconButton, Spacer } from '@sparemin/blockhead';
import styled from 'styled-components';
import { ellipsis } from 'styles';
import { EpisodeInputContainer } from '../styles';

export const Container = styled(EpisodeInputContainer)`
  border-style: solid;
  gap: 15px;
`;

export const ThumbnailContainer = styled.div`
  flex-shrink: 0;
  width: 45px;
`;

export const EpisodeMedata = styled(Spacer).attrs({
  orientation: 'vertical',
  space: '9px',
})`
  flex-grow: 1;
  min-width: 0;
`;

export const PodcastName = styled.span`
  ${ellipsis};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
`;

export const EpisodeName = styled(Heading).attrs({
  level: 3,
})`
  ${ellipsis};
  width: 100%;
`;

export const SearchButton = styled(IconButton).attrs({
  variant: 'solid',
})`
  flex: 0 0 30px;
`;
