import { request } from 'api/http';

import {
  PODCAST_V1_ALL_PODCAST_URL,
  PODCAST_V1_DISCO_EPISODE_URL,
  PODCAST_V1_PODCAST_EPISODE_URL,
  PODCAST_V1_PODCAST_FEED_URL,
  PODCAST_V1_PODCAST_FOLLOWS_URL,
  PODCAST_V1_URL,
} from './constants';
import {
  AllPodcastContentResponse,
  AllEpisodesResponse,
  AllEpisodesArgs,
  AllPodcastsArgs,
  EpisodeSegmentsArgs,
  EpisodeSegmentsResponse,
  SegmentUpdateArgs,
  AllEpisodesOptions,
  DiscoImageArgs,
  DiscoImageResponse,
  UpdateDiscoImageArgs,
  AddEpisodeSegmentArgs,
  EpisodeDetailArgs,
  EpisodeDetailResponse,
  UpdateAllEpisodeSegmentsArgs,
  FeedFollowersResponse,
  UpdateDiscoEpisodeArgs,
} from './types';

export async function getAllPodcastContent({
  partnersPublisherUserId,
  ownedPodcastsOnly,
}: AllPodcastsArgs) {
  const { data } = await request.get<AllPodcastContentResponse>(
    PODCAST_V1_ALL_PODCAST_URL,
    {
      params: {
        partnersPublisherUserId,
        ownedPodcastsOnly,
      },
    },
  );
  return data;
}

export async function getPodcastEpisodes(
  { podcastFeedId }: AllEpisodesArgs,
  opts?: AllEpisodesOptions,
): Promise<AllEpisodesResponse> {
  const { data } = await request.get<AllEpisodesResponse>(
    `${PODCAST_V1_PODCAST_FEED_URL}/${podcastFeedId}`,
    {
      params: {
        nextEpisodePubAtMillis: opts?.nextEpisodePubAtMillis,
        includeDiscoEpisodeDetail: true,
      },
    },
  );

  return data;
}

export async function getEpisodeDetails({
  internalEpisodeId,
}: EpisodeDetailArgs) {
  const { data } = await request.get<EpisodeDetailResponse>(
    `${PODCAST_V1_PODCAST_EPISODE_URL}/${internalEpisodeId}`,
    {
      params: {
        includeDiscoDetail: true,
      },
    },
  );
  return data;
}

export async function getEpisodeSegments({
  internalEpisodeId,
}: EpisodeSegmentsArgs) {
  const { data } = await request.get<EpisodeSegmentsResponse>(
    `${PODCAST_V1_PODCAST_EPISODE_URL}/${internalEpisodeId}/segments`,
  );

  return data;
}

export async function addEpisodeSegment({
  internalEpisodeId,
  startMillis,
  endMillis,
}: AddEpisodeSegmentArgs) {
  await request.post<void>(
    `${PODCAST_V1_PODCAST_EPISODE_URL}/${internalEpisodeId}/segments`,
    { startMillis, endMillis },
  );
}

export async function updateEpisodeSegment({
  segmentId,
  isEnabled,
}: SegmentUpdateArgs) {
  await request.put<void>(`${PODCAST_V1_URL}/segment/${segmentId}`, {
    isEnabled,
  });
}

export async function updateAllEpisodeSegments({
  internalEpisodeId,
  isEnabled,
}: UpdateAllEpisodeSegmentsArgs) {
  await request.put<void>(
    `${PODCAST_V1_PODCAST_EPISODE_URL}/${internalEpisodeId}/segments`,
    { isEnabled },
  );
}

export async function getDiscoImage({ internalEpisodeId }: DiscoImageArgs) {
  const { data } = await request.get<DiscoImageResponse>(
    `${PODCAST_V1_PODCAST_EPISODE_URL}/${internalEpisodeId}/disco-image`,
  );

  return data;
}

export async function updateDiscoImage({
  internalEpisodeId,
  croppedImage,
  originalImageFile,
}: UpdateDiscoImageArgs) {
  const formData = new FormData();
  formData.append('originalImageFile', originalImageFile);
  if (croppedImage) formData.append('croppedImage', croppedImage);
  await request.put<void>(
    `${PODCAST_V1_PODCAST_EPISODE_URL}/${internalEpisodeId}/disco-image`,
    formData,
  );
}

export async function getFeedFollowers(feedId: number) {
  const { data } = await request.get<FeedFollowersResponse>(
    `${PODCAST_V1_PODCAST_FOLLOWS_URL}/${feedId}/followers`,
  );

  return data;
}

export async function updateDiscoEpisode({
  episodeId,
  isEnabled,
  urlOverride,
}: UpdateDiscoEpisodeArgs) {
  await request.put<void>(`${PODCAST_V1_DISCO_EPISODE_URL}/${episodeId}`, {
    isEnabled,
    urlOverride,
  });
}
