import React, { useCallback } from 'react';
import { useModalControls } from 'state/modals';
import PodcastEpisodeEmptyInput from './PodcastEpisodeEmptyInput';
import PodcastEpisodeNonEmptyInput from './PodcastEpisodeNonEmptyInput';
import { PodcastEpisodeInputValue } from './types';

export type PodcastEpisodeInputProps = {
  episode?: PodcastEpisodeInputValue;
  onChange?: (episode: PodcastEpisodeInputValue) => void;
};

const PodcastEpisodeInput = React.forwardRef<
  HTMLButtonElement,
  PodcastEpisodeInputProps
>(({ episode, onChange }, ref) => {
  const { open, close } = useModalControls();

  const handleModalOpen = useCallback(() => {
    open({
      name: 'PodcastSearch',
      params: {
        onEpisodeSelected(selectedEpisode) {
          onChange?.(selectedEpisode);
          close();
        },
      },
    });
  }, [close, onChange, open]);

  return !episode ? (
    <PodcastEpisodeEmptyInput onPress={handleModalOpen} ref={ref} />
  ) : (
    <PodcastEpisodeNonEmptyInput
      buttonRef={ref}
      episode={episode}
      onSearchPress={handleModalOpen}
    />
  );
});

export default PodcastEpisodeInput;
