import { SUPPORT_EMAIL } from 'utils/constants';
import { CreateWidgetStep } from './types';

export const CREATE_WIDGET_MODAL_TITLE = "Let's get some listens!";

export const CREATE_WIDGET_MODAL_INSTRUCTIONS =
  'Fill out the fields below to get your widget code:';

export const UNSUPPORTED_LANG_ERROR_MESSAGE = `Widgets currently support rss-feeds with language-tags of 'en' or 'en-us'. For assistance, contact ${SUPPORT_EMAIL}`;

export const DUPLICATE_RSS_ERROR_MESSAGE = `A widget already exists for this rss feed. Contact ${SUPPORT_EMAIL} if you believe this is an error.`;

export const WIDGET_LIMIT_ERROR_MESSAGE = `You have reached your limit of free widgets. Contact ${SUPPORT_EMAIL} to discuss your options.`;

export const WIDGET_CREATION_ERROR_MESSAGE = `There was an error. Email ${SUPPORT_EMAIL} for assistance or try again later.`;

export const WEBSITE_URL_LABEL = "Website URL (where you'll place your widget)";

export const steps: CreateWidgetStep[] = ['style', 'playback', 'feed'];
