import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextStack = styled(Stack)`
  flex-grow: 1;
`;

export const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.label.fontSize}; ;
`;
