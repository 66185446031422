import { Stack, Typography } from '@mui/material';
import * as TableStyles from '../styles';
import * as S from './styles';

export interface PodcastInfoCellProps {
  url: string;
  title: string;
  episodeCount: number;
}

const PodcastInfoCell: React.FC<PodcastInfoCellProps> = ({
  url,
  title,
  episodeCount,
}) => (
  <Stack direction="row" spacing={2}>
    <TableStyles.Img src={url} alt="podcast artwork" />
    <S.TextStack direction="column" justifyContent="center" spacing={0}>
      <S.Title>
        <strong>{title}</strong>
      </S.Title>
      <Typography variant="caption">{episodeCount} episodes</Typography>
    </S.TextStack>
  </Stack>
);

export default PodcastInfoCell;
