import { useNotification } from 'components/Notification';
import { useEffect, useRef } from 'react';
import { useEventTracking } from 'state/eventTracking';
import useCreateAdStatusQueryParams from './useCreateAdStatusQueryParams';

export interface UseCheckCreateAdStatusConfig {
  onSuccess?: () => void;
}

const useCheckCreateAdStatus = ({
  onSuccess,
}: UseCheckCreateAdStatusConfig) => {
  const isCheckoutResponseReceivedRef = useRef(false);

  const { checkoutResponse } = useCreateAdStatusQueryParams();
  const { trackCreatePromoCheckout } = useEventTracking();
  const { showError, showSuccess } = useNotification();

  useEffect(() => {
    if (isCheckoutResponseReceivedRef.current) {
      return;
    }

    if (checkoutResponse === 'success') {
      isCheckoutResponseReceivedRef.current = true;

      onSuccess?.();
      showSuccess(
        'Thanks for setting up your campaign with Headliner! An account rep will reach out soon to review your content with you and answer any questions before making your campaign live.',
      );
      trackCreatePromoCheckout(true);
    } else if (checkoutResponse === 'cancel') {
      isCheckoutResponseReceivedRef.current = true;

      showError('Something went wrong.');
      trackCreatePromoCheckout(false);
    }
  }, [
    showError,
    showSuccess,
    trackCreatePromoCheckout,
    checkoutResponse,
    onSuccess,
  ]);
};

export default useCheckCreateAdStatus;
