import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableInstance } from 'react-table';
import * as S from '../styles';

export interface DataTableFooterProps {
  table: TableInstance<any>;
}

const DataTableFooter: React.FC<DataTableFooterProps> = ({ table }) => {
  const { footerGroups } = table;

  return (
    <S.StickyFooter>
      {footerGroups.map((group) => (
        <TableRow {...group.getFooterGroupProps()}>
          {group.headers.map((column) => (
            <TableCell width={column.width} {...column.getFooterProps()}>
              <strong>{column.render('Footer')}</strong>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </S.StickyFooter>
  );
};

export default DataTableFooter;
