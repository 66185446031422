import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import * as S from './styles';

type HeaderCellWithButtonProps = {
  text: string;
  button: ReactNode;
};

const HeaderCellWithButton: React.FC<HeaderCellWithButtonProps> = ({
  text,
  button,
}) => (
  <S.HeaderCellWrapper>
    <Stack direction="row" spacing={1} alignItems="center">
      {text}
      {button}
    </Stack>
  </S.HeaderCellWrapper>
);
export default HeaderCellWithButton;
