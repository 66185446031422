import DiscoWidgetMarketingCard from 'components/DiscoWidgetMarketingCard';
import { WIDGET_INSTALL_INSTRUCTIONS } from 'utils/constants';
import {
  WIDGET_IN_PROGRESS,
  WIDGET_IN_PROGRESS_DESCRIPTION,
} from './constants';
import * as S from './styles';

type WidgetInProgressProps = {};

const WidgetInProgress: React.FC<WidgetInProgressProps> = () => (
  <DiscoWidgetMarketingCard
    heading={WIDGET_IN_PROGRESS}
    description={WIDGET_IN_PROGRESS_DESCRIPTION}
  >
    <S.Button href={WIDGET_INSTALL_INSTRUCTIONS}>
      Review our best practices
    </S.Button>
  </DiscoWidgetMarketingCard>
);
export default WidgetInProgress;
