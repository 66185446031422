import { Button, Heading } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import PreviousCampaignsList from './PreviousCampaignsList';
import * as S from './styles';

export interface PreviousCampaignsProps {
  onCreateNewCampaign: () => void;
  onRunAgain: () => void;
}

const PreviousCampaigns: React.FC<PreviousCampaignsProps> = ({
  onCreateNewCampaign,
  onRunAgain,
}) => {
  const { trackPodcastPromoPurchaseNext } = useEventTracking();

  const handleCreateNewCampaign = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'Intro',
      view: 'ReturningPurchaser',
      buttonClick: 'CreateNew',
    });

    onCreateNewCampaign();
  }, [onCreateNewCampaign, trackPodcastPromoPurchaseNext]);

  return (
    <S.Root>
      <S.PreviousCampaignsHeading>
        <Heading level={1}>Your previous campaigns</Heading>

        <Button size="small" onPress={handleCreateNewCampaign}>
          create new campaign
        </Button>
      </S.PreviousCampaignsHeading>

      <PreviousCampaignsList onRunAgain={onRunAgain} />
    </S.Root>
  );
};

export default PreviousCampaigns;
