import { Stack, Typography } from '@mui/material';
import { AudioPlaybackMethod } from 'api/services';
import React from 'react';
import { PLAYBACK_MODAL_TITLE, PLAYBACK_MODAL_SUBTITLE } from './constants';
import * as S from './styles';
import WidgetPlaybackCards from './WidgetPlaybackCards';

interface PlaybackSelectorProps {
  onClick: (val: AudioPlaybackMethod) => void;
}

const PlaybackSelector: React.FC<PlaybackSelectorProps> = ({ onClick }) => (
  <Stack spacing={4} alignItems="center">
    <Typography variant="h1" textAlign="center">
      {PLAYBACK_MODAL_TITLE}
    </Typography>
    <Typography variant="h2" textAlign="center">
      {PLAYBACK_MODAL_SUBTITLE} (
      <S.ExternalLink href="https://learn.headliner.app/hc/en-us/articles/16194728975255">
        learn more
      </S.ExternalLink>
      ).
    </Typography>
    <WidgetPlaybackCards onClick={onClick} />
  </Stack>
);
export default PlaybackSelector;
