import { PeriodArgs } from 'components/StatsPeriodSelector';
import React, { useContext, useMemo } from 'react';
import { usePromoByDayMetrics } from './state';
import { AdvertisersDashboardContextType, SelectedCampaignSids } from './types';

const AdvertisersDashboardContext = React.createContext<
  AdvertisersDashboardContextType | undefined
>(undefined);

export const useAdvertisersDashboard = () => {
  const context = useContext(AdvertisersDashboardContext);

  if (context === undefined) {
    throw new Error(
      'useAdvertisersDashboard must be used within AdvertisersDashboardProvider',
    );
  }

  return context;
};

interface AdvertisersDashboardProviderProps {
  periodArgs: PeriodArgs;
  campaignIds: SelectedCampaignSids;
}

const AdvertisersDashboardProvider: React.FunctionComponent<
  AdvertisersDashboardProviderProps
> = (props) => {
  const { children, periodArgs, campaignIds } = props;

  const {
    data: dailyStatsData = [],
    isError,
    isLoading,
  } = usePromoByDayMetrics(
    {
      endMillis: periodArgs.endMillis,
      startMillis: periodArgs.startMillis,
      campaignSids: campaignIds.includes('All') ? undefined : campaignIds,
    },
    {
      keepPreviousData: true,
    },
  );

  return (
    <AdvertisersDashboardContext.Provider
      value={useMemo(
        () => ({
          campaignIds,
          dailyStats: {
            data: dailyStatsData,
            error: isError,
            loading: isLoading,
          },
          periodArgs,
        }),
        [campaignIds, dailyStatsData, isError, isLoading, periodArgs],
      )}
    >
      {children}
    </AdvertisersDashboardContext.Provider>
  );
};

export default AdvertisersDashboardProvider;
