import React, { useContext } from 'react';
import { PlaybackContextType, PlaybackProviderProps } from './types';
import useSegmentPlayback from './useSegmentPlayback';

const PlaybackContext = React.createContext<PlaybackContextType | undefined>(
  undefined,
);

export const PlaybackProvider: React.FC<PlaybackProviderProps> = ({
  children,
  element,
  internalEpisodeId,
}) => (
  <PlaybackContext.Provider
    value={useSegmentPlayback({
      element,
      internalEpisodeId,
    })}
  >
    {children}
  </PlaybackContext.Provider>
);

export function usePlayback() {
  const context = useContext(PlaybackContext);

  if (context === undefined) {
    throw new Error('usePlayback must be used within PlaybackProvider');
  }

  return context;
}
