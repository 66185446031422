import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ResetPasswordCard } from '@sparemin/auth';
import { RoleName } from 'api/services';
import LoginCard from 'components/LoginCard';
import RegisterCard from 'components/RegisterCard';
import RequireAuth from 'components/RequireAuth/RequireAuth';
import ScopeCheckContainer from 'components/ScopeCheckContainer';
import Account from 'pages/Account';
import AdCampaignPage from 'pages/AdCampaignPage';
import ADCampaignPage from 'pages/AdCampaignPage/AdCampaignPage';
import AdvertisersDashboard from 'pages/AdvertisersDashboard';
import ContentManagementPage from 'pages/ContentManagementPage';
import Dashboard from 'pages/Dashboard';
import {
  PlatformBreakoutDashboard,
  PublisherDashboard,
  UrlBreakoutDashboard,
} from 'pages/discoStats';
import { HeadlinerVideos, HeadlinerVideosByRSS } from 'pages/headliner';
import Home from 'pages/Home';
import Play from 'pages/Play';
import Widget, { WidgetPage } from 'pages/Widget';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  BrowserRouter as AppRouterProvider,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import routes from 'utils/routes';
import useOnAppLoad from './useOnAppLoad';
import UserAuthenticationRoute from './UserAuthenticationRoute';
import WidgetMobileFallback from './WidgetMobileFallback';

export interface AppRouterProps {}

export { AppRouterProvider };

const AppRouter: React.FC<AppRouterProps> = () => {
  useOnAppLoad();
  const location = useLocation();

  useEffect(() => {
    const page = `${location.pathname}${location.search}`;
    ReactGA.send({ hitType: 'pageview', page });
  }, [location]);
  const theme = useTheme();
  const showMobileFallback = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Routes>
      <Route index element={<Navigate replace to={routes.home.path} />} />
      <Route element={<UserAuthenticationRoute />}>
        <Route path={routes.login.path} element={<LoginCard />} />
        <Route path={routes.register.path} element={<RegisterCard />} />
        <Route
          path={routes.resetPassword.path}
          element={<ResetPasswordCard />}
        />
      </Route>

      <Route
        path={routes.dashboard.path}
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate replace to={routes.home.path} />} />
        <Route path={routes.home.path} element={<Home />} />
        <Route path={routes.discoWidgetDashboard.path}>
          <Route
            index
            element={
              showMobileFallback ? (
                <WidgetMobileFallback />
              ) : (
                <ScopeCheckContainer
                  requiredScopes={[RoleName.publisher]}
                  fallback={<Widget />}
                >
                  <Navigate replace to={routes.discoWidgetDailyStats.path} />
                </ScopeCheckContainer>
              )
            }
          />
          <Route
            path={routes.discoWidgetDailyStats.path}
            element={<PublisherDashboard />}
          />
          <Route
            path={routes.platformBreakoutDashboard.path}
            element={<PlatformBreakoutDashboard />}
          />
          <Route
            path={routes.urlBreakoutDashboard.path}
            element={<UrlBreakoutDashboard />}
          />
          <Route
            path={routes.contentManagement.path}
            element={<ContentManagementPage />}
          />
          <Route path={routes.discoWidget.path} element={<WidgetPage />} />
        </Route>

        <Route path={routes.discoAdsDashboard.path}>
          <Route
            index
            element={
              showMobileFallback ? (
                <ADCampaignPage />
              ) : (
                <ScopeCheckContainer
                  requiredScopes={[RoleName.advertiser]}
                  fallback={<ADCampaignPage />}
                >
                  <Navigate replace to={routes.discoAdsDailyStats.path} />
                </ScopeCheckContainer>
              )
            }
          />
          <Route
            path={routes.discoAdsDailyStats.path}
            element={<AdvertisersDashboard />}
          />
          <Route path={routes.adCampaign.path} element={<AdCampaignPage />} />
        </Route>
        <Route path={routes.studioDashboard.path}>
          <Route
            index
            element={<Navigate replace to={routes.allVideos.path} />}
          />
          <Route path={routes.allVideos.path} element={<HeadlinerVideos />} />
          <Route
            path={routes.podcasts.path}
            element={<HeadlinerVideosByRSS />}
          />
        </Route>
        <Route path={routes.playAnalytics.path} element={<Play />} />

        <Route path={routes.account.path} element={<Account />} />
      </Route>
      <Route path="*" element={<Navigate to={routes.home.path} />} />
    </Routes>
  );
};

export default AppRouter;
