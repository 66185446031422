import TablePageContainer from 'components/TablePageContainer';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import StatsSummary from 'pages/Play/StatsSummary';
import { useState } from 'react';
import PodcastProvider from '../state/PodcastContext';
import TableCard from './TableCard';
import TableTitle from './TableTitle';

type TableContainerProps = {};

const TableContainer: React.FC<TableContainerProps> = () => {
  useTrackPageLoad({
    section: 'Play',
    page: 'DefaultState',
    state: 'Populated',
  });

  const [podcastInfo, setPodcastInfo] = useState<string>();

  const handleBackClick = () => setPodcastInfo(undefined);

  return (
    <PodcastProvider podcastId={podcastInfo}>
      <TablePageContainer>
        <TableTitle onBackClick={handleBackClick} />
        {podcastInfo && <StatsSummary />}
        <TableCard onSelectPodcast={setPodcastInfo} />
      </TablePageContainer>
    </PodcastProvider>
  );
};
export default TableContainer;
