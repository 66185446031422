export const columnInfoLookup = {
  load: {
    text: 'Widget Load',
    description: 'Total requests',
  },
  view: {
    text: 'Widget View',
    description: "Total times widget was in users' viewport",
  },
  play: {
    text: 'Preview Play',
    description: 'Total clips played',
  },
  clickthrough: {
    text: 'Link Click',
    description: 'Total clickthroughs',
  },
  engage: {
    text: 'Engagement',
    description: 'Sum of Preview Play and Link Click',
  },
  ctr: {
    text: 'CTR',
    description: 'Engagement / Widget View',
  },
  adPlayed: {
    text: 'Promo Play',
    description: 'Total promo clips played',
  },
  adClickThrough: {
    text: 'Promo Click',
    description: 'Total clickthroughs',
  },
  spEngaged: {
    text: 'Unique Engagement',
    description:
      'Total of unique user sessions with at least one click or play.',
  },
  avgCpc: {
    text: 'Avg CPE',
    description: 'Total $ / Unique Engagement',
  },
  total: {
    text: 'Total $',
    description: 'Total in USD',
  },
  unitLoad: {
    text: 'Impressions',
    description: 'Total requests',
  },
  unitInViewport: {
    text: 'Viewable Impressions',
    description: "Total times unit was in users' viewport",
  },
} as const;
