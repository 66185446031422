import { AxiosClient, configure as configureApi } from '@sparemin/api-sdk';
import { configure as configureAuth, sso } from '@sparemin/auth';
import { configure as configureMediaImport } from '@sparemin/media-import';
import { request } from 'api/http';
import { APPLE_AUTH_REDIRECT_URI, RUNTIME_ENV } from 'config';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

const environment = (() => {
  if (process.env.NODE_ENV !== 'production') {
    return 'local';
  }

  if (RUNTIME_ENV === 'production') {
    return 'prod';
  }

  return 'dev';
})();

// NB: the api sdk will add an axios interceptor that adds the auth token
// to every request.  see types in typings/axios.d.ts for information on how to
// configure this for individual requests
configureApi({
  getSpareminToken: sso.getToken,
  client: new AxiosClient(request),
  environment,
});

configureAuth({
  environment,
  appleRedirectURI: APPLE_AUTH_REDIRECT_URI,
  application: 'discoDashboard',
});

configureMediaImport({ environment });
