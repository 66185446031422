import Box from '@mui/material/Box';
import PasswordField from 'components/PasswordField/PasswordField';
import * as S from './styles';
import useChangePasswordForm from './useChangePasswordForm';

export interface ChangePasswordFormProps {}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = () => {
  const {
    formState: { errors },
    isLoading,
    onSubmit,
    register,
  } = useChangePasswordForm();

  return (
    <Box component="form" onSubmit={onSubmit}>
      <PasswordField
        disabled={isLoading}
        error={!!errors.currentPassword?.message}
        helperText={errors.currentPassword?.message}
        id="current-password"
        label="Current Password"
        size="small"
        {...register('currentPassword')}
      />
      <PasswordField
        disabled={isLoading}
        error={!!errors.newPassword?.message}
        helperText={errors.newPassword?.message}
        id="new-password"
        label="New Password"
        size="small"
        {...register('newPassword')}
      />
      <PasswordField
        disabled={isLoading}
        error={!!errors.confirmPassword?.message}
        helperText={errors.confirmPassword?.message}
        id="confirm-password"
        label="Confirm Password"
        size="small"
        {...register('confirmPassword')}
      />
      <S.ChangePasswordButton
        disabled={isLoading}
        fullWidth
        type="submit"
        variant="contained"
      >
        Update Password
      </S.ChangePasswordButton>
    </Box>
  );
};

export default ChangePasswordForm;
