import SocialStatsRow, {
  SocialStatsRowProps,
} from 'pages/headliner/components/SocialStatsRow';
import React from 'react';

export type MobileDetailCardContentsProps = SocialStatsRowProps & {
  details: React.ReactNode;
};

const MobileDetailCardContents: React.FC<MobileDetailCardContentsProps> = ({
  likeCount,
  viewCount,
  commentCount,
  platform,
  details,
  isViewCountStale,
}) => (
  <>
    {details}
    <SocialStatsRow
      {...{ likeCount, commentCount, viewCount, platform, isViewCountStale }}
    />
  </>
);
export default MobileDetailCardContents;
