import Container, { ContainerProps } from '@mui/material/Container';
import * as S from './styles';

export interface DashboardContentProps extends Omit<ContainerProps, 'title'> {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const DashboardContent: React.FC<DashboardContentProps> = ({
  children,
  title,
  subtitle,
  ...props
}) => (
  <Container {...props}>
    {title && (
      <S.DashboardContentTitleContainer>
        {title}
      </S.DashboardContentTitleContainer>
    )}
    {subtitle && (
      <S.DashboardContentInstructionsContainer>
        {subtitle}
      </S.DashboardContentInstructionsContainer>
    )}
    {children}
  </Container>
);

export default DashboardContent;
