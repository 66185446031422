import { Grid } from '@mui/material';

interface DashboardPageFiltersProps {
  filters: React.ReactElement[];
}

const DashboardPageFilters: React.FunctionComponent<
  DashboardPageFiltersProps
> = (props) => {
  const { filters } = props;

  return (
    <Grid item xs={12}>
      <Grid container gap={2} justifyContent="flex-end">
        {filters.map((filterComponent, index) => {
          const key = `filter_${index}`;
          return <Grid key={key}>{filterComponent}</Grid>;
        })}
      </Grid>
    </Grid>
  );
};

export default DashboardPageFilters;
