import { Grid } from '@mui/material';
import { Platform, RoleName } from 'api/services';
import PlatformBreakoutFilter from 'components/PlatformBreakoutFilter';
import ScopeCheckContainer from 'components/ScopeCheckContainer';
import StatsPeriodSelector, {
  getDefaultPeriodArgs,
} from 'components/StatsPeriodSelector';
import WidgetIdSelector, {
  SelectedWidgetIds,
} from 'components/WidgetIdSelector';
import { DashboardContent, DashboardContentTitle } from 'pages/Dashboard';
import DashboardPageFilters from 'pages/Dashboard/DashboardPageFilters';
import NoDataPage from 'pages/NoDataPage';
import { useCallback, useState } from 'react';
import { useWidgetIds } from 'state/user';
import PlatformBreakoutChartCard from './PlatformBreakoutChartCard';
import PlatformBreakoutDashboardProvider from './PlatformBreakoutDashboardContext';
import PlatformBreakoutTableCard from './PlatformBreakoutTableCard';
import { INITIAL_PLATFORM_FILTER } from './utils';

const PlatformBreakoutDashboard: React.FunctionComponent = () => {
  const [selectedWids, setSelectedWids] = useState<
    SelectedWidgetIds | undefined
  >(undefined);
  const [draftWids, setDraftWids] = useState<SelectedWidgetIds | undefined>(
    undefined,
  );

  const [platforms, setPlatforms] = useState<Platform[]>(
    INITIAL_PLATFORM_FILTER,
  );

  const [periodArgs, setPeriodArgs] = useState(getDefaultPeriodArgs());

  const data = useWidgetIds(setDraftWids);

  const widgetIds = data.isSuccess ? data.data : [];

  const handleConfirmWidgetSelection = useCallback(() => {
    setSelectedWids(draftWids);
  }, [draftWids]);

  return (
    <ScopeCheckContainer
      requiredScopes={[RoleName.publisher]}
      fallback={
        <NoDataPage
          trackingProps={{
            section: 'DiscoWidget',
            page: 'PlatformBreakout',
            state: 'Empty',
          }}
        />
      }
    >
      <PlatformBreakoutDashboardProvider
        periodArgs={periodArgs}
        platforms={platforms}
        widgetIds={selectedWids || []}
      >
        <DashboardContent
          maxWidth="xl"
          title={
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12}>
                <DashboardContentTitle>Platform Breakout</DashboardContentTitle>
              </Grid>
              <DashboardPageFilters
                filters={[
                  <WidgetIdSelector
                    onChange={setDraftWids}
                    onClose={handleConfirmWidgetSelection}
                    value={draftWids || []}
                    widgetIds={widgetIds}
                  />,
                  <PlatformBreakoutFilter
                    platformOptions={INITIAL_PLATFORM_FILTER}
                    onChange={setPlatforms}
                    value={platforms}
                  />,
                  <StatsPeriodSelector onSelectPeriod={setPeriodArgs} />,
                ]}
              />
            </Grid>
          }
        >
          <Grid container spacing={2} marginBottom={5}>
            <Grid item xs={12} md={12}>
              <PlatformBreakoutChartCard />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <PlatformBreakoutTableCard />
            </Grid>
          </Grid>
        </DashboardContent>
      </PlatformBreakoutDashboardProvider>
    </ScopeCheckContainer>
  );
};

export default PlatformBreakoutDashboard;
