import DailyStatsChartCardComponent from 'components/DailyStatsChartCard';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { useAdvertisersDashboard } from '../AdvertisersDashboardContext';
import { CHART_STATS_SELECTOR_OPTIONS } from './utils';

const DailyStatsChartCard: React.FunctionComponent = () => {
  const {
    dailyStats: { data },
  } = useAdvertisersDashboard();

  useTrackPageLoad({
    section: 'DiscoPromo',
    page: 'DailyStats',
    state: 'Populated',
  });

  return (
    <DailyStatsChartCardComponent
      cardTitle="Daily Stats Chart"
      initialSelectedSeries="unitLoad"
      seriesData={data}
      statOptions={CHART_STATS_SELECTOR_OPTIONS}
    />
  );
};

export default DailyStatsChartCard;
