import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface TrackingQueryParams {
  source: string | null;
  isRead: boolean;
}

export default function useTrackingQueryParams() {
  const [searchParams] = useSearchParams();

  const [params, setParams] = useState<TrackingQueryParams>({
    source: null,
    isRead: false,
  });

  const source = searchParams.get('dtm_source');
  if (params.source !== source) {
    setParams({ source, isRead: true });
  }

  // searchParams.get returns null if the search key is not present
  return { ...params, source: params.source ?? undefined };
}
