import PodcastContentTable from 'components/PodcastContentTable';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import EpisodesMobileList from './EpisodesMobileList';
import { SocialSharePodcastEpisode } from './types';
import useSocialEpisodesInfo from './useSocialEpisodesInfo';
import columns from './utils';

type VideosByEpisodeTableProps = {
  podcastId: string;
  onRowClick?: (val: SocialSharePodcastEpisode) => void;
};

const VideosByEpisodeTable: React.FC<VideosByEpisodeTableProps> = ({
  podcastId,
  onRowClick,
}) => {
  const query = useSocialEpisodesInfo(podcastId);
  const data = query.data ?? [];
  const isMobile = useMobileBreakpoint('md');

  return isMobile ? (
    <EpisodesMobileList podcastId={podcastId} onClick={onRowClick} />
  ) : (
    <PodcastContentTable<SocialSharePodcastEpisode>
      columns={columns}
      podcastData={data}
      isLoading={query.isLoading}
      tableLayout="fixed"
      onRowClick={onRowClick}
    />
  );
};
export default VideosByEpisodeTable;
