import { CardActionArea } from '@mui/material';
import React from 'react';
import * as S from './styles';

interface ClickableMobileDetailCardProps {
  onClick?: () => void;
  children: React.ReactNode;
}

const ClickableMobileDetailCard: React.FC<ClickableMobileDetailCardProps> = ({
  onClick,
  children,
}) => (
  <S.Content>
    <CardActionArea onClick={onClick}>{children}</CardActionArea>
  </S.Content>
);
export default ClickableMobileDetailCard;
