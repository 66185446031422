import {
  MediaImporter,
  OnMediaImported,
  useEpisodeSearchPlugin,
  usePodcastSearchPlugin,
} from '@sparemin/media-import';
import React, { useCallback } from 'react';
import { useConnectedModal } from 'state/modals';
import { MODAL_NAME } from './constants';

export interface PodcastSearchModalContentsProps {
  isMobile?: boolean;
}

const PodcastSearchModalContents: React.FC<PodcastSearchModalContentsProps> = ({
  isMobile,
}) => {
  const {
    params: { onEpisodeSelected },
  } = useConnectedModal({ name: MODAL_NAME });

  const handleMediaImported: OnMediaImported = useCallback(
    (media) => {
      if (media.pluginId === 'episodeSearch') {
        onEpisodeSelected?.(media.payload);
      }
    },
    [onEpisodeSelected],
  );

  return (
    <MediaImporter
      layout={isMobile ? 'mobile' : 'desktop'}
      onMediaImported={handleMediaImported}
      plugins={[usePodcastSearchPlugin(), useEpisodeSearchPlugin()]}
    />
  );
};

export default PodcastSearchModalContents;
