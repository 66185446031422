import { useTheme } from '@mui/material';
import { Followers } from '@sparemin/blockhead';
import * as S from './styles';

type FollowersGradientIconProps = {};

const FollowersGradientIcon: React.FC<FollowersGradientIconProps> = () => {
  const theme = useTheme();

  return (
    <S.IconContainer>
      <Followers
        width="40px"
        height="32px"
        color={theme.palette.common.black}
      />
    </S.IconContainer>
  );
};
export default FollowersGradientIcon;
