import {
  GetByDayWidgetMetricsArgs,
  GetPlatformByDayWidgetMetricsArgs,
  GetUrlWidgetMetricsArgs,
} from 'api/services';

export const discoMetricsQueries = {
  all: ['metrics'] as const,

  daily: (args: GetByDayWidgetMetricsArgs) => [discoMetricsQueries.all, args],
  platformDaily: (args: GetPlatformByDayWidgetMetricsArgs) => [
    discoMetricsQueries.all,
    args,
  ],
  url: (args: GetUrlWidgetMetricsArgs) => [...discoMetricsQueries.all, args],
};
