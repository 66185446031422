import * as S from './styles';

export type ActionItemRowProps = {
  isDisabled?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export interface ActionItemRowComponent extends React.FC<ActionItemRowProps> {
  HeadingContainer: typeof S.HeadingContainer;
  Title: typeof S.Title;
  Description: typeof S.Description;
}

const ActionItemRow: ActionItemRowComponent = ({
  isDisabled,
  className,
  children,
}) => (
  <S.ActionItemRowContainer className={className} $isDisabled={isDisabled}>
    {children}
  </S.ActionItemRowContainer>
);

ActionItemRow.HeadingContainer = S.HeadingContainer;
ActionItemRow.Title = S.Title;
ActionItemRow.Description = S.Description;

export default ActionItemRow;
