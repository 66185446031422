import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogProps,
} from '@mui/material';
import React from 'react';
import { ModalName } from 'state/modals';
import useConnectedModal from 'state/modals/useConnectedModal';
import * as S from './styles';

export interface DialogBoxProps
  extends Omit<DialogProps, 'title' | 'open' | 'onClose'> {
  title?: string;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  /**
   * Render a close button in the upper right corner of the dialog
   */
  closeButton?: boolean;
  /**
   * Expand the dialog to its full height, leaving only space for the top and
   * bottom gutters
   */
  fullHeight?: boolean;
  actions?: React.ReactNode;
  name: ModalName;
  // allow modals to pass in additional actions onClose
  onClose?: () => void;
}

const DialogBox: React.FC<DialogBoxProps> = ({
  closeButton,
  title,
  subtitle,
  actions,
  children,
  fullHeight = false,
  name,
  onClose,
  ...props
}) => {
  const { show, handleRequestClose, handleAfterClose } = useConnectedModal({
    name,
    onRequestClose: onClose,
  });

  return (
    <S.Dialog
      {...props}
      $fullHeight={fullHeight}
      open={show}
      onClose={handleRequestClose}
      TransitionProps={{ onExited: handleAfterClose }}
    >
      {closeButton && <S.CloseButton onPress={handleRequestClose} />}
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>
        <S.ContentWrapper $fullHeight={fullHeight} spacing={2}>
          {subtitle ? <DialogContentText>{subtitle}</DialogContentText> : null}
          {children}
        </S.ContentWrapper>
      </DialogContent>
      {actions && <S.DialogActions>{actions}</S.DialogActions>}
    </S.Dialog>
  );
};
export default DialogBox;
