import {
  getSocialShareStats,
  SocialShareStatsResponse,
  SocialShareStatsArgs,
} from 'api/services';
import { UseQueryOptions, useQuery } from 'react-query';
import { SocialShareVideos } from '../types';
import { embedVideoQueries } from './queryKeys';
import {
  socialStatsSummarySelector,
  socialVideoInfoSelector,
} from './selectors';

type QueryKey = ReturnType<typeof embedVideoQueries.videos>;

function useGetSocialShareStatistics<TData = SocialShareStatsResponse>(
  args?: SocialShareStatsArgs,
  opts?: Omit<
    UseQueryOptions<SocialShareStatsResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<SocialShareStatsResponse, unknown, TData, QueryKey>(
    embedVideoQueries.videos(args),
    () => getSocialShareStats(args),
    opts,
  );
}

export function useSocialVideoInfo(args: SocialShareStatsArgs) {
  return useGetSocialShareStatistics<SocialShareVideos[]>(args, {
    select: socialVideoInfoSelector,
  });
}

export function useCheckSocialShareStatistics(requestStatus?: boolean) {
  return useGetSocialShareStatistics(
    {},
    { select: socialVideoInfoSelector, enabled: requestStatus },
  );
}

export function useGetSocialStatsSummary() {
  return useGetSocialShareStatistics(
    { episodeId: undefined },
    {
      select: socialStatsSummarySelector,
    },
  );
}

export function useGetSocialShareStatsByEpisode(episodeId: string) {
  return useGetSocialShareStatistics(
    { episodeId },
    { select: socialStatsSummarySelector, enabled: !!episodeId },
  );
}
