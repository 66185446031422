import { Typography } from '@mui/material';

export interface DashboardContentTitleProps {
  children?: React.ReactNode;
}

const DashboardContentTitle: React.FC<DashboardContentTitleProps> = ({
  children,
}) => <Typography variant="h1">{children}</Typography>;

export default DashboardContentTitle;
