import { WidgetVariantId } from 'api/services';
import Tabs from 'components/Tabs';
import useToggle from 'hooks/useToggle';
import { useState } from 'react';
import CustomizeTab from './CustomizeTab/CustomizeTab';
import InstructionsTab from './InstructionsTab';
import WidgetIframe from './WidgetIframe';

type SetupWidgetProps = {
  widgetId: string;
  widgetVariantId?: WidgetVariantId;
};

const SetupWidget: React.FC<SetupWidgetProps> = ({
  widgetId,
  widgetVariantId,
}) => {
  const {
    value: loading,
    toggleOn: onLoading,
    toggleOff: onLoadingComplete,
  } = useToggle(true);
  const [iframeKey, setIframeKey] = useState<WidgetVariantId | undefined>(
    widgetVariantId,
  );

  const handleChange = (val: WidgetVariantId) => {
    if (val !== iframeKey) {
      setIframeKey(val);
      onLoading();
    }
  };
  return (
    <>
      <WidgetIframe
        loading={loading}
        onLoadingComplete={onLoadingComplete}
        widgetVariantId={iframeKey}
        widgetId={widgetId}
      />
      <Tabs
        content={[
          { label: 'code', content: <InstructionsTab widgetId={widgetId} /> },
          {
            label: 'customize',
            content: (
              <CustomizeTab
                onVariantUpdate={handleChange}
                widgetId={widgetId}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default SetupWidget;
