import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  Stack,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { DialogCloseButton } from '@sparemin/blockhead';
import styled from 'styled-components';
import { DialogStyleProps } from './types';

export const Dialog = muiStyled(MuiDialog)<DialogStyleProps>`
  & .cropper-modal {
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  & .cropper-body {
    height: 100%;
    width: 100%;
  }

  ${({ $fullHeight }) =>
    $fullHeight &&
    `
    .MuiDialog-paper {
      height: 100%;
    }
  `}
`;

export const DialogActions = muiStyled(MuiDialogActions)`
  & .MuiDialogActions-root {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const ContentWrapper = muiStyled(Stack)<DialogStyleProps>`
  ${({ $fullHeight }) => $fullHeight && 'height: 100%;'}
`;

export const CloseButton = styled(DialogCloseButton)`
  position: absolute;
  top: 15px;
  right: 15px;
`;
