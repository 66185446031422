import { SocialShareStatsByPodcastResponse } from 'api/services';
import { useGetSocialShareStatsByPodcast } from '../state';
import { SocialSharePodcasts } from './TableContainer/TableCard/VideosByPodcast/types';

export default function useSocialPodcastsInfo() {
  return useGetSocialShareStatsByPodcast<SocialSharePodcasts[]>({
    select: (data: SocialShareStatsByPodcastResponse) =>
      data.podcasts.map((podcast) => ({
        podcastId: podcast.podcastId,
        title: podcast.title ?? 'unknown',
        thumbnailUrl: podcast.thumbnailUrl ?? '',
        totalEpisodes: podcast.totalEpisodes,
        statistics: { ...podcast.statistics },
      })),
  });
}
