import { Stack } from '@mui/material';
import PlayBackButton, { PlayBackButtonProps } from 'components/PlayBackButton';

interface AudioCellProps extends PlayBackButtonProps {
  transcriptText: string;
}

const AudioCell: React.FC<AudioCellProps> = ({
  loading,
  onTogglePlayback,
  playing,
  transcriptText,
}) => (
  <Stack direction="row" spacing={1.5}>
    <PlayBackButton
      loading={loading}
      onTogglePlayback={onTogglePlayback}
      playing={playing}
    />
    <div>{transcriptText}</div>
  </Stack>
);

export default AudioCell;
