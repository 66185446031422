import {
  getSocialShareStatsByEpisode,
  SocialShareStatsByEpisodeResponse,
  GetSocialStatsByEpisodeArgs,
} from 'api/services';
import { UseQueryOptions, useQuery } from 'react-query';
import { embedVideoQueries } from './queryKeys';

type QueryKey = ReturnType<typeof embedVideoQueries.podcast>;

export default function useGetSocialShareStatsByEpisode<
  TData = SocialShareStatsByEpisodeResponse,
>(
  args: Partial<GetSocialStatsByEpisodeArgs>,
  opts?: Omit<
    UseQueryOptions<
      SocialShareStatsByEpisodeResponse,
      unknown,
      TData,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<SocialShareStatsByEpisodeResponse, unknown, TData, QueryKey>(
    embedVideoQueries.podcast({ podcastId: args.podcastId as string }),
    () => getSocialShareStatsByEpisode({ podcastId: args.podcastId as string }),
    {
      enabled: !!args.podcastId,
      ...opts,
    },
  );
}
