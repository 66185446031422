import { Stack, Divider } from '@mui/material';
import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import React from 'react';
import * as S from './styles';

interface DetailCardProps {
  children: React.ReactNode;
}

const DetailCard: React.FC<DetailCardProps> = ({ children }) => {
  const isMobile = useMobileBreakpoint('md');

  return (
    <S.Card $isMobile={isMobile}>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        divider={<Divider flexItem orientation="vertical" variant="middle" />}
      >
        {children}
      </Stack>
    </S.Card>
  );
};

export default DetailCard;
