import DialogBox from 'components/DialogBox';
import React from 'react';
import { useConnectedModal, useModalControls } from 'state/modals';
import {
  TOGGLE_CLIPS_DIALOG_SUBTITLE,
  TOGGLE_CLIPS_DIALOG_TITLE,
} from '../constants';
import ToggleAllClipsForm from './ToggleAllClipsForm';

export interface ToggleAllClipsProps {}

const ToggleAllClips: React.FC<ToggleAllClipsProps> = () => {
  const name = 'ToggleAllClips';
  const { close } = useModalControls();
  const { params } = useConnectedModal({ name });

  return (
    <DialogBox
      title={TOGGLE_CLIPS_DIALOG_TITLE}
      subtitle={TOGGLE_CLIPS_DIALOG_SUBTITLE}
      name={name}
    >
      <ToggleAllClipsForm {...params} onClose={close} />
    </DialogBox>
  );
};
export default ToggleAllClips;
