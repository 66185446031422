import Spinner from 'components/Spinner';
import { useFetchNewSocialStats } from 'pages/headliner/useFetchNewSocialStats';
import React from 'react';
import MakeEmptyState from '../MakeEmptyState';
import { useGetSocialShareStatsByPodcast } from '../state';
import {
  PODCASTS_EMPTY_STATE_DESCRIPTION,
  PODCASTS_EMPTY_STATE_TITLE,
} from './constants';
import TableContainer from './TableContainer';

type HeadlinerVideosByRSSProps = {};

const HeadlinerVideosByRSS: React.FC<HeadlinerVideosByRSSProps> = () => {
  const { requestStatus } = useFetchNewSocialStats();

  const query = useGetSocialShareStatsByPodcast({ enabled: requestStatus });

  const data = query.isSuccess ? query.data.podcasts : undefined;
  if (data === undefined) return <Spinner />;
  if (data.length > 0) return <TableContainer />;
  return (
    <MakeEmptyState
      page="Podcasts"
      title={PODCASTS_EMPTY_STATE_TITLE}
      description={PODCASTS_EMPTY_STATE_DESCRIPTION}
      cta="return to your audiograms"
    />
  );
};
export default HeadlinerVideosByRSS;
