import { Stack } from '@mui/material';
import Spinner from 'components/Spinner';
import { useState } from 'react';
import { useModalControls } from 'state/modals';
import { RequestStatus } from 'types';
import {
  CREATE_WIDGET_MODAL_TITLE,
  CREATE_WIDGET_MODAL_INSTRUCTIONS,
} from './constants';
import CreateWidgetForm from './CreateWidgetForm';
import * as S from './styles';
import { UseCreateWidgetFormConfig } from './useCreateWidgetForm';

interface CreateWidgetModalFeedProps
  extends Pick<
    UseCreateWidgetFormConfig,
    'widgetStyleVariant' | 'audioPlaybackMethod'
  > {}

const CreateWidgetModalFeed: React.FC<CreateWidgetModalFeedProps> = ({
  widgetStyleVariant,
  audioPlaybackMethod,
}) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('idle');
  const { close } = useModalControls();

  return (
    <>
      {requestStatus === 'request' && <Spinner />}
      <Stack spacing={2} alignItems="center">
        <S.Typography variant="h1" textAlign="center">
          {CREATE_WIDGET_MODAL_TITLE}
        </S.Typography>
        <S.Typography variant="h2" textAlign="center">
          {CREATE_WIDGET_MODAL_INSTRUCTIONS}
        </S.Typography>
        <CreateWidgetForm
          onRequestStatusChange={setRequestStatus}
          onClose={close}
          widgetStyleVariant={widgetStyleVariant}
          audioPlaybackMethod={audioPlaybackMethod}
        />
      </Stack>
    </>
  );
};
export default CreateWidgetModalFeed;
