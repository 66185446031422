import { CampaignCategories } from 'api';
import { Option } from 'components/MultiSelect';

export const NO_PREFERENCE = 'no preference';

export const CATEGORIES_OPTIONS: Option<CampaignCategories>[] = [
  { itemValue: 'General Interest', primaryItemLabel: 'General Interest' },
  {
    itemValue: 'Arts & Entertainment',
    primaryItemLabel: 'Arts & Entertainment',
  },
  { itemValue: 'Automotive', primaryItemLabel: 'Automotive' },
  { itemValue: 'Business', primaryItemLabel: 'Business' },
  { itemValue: 'Family & Parenting', primaryItemLabel: 'Family & Parenting' },
  { itemValue: 'Food & Drink', primaryItemLabel: 'Food & Drink' },
  { itemValue: 'Health & Fitness', primaryItemLabel: 'Health & Fitness' },
  { itemValue: 'Home & Garden', primaryItemLabel: 'Home & Garden' },
  {
    itemValue: 'Law, Gov’t & Politics',
    primaryItemLabel: 'Law, Gov’t & Politics',
  },
  { itemValue: 'Mature', primaryItemLabel: 'Mature' },
  { itemValue: 'News', primaryItemLabel: 'News' },
  { itemValue: 'Outdoor & Hunting', primaryItemLabel: 'Outdoor & Hunting' },
  { itemValue: 'Pets', primaryItemLabel: 'Pets' },
  { itemValue: 'Science', primaryItemLabel: 'Science' },
  { itemValue: 'Shopping', primaryItemLabel: 'Shopping' },
  { itemValue: 'Sports', primaryItemLabel: 'Sports' },
  { itemValue: 'Style & Fashion', primaryItemLabel: 'Style & Fashion' },
  {
    itemValue: 'Technology & Computing',
    primaryItemLabel: 'Technology & Computing',
  },
  { itemValue: 'Travel', primaryItemLabel: 'Travel' },
];
