import { TextField } from '@mui/material';
import { Button } from '@sparemin/blockhead';
import RadioCard from 'components/RadioCard';
import { useCampaignCreation } from 'pages/AdCampaignPage/CampaignCreationContext';
import { MIN_CUSTOM_BUDG } from 'pages/AdCampaignPage/constants';
import PageHeading from 'pages/AdCampaignPage/PageHeading';
import { useCallback } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { STEP_TITLES } from '../constants';
import * as S from './styles';

export interface ObjectiveStepProps {
  onContinue: () => void;
}

const ObjectiveStep: React.FC<ObjectiveStepProps> = ({ onContinue }) => {
  const {
    state: {
      pricesList,
      isCustomBudget,
      values: { campaignBudgetMilliCent, campaignDuration },
    },
    dispatch,
  } = useCampaignCreation();
  const { trackPodcastPromoPurchaseNext } = useEventTracking();

  const defaultBudget = campaignBudgetMilliCent ?? pricesList[0].value;
  const isValidCustomBudget =
    Number(campaignBudgetMilliCent) >= MIN_CUSTOM_BUDG;

  const handleContinue = useCallback((): void => {
    trackPodcastPromoPurchaseNext({
      step: 'Objective',
      budget: campaignBudgetMilliCent,
    });
    onContinue();
  }, [campaignBudgetMilliCent, onContinue, trackPodcastPromoPurchaseNext]);

  return (
    <>
      <PageHeading
        title={STEP_TITLES.objective}
        titleId="title"
        description="You only pay for the listens you get, so no dollar is wasted."
      />

      <S.OptionsContainer
        row
        aria-labelledby="title"
        name="prices-radio-buttons-group"
        value={isCustomBudget ? 'custom' : defaultBudget}
        onChange={(e) => {
          const { value } = e.target;

          dispatch({
            type: 'TOGGLE_IS_CUSTOM_BUDGET',
            payload: value === 'custom',
          });

          if (value === 'custom') {
            return dispatch({
              type: 'CHANGE_VALUES',
              payload: {
                campaignBudgetMilliCent: undefined,
              },
            });
          }

          return dispatch({
            type: 'CHANGE_VALUES',
            payload: {
              campaignBudgetMilliCent: Number(value),
            },
          });
        }}
      >
        {pricesList.map((price) => (
          <RadioCard
            {...price}
            key={price.value}
            isChecked={defaultBudget === price.value && !isCustomBudget}
            onClick={(value) => {
              dispatch({
                type: 'CHANGE_VALUES',
                payload: {
                  campaignBudgetMilliCent: value,
                },
              });

              dispatch({
                type: 'TOGGLE_IS_CUSTOM_BUDGET',
                payload: false,
              });
            }}
          />
        ))}

        <RadioCard
          value="custom"
          label="Custom budget"
          description="Up to you!"
          isChecked={isCustomBudget}
          onClick={() => {
            dispatch({
              type: 'CHANGE_VALUES',
              payload: {
                campaignBudgetMilliCent: undefined,
              },
            });

            dispatch({
              type: 'TOGGLE_IS_CUSTOM_BUDGET',
              payload: true,
            });
          }}
        />
      </S.OptionsContainer>

      {isCustomBudget && (
        <S.BudgetContainer>
          <TextField
            fullWidth
            label={`$${MIN_CUSTOM_BUDG}+`}
            value={campaignBudgetMilliCent || ''}
            onChange={(e) =>
              dispatch({
                type: 'CHANGE_VALUES',
                payload: {
                  campaignBudgetMilliCent: Number(e.target.value),
                },
              })
            }
          />

          {Boolean(campaignBudgetMilliCent) && !isValidCustomBudget && (
            <S.ErrorText>
              Custom budget amount must be greater than ${MIN_CUSTOM_BUDG - 1}
            </S.ErrorText>
          )}
        </S.BudgetContainer>
      )}

      <TextField
        fullWidth
        placeholder="Campaign duration (optional)"
        value={campaignDuration || ''}
        onChange={(e) =>
          dispatch({
            type: 'CHANGE_VALUES',
            payload: {
              campaignDuration: e.target.value,
            },
          })
        }
      />

      <Button
        fluid
        isDisabled={
          isCustomBudget && (!campaignBudgetMilliCent || !isValidCustomBudget)
        }
        onPress={handleContinue}
      >
        review your order
      </Button>
    </>
  );
};

export default ObjectiveStep;
