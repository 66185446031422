import { styled } from '@mui/material/styles';
import BaseDialogBox from 'components/DialogBox';
import { PodcastSearchModalStyleProps } from './types';

export const DialogBox = styled(BaseDialogBox)<PodcastSearchModalStyleProps>`
  .MuiDialog-paper {
    border-radius: ${({ $isMobile }) => ($isMobile ? '0px' : '30px')};
  }

  .MuiDialogContent-root {
    padding: 0;
  }
`;
