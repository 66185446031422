import { Card } from '@mui/material';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { DashboardContent, DashboardContentTitle } from 'pages/Dashboard';
import * as S from './styles';

export interface WidgetPageLayoutProps {
  title?: React.ReactNode;
  content: React.ReactNode;
}

const WidgetPageLayout: React.FC<WidgetPageLayoutProps> = ({
  title,
  content,
}: WidgetPageLayoutProps) => {
  useTrackPageLoad({
    section: 'DiscoWidget',
    page: 'WidgetCode',
    state: 'Populated',
  });

  return (
    <DashboardContent
      maxWidth="md"
      title={<DashboardContentTitle>{title}</DashboardContentTitle>}
    >
      <Card>
        <S.Content>{content}</S.Content>
      </Card>
    </DashboardContent>
  );
};

export default WidgetPageLayout;
