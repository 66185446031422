import { Stack, Typography as MuiTypograpy } from '@mui/material';
import { styled } from '@mui/material/styles';
import CardButton from 'components/CardButton';
import CardImageMedia from 'components/CardImageMedia';

export const Body = styled(Stack)`
  margin: 28px 16px 20px;
`;

export const Typography = styled(MuiTypograpy)`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const ButtonCard = styled(CardButton)`
  border-radius: ${({ theme }) => theme.spacing(3)};
  border: solid 2px;
  border-color: ${({ theme }) => theme.palette.grey[500]};
`;

export const Heading = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
`;

export const Subheading = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const Image = styled(CardImageMedia)`
  object-position: 50% 50%;
  height: 280px;
  width: 500px;
`;
