import play from 'assets/playImagery/imagery-play.png';
import play2x from 'assets/playImagery/imagery-play@2x.png';
import play3x from 'assets/playImagery/imagery-play@3x.png';
import ImageCardWithCenteredContents from 'components/ImageCardWithCenteredContents';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { DashboardContent } from 'pages/Dashboard';
import React from 'react';
import {
  PLAY_EMPTY_STATE_TITLE,
  PLAY_EMPTY_STATE_DESCRIPTION,
} from './constants';

type EmptyStateProps = {};

const EmptyState: React.FC<EmptyStateProps> = () => {
  const srcSet = `${play} 1x, ${play2x} 2x, ${play3x} 3x`;

  useTrackPageLoad({ section: 'Play', page: 'EmptyState', state: 'Empty' });
  return (
    <DashboardContent maxWidth="md">
      <ImageCardWithCenteredContents
        heading={PLAY_EMPTY_STATE_TITLE}
        description={PLAY_EMPTY_STATE_DESCRIPTION}
        src={play}
        srcSet={srcSet}
      />
    </DashboardContent>
  );
};
export default EmptyState;
