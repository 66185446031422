import { Switch, SwitchProps } from '@mui/material';
import * as S from './styles';
import { AlignedCellProps } from './types';

type SwitchCellProps = Pick<SwitchProps, 'checked' | 'onChange' | 'disabled'> &
  Pick<AlignedCellProps, 'textAlign'>;

const SwitchCell: React.FC<SwitchCellProps> = ({
  checked,
  onChange,
  textAlign,
  disabled,
}) => (
  <S.AlignedCell
    textAlign={textAlign}
    // If a SwitchCell is used in a table row with an onRowClick event, the user
    // should still be able to click on the toggle without activating the row click
    onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
  >
    <Switch {...{ checked, onChange, disabled }} />
  </S.AlignedCell>
);
export default SwitchCell;
