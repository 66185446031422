import {
  getAdvertisersByDayMetrics,
  AdvertisersByDayWidgetResponse,
  GetAdvertisersByDayWidgetMetricsArgs,
} from 'api/services';
import { UseQueryOptions, useQuery } from 'react-query';
import { ParsedAdvertisersDailyStatsData } from '../types';
import { promoMetricsQueries } from './queryKeys';
import { promoDailyStatsDataSelector } from './selectors';

type QueryKey = ReturnType<typeof promoMetricsQueries.daily>;

function useAdvertisersByDayWidgetMetrics<TData = unknown>(
  args: GetAdvertisersByDayWidgetMetricsArgs,
  opts?: Omit<
    UseQueryOptions<AdvertisersByDayWidgetResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<AdvertisersByDayWidgetResponse, unknown, TData, QueryKey>(
    promoMetricsQueries.daily(args),
    () => getAdvertisersByDayMetrics(args),
    opts,
  );
}

export function usePromoByDayMetrics(
  args: GetAdvertisersByDayWidgetMetricsArgs,
  opts?: Omit<
    UseQueryOptions<
      AdvertisersByDayWidgetResponse,
      unknown,
      ParsedAdvertisersDailyStatsData[],
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
) {
  return useAdvertisersByDayWidgetMetrics(args, {
    select: promoDailyStatsDataSelector,
    ...opts,
  });
}
