import React from 'react';
import { usePodcast } from '../../state/PodcastContext';
import AllPodcastsTable from './AllPodcastsTable';
import { OwnPodcast } from './AllPodcastsTable/types';
import PodcastEpisodesView from './PodcastEpisodesView';
import * as S from './styles';

type TableCardProps = {
  onSelectPodcast?: (val: string) => void;
};

const TableCard: React.FC<TableCardProps> = ({ onSelectPodcast }) => {
  const handleSelectPodcast = (val: OwnPodcast): void => {
    onSelectPodcast?.(val.podcastId);
  };

  const { podcastId } = usePodcast();

  const getComponentFromState = () => {
    if (podcastId) {
      return <PodcastEpisodesView />;
    }
    return <AllPodcastsTable onRowClick={handleSelectPodcast} />;
  };

  return <S.ContentCard>{getComponentFromState()}</S.ContentCard>;
};
export default TableCard;
