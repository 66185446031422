import { FieldErrors } from 'react-hook-form';

export const getFormErrorMessage = (
  errorObject: FieldErrors,
  otherError?: string | undefined,
) => {
  const errorArray = Object.entries(errorObject);
  if (errorArray.length > 0) return errorArray[0][1]?.message;
  return otherError ?? undefined;
};
