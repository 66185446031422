import { Stack, Typography } from '@mui/material';
import * as S from './styles';

type InstructionsHeaderProps = {
  header: string;
  href: string;
};

const InstructionsHeader: React.FC<InstructionsHeaderProps> = ({
  header,
  href,
}) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="h3">{header}:</Typography>
    <S.ExternalLink target="_blank" rel="noreferrer" href={href}>
      <Typography variant="body1"> Install Instructions</Typography>
    </S.ExternalLink>
  </Stack>
);
export default InstructionsHeader;
