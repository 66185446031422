import { useTheme } from '@mui/material';
import { Visibility, Heart, Comment } from '@sparemin/blockhead';
import TableCells from 'components/TableCells';
import { Column, CellProps } from 'react-table';
import { SocialSharePodcastEpisode } from './types';

const columns: ReadonlyArray<Column<SocialSharePodcastEpisode>> = [
  {
    Header: 'Episode',
    id: 'episode',
    Cell: ({ row }: CellProps<SocialSharePodcastEpisode>) => (
      <TableCells.PodcastEpisodeCell
        thumbnailUrl={row.original.thumbnailUrl}
        episodeTitle={row.original.episodeTitle}
      />
    ),
    disableSortBy: true,
    width: 800,
  },
  {
    Header: () => {
      const theme = useTheme();
      return (
        <TableCells.HeaderCell
          text={
            <Visibility
              width="23px"
              height="16px"
              color={theme.palette.grey[700]}
            />
          }
        />
      );
    },
    id: 'views',
    Cell: ({ row }: CellProps<SocialSharePodcastEpisode>) => (
      <TableCells.NumericCell
        value={row.original.statistics.viewCount}
        isValueStale={row.original.statistics.isViewCountStale}
      />
    ),
    disableSortBy: true,
    width: 100,
  },
  {
    Header: () => {
      const theme = useTheme();
      return (
        <TableCells.HeaderCell
          text={
            <Heart width="16px" height="14px" color={theme.palette.grey[700]} />
          }
        />
      );
    },
    id: 'likes',
    Cell: ({ row }: CellProps<SocialSharePodcastEpisode>) => (
      <TableCells.NumericCell value={row.original.statistics.likeCount} />
    ),
    disableSortBy: true,
    width: 100,
  },
  {
    Header: () => {
      const theme = useTheme();
      return (
        <TableCells.HeaderCell
          text={
            <Comment
              width="16px"
              height="16px"
              color={theme.palette.grey[700]}
            />
          }
        />
      );
    },
    id: 'comments',
    Cell: ({ row }: CellProps<SocialSharePodcastEpisode>) => (
      <TableCells.NumericCell value={row.original.statistics.commentCount} />
    ),
    disableSortBy: true,
    width: 100,
  },
];

export default columns;
