import studio from 'assets/studioImagery/imagery-studio.png';
import studio2x from 'assets/studioImagery/imagery-studio@2x.png';
import studio3x from 'assets/studioImagery/imagery-studio@3x.png';
import ImageCardWithCenteredContents from 'components/ImageCardWithCenteredContents';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { DashboardContent } from 'pages/Dashboard';
import React from 'react';
import { useEventTracking } from 'state/eventTracking';
import { LINK_TO_MAKE_PROJECTS_PAGE } from './constants';
import * as S from './styles';

export interface MakeEmptyStateProps {
  page?: 'AllVideos' | 'Podcasts';
  title: string;
  description: string;
  cta: string;
}

const EmptyState: React.FC<MakeEmptyStateProps> = ({
  page = 'AllVideos',
  title,
  description,
  cta,
}) => {
  const { trackStudioTabEmptyStateCtaClick } = useEventTracking();
  const srcSet = `${studio} 1x, ${studio2x} 2x, ${studio3x} 3x`;
  useTrackPageLoad({ section: 'Studio', page, state: 'Empty' });

  return (
    <DashboardContent maxWidth="md">
      <ImageCardWithCenteredContents
        heading={title}
        description={description}
        src={studio}
        srcSet={srcSet}
      >
        <S.Button
          href={LINK_TO_MAKE_PROJECTS_PAGE}
          onClick={trackStudioTabEmptyStateCtaClick}
        >
          {cta}
        </S.Button>
      </ImageCardWithCenteredContents>
    </DashboardContent>
  );
};
export default EmptyState;
