import { Stack } from '@mui/material';
import * as S from './styles';

export interface ContentsProps {
  badge?: React.ReactNode;
  heading: React.ReactNode;
  subheading: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const Contents: React.FC<ContentsProps> = ({
  badge,
  heading,
  subheading,
  className,
  children,
}) => (
  <S.Root className={className}>
    {badge && <S.Badge>{badge}</S.Badge>}

    <Stack spacing={0}>
      <S.Heading>{heading}</S.Heading>

      <div>
        <S.Subheading>{subheading}</S.Subheading>
      </div>
    </Stack>

    {children}
  </S.Root>
);

export default Contents;
