import { Alert, AlertTitle } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { useCallback, useState } from 'react';

const UrlBreakoutDisclaimer: React.FunctionComponent = () => {
  const [hidden, setHidden] = useState(false);

  const hideDisclaimer = useCallback(() => {
    setHidden(true);
  }, []);

  return (
    <Collapse in={!hidden}>
      <Alert onClose={hideDisclaimer} severity="info">
        <AlertTitle>Note:</AlertTitle>
        URL breakout data is refreshed daily at <b>Midnight UTC</b>.
      </Alert>
    </Collapse>
  );
};

export default UrlBreakoutDisclaimer;
