import { Money, getThemeColor } from '@sparemin/blockhead';
import styled from 'styled-components';
import Stat from '../Stat';

type StatStyleProps = {
  $isDisabled?: boolean;
};

export const MoneyIcon = styled(Money)`
  width: 24px;
  height: 16px;
  color: ${getThemeColor('s3')};
`;

export const RevenueStat = styled(Stat)<StatStyleProps>`
  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    `}
`;
