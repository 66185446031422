import { FormControlLabel } from '@mui/material';
import { Text } from '@sparemin/blockhead';
import { useCallback } from 'react';
import * as S from './styles';

export interface RadioCardProps<TValue = string> {
  label: string;
  value: TValue;
  description: React.ReactNode;
  isChecked: boolean;
  className?: string;
  onClick: (value: TValue) => void;
}

const RadioCard = <TValue,>({
  label,
  value,
  description,
  isChecked,
  className,
  onClick,
}: RadioCardProps<TValue>) => {
  const handleClick = useCallback((): void => {
    onClick(value);
  }, [onClick, value]);

  return (
    <S.Root className={className} $isChecked={isChecked} onClick={handleClick}>
      <FormControlLabel
        label={
          <Text variant="p" textTransform="none">
            {label}
          </Text>
        }
        value={value}
        control={<S.Radio size="small" />}
      />

      <S.Description>{description}</S.Description>
    </S.Root>
  );
};

export default RadioCard;
