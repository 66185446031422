import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '@sparemin/blockhead';

export const Form = styled(Box)`
  width: 100%;
`;

export const SubmitCampaignButton = styled(Button)`
  height: 50px;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
