import PodcastContentTable from 'components/PodcastContentTable';
import { usePodcastData } from '../state';
import { PodcastDetails } from './types';
import columns from './utils';

export interface AllPodcastTableProps {
  onRowClick?: (val: PodcastDetails) => void;
}

const AllPodcastTable: React.FC<AllPodcastTableProps> = ({ onRowClick }) => {
  const { data = [], isLoading } = usePodcastData();

  return (
    <PodcastContentTable<PodcastDetails>
      columns={columns}
      podcastData={data}
      isLoading={isLoading}
      tableLayout="fixed"
      onRowClick={onRowClick}
    />
  );
};

export default AllPodcastTable;
