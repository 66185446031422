import { AudioPlaybackMethod } from 'api/services';
import InfoCard from 'components/InfoCard';
import React from 'react';
import * as S from './styles';

interface HeadlinerPlaybackCardProps {
  onClick: (val: AudioPlaybackMethod) => void;
}

const HeadlinerPlaybackCard: React.FC<HeadlinerPlaybackCardProps> = ({
  onClick,
}) => (
  <InfoCard>
    <InfoCard.ActionArea<AudioPlaybackMethod>
      cardType="headlinerOnly"
      onClick={onClick}
    >
      <InfoCard.Image>
        <S.HeadlinerIcon />
      </InfoCard.Image>

      <InfoCard.Contents
        heading="Headliner only"
        subheading={
          <>
            <strong>
              If you don’t want widget plays to count as listens in your host
              dashboard,{' '}
            </strong>
            select this option. You can still review widget engagement data
            within Headliner.
          </>
        }
      />
    </InfoCard.ActionArea>
  </InfoCard>
);
export default HeadlinerPlaybackCard;
