export const EVENTS = {
  dashboardPageLoaded: 'DashboardPageLoaded',
  signup: 'Signup',
  login: 'Login',
  podcastPromoPurchaseNext: 'PodcastPromoPurchaseNext',
  downloadFollowers: 'DownloadFollowers',
  clickCreatePromoButton: 'ClickCreatePromoButton',
  createPromoCheckout: 'CreatePromoCheckout',
  updateDiscoEpisodeDestinationUrl: 'UpdateDiscoEpisodeDestinationUrl',
  reportAdjust: 'ReportAdjust',
  reportExport: 'ReportExport',
  thumbnailCrop: 'ThumbnailCrop',
  thumbnailReplace: 'ThumbnailReplace',
  selectNewClip: 'SelectNewClip',
  showInWidgetToggleAllOff: 'ShowInWidgetToggleAllOff',
  showInWidgetToggleAllOn: 'ShowInWidgetToggleAllOn',
  showInWidgetToggleClipOff: 'ShowInWidgetToggleClipOff',
  showInWidgetToggleClipOn: 'ShowInWidgetToggleClipOn',
  contactUsToGetStarted: 'ContactUsToGetStarted',
  createYourWidget: 'CreateYourWidget',
  createYourWidgetError: 'CreateYourWidgetError',
  createYourWidgetSuccess: 'CreateYourWidgetSuccess',
  contactSupport: 'ContactSupport',
  createPromo: 'CreatePromo',
  createPromoError: 'CreatePromoError',
  createPromoSuccess: 'CreatePromoSuccess',
  emptyStateCTA: 'EmptyStateCTA',
  seePost: 'SeePost',
} as const;
