import {
  createWidget,
  CreateWidgetArgs,
  CreateWidgetResponse,
} from 'api/services';
import { useMutation, UseMutationOptions } from 'react-query';

export default function useCreateWidget(
  config?: Omit<
    UseMutationOptions<
      CreateWidgetResponse,
      unknown,
      CreateWidgetArgs,
      unknown
    >,
    'mutationFn'
  >,
) {
  return useMutation<CreateWidgetResponse, unknown, CreateWidgetArgs>(
    ['widgetSubmittedStatus'],
    (args) => createWidget(args),
    config,
  );
}
