import {
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useEventTracking } from 'state/eventTracking';
import StatsPeriodSelectorCustomPicker from './StatsPeriodSelectorCustomPicker';
import * as S from './styles';
import { PeriodArgs, PeriodOptionKey } from './types';
import {
  calculateMillis,
  DEFAULT_PERIOD_OPTION,
  PERIOD_OPTIONS,
} from './utils';

interface StatsPeriodSelectorProps {
  onSelectPeriod: (args: PeriodArgs) => void;
}

const StatsPeriodSelector: React.FunctionComponent<StatsPeriodSelectorProps> = (
  props,
) => {
  const { trackReportAdjust } = useEventTracking();

  const { onSelectPeriod } = props;

  const [customPickerVisible, setCustomPickerVisible] = useState(false);
  const [selectedOptionKey, setSelectedOptionKey] = useState<PeriodOptionKey>(
    DEFAULT_PERIOD_OPTION,
  );
  const [currRange, setCurrRange] =
    useState<{ startMillis: number; endMillis: number }>();

  const handleSelectPeriod = useCallback(
    (evt: SelectChangeEvent<PeriodOptionKey>) => {
      const key = evt.target.value as PeriodOptionKey;
      const option = PERIOD_OPTIONS.find(
        (periodOption) => periodOption.value === key,
      );
      // range and current option is only changes when option is not custom
      // for custom range picker, option will be saved when confirming
      // date selection modal
      if (option && !option.isCustom) {
        const args = calculateMillis(option.startMillisArgs);
        setCurrRange(args);
        onSelectPeriod(args);
        setSelectedOptionKey(key);
      }
    },
    [onSelectPeriod],
  );

  const handleCloseCustomPicker = useCallback(() => {
    setCustomPickerVisible(false);
  }, []);

  const handleOpenCustomPicker = useCallback(() => {
    setCustomPickerVisible(true);
  }, []);

  const handleConfirmCustomPicker = useCallback(
    (startMillis: number, endMillis: number) => {
      handleCloseCustomPicker();
      setCurrRange({ startMillis, endMillis });
      onSelectPeriod({ startMillis, endMillis });
      setSelectedOptionKey('custom_range');
      trackReportAdjust({ dropdown: 'Period' });
    },
    [handleCloseCustomPicker, onSelectPeriod, trackReportAdjust],
  );

  const renderValue = useCallback((value: string): string => {
    const option = PERIOD_OPTIONS.find(
      (periodOption) => periodOption.value === value,
    );
    return option?.label ?? '';
  }, []);

  const options = PERIOD_OPTIONS.map((option) => (
    <MenuItem
      key={option.value}
      onClick={
        option.value === 'custom_range' ? handleOpenCustomPicker : undefined
      }
      value={option.value}
    >
      <ListItemText primary={option.label} />
    </MenuItem>
  ));

  return (
    <>
      <S.PeriodSelectorFormControl>
        <InputLabel id="period-selector-input-label">Period</InputLabel>
        <Select
          id="period-selector"
          input={<OutlinedInput label="Period" />}
          labelId="period-selector-selector-label"
          onChange={handleSelectPeriod}
          renderValue={renderValue}
          value={selectedOptionKey}
        >
          {options}
        </Select>
      </S.PeriodSelectorFormControl>
      <StatsPeriodSelectorCustomPicker
        currRange={currRange}
        onClose={handleCloseCustomPicker}
        onConfirm={handleConfirmCustomPicker}
        visible={customPickerVisible}
      />
    </>
  );
};

export default StatsPeriodSelector;
