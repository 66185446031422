import React from 'react';
import ActionArea from './ActionArea';
import Contents from './Contents';
import Image from './Image';
import * as S from './styles';

interface InfoCardProps {
  children?: React.ReactNode;
  className?: string;
}

interface InfoCardComponent extends React.FC<InfoCardProps> {
  ActionArea: typeof ActionArea;
  Image: typeof Image;
  Contents: typeof Contents;
}

const InfoCard: InfoCardComponent = ({ className, children }) => (
  <S.Root className={className}>{children}</S.Root>
);

InfoCard.ActionArea = ActionArea;
InfoCard.Image = Image;
InfoCard.Contents = Contents;

export default InfoCard;
