import { Grid, Modal } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

export const PeriodSelectorFormControl = styled(FormControl)`
  width: 200px;
`;

export const ModalComponent = styled(Modal)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBody = styled(Grid)`
  min-width: 30%;
  max-width: 95%;
  padding: ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;

export const ModalPickersContainer = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(2)};
`;
