import { Stack } from '@mui/material';
import React from 'react';
import { NumericCellFormats } from '../types';
import { formatNumericValue } from '../utils';
import * as S from './styles';

type NumericCellProps = {
  value: string | number | null;
  type?: NumericCellFormats;
  isValueStale?: boolean;
};

const NumericCell: React.FC<NumericCellProps> = ({
  value,
  type,
  isValueStale,
}) => {
  const count = value === null ? 0 : value;
  const valueUncertain = value === null || isValueStale;

  return (
    <Stack direction="row">
      <S.NumericCell>{formatNumericValue(count, type)}</S.NumericCell>
      {valueUncertain && <span>*</span>}
    </Stack>
  );
};
export default NumericCell;
