import {
  Button,
  Container,
  breakpointDown,
  getThemeColor,
} from '@sparemin/blockhead';
import ActionItemsList from 'components/ActionItemsList';
import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;

  ${breakpointDown('md')`
    padding-top: 0;
    gap: 16px;
  `}
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  align-items: center;

  ${breakpointDown('md')`
    flex-direction: column;
    gap: 16px;
  `}
`;

export const IntroViewCard = styled(Container)`
  width: 100%;
  height: 170px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${getThemeColor('l3')};
`;

export const PreviousCampaignsHeading = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${breakpointDown('md')`
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const PreviousCampaignsListRoot = styled(ActionItemsList)`
  max-height: 307px;
`;

export const Thumbnail = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
`;

export const RunAgainButton = styled(Button).attrs({
  size: 'small',
})`
  white-space: nowrap;
`;
