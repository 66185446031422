import React from 'react';
import * as S from './styles';

type TablePageContainerProps = {
  children: React.ReactNode;
};

const TablePageContainer: React.FC<TablePageContainerProps> = ({
  children,
}) => <S.ContentPageContainer maxWidth="xl">{children}</S.ContentPageContainer>;
export default TablePageContainer;
