import { getAllPodcastContent, AllPodcastContentResponse } from 'api/services';
import { podcastQueries } from 'pages/ContentManagementPage/state';
import { UseQueryOptions, useQuery } from 'react-query';
import { useAuth } from 'state/auth';
import {
  podcastAspectRatioSelector,
  podcastDataSelector,
  podcastTitleSelector,
} from './selectors';

type QueryKey = ReturnType<typeof podcastQueries.publisher>;

function useAllPodcasts<TData = AllPodcastContentResponse>(
  opts?: Omit<
    UseQueryOptions<AllPodcastContentResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  const { userId } = useAuth();
  return useQuery<AllPodcastContentResponse, unknown, TData, QueryKey>(
    podcastQueries.publisher({ partnersPublisherUserId: userId }),
    () => getAllPodcastContent({ partnersPublisherUserId: userId }),
    { enabled: !!userId, ...opts },
  );
}

export function usePodcastData() {
  const { data, isLoading } = useAllPodcasts({ select: podcastDataSelector });
  return { data, isLoading };
}

export function usePodcastTitle(podcastId: number | undefined) {
  return useAllPodcasts({
    select: (result) => podcastTitleSelector(result, podcastId),
  });
}

export function usePodcastAspectRatio(podcastId: number) {
  return useAllPodcasts({
    select: (data) => podcastAspectRatioSelector(data, podcastId),
  });
}
