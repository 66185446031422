import { useTheme, useMediaQuery } from '@mui/material';
import DialogBox from 'components/DialogBox';
import { useConnectedModal } from 'state/modals';
import PlaybackModalContents from './PlaybackModalContents';

export interface PlaybackModalProps {}

const PlaybackModal: React.FC<PlaybackModalProps> = () => {
  const name = 'Playback';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { params } = useConnectedModal({ name });
  return (
    <DialogBox name={name} maxWidth="lg" fullWidth fullScreen={isMobile}>
      <PlaybackModalContents isMobile={isMobile} {...params} />
    </DialogBox>
  );
};
export default PlaybackModal;
