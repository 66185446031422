import pickBy from 'lodash/pickBy';

/**
 * Basic params serializer for serializing an array value to a valid
 * search params format
 * @param {string} paramName - Name of the param to serialize
 * @returns {Function} - Formatter to be provided to axios instance.
 * It parses params, extracts and serialized the target param.
 */
export const serializeParamsArray = (paramName: string) => (params: any) => {
  const filteredParams = pickBy(params, (val) => val !== undefined);
  const searchParams = new URLSearchParams(filteredParams);

  if (searchParams.has(paramName)) {
    searchParams.delete(paramName);

    params[paramName].forEach((value: string) => {
      searchParams.append(paramName, value);
    });
  }

  return searchParams.toString();
};
