import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';
import WidgetPageLayout from 'components/WidgetPageLayout';
import { useEventTracking } from 'state/eventTracking';
import { useIsSelfServePublisher } from 'state/user';
import {
  PUBLISHER_WIDGET_CODE_HEADER,
  PUBLISHER_WIDGET_CODE_MESSAGE,
} from '../constants';
import SelfServePage from './SelfServePage';

type WidgetPageProps = {};

const WidgetPage: React.FC<WidgetPageProps> = () => {
  const { data: selfServeId, isSuccess } = useIsSelfServePublisher();
  const { trackPopulatedWidgetContactSupport } = useEventTracking();

  if (!isSuccess)
    return (
      <WidgetPageLayout
        content={
          <div>
            <Spinner />
          </div>
        }
      />
    );

  if (selfServeId) {
    return <SelfServePage id={selfServeId} />;
  }

  return (
    <EmptyState
      trackingProps={{
        section: 'DiscoWidget',
        page: 'WidgetCode',
        state: 'Empty',
      }}
      header={PUBLISHER_WIDGET_CODE_HEADER}
      subHeader={PUBLISHER_WIDGET_CODE_MESSAGE}
      onCtaClick={trackPopulatedWidgetContactSupport}
    />
  );
};
export default WidgetPage;
