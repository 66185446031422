import TableCells from 'components/TableCells';
import { CellProps, Column } from 'react-table';
import { currency, sorters } from 'utils/table';

import { ParsedAdvertisersDailyStatsData } from '../types';
import { ReportInput, ReportOutput } from './types';

export const defaultSort = [{ id: 'date', desc: true }];

export const mapExportData = (rowData: ReportInput): ReportOutput => [
  rowData[0],
  rowData[1],
  rowData[2],
  rowData[3],
  rowData[4],
  rowData[5],
  currency.formatMiliCentsValue(rowData[6]),
  currency.formatMiliCentsValue(rowData[7]),
];

const columns: ReadonlyArray<Column<ParsedAdvertisersDailyStatsData>> = [
  {
    Footer: 'Totals',
    Header: 'Date',
    accessor: 'date',
    sortType: sorters.sortByDate('date'),
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell columnName="unitLoad" tableData={info} />
    ),
    Header: <TableCells.HeaderCell accessor="unitLoad" />,
    accessor: 'unitLoad',
    sortType: 'number',
    width: 125,
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell
        columnName="unitInViewport"
        tableData={info}
      />
    ),
    Header: <TableCells.HeaderCell accessor="unitInViewport" />,
    accessor: 'unitInViewport',
    sortType: 'number',
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell columnName="adPlayed" tableData={info} />
    ),
    Header: <TableCells.HeaderCell accessor="adPlayed" />,
    accessor: 'adPlayed',
    sortType: 'number',
    width: 125,
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell
        columnName="adClickThrough"
        tableData={info}
      />
    ),
    Header: <TableCells.HeaderCell accessor="adClickThrough" />,
    accessor: 'adClickThrough',
    sortType: 'number',
    width: 125,
  },
  {
    Cell: TableCells.NumericCell,
    Footer: (info) => (
      <TableCells.SummedNumericCell columnName="spEngaged" tableData={info} />
    ),
    Header: <TableCells.HeaderCell accessor="spEngaged" />,
    accessor: 'spEngaged',
    sortType: 'number',
  },
  {
    Cell: ({
      value,
    }: CellProps<
      ParsedAdvertisersDailyStatsData,
      ParsedAdvertisersDailyStatsData['avgCpc']
    >) => <TableCells.NumericCell type="mili-cents" value={value} />,
    Footer: (info) => (
      <TableCells.AveragedTotalsNumericCells
        totalColumn="total"
        divisorColumn="spEngaged"
        tableData={info}
        type="mili-cents"
      />
    ),
    Header: <TableCells.HeaderCell accessor="avgCpc" />,
    accessor: 'avgCpc',
    sortType: 'number',
    width: 125,
  },
  {
    Cell: ({
      value,
    }: CellProps<
      ParsedAdvertisersDailyStatsData,
      ParsedAdvertisersDailyStatsData['total']
    >) => <TableCells.NumericCell type="mili-cents" value={value} />,
    Footer: (info) => (
      <TableCells.SummedNumericCell
        columnName="total"
        tableData={info}
        type="mili-cents"
      />
    ),
    Header: <TableCells.HeaderCell accessor="total" />,
    accessor: 'total',
    sortType: 'number',
    width: 125,
  },
];

export default columns;
