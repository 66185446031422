/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { COUNTRIES } from 'utils/countries';
import {
  HEADLINER_PRICES,
  PUBNET_PRICES,
} from '../CampaignCreationView/ObjectiveStep/constants';
import { CATEGORIES_OPTIONS } from '../CampaignCreationView/PlacementStep/TargetingSelection/constants';
import { CampaignCreationAction, CampaignCreationState } from './types';

export const defaultState: CampaignCreationState = {
  values: {
    episode: undefined,
    sponsorPlacement: undefined,
    targetCategories: [CATEGORIES_OPTIONS[0].itemValue],
    targetCountryCodes: [COUNTRIES[0].itemValue],
    campaignBudgetMilliCent: Number(HEADLINER_PRICES[0].value),
    campaignDuration: undefined,
  },
  step: 'episode',
  subStep: undefined,
  pricesList: HEADLINER_PRICES,
  isCustomBudget: false,
};

export default function reducer(
  state: CampaignCreationState,
  action: CampaignCreationAction,
) {
  switch (action.type) {
    case 'CHANGE_VALUES': {
      const { payload: newValues } = action;

      return produce(state, (d) => {
        d.values = {
          ...d.values,
          ...newValues,
        };
      });
    }

    case 'TOGGLE_IS_CUSTOM_BUDGET': {
      const { payload: isCustomBudget } = action;

      return produce(state, (d) => {
        d.isCustomBudget = isCustomBudget ?? !d.isCustomBudget;
      });
    }

    case 'CHANGE_PLACEMENT': {
      const { payload: nextPlacement } = action;

      return produce(state, (d) => {
        const { values } = d;
        const isHeadlinerPlacement = nextPlacement === 'headlinerApp';

        values.sponsorPlacement = nextPlacement;
        values.targetCategories = isHeadlinerPlacement
          ? []
          : [CATEGORIES_OPTIONS[0].itemValue];
        values.targetCountryCodes = isHeadlinerPlacement
          ? []
          : [COUNTRIES[0].itemValue];

        d.pricesList = isHeadlinerPlacement ? HEADLINER_PRICES : PUBNET_PRICES;
      });
    }

    case 'RE_RUN_CAMPAIGN': {
      const { payload: campaign } = action;

      const {
        contentDetail: { podcastThumbnailUrl, podcastTitle, episodeTitle },
        podcastId = '',
        remoteEpisodeId = '',
        sponsorPlacement,
        targetCategories,
        targetCountryCodes,
        campaignBudgetMilliCent,
        campaignDuration,
      } = campaign;

      const categories = targetCategories ?? [CATEGORIES_OPTIONS[0].itemValue];
      const countries = targetCountryCodes ?? [COUNTRIES[0].itemValue];

      return produce(state, (d) => {
        d.isCustomBudget = !d.pricesList.some(
          ({ value }) => value === campaignBudgetMilliCent,
        );

        d.values = {
          ...d.values,
          episode: {
            episodeId: remoteEpisodeId,
            podcast: {
              podcastId,
              imageUrl: podcastThumbnailUrl,
              thumbnailUrl: podcastThumbnailUrl,
              title: podcastTitle,
            },
            thumbnailUrl: podcastThumbnailUrl,
            title: episodeTitle,
          },
          sponsorPlacement,
          targetCategories: categories,
          targetCountryCodes: countries,
          campaignBudgetMilliCent,
          campaignDuration,
        };
      });
    }

    case 'GO_TO_STEP': {
      const {
        payload: { step, subStep },
      } = action;

      return produce(state, (d) => {
        d.step = step;
        d.subStep = subStep;
      });
    }

    case 'RESET_VALUES': {
      return defaultState;
    }

    default:
      return state;
  }
}
