import { Stack, Typography } from '@mui/material';
import { useEventTracking } from 'state/eventTracking';
import { displayDate } from 'utils/date';
import * as TableStyles from '../styles';
import * as S from './styles';

export interface SocialVideoCellProps {
  videoName: string;
  projectName: string;
  datePosted: number;
  imgUrl: string;
  videoUrl: string;
  socialPlatform: string;
}

const SocialVideoCell: React.FC<SocialVideoCellProps> = ({
  videoName,
  projectName,
  datePosted,
  imgUrl,
  videoUrl,
  socialPlatform,
}) => {
  const { trackStudioTabSeePostClick } = useEventTracking();
  return (
    <Stack direction="row" spacing={2}>
      <TableStyles.Img src={imgUrl} alt="podcast artwork" />
      <S.TextStack direction="column" justifyContent="center" spacing={0}>
        <S.Title>
          <strong>
            {videoName}
            {` / `}
            {projectName}
          </strong>
        </S.Title>
        <Typography variant="caption">
          Posted to {socialPlatform} on {displayDate(datePosted, 'MM.DD.YYYY')}
          {` (`}
          <a
            target="_blank"
            rel="noreferrer"
            href={videoUrl}
            onClick={trackStudioTabSeePostClick}
          >
            see post
          </a>
          )
        </Typography>
      </S.TextStack>
    </Stack>
  );
};

export default SocialVideoCell;
