import useMobileBreakpoint from 'hooks/useMobileBreakpoint';
import useToggle from 'hooks/useToggle';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ScopesProvider } from 'state/scopes';
import Header from './Header';
import NavigationDrawer from './NavigationDrawer/NavigationDrawer';
import * as S from './styles';

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const isMobile = useMobileBreakpoint('lg');
  const showMobileFallback = useMobileBreakpoint('md');

  const {
    value: isMenuOpen,
    toggle: toggleMenuOpen,
    toggleOff: closeMenu,
  } = useToggle(true);

  // on mobile, isMobile is false on first render, then becomes true, so setting
  // the default value of the toggle to !isMobile, for example, does not in fact
  // initialize the drawer to be hidden on mobile.
  useEffect(() => {
    if (isMobile) {
      closeMenu();
    }
  }, [closeMenu, isMobile]);

  return (
    <ScopesProvider>
      <Header onToggleMenu={toggleMenuOpen} {...{ isMenuOpen, isMobile }} />
      <S.Body>
        <NavigationDrawer
          isMobile={isMobile}
          showDesktopView={!showMobileFallback}
          onClose={closeMenu}
          open={isMenuOpen}
        />
        <S.Main component="main">
          {/* https://github.com/mui/material-ui/issues/10076#issuecomment-361232810 */}
          <S.HeaderSpacer />
          <Outlet />
        </S.Main>
      </S.Body>
    </ScopesProvider>
  );
};

export default Dashboard;
