import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useVirtualizer } from '@tanstack/react-virtual';
import { TableInstance } from 'react-table';
import { VIRTUAL_ROW_HEIGHT } from '../constants';

export interface VirtualTableBodyProps {
  scrollElement?: HTMLElement;
  table: TableInstance<any>;
  rowHeight?: number;
}
const VirtualTableBody: React.FC<VirtualTableBodyProps> = ({
  scrollElement,
  table,
  rowHeight = VIRTUAL_ROW_HEIGHT,
}) => {
  const { prepareRow, rows } = table;

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => rowHeight,
    getScrollElement: () => scrollElement,
  });

  return (
    <TableBody
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
        position: 'relative',
        width: '100%',
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
        const row = rows[virtualRow.index];
        prepareRow(row);

        const { style: tableRowStyle, ...rowProps } = row.getRowProps();
        const rowStyle: React.CSSProperties = {
          ...tableRowStyle,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: `${virtualRow.size}px`,
          transform: `translateY(${virtualRow.start}px)`,
        };
        return (
          <TableRow style={rowStyle} {...rowProps}>
            {row.cells.map((cell) => (
              <TableCell width={cell.column.width} {...cell.getCellProps()}>
                {cell.render('Cell')}
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default VirtualTableBody;
