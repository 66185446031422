import {
  getPlatformMetrics,
  GetPlatformByDayWidgetMetricsArgs,
  GetPlatformByDayWidgetMetricsResponse,
} from 'api/services';

import { UseQueryOptions, useQuery } from 'react-query';
import { discoMetricsQueries } from './queryKeys';
import { platformStatsDataSelector } from './selectors';

type QueryKey = ReturnType<typeof discoMetricsQueries.platformDaily>;

function usePlatformByDayWidgetMetrics<TData = unknown>(
  args: GetPlatformByDayWidgetMetricsArgs,
  opts?: Omit<
    UseQueryOptions<
      GetPlatformByDayWidgetMetricsResponse,
      unknown,
      TData,
      QueryKey
    >,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery<
    GetPlatformByDayWidgetMetricsResponse,
    unknown,
    TData,
    QueryKey
  >(
    discoMetricsQueries.platformDaily(args),
    () => getPlatformMetrics(args),
    opts,
  );
}

export function usePlatformDailyStatsData(
  args: GetPlatformByDayWidgetMetricsArgs,
) {
  return usePlatformByDayWidgetMetrics(args, {
    keepPreviousData: true,
    select: platformStatsDataSelector,
  });
}
