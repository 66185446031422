import { EpisodeMetrics } from 'api';
import TableCells from 'components/TableCells';
import { Column, CellProps } from 'react-table';

const columns: ReadonlyArray<Column<EpisodeMetrics>> = [
  {
    Header: 'Episode',
    id: 'episode',
    Cell: ({ row }: CellProps<EpisodeMetrics>) => (
      <TableCells.PodcastEpisodeCell
        thumbnailUrl={row.original.thumbnailUrl}
        episodeTitle={row.original.title}
        pubDateInMillis={row.original.publishedAtMillis}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: 'Plays',
    id: 'plays',
    Cell: ({ row }: CellProps<EpisodeMetrics>) => row.original.playCount,
  },
];

export default columns;
