import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { PodcastContentTableConfig } from './types';

const usePodcastContentTable = <TData extends object>(
  config: PodcastContentTableConfig<TData>,
) => {
  const { columns, getRowId, podcastData, defaultSort, isLoading } = config;
  const initialState = defaultSort ? { sortBy: defaultSort } : undefined;

  const tableInstance = useTable(
    {
      columns,
      getRowId,
      initialState,
      data: podcastData,
    },
    useSortBy,
    useFlexLayout,
  );

  return { loading: isLoading, tableInstance };
};

export default usePodcastContentTable;
