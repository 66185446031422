import { List, Divider } from '@mui/material';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { useFollowersList } from 'pages/Play/state/useFeedFollowers';
import React from 'react';
import DownloadCsvButton from './DownloadCsvButton';
import ListItem from './ListItem';

type FollowersListProps = {};

const FollowersList: React.FC<FollowersListProps> = () => {
  const { data: followers = [] } = useFollowersList();

  useTrackPageLoad({
    section: 'Play',
    page: 'FollowersPage',
    state: 'Populated',
  });

  return (
    <>
      <DownloadCsvButton />

      <List>
        {followers.map((follower, index) => (
          <div key={follower.email}>
            <ListItem
              email={follower.email}
              followDate={follower.followStartedAt}
            />
            {index !== followers.length - 1 && <Divider variant="middle" />}
          </div>
        ))}
      </List>
    </>
  );
};
export default FollowersList;
