import { yupResolver } from '@hookform/resolvers/yup';
import { useNotification } from 'components/Notification/NotificationContext';
import { useForm } from 'react-hook-form';
import { useAuth } from 'state/auth';
import { object, SchemaOf, string, ref } from 'yup';
import useChangePassword from './state';

export type ChangePasswordField =
  | 'currentPassword'
  | 'newPassword'
  | 'confirmPassword';

type ChangePasswordFormData = Record<ChangePasswordField, string>;

const successMessage = 'Password updated successfully';
const errorMessage = 'Error updating password';

const schema: SchemaOf<ChangePasswordFormData> = object()
  .shape({
    currentPassword: string().required(),
    newPassword: string()
      .min(8, 'Password must be at least 8 characters')
      .required(),
    confirmPassword: string()
      .oneOf([ref('newPassword'), null], 'Passwords must match')
      .required(),
  })
  .required();

export default function useChangePasswordForm() {
  const { userId } = useAuth();
  const { showError, showSuccess } = useNotification();

  const { handleSubmit, reset, ...form } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(schema),
  });

  const changePassword = useChangePassword({
    onError: () => showError(errorMessage),
    onSuccess: () => {
      reset();
      showSuccess(successMessage);
    },
  });

  const onSubmit = ({
    confirmPassword,
    currentPassword,
    newPassword,
  }: ChangePasswordFormData) => {
    if (!userId) {
      showError(errorMessage);
    } else {
      changePassword.mutate({
        confirmPassword,
        currentPassword,
        userId,
        password: newPassword,
      });
    }
  };

  return {
    ...form,
    reset,
    isLoading: changePassword.isLoading,
    onSubmit: handleSubmit(onSubmit),
  };
}
