import { DrawerProps } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { RoleName } from 'api/services';
import routes from 'utils/routes';
import { Mobile } from '../types';
import NavigationLink from './NavigationLink';
import * as S from './styles';

export interface NavigationDrawerProps
  extends Mobile,
    Pick<DrawerProps, 'onClose'> {
  open: boolean;
  showDesktopView: boolean;
}

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  isMobile,
  onClose,
  open,
  showDesktopView,
}) => (
  <S.NavDrawer
    variant={isMobile ? 'temporary' : 'permanent'}
    {...{ isMobile, onClose, open }}
  >
    <Toolbar>
      <S.HeadlinerLogo />
    </Toolbar>

    <S.NavDrawerContents component="nav">
      <NavigationLink
        path={routes.home.path}
        icon={<S.HomeIcon />}
        text="Home"
      />

      <NavigationLink
        nestedLinks={
          showDesktopView
            ? [
                {
                  path: routes.discoWidgetDailyStats.path,
                  text: 'Daily Stats',
                  requiredScopes: [RoleName.publisher],
                },
                {
                  path: routes.platformBreakoutDashboard.path,
                  text: 'Platform Breakout',
                  requiredScopes: [RoleName.publisher],
                },
                {
                  path: routes.urlBreakoutDashboard.path,
                  text: 'URL Breakout',
                  requiredScopes: [RoleName.publisher],
                },
                {
                  path: routes.contentManagement.path,
                  text: 'Content Management',
                  requiredScopes: [RoleName.publisher],
                },
                {
                  path: routes.discoWidget.path,
                  text: 'Widget Code',
                  requiredScopes: [RoleName.publisher],
                },
              ]
            : []
        }
        path={routes.discoWidgetDashboard.path}
        icon={<S.RadioIcon />}
        text="Disco"
      />

      <NavigationLink
        nestedLinks={
          showDesktopView
            ? [
                {
                  path: routes.discoAdsDailyStats.path,
                  text: 'Daily Stats',
                  requiredScopes: [RoleName.advertiser],
                },
                {
                  path: routes.adCampaign.path,
                  text: 'Create Promo',
                  requiredScopes: [RoleName.advertiser],
                },
              ]
            : []
        }
        path={routes.discoAdsDashboard.path}
        icon={<S.MegaphoneIcon />}
        text="Podcast Promo"
      />
      <NavigationLink
        path={routes.playAnalytics.path}
        icon={<S.PLayIcon />}
        text="Play"
      />
      <NavigationLink
        nestedLinks={[
          {
            path: routes.allVideos.path,
            text: 'All videos',
          },
          { path: routes.podcasts.path, text: 'Podcasts' },
        ]}
        path={routes.studioDashboard.path}
        icon={<S.VideoIcon />}
        text="Make"
      />

      <NavigationLink
        icon={<S.UserAccount />}
        path={routes.account.path}
        text="Account"
      />
    </S.NavDrawerContents>
  </S.NavDrawer>
);
export default NavigationDrawer;
