import CssBaseline from '@mui/material/CssBaseline';
import { AxiosGlobalConfiguration } from 'api/http';
import { NotificationProvider } from 'components/Notification';
import QueryClientProvider from 'components/QueryClientProvider';
import ThemeProvider, { ChartsThemeProvider } from 'components/Theme';
import { env } from 'config';
import React from 'react';
import ReactGA from 'react-ga4';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from 'state/auth';
import { EventTrackingProvider } from 'state/eventTracking';
import { ModalProvider } from 'state/modals';
import AppRouter, { AppRouterProvider } from './AppRouter';
import 'simplebar/dist/simplebar.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import Modals from './Modals';

export interface AppProps {}

if (!env.GA_TRACKING_ID) {
  throw new Error('missing GA tracking id');
}

ReactGA.initialize(env.GA_TRACKING_ID);

const App: React.FC<AppProps> = () => (
  <ThemeProvider>
    <AppRouterProvider>
      <QueryClientProvider>
        <EventTrackingProvider>
          <AuthProvider>
            <ModalProvider>
              <ChartsThemeProvider>
                <NotificationProvider>
                  <AxiosGlobalConfiguration />
                  <CssBaseline />

                  <AppRouter />
                  <Modals />
                </NotificationProvider>
              </ChartsThemeProvider>
            </ModalProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen />
        </EventTrackingProvider>
      </QueryClientProvider>
    </AppRouterProvider>
  </ThemeProvider>
);

export default App;
