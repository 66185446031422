import { CardContent } from '@mui/material';
import Simplebar from 'simplebar-react';
import AllPodcastTable from '../AllPodcastTable';
import { PodcastDetails } from '../AllPodcastTable/types';
import EpisodeSegmentsCardContents from '../episodeSegments';
import PodcastEpisodesTable from '../PodcastEpisodesTable';
import { PodcastEpisodeTableData } from '../PodcastEpisodesTable/types';
import PodcastFeedProvider from '../state/PodcastFeedContext';

import * as S from './styles';

type ContentManagementPageCardProps = {
  podcastId: number | undefined;
  episodeId: number | undefined;
  onSelectPodcast?: (podcastId: number) => void;
  onSelectEpisode?: (episodeId: number) => void;
};

const ContentManagementPageCard: React.FC<ContentManagementPageCardProps> = ({
  episodeId,
  onSelectEpisode,
  onSelectPodcast,
  podcastId,
}) => {
  const handleSelectPodcast = (val: PodcastDetails): void => {
    onSelectPodcast?.(val.podcastFeedId);
  };

  const handleSelectEpisode = (val: PodcastEpisodeTableData) => {
    onSelectEpisode?.(val.internalEpisodeId);
  };

  return (
    <S.ContentCard>
      <CardContent component={Simplebar}>
        {!podcastId && <AllPodcastTable onRowClick={handleSelectPodcast} />}
        <PodcastFeedProvider podcastId={podcastId}>
          {podcastId && !episodeId && (
            <PodcastEpisodesTable
              onRowClick={handleSelectEpisode}
              podcastId={podcastId}
            />
          )}
          {podcastId && episodeId && (
            <EpisodeSegmentsCardContents episodeId={episodeId} />
          )}
        </PodcastFeedProvider>
      </CardContent>
    </S.ContentCard>
  );
};

export default ContentManagementPageCard;
