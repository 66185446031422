import { Typography } from '@mui/material';
import GoBackButton from 'components/GoBackButton';
import { ALL_EPISODES_TABLE_PAGE_SUBHEADER } from './constants';

export type PageHeaderWithPodcastNavigationProps = {
  podcastId?: string | number;
  episodeId?: string | number;
  onBackClick?: () => void;
  podcastTitle?: string;
  episodeTitle?: string;
  defaultPageTitle: string;
  defaultPageSubtitle: React.ReactNode;
};

const PageHeaderWithPodcastNavigation: React.FC<
  PageHeaderWithPodcastNavigationProps
> = ({
  podcastId,
  episodeId,
  onBackClick,
  podcastTitle,
  episodeTitle,
  defaultPageTitle,
  defaultPageSubtitle,
}) => {
  const getTitle = () => {
    if (episodeId) return episodeTitle;
    if (podcastId) return podcastTitle;
    return defaultPageTitle;
  };
  const getSubtitle = () => {
    if (episodeId) {
      return (
        <GoBackButton
          onClick={onBackClick}
          message={`Return to all episodes of ${podcastTitle}`}
        />
      );
    }
    if (podcastId) {
      return (
        <GoBackButton
          onClick={onBackClick}
          message={ALL_EPISODES_TABLE_PAGE_SUBHEADER}
        />
      );
    }
    return defaultPageSubtitle;
  };
  return (
    <>
      <Typography variant="h1" gutterBottom>
        {getTitle()}
      </Typography>
      <Typography variant="h2" gutterBottom>
        {getSubtitle()}
      </Typography>
    </>
  );
};
export default PageHeaderWithPodcastNavigation;
