import { yupResolver } from '@hookform/resolvers/yup';
import { useNotification } from 'components/Notification/NotificationContext';
import { isEmpty } from 'lodash';
import {
  useAddEpisodeSegment,
  useEpisodeAudioDuration,
} from 'pages/ContentManagementPage/state';
import { useForm } from 'react-hook-form';
import { useEventTracking } from 'state/eventTracking';
import { number, object, SchemaOf } from 'yup';
import {
  SEGMENT_ADDED_ERROR,
  SEGMENT_ADDED_SUCCESS,
  DEFAULT_CLIP_END,
  DEFAULT_CLIP_START,
  MAX_CLIP_DURATION_MILLIS,
  INVALID_CLIP_ERROR,
  CLIP_TOO_LONG_ERROR,
  getClipOutsideEpisodeError,
} from './constants';
import { timeCodeInMillis } from './utils';

type NewEpisodeSegmentFormData = {
  startTime: number;
  endTime: number;
};

const defaultValues = {
  startTime: timeCodeInMillis(DEFAULT_CLIP_START),
  endTime: timeCodeInMillis(DEFAULT_CLIP_END),
};

const schema: SchemaOf<NewEpisodeSegmentFormData> = object()
  .shape({
    startTime: number().test('maximumDuration', (val, ctx) => {
      const duration = ctx.parent.endTime - (val as number);
      if (duration > MAX_CLIP_DURATION_MILLIS) {
        return ctx.createError({ message: CLIP_TOO_LONG_ERROR });
      }
      if (duration <= 0) {
        return ctx.createError({ message: INVALID_CLIP_ERROR });
      }
      return true;
    }),

    endTime: number()
      .test('maximumDuration', (val, ctx) => {
        const duration = (val as number) - ctx.parent.startTime;
        if (duration > MAX_CLIP_DURATION_MILLIS) {
          return ctx.createError({ message: CLIP_TOO_LONG_ERROR });
        }
        if (duration <= 0) {
          return ctx.createError({ message: INVALID_CLIP_ERROR });
        }
        return true;
      })
      .test('outsideEpisodeDuration', (val, ctx) => {
        const audioDuration = ctx.options.context?.audioLength;
        if (audioDuration !== undefined) {
          if ((val as number) > audioDuration)
            return ctx.createError({
              message: getClipOutsideEpisodeError(audioDuration),
            });
        }
        return true;
      }),
  })
  .required();

export default function useNewEpisodeSegmentForm(
  episodeId: number,
  onClose: () => void,
) {
  const { trackSelectNewClip } = useEventTracking();

  const { showError, showSuccess } = useNotification();
  const { data: audioDurationMillis } = useEpisodeAudioDuration(episodeId);

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    ...form
  } = useForm<NewEpisodeSegmentFormData>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
    context: { audioLength: audioDurationMillis },
  });

  const newEpisodeSegment = useAddEpisodeSegment({
    onError: () => showError(SEGMENT_ADDED_ERROR),
    onSuccess: () => {
      showSuccess(SEGMENT_ADDED_SUCCESS);
      onClose();
      trackSelectNewClip();
    },
  });

  const onSubmit = ({ startTime, endTime }: NewEpisodeSegmentFormData) => {
    if (!episodeId) {
      showError(SEGMENT_ADDED_ERROR);
    } else {
      newEpisodeSegment.mutate({
        internalEpisodeId: episodeId,
        startMillis: startTime,
        endMillis: endTime,
      });
    }
  };

  return {
    ...form,
    errors,
    isError: !isEmpty(errors),
    onSubmit: handleSubmit(onSubmit),
  };
}
