import DailyStatsTableCard from 'components/DailyStatsTableCard';
import { useAdvertisersDashboard } from '../AdvertisersDashboardContext';

import columns, { defaultSort, mapExportData } from './utils';

export interface DailyStatsCardProps {}

const DailyStatsCard: React.FC<DailyStatsCardProps> = () => {
  const { dailyStats } = useAdvertisersDashboard();

  return (
    <DailyStatsTableCard
      columns={columns}
      dailyStats={dailyStats}
      defaultSort={defaultSort}
      exportArgs={{
        fileName: 'Advertisers_daily_stats',
        formatter: mapExportData,
      }}
      footer
      reportType="DiscoPromoDailyStats"
    />
  );
};

export default DailyStatsCard;
