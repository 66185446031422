import { Box, StepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)`
  padding: 15px 0;
  width: 100%;
`;

export const Label = styled(StepLabel)`
  text-transform: Capitalize;
  .MuiStepLabel-label {
    font-size: 14px;
  }
`;
