import PageHeaderWithPodcastNavigation, {
  PageHeaderWithPodcastNavigationProps,
} from 'components/PageHeaderWithPodcastNavigation';
import {
  useEpisodeTitle,
  usePodcastTitle,
} from 'pages/ContentManagementPage/state';
import React from 'react';
import { DEFAULT_SUBTITLE, DEFAULT_TITLE } from '../constants';

interface ContentManagementPageTitleProps
  extends Omit<
    PageHeaderWithPodcastNavigationProps,
    'podcastTitle' | 'episodeTitle' | 'defaultPageTitle' | 'defaultPageSubtitle'
  > {
  episodeId: number | undefined;
  podcastId: number | undefined;
  onBackClick?: () => void;
}

const ContentManagementPageTitle: React.FC<ContentManagementPageTitleProps> = ({
  podcastId,
  episodeId,
  ...props
}) => {
  const { data: podcastTitle } = usePodcastTitle(podcastId);

  const { data: episodeTitle } = useEpisodeTitle(episodeId);

  return (
    <PageHeaderWithPodcastNavigation
      podcastTitle={podcastTitle}
      episodeTitle={episodeTitle}
      defaultPageTitle={DEFAULT_TITLE}
      defaultPageSubtitle={DEFAULT_SUBTITLE}
      podcastId={podcastId}
      episodeId={episodeId}
      {...props}
    />
  );
};

export default ContentManagementPageTitle;
