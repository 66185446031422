import { camelCase, isString } from 'lodash';

export function capitalize(str: string) {
  if (!isString(str)) return str;
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function pascalCase(str: string) {
  return capitalize(camelCase(str));
}
