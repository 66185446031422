import { RoleName } from 'api/services';
import { useEffect } from 'react';
import { useEventTracking } from 'state/eventTracking';
import { useScopesContext } from 'state/scopes';
import useTrackingQueryParams from './useTrackingQueryParams';

export default function useTrackAdCampaignPage() {
  const { availableScopes } = useScopesContext();

  const { trackDashboardPageLoad } = useEventTracking();

  const { source, isRead } = useTrackingQueryParams();

  useEffect(() => {
    if (availableScopes && isRead) {
      trackDashboardPageLoad({
        section: 'DiscoPromo',
        page: availableScopes.includes(RoleName.advertiser)
          ? 'CreatePromo'
          : 'EmptyState',
        state: 'Empty',
        source,
      });
    }
  }, [availableScopes, trackDashboardPageLoad, isRead, source]);
}
