import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface AccountCardProps {
  children?: React.ReactNode;
  title: string;
}

const AccountCard: React.FC<AccountCardProps> = ({ children, title }) => (
  <Card>
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>{children}</Box>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default AccountCard;
