import { ButtonProps, Search, Thumbnail } from '@sparemin/blockhead';
import React from 'react';
import { PodcastEpisodeInputValue } from '../types';
import * as S from './styles';

export type PodcastEpisodeNonEmptyInputProps = {
  episode: PodcastEpisodeInputValue;
  onSearchPress?: ButtonProps['onPress'];
  buttonRef?: React.Ref<HTMLButtonElement>;
};

const PodcastEpisodeNonEmptyInput: React.FC<
  PodcastEpisodeNonEmptyInputProps
> = ({ buttonRef, episode, onSearchPress }) => (
  <S.Container>
    <S.ThumbnailContainer>
      <Thumbnail
        alt="selected podcast episode thumbnail"
        ratio={1}
        src={episode.thumbnailUrl ?? episode.podcast.thumbnailUrl}
      />
    </S.ThumbnailContainer>
    <S.EpisodeMedata>
      <S.PodcastName>{episode.podcast.title}</S.PodcastName>
      <S.EpisodeName>{episode.title}</S.EpisodeName>
    </S.EpisodeMedata>
    <S.SearchButton onPress={onSearchPress} ref={buttonRef}>
      <Search />
    </S.SearchButton>
  </S.Container>
);

export default PodcastEpisodeNonEmptyInput;
