import { Card as MuiCard, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Card = styled(MuiCard)`
  max-width: 500px;
  &:hover {
    border-color: #019ce3;
  }
`;

export const Button = styled(CardActionArea)`
  .MuiCardActionArea-focusHighlight {
    background: transparent;
  }
`;
