import { formatTimeValue } from 'utils/time';
import * as S from './styles';
import { AlignedCellProps } from './types';

interface NumericCellProps extends AlignedCellProps {
  startTime: number;
  endTime: number;
}

const TimeSegmentsCell: React.FC<NumericCellProps> = ({
  startTime,
  endTime,
  textAlign,
}) => (
  <S.AlignedCell textAlign={textAlign}>
    {formatTimeValue(startTime)} - {formatTimeValue(endTime)}
  </S.AlignedCell>
);

export default TimeSegmentsCell;
