import { KeyboardBackspace as BackIcon } from '@mui/icons-material';
import * as S from './styles';

type GoBackButtonProps = {
  message?: string;
  onClick?: () => void;
};

const GoBackButton: React.FC<GoBackButtonProps> = ({ message, onClick }) => (
  <S.Button onClick={onClick}>
    <BackIcon />
    {message}
  </S.Button>
);

export default GoBackButton;
