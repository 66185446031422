import { sso, decodeSpareminToken } from '@sparemin/auth';
import { AuthState } from './types';

export function getUserIdFromToken(token: string) {
  const decodedToken = decodeSpareminToken(token);
  return decodedToken.sub;
}

export function persistToken(token: string) {
  sso.storeToken(token);
}

export function rehydrateToken() {
  return sso.getToken();
}

export function deleteToken() {
  sso.clearToken();
}

export function createAuthState(token: string, userId?: number): AuthState {
  return {
    accessToken: token,
    userId: userId !== undefined ? userId : getUserIdFromToken(token),
  };
}
