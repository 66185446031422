import { FeedFollowersResponse, getFeedFollowers } from 'api';
import { useQuery, UseQueryOptions } from 'react-query';
import { usePodcast } from './PodcastContext';
import { ownedPodcastQueries } from './queryKeys';
import {
  followersFormattedForCsvSelector,
  followersListSelector,
  hasFollowersSelector,
} from './selectors';
import { useFeedIdFromPodcastId } from './useClaimedPodcasts';

type QueryKey = ReturnType<typeof ownedPodcastQueries.followers>;

function useFeedFollowers<TData = FeedFollowersResponse>(
  opts?: Omit<
    UseQueryOptions<FeedFollowersResponse, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  const { podcastId } = usePodcast();
  const { data: feedId } = useFeedIdFromPodcastId(podcastId);

  return useQuery<FeedFollowersResponse, unknown, TData, QueryKey>(
    ownedPodcastQueries.followers(feedId as number),
    () => getFeedFollowers(feedId as number),

    { enabled: !!feedId, ...opts },
  );
}

export function useFollowersList() {
  return useFeedFollowers({ select: followersListSelector });
}

export function useHasFollowers() {
  return useFeedFollowers({ select: hasFollowersSelector });
}

export function useFollowersFormattedForCsv() {
  return useFeedFollowers({ select: followersFormattedForCsvSelector });
}
