import React, { useCallback, useContext, useMemo, useState } from 'react';
import Notification from './Notification';
import {
  NotificationContextType,
  NotificationProviderProps,
  NotificationState,
  ShowNotificationConfig,
} from './types';

const NotificationContext = React.createContext<
  NotificationContextType | undefined
>(undefined);

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<NotificationState | undefined>(undefined);

  const handleShowNotification = useCallback(
    (config: ShowNotificationConfig) => {
      setState({
        ...config,
        show: true,
      });
    },
    [],
  );

  const showError = useCallback(
    (message: string) => {
      handleShowNotification({ severity: 'error', message });
    },
    [handleShowNotification],
  );

  const showSuccess = useCallback(
    (message: string) => {
      handleShowNotification({ severity: 'success', message });
    },
    [handleShowNotification],
  );

  return (
    <NotificationContext.Provider
      value={useMemo(
        () => ({
          showNotification: handleShowNotification,
          showError,
          showSuccess,
        }),
        [handleShowNotification, showError, showSuccess],
      )}
    >
      {children}
      <Notification
        onAnimationEnd={() => setState(undefined)}
        onClose={() => {
          setState((current) =>
            !current
              ? undefined
              : {
                  ...current,
                  show: false,
                },
          );
        }}
        open={state?.show}
        severity={state?.severity}
      >
        {state?.message}
      </Notification>
    </NotificationContext.Provider>
  );
};

export function useNotification() {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('useNotification must be used within NotificationProvider');
  }

  return context;
}
