import { Card as MuiCard, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IMAGE_CARD_MAX_WIDTH } from 'utils/constants';

export const Content = styled(CardContent)`
  &.MuiCardContent-root {
    padding: 30px 50px 50px;

    ${(props) => props.theme.breakpoints.down('md')} {
      padding: 16px 24px 32px;
    }
  }
`;

export const Card = styled(MuiCard)`
  max-width: ${IMAGE_CARD_MAX_WIDTH};
`;
