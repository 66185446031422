import EmptyState, { EmptyStateProps } from 'components/EmptyState';
import PageContainer from 'components/PageContainer';

import {
  NO_ACCOUNT_DATA_HEADER,
  NO_ACCOUNT_DATA_SUB_HEADER,
} from './constants';

type NoDataPageProps = Pick<
  EmptyStateProps,
  'header' | 'subHeader' | 'trackingProps'
>;

const NoDataPage: React.FC<NoDataPageProps> = ({
  header = NO_ACCOUNT_DATA_HEADER,
  subHeader = NO_ACCOUNT_DATA_SUB_HEADER,
  trackingProps,
}) => (
  <PageContainer>
    <EmptyState
      header={header}
      subHeader={subHeader}
      trackingProps={trackingProps}
    />
  </PageContainer>
);
export default NoDataPage;
