import { Button, Radio as RadioBase } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { Heading } from '@sparemin/blockhead';
import styled from 'styled-components';
import { RootStyleProps } from './types';

export const Root = muiStyled(Button)<RootStyleProps>`
  width: 176px;
  height: 108px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 2px solid;
  border-radius: 24px;
  border-color: ${({ $isChecked, theme }) =>
    $isChecked ? theme.palette.primary.main : theme.palette.grey[500]};
  color: black;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const Radio = muiStyled(RadioBase)`
  padding-left: 16px;
`;

export const Description = styled(Heading).attrs({
  level: 3,
})`
  width: 100%;
  margin-top: 6px;
  text-align: center;
  text-transform: none;

  ${(props) => props.theme.breakpoints.down('md')} {
    text-align: start;
    padding-left: 16px;
  }
`;
