import { getMyUserRoles, UserRoles } from 'api/services';
import { useQuery, UseQueryOptions } from 'react-query';
import { userDataQueries } from './queryKeys';

type QueryKey = typeof userDataQueries.all;

export default function useUserRoles<TData = UserRoles>(
  opts: Omit<
    UseQueryOptions<UserRoles, unknown, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery(userDataQueries.all, () => getMyUserRoles(), opts);
}
