import {
  LoginCard as Login,
  UserAuthenticationModal,
  AuthStep,
} from '@sparemin/auth';
import useAuthSuccess from 'hooks/useAuthSuccess';
import useToggle from 'hooks/useToggle';

type LoginCardProps = {};

const FORGOT_PASSWORD: AuthStep[] = ['forgot-password'];

const LoginCard: React.FC<LoginCardProps> = () => {
  const { onAuthSuccess } = useAuthSuccess('login');
  const {
    value: isOpen,
    toggleOn: openModal,
    toggleOff: closeModal,
  } = useToggle(false);

  return (
    <>
      <Login
        onAuthenticateSuccess={onAuthSuccess}
        onForgotPasswordPress={openModal}
      />
      <UserAuthenticationModal
        features={FORGOT_PASSWORD}
        isOpen={isOpen}
        initialStep="forgot-password"
        onClose={closeModal}
      />
    </>
  );
};
export default LoginCard;
