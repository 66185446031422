import TablePageContainer from 'components/TablePageContainer';
import useTrackPageLoad from 'hooks/useTrackPageLoad';
import { useCallback, useState } from 'react';
import ContentManagementPageCard from './ContentManagementPageCard';
import ContentManagementPageImageCard from './ContentManagementPageImageCard';
import ContentManagementPageTitle from './ContentManagementPageTitle';

type ContentManagementPageProps = {};

const ContentManagementPage: React.FC<ContentManagementPageProps> = () => {
  useTrackPageLoad({
    section: 'DiscoWidget',
    page: 'ContentManagement',
    state: 'Populated',
  });

  const [podcastId, setPodcastId] = useState<number>();
  const [episodeId, setEpisodeId] = useState<number>();

  const handleBackClick = useCallback(() => {
    if (episodeId) {
      setEpisodeId(undefined);
    } else if (podcastId) {
      setPodcastId(undefined);
    }
  }, [episodeId, podcastId]);

  return (
    <TablePageContainer>
      <ContentManagementPageTitle
        {...{ episodeId, podcastId }}
        onBackClick={handleBackClick}
      />
      {episodeId && podcastId && (
        <ContentManagementPageImageCard
          episodeId={episodeId}
          podcastId={podcastId}
        />
      )}
      <ContentManagementPageCard
        podcastId={podcastId}
        episodeId={episodeId}
        onSelectEpisode={setEpisodeId}
        onSelectPodcast={setPodcastId}
      />
    </TablePageContainer>
  );
};
export default ContentManagementPage;
