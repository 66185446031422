import { flow } from 'lodash';
import mixpanel, { Dict } from 'mixpanel-browser';
import {
  getCurrentSessionURL,
  fullstoryIdentify,
  fullstoryAnonymize,
} from 'utils/fullstory';
import { pascalKeys } from 'utils/object';
import { ClearUserTrackingInfo, SetUserTrackingInfo } from '../types';
import { AppNameProps, FSMixpanelProps } from './types';

function addFullstoryURLToProperties<T extends Dict | undefined>(
  properties: T,
): T & FSMixpanelProps {
  const fullstoryUrl = getCurrentSessionURL(true);

  const fsProperties = {
    'FS Link': fullstoryUrl ?? 'FullStory not ready. Cannot get link',
  };

  return {
    ...properties,
    ...fsProperties,
  };
}

// Partners sends data to the same Mixpanel Project as Make. In order to identify the source of events
// the AppName property should be sent with every event, rather than relying on the property being set as a super property,
// which does not reliably get overwritten when users' switch application.
function addAppNameToProperties<T extends Dict | undefined>(
  properties: T,
): T & AppNameProps {
  const appName = { appName: 'discoDashboard' };
  return { ...properties, ...appName };
}

export const track: typeof mixpanel.track = (
  eventName,
  properties,
  optionsOrCallback,
  callback,
) => {
  const allProperties = flow(
    addFullstoryURLToProperties,
    addAppNameToProperties,
  )(properties);

  const transformedProperties = !allProperties
    ? undefined
    : pascalKeys(allProperties, ['FS Link']);

  mixpanel.track(eventName, transformedProperties, optionsOrCallback, callback);
};

export const setUserTrackingInfo: SetUserTrackingInfo = (
  email: string,
  userId: number,
) => {
  mixpanel.identify(email);
  mixpanel.people.set({
    $email: email,
    last_login: new Date(),
    user_id: userId,
  });
  mixpanel.people.set_once('firstLogin', new Date().toISOString());
  fullstoryIdentify({ userId, email });
};

export const clearUserTrackingInfo: ClearUserTrackingInfo = () => {
  mixpanel.reset();
  fullstoryAnonymize();
};
